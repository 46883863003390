import { Flex } from 'src/components/primitives'
import { LogoSymbol } from 'src/components/primitives/logo'
import { Paragraph } from 'src/components/primitives/typography'
import * as S from './source-end.styled'

export const SourceEnd = (): JSX.Element => {
  return (
    <S.Container>
      <Flex $direction="column" $gap={16} $align="center" $maxWidth="520px">
        <LogoSymbol width={40} height={40} />
        <Paragraph $align="center">
          Add candidates to outreach or reject them to see additional recommendations. Pin learns
          from your choices and improves over time.
        </Paragraph>
      </Flex>
      {/* <Button
        $variant="raised"
        $colorTheme="tint"
        onClick={() => {
          // TODO Implement this when the feature is ready
        }}
      >
        Source more candidates
      </Button> */}
    </S.Container>
  )
}
