import { useGetCompaniesPreferences } from 'src/hooks/queries/use-get-companies-preferences'
import * as S from './companies-ratings.styled'
import { useCallback, useMemo } from 'react'
import { CompanyPreferenceType } from 'src/libs/api/backend/company_preferences'
import type { CompanyPreference } from 'src/libs/api/backend/company_preferences'
import { Button, Flex } from 'src/components/primitives'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Icon, Icons } from 'src/components/primitives/icon'
import { Avatar } from 'src/components/primitives/avatar'
import { useDeleteCompanyPreference } from 'src/hooks/mutations/use-delete-company-preference'
import { useDialog } from 'src/hooks/use-dialog'
import { CompaniesSearch } from './companies-search'
import { useAddCompanyPreference } from 'src/hooks/mutations/use-add-company-preference'
import { useParams } from 'react-router'
import { When } from '../when'

interface CompanyRatingTileProps {
  companyPreference: CompanyPreference
  onClick: () => void
}

const CompanyRatingTile = ({ companyPreference, onClick }: CompanyRatingTileProps): JSX.Element => {
  return (
    <S.CompanyRatingTile>
      <S.CompanyRatingTitle>
        <Avatar
          $size={24}
          $type="logo"
          $innerPadding={0}
          company={{
            name: companyPreference.name ?? '',
            logoUrl: companyPreference.logoUrl
          }}
        />
        <Paragraph size="SM" $color='fgPrimary'>
          {companyPreference.name ?? 'No data available'}
        </Paragraph>
        <S.RatingLinkedInButton>
          <Button
            $variant="ghost"
            $colorTheme="muted"
            $width={20}
            $height={20}
            $fontSize={12}
            leadingIcon="linkedin"
            href={`https://www.linkedin.com/${companyPreference.linkedin}`}
          />
        </S.RatingLinkedInButton>
      </S.CompanyRatingTitle>
      <S.RatingDeleteButton>
        <Button
          $width={12}
          $height={12}
          leadingIcon={Icons.x}
          $variant="ghost"
          $colorTheme="muted"
          onClick={onClick}
        />
      </S.RatingDeleteButton>
    </S.CompanyRatingTile>
  )
}

export const CompaniesRatings = (): JSX.Element => {
  const { jobId } = useParams()
  const { data: companiesPreferences = [] } = useGetCompaniesPreferences()
  const { setDeleteCompanyPreference } = useDeleteCompanyPreference()
  const { addCompany } = useAddCompanyPreference()
  const { openAlert } = useDialog()

  const [liked, disliked] = useMemo(
    () =>
      companiesPreferences.reduce(
        (acc: [CompanyPreference[], CompanyPreference[]], companyPreference: CompanyPreference) => {
          if (companyPreference.type === CompanyPreferenceType.LIKED) {
            acc[0].push(companyPreference)
          } else {
            acc[1].push(companyPreference)
          }
          return acc
        },
        [[], []]
      ),
    [companiesPreferences]
  )

  const onDeleteClick = useCallback(
    (id: string) => {
      openAlert({
        message: 'Are you sure you want to delete this company rating?',
        description: 'This will affect our recommendations for this job.',
        confirmText: 'Delete',
        cancelText: 'Cancel',
        onConfirm: () => {
          setDeleteCompanyPreference({ id })
        }
      })
    },
    [openAlert, setDeleteCompanyPreference]
  )

  return (
    <S.RatingsGrid>
      <S.RatingColumn>
        <Paragraph size="SM" $color="fgPrimary">
          <Flex $gap={8}>
            <Icon name={Icons.thumbsUp} />
            <span>More from these companies</span>
          </Flex>
        </Paragraph>
        <CompaniesSearch
          onAddCompany={(company) => {
            if (!jobId) {
              throw new Error('Now jobId provided')
            }
            addCompany({
              type: CompanyPreferenceType.LIKED,
              name: company.name,
              linkedin: company.linkedin,
              jobId
            })
          }}
        />
        <When condition={!liked.length}>
          <S.EmptyState>
            <Caption size="SM" $color="fgTertiary">No companies to display</Caption>
          </S.EmptyState>
        </When>
        <When condition={liked?.length >= 1}>
          <Flex $direction='column' $gap={8}>
            {liked.map((companyPreference) => (
              <CompanyRatingTile
                key={companyPreference.id}
                companyPreference={companyPreference}
                onClick={() => {
                  onDeleteClick(companyPreference.id)
                }}
              />
            ))}
          </Flex>
        </When>
      </S.RatingColumn>
      <S.RatingColumn>
        <Paragraph size="SM" $color="fgPrimary">
          <Flex $gap={8}>
            <Icon name={Icons.thumbsDown} />
            <span>Fewer from these companies</span>
          </Flex>
        </Paragraph>
        <CompaniesSearch
          onAddCompany={(company) => {
            if (!jobId) {
              throw new Error('Now jobId provided')
            }
            addCompany({
              type: CompanyPreferenceType.DISLIKED,
              name: company.name,
              linkedin: company.linkedin,
              jobId
            })
          }}
        />
        <When condition={!disliked.length}>
          <S.EmptyState>
            <Caption size="SM" $color="fgTertiary">No companies to display</Caption>
          </S.EmptyState>
        </When>
        <When condition={disliked?.length >= 1}>
          <Flex $direction='column' $gap={8}>
            {disliked.map((companyPreference) => (
              <CompanyRatingTile
                key={companyPreference.id}
                companyPreference={companyPreference}
                onClick={() => {
                  onDeleteClick(companyPreference.id)
                }}
              />
            ))}
          </Flex>
        </When>
      </S.RatingColumn>
    </S.RatingsGrid>
  )
}
