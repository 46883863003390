import { useMutation } from '@tanstack/react-query'
import { useNotification } from '../use-notification'
import queryClient from '../query-client'
import { queryKeys } from 'src/libs/query-keys'
import { linkJobWithMergeJob } from 'src/libs/api/backend/merge'

interface Args {
  jobId: string
  mergeAtsJobId: string | null
  onSuccess?: () => void
  onError?: () => void
}

interface Res {
  linkJobWithMergeAtsJob: ({ jobId, mergeAtsJobId, onSuccess, onError }: Args) => void
}

export const useLinkJobWithMergeJob = (): Res => {
  const { notify } = useNotification()

  const mutation = useMutation({
    mutationFn: async ({ jobId, mergeAtsJobId }: Args) => {
      await linkJobWithMergeJob({ jobId, mergeAtsJobId })
    },
    onError: (err, variables) => {
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: `An error occurred when linking job in ATS: ${err.message}`
      })
      variables.onError?.()
    },
    onSuccess: async (_, variables) => {
      variables.onSuccess?.()

      notify({
        type: 'toast',
        variant: 'positive',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: 'Successfully synced with ATS'
      })

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [
            queryKeys.mergeLinkJobs
            // queryKeys.jobs
          ]
        })
      ])
    }
  })

  const linkJobWithMergeAtsJob = (args: Args): void => {
    mutation.mutate(args)
  }

  return { linkJobWithMergeAtsJob }
}
