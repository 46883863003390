import { useState } from 'react'
import { useCandidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'
import { EmptyState } from 'src/components/blocks/empty-state'
import RouteBuilder from 'src/libs/route-builder'
import { CandidateJobStage } from 'src/libs/api/backend/candidate_jobs'
import { useParams, useSearchParams } from 'react-router-dom'
import { isNil, isString, parseInt } from 'lodash'
import { findCandidatesWithErrors } from 'src/libs/find-candidates-with-errors'
import { CandidatesTable } from '../candidates-table'
import {
  ExportToAtsButton,
  MoveToAnotherJobButton,
  PauseResumeSequenceButton,
  RejectButton,
  WriteEmailButton
} from '../candidate-table-actions'
import { COLUMN } from '../candidates-table/table-builder'
import { useSession } from 'src/hooks/use-session'
import { FeatureFlags } from 'src/libs/api/backend/session'
import { useJobQuery } from 'src/hooks/queries/use-job'

interface CandidatesInSequenceTableProps {
  pageHeaderHeight: number
}

export const CandidatesInSequenceTable = ({
  pageHeaderHeight = 0
}: CandidatesInSequenceTableProps): JSX.Element => {
  const { jobId } = useParams()
  const { data: job } = useJobQuery()
  const [searchParams] = useSearchParams()
  const [rowSelection, setRowSelection] = useState({})
  const { featureFlags, org } = useSession()
  const positionSearchParam = searchParams.get('position')
  const position: number | undefined = isString(positionSearchParam)
    ? parseInt(positionSearchParam)
    : undefined
  const filterSearchParams = searchParams.get('filter')

  const { isPending, data: candidateJobs } = useCandidateJobsQuery({
    stage: CandidateJobStage.PROSPECTING,
    sequenceStepPosition: !isNil(position) && !isNaN(position) ? position : undefined
  })

  let emptyState = (
    <EmptyState
      heading="No one in sequence"
      description="Looks like you don’t have any prospects yet, start sourcing candidates and all of them will show up right here"
      svg="profileCard"
      actions={[
        {
          href: RouteBuilder.build('JOBS_CANDIDATES_SOURCING', { jobId }),
          children: 'Go to sourcing'
        }
      ]}
    />
  )

  if (!isNil(position)) {
    emptyState = (
      <EmptyState
        heading="No one at this step"
        description="Looks like you don’t have any prospects at this step yet, try viewing all candidates in outreach"
        svg="profileCard"
        actions={[
          {
            href: RouteBuilder.build('JOBS_CANDIDATES_IN_SEQUENCE', { jobId }),
            children: 'Go to all in outreach'
          }
        ]}
      />
    )
  }

  const renderedCandidates =
    filterSearchParams === 'errors' ? findCandidatesWithErrors(candidateJobs ?? []) : candidateJobs

  return (
    <>
      <CandidatesTable
        pageHeaderHeight={pageHeaderHeight}
        isLoading={isPending}
        candidateJobs={renderedCandidates}
        emptyState={emptyState}
        visibleColumns={[COLUMN.FAVORITE, COLUMN.NAME, COLUMN.STATUS, COLUMN.LAST_CONTACT, COLUMN.NEXT_CONTACT, COLUMN.SOURCE]}
        setRowSelection={setRowSelection}
        rowSelection={rowSelection}
        selectedRowsActions={
          <>
            <PauseResumeSequenceButton
              selectedRows={rowSelection}
              onResetSelectedRows={() => {
                setRowSelection({})
              }}
            />
            <RejectButton
              selectedRows={rowSelection}
              onResetSelectedRows={() => {
                setRowSelection({})
              }}
            />
            <WriteEmailButton selectedRows={rowSelection} candidateJobs={candidateJobs} />
            <MoveToAnotherJobButton selectedRows={rowSelection} candidateJobs={candidateJobs} />
            {featureFlags?.includes(FeatureFlags.ATS_INTEGRATION) && !isNil(job) && !isNil(org?.mergeAtsIntegration) &&
              <ExportToAtsButton selectedRows={rowSelection} candidateJobs={candidateJobs} job={job}/>}
          </>
        }
      />
    </>
  )
}
