import { Avatar } from '../../primitives/avatar'
import { TimelineEntryTitle } from './timeline-entry-title'
import { TimelineEntrySubtitle } from './timeline-entry-subtitle'
import type { IconName } from 'src/components/primitives/icon'
import * as S from './timeline.styled'
import type { Spacing } from 'src/styles/theme/types'
import { DivAsButton } from 'src/components/primitives/div-as-button'
import { useState } from 'react'
import type { CandidateExperience, CompanyInformation } from 'src/libs/api/backend/candidate_jobs'
import { useSearchParams } from 'react-router-dom'

export interface EntryStyleProps {
  $padding?: {
    left: Spacing
    right: Spacing
  }
}

interface EntryHeading {
  title: string // E.g. company name
  subtitle?: string // E.g. context such as company size, funding, founding year
  description: string // Written description
  website?: string | null
  linkedin?: string | null
  logoUrl?: string | null
  tags?: string[] | null
  overlappingEmployees?: CandidateExperience['overlappingClientCompanyEmployees'] | null
  companyInformation?: CompanyInformation
}

interface EntryDetails {
  title?: string | null
  timespan?: string | null
  description?: string | null
}

export interface EntryProps extends EntryStyleProps {
  heading: EntryHeading
  details: EntryDetails[]
  avatarFallbackIcon?: IconName
  isCollapsible?: boolean
}

export const Entry = ({
  heading,
  details,
  avatarFallbackIcon,
  $padding = { left: 24, right: 24 },
  isCollapsible = false
}: EntryProps): JSX.Element => {
  const [searchParams] = useSearchParams()
  const [isCollapsed, setIsCollapsed] = useState(true)

  const hasNonNullFields = (obj: EntryDetails): boolean => {
    return Object.values(obj).some(value => value !== null && value !== '')
  }
  const showSubtitleSection = details?.length > 0 && details.some(detail => hasNonNullFields(detail))

  const candidateSearchParams = searchParams.get('candidate')
  const contentShouldHaveFixedWidth = Boolean(candidateSearchParams?.length)

  return (
    <S.Entry data-name="Entry" $padding={$padding} aria-expanded={!isCollapsed}>
      <DivAsButton
        ariaLabel={isCollapsed ? 'Show additional information' : 'Hide additional information'}
        isDisabled={!isCollapsible}
        onClick={() => {
          setIsCollapsed(!isCollapsed)
        }}
      >
        <S.EntryHeader data-name="EntryHeader" $align={showSubtitleSection ? 'flex-start' : 'center'}>
          <S.Avatar>
            <Avatar
              $size={40}
              $shape="soft"
              $type="logo"
              company={{ name: heading.title, url: heading.website, logoUrl: heading.logoUrl }}
              fallbackIcon={{ name: avatarFallbackIcon ?? 'building-2' }}
            />
          </S.Avatar>
          <S.EntryContent
            data-name="EntryContent"
            $isCollapsed={isCollapsed}
            $isCollapsible={isCollapsible}
            $contentWidth={contentShouldHaveFixedWidth ? '462px' : ''}
          >
            <TimelineEntryTitle
              heading={heading}
              isCollapsed={isCollapsed}
              onSetIsCollapsed={() => {
                setIsCollapsed(!isCollapsed)
              }}
              isCollapsible={isCollapsible}
            />
            {showSubtitleSection && <TimelineEntrySubtitle
              details={details}
              isCollapsed={isCollapsed}
            />}
          </S.EntryContent>
        </S.EntryHeader>
      </DivAsButton>
    </S.Entry>
  )
}
