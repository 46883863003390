import { CANDIDATES_PAGES_MAX_WIDTH, CONTENT_PADDING } from 'src/styles/constants'
import styled from 'styled-components'

export const CandidatesTablePagesContentInner = styled.div`
  padding: 0 ${CONTENT_PADDING};
  padding-bottom: ${CONTENT_PADDING};
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: calc(${CANDIDATES_PAGES_MAX_WIDTH} + 2rem);
`

export const CandidatesTablePagesHeader = styled.div`
  // padding: 0 ${CONTENT_PADDING};
`
