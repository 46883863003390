import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Start } from './start'
import { Create } from './create'
import { OnboardingHeader } from '../onboarding-header'
import { When } from '../when'
import RouteBuilder from 'src/libs/route-builder'
import * as S from './create-job-form.styled'
import { isNil } from 'lodash'
import type { NewJobFormData } from './types/new-job-form-data'

import { BackButton } from '../back-button'
import { Spacer } from 'src/components/primitives/spacer'
import { useCreateNewJob } from 'src/hooks/mutations/use-create-new-job'
import { useSession } from 'src/hooks/use-session'
import type { ExtractJobRequirementsInput } from 'src/libs/api/backend/gpt'

export type Step = 'start' | 'import' | 'create' | 'generate'

interface CreateJobFormProps {
  hasHeadings?: boolean
  redirectType?: 'onboarding' | 'jobs'
  onCreateJob?: () => void
  onMergeLinkVisibility?: (isVisible: boolean) => void
}

export const CreateJobForm = ({
  hasHeadings = true,
  redirectType = 'onboarding',
  onCreateJob,
  onMergeLinkVisibility
}: CreateJobFormProps): JSX.Element => {
  const navigate = useNavigate()
  const [step, setStep] = useState<Step>('start')
  const [selectedImportedJob, setSelectedImportedJob] = useState<ExtractJobRequirementsInput | null>(null)
  const { org } = useSession()

  const { createNewJob } = useCreateNewJob()

  const handleCreateJob = async (jobData: NewJobFormData): Promise<void> => {
    createNewJob({
      job: {
        ...jobData,
        title: jobData.title ?? ''
      },
      onSuccess: (createdJob) => {
        let redirectRoute = RouteBuilder.build('ONBOARDING_CONNECTED_ACCOUNTS')
        if (redirectType === 'jobs') {
          redirectRoute = RouteBuilder.build('JOBS_CANDIDATES', { jobId: createdJob.id })
        }

        navigate(redirectRoute)

        if (!isNil(onCreateJob)) {
          onCreateJob()
        }
      }
    })
  }

  return (
    <>
      <S.CreateJobForm $step={step}>
        <When condition={step === 'start'}>
          <When condition={hasHeadings}>
            <OnboardingHeader
              heading="What position are you hiring for?"
              subheading="Choose an existing job or create a new one."
            />
          </When>
          <Start
            onNewJobClick={() => {
              setSelectedImportedJob(null)
              setStep('create')
            }}
            onCreateNewJobFromImportClick={(jobListing) => {
              if (!isNil(jobListing)) {
                setSelectedImportedJob(jobListing)
              }
              setStep('create')
            }}
            onMergeLinkVisibility={onMergeLinkVisibility}
          />
        </When>
        <When condition={step === 'create'}>
          <BackButton
            onClick={() => {
              setStep('start')
            }}
            $offset="-14px"
          />
          <Spacer $size={32} />
          <When condition={hasHeadings}>
            <OnboardingHeader
              heading="New job position"
              subheading="Tell us about who you’re looking to hire."
            />
          </When>
          <Create
            importedJob={selectedImportedJob}
            onSubmitClick={(data) => {
              void handleCreateJob({
                title: data.title,
                // country: data.country,
                locations: data.locations,
                workspace: data.workspace,
                salaryMin: data.salaryMin,
                salaryMax: data?.salaryMax,
                description: data.description,
                departmentId: (!isNil(org) && data.departmentId === org?.id) ? null : data.departmentId,
                diverseHire: data?.diverseHire,
                jobBoardDescription: data.jobBoardDescription
              })
            }}
          />
        </When>
      </S.CreateJobForm>
    </>
  )
}
