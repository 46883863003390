import { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import { BubbleMenu, EditorContent, useEditor } from '@tiptap/react'
import type { Editor as TiptapEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import Paragraph from '@tiptap/extension-paragraph'
import Placeholder from '@tiptap/extension-placeholder'
import { mergeAttributes } from '@tiptap/core'
import { Icon } from 'src/components/primitives/icon'
import { When } from '../when'
import type { EmailVariableType } from './types'
import { MenuButton } from './menu-button'
import * as S from './editor.styled'
import { deserializeEditorContent, serializeEditorContent } from './serializers'
import { EmailVariableButton } from '../email-composer/actions'
import VariableHighlighter from './extensions/variableHighlighter'
import ImageExtension from './extensions/imageExtension'
import { useTheme } from 'styled-components'

export interface VariableToSet {
  variable: EmailVariableType
  id: string
}

export interface EditorProps {
  $editorHeight?: string
  $minHeight?: string
  $maxHeight?: string
  isEditable?: boolean
  initialContent?: string
  content: string | null | undefined
  placeholder?: string
  contentType?: 'html' | 'text'
  onDataChanged: (data: string) => void
  onEditorHeightChange?: (height: number) => void
  forceEditorFocus?: boolean
  useVariables?: boolean
  useFileHandler?: boolean
  toolbarActions?: React.ReactNode
  customToolbar?: React.ReactNode
  customActions?: React.ReactNode | null
  editorFooterContent?: React.ReactNode | null
  setEditor?: (editor: TiptapEditor | null) => void
  onBlur?: () => void
}

export const Editor = ({
  $editorHeight,
  $minHeight,
  $maxHeight,
  isEditable = true,
  initialContent,
  customActions,
  content,
  placeholder = 'Write something...',
  onDataChanged,
  onEditorHeightChange,
  forceEditorFocus = false,
  contentType = 'html',
  useVariables = false,
  useFileHandler = false,
  toolbarActions,
  customToolbar,
  editorFooterContent,
  setEditor,
  onBlur
}: EditorProps): JSX.Element => {
  const { colors } = useTheme()

  const extensions = useMemo(() => {
    const exts = [
      StarterKit.configure({
        paragraph: false,
        dropcursor: {
          width: 3,
          color: colors.tintBg
        }
      }),
      // This converts using <div> instead of <p> for paragraphs
      Paragraph.extend({
        parseHTML () {
          return [{ tag: 'p' }, { tag: 'div' }]
        },
        renderHTML ({ HTMLAttributes }) {
          return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0] // eslint-disable-line
        }
      }),
      Link.configure({
        openOnClick: false,
        linkOnPaste: true,
        HTMLAttributes: {
          class: 'editor-link'
        }
      }),
      Placeholder.configure({
        placeholder
      })
    ]
    exts.push(VariableHighlighter)
    if (useFileHandler) {
      exts.push(ImageExtension) // IMPORTANT: contentType must be 'html' for this to work properly
    }
    return exts
  }, [colors.tintBg, placeholder, useFileHandler])

  const editor = useEditor({
    onUpdate: ({ editor }) => {
      if (contentType === 'html') {
        const updatedContent = serializeEditorContent(editor.getHTML())
        // updatedContent = updatedContent.replace(/<\/div><div><\/div><div>/g, '</div><div><br></div><div>')
        onDataChanged(updatedContent)
      } else if (contentType === 'text') {
        // const output = editor.getText({ blockSeparator: '\n\n' })
        const output = editor.getText()
        onDataChanged(output)
      }
    },
    onBlur: () => {
      onBlur?.()
    },
    extensions,
    content
  }, [initialContent])

  const editorRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (onEditorHeightChange && editorRef.current) {
      onEditorHeightChange(editorRef.current.offsetHeight)
    }
  })

  useEffect(() => {
    if (!editor) {
      return
    }
    editor.setEditable(isEditable)
  }, [isEditable, editor])

  useEffect(() => {
    if (!editor) {
      return
    }

    if (initialContent && initialContent?.length >= 1) {
      editor.commands.setContent(deserializeEditorContent(initialContent))
    }
  }, [editor, initialContent])

  const handleSetLink = useCallback(() => {
    if (!editor) {
      return
    }
    const previousUrl: string = editor.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    if (url === null) {
      return
    }

    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run()
    } else {
      editor.chain().focus().extendMarkRange('link').setLink({ href: url, target: '_blank' }).run()
    }
  }, [editor])

  const handleSetFocus = useCallback(() => {
    if (!editor) {
      return
    }
    editor.commands.focus()
  }, [editor])

  useEffect(() => {
    if (!editor) {
      return
    }
    if (forceEditorFocus) {
      editor.chain().focus('end').run()
    }
  }, [forceEditorFocus, editor])

  useEffect(() => {
    setEditor?.(editor)
    return () => {
      setEditor?.(null)
      editor?.destroy()
    }
  }, [editor, setEditor])

  const toolbar = useMemo(() => {
    if (customToolbar) {
      return customToolbar
    }
    if (useVariables || toolbarActions) {
      return (
        <S.Toolbar>
          <S.ToolbarActions>
            {toolbarActions}
            {useVariables && (<EmailVariableButton editor={editor} />)}
          </S.ToolbarActions>
        </S.Toolbar>
      )
    }
  }, [customToolbar, editor, toolbarActions, useVariables])

  return (
    <S.Editor ref={editorRef} onClick={handleSetFocus}>
      {editor && (
        <>
          <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
            <S.BubbleMenu>
              <MenuButton
                icon="bold"
                isActive={editor.isActive('bold')}
                onClick={() => editor.chain().focus().toggleBold().run()}
              />
              <MenuButton
                icon="italic"
                isActive={editor.isActive('italic')}
                onClick={() => editor.chain().focus().toggleItalic().run()}
              />
              <MenuButton
                icon="list"
                isActive={editor.isActive('bulletList')}
                onClick={() => editor.chain().focus().toggleBulletList().run()}
              />
              <MenuButton
                icon="listOrdered"
                isActive={editor.isActive('orderedList')}
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
              />
              <When condition={editor.isActive('link')}>
                <S.EditorButton
                  $isActive={editor.isActive('link')}
                  onClick={() => editor.chain().focus().unsetLink().run()}
                >
                  <Icon name="unlink" color="bgPrimary" size={12} />
                </S.EditorButton>
              </When>
              <When condition={!editor.isActive('link')}>
                <S.EditorButton $isActive={editor.isActive('link')} onClick={handleSetLink}>
                  <Icon name="link" color="bgPrimary" size={12} />
                </S.EditorButton>
              </When>
            </S.BubbleMenu>
          </BubbleMenu>
        </>
      )}

      <S.EditorContent $editorHeight={$editorHeight} $minHeight={$minHeight} $maxHeight={$maxHeight}>
        <EditorContent editor={editor} />
      </S.EditorContent>
      {editorFooterContent && (
        <S.EditorFooterContent>
          {editorFooterContent}
        </S.EditorFooterContent>
      )}
      {customActions}
      {toolbar}
    </S.Editor>
  )
}
