import { useMutation } from '@tanstack/react-query'
import { sendCustomOutreach as sendCustomOutreachApi } from 'src/libs/api/backend/custom_outreach'
import type { SendCustomOutreachParams } from 'src/libs/api/backend/custom_outreach'
import { useNotification } from '../use-notification'
import { useDialog } from '../use-dialog'
import { DialogId } from 'src/contexts/dialogs'
import queryClient from '../query-client'
import { queryKeys } from 'src/libs/query-keys'

interface Args {
  sendCustomOutreachParams: SendCustomOutreachParams
  onSuccess?: () => void
}

interface Res {
  sendCustomOutreach: ({ sendCustomOutreachParams, onSuccess }: Args) => void
}

export const useSendCustomOutreach = (): Res => {
  const { notify } = useNotification()
  const { closeDialog } = useDialog()

  const mutation = useMutation({
    mutationFn: async ({ sendCustomOutreachParams }: Args) => {
      await sendCustomOutreachApi(sendCustomOutreachParams)
    },
    onError: (err) => {
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: `An error occurred when setting 1st personalized outreach email: ${err.message}`
      })
    },
    onSuccess: async (_data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateJobs]
      })

      if (variables.onSuccess) {
        variables.onSuccess()
      } else {
        closeDialog(DialogId.WRITE_CUSTOM_OUTREACH)
        notify({
          type: 'toast',
          variant: 'positive',
          position: 'bottom-right',
          icon: 'mails',
          message: 'Successfully added candidate and set 1st personalized outreach email',
          autoClose: true
        })
      }
    }
  })

  const sendCustomOutreach = ({ sendCustomOutreachParams, onSuccess }: Args): void => {
    mutation.mutate({ sendCustomOutreachParams, onSuccess })
  }

  return { sendCustomOutreach }
}
