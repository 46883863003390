import * as OL from 'src/components/forms/options-list/options-list.styled'
import { Caption } from '../primitives/typography'
import { Button, Flex } from '../primitives'
import { SenderSelection } from '../blocks/sender-selection'
import type { EmailAccountWithProfilePhoto } from '../blocks/sender-selection'
import type { OrgUser } from 'src/libs/api/backend/users'
import { useEffect, useMemo, useState } from 'react'
import { isNil } from 'lodash'

interface TransferArchiveUserConfirmationProps {
  onClick: (selectedEmailAccountId: string) => void
  orgUsers: OrgUser[]
  orgUser: OrgUser
}

export const TransferArchiveUserConfirmation = ({ onClick, orgUsers, orgUser }: TransferArchiveUserConfirmationProps): JSX.Element => {
  const userEmailAccounts = useMemo(() => {
    return orgUsers.filter((curr) => curr.id !== orgUser.id).flatMap((user) => {
      return user.emailAccounts.map((emailAccount) => ({
        ...emailAccount,
        profilePhotoUrl: user.profilePhotoUrl
      }))
    }) ?? []
  }, [orgUser.id, orgUsers])
  const [selectedEmailAccount, setSelectedEmailAccount] = useState<EmailAccountWithProfilePhoto | undefined>(undefined)

  useEffect(() => {
    if (isNil(selectedEmailAccount) && userEmailAccounts.length > 0) {
      setSelectedEmailAccount(userEmailAccounts.find((emailAccount) => emailAccount.currentUserHasSendAsPermission))
    }
  }, [orgUser.emailAccounts, selectedEmailAccount, userEmailAccounts])

  return (
    <OL.OptionConfirmation>
      <OL.OptionConfirmationTitle>
        <Caption size='XS' $ellipsis>This user's email is used in email sequences</Caption>
        <Caption size='XS' $color='fgSecondary' $ellipsis>Transfer them to another user before disabling.</Caption>
      </OL.OptionConfirmationTitle>
      <Flex $align='center' $gap={4} $width='auto'>
        <SenderSelection
          handleSenderChange={setSelectedEmailAccount}
          selectedEmailAccount={selectedEmailAccount}
          userEmailAccounts={userEmailAccounts}
        />
        <Button
          $variant='raised-light'
          $colorTheme='negative'
          $height={24}
          $fontSize={12}
          onClick={() => {
            if (selectedEmailAccount) {
              onClick(selectedEmailAccount.id)
            }
          }}
        >
          Transfer and disable
        </Button>
      </Flex>
    </OL.OptionConfirmation>
  )
}
