import styled from 'styled-components'
import type { BannerStyleProps } from './banner'

export const Banner = styled.div<BannerStyleProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: solid 1px;
  width: 100%;
  border-color: ${({ $variant, theme }) => {
    switch ($variant) {
      case 'neutral': {
        return theme.colors.bgSecondary
      }
      case 'muted': {
        return theme.colors.borderTranslucent
      }
      case 'positive': {
        return theme.colors.positiveTranslucent5
      }
      case 'tint': {
        return theme.colors.tintTranslucent5
      }
      case 'negative': {
        return theme.colors.negativeTranslucent10
      }
      case 'warning': {
        return theme.colors.warningTranslucent25
      }
    }
  }};
  border-radius: 6px;
  background-color: ${({ $variant, theme }) => {
    switch ($variant) {
      case 'neutral': {
        return theme.colors.bgTertiary
      }
      case 'muted': {
        return theme.colors.bgSecondary
      }
      case 'positive': {
        return theme.colors.positiveFaded
      }
      case 'tint': {
        return theme.colors.tintTranslucent5
      }
      case 'negative': {
        return theme.colors.negativeTranslucent10
      }
      case 'warning': {
        return theme.colors.warningTranslucent10
      }
    }
  }};
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  p {
    font-size: ${({ theme }) => theme.fontSizes[12]};
    font-weight: ${({ theme }) => theme.fontWeights[400]};
    color: ${({ $variant, theme }) => {
      switch ($variant) {
        case 'neutral': {
          return theme.colors.fgPrimary
        }
        case 'muted': {
          return theme.colors.fgSecondary
        }
        case 'positive': {
          return theme.colors.positiveBg
        }
        case 'tint': {
          return theme.colors.fgPrimary
        }
        case 'negative': {
          return theme.colors.fgPrimary
        }
        case 'warning': {
          return theme.colors.warningBg
        }
      }
    }};
  }
`
