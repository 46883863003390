import { useDialog } from 'src/hooks/use-dialog'
import { DialogId } from 'src/contexts/dialogs'
import { Button, Flex } from 'src/components/primitives'
import { Paragraph } from 'src/components/primitives/typography'
import { generateUrlWithParams } from 'src/utils/format-url'
import { Box } from 'src/components/primitives/box'

interface UpgradeYourPlanFromTrialDialogProps {
  onClick: () => void
}

export const UpgradeYourPlanFromTrial = ({ onClick }: UpgradeYourPlanFromTrialDialogProps): JSX.Element => {
  const { openDialog } = useDialog()

  return (
    <Box
      $display='flex'
      $flexDirection='column'
      $width='100%'
      $padding={{ top: 24, right: 24, bottom: 24, left: 24 }}
      $gap={24}
    >
      <Flex $direction='column'>
        <Paragraph size='SM'>You’ve reached the limit of 2 open job positions during trial.</Paragraph>
        <Paragraph size='SM'>Upgrade to Professional plan today to unlock more job positions.</Paragraph>
      </Flex>
      <Button
        $variant='fill'
        $colorTheme='tint'
        $fontSize={14}
        href={generateUrlWithParams('', {
          settings: 'subscription'
        })}
        onClick={() => {
          onClick()
          openDialog(DialogId.DEFAULT_SETTINGS)
        }}
      >
        Upgrade to Professional plan
      </Button>
    </Box>
  )
}
