import { Flex } from 'src/components/primitives'
import * as S from './notification-preferences.styled'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { NotificationType } from 'src/libs/api/backend/notifications'
import { Toggle } from 'src/components/primitives/toggle'
import { useUserNotificationPreferencesQuery } from 'src/hooks/queries/use-user-notifications-preferences'
import { useToggleUserNotificationPreference } from 'src/hooks/mutations/use-toggle-user-notification-preference'
import { useCallback } from 'react'

interface NotificationPreferenceTileProps {
  title: string
  description: string
  type: NotificationType
  isDisabled?: boolean
  onClick: (type: NotificationType, isChecked: boolean) => void
  isChecked: boolean
}

export const NotificationPreferenceTile = ({ title, description, type, isDisabled, onClick, isChecked }: NotificationPreferenceTileProps): JSX.Element => {
  return (
    <S.NotificationPreferenceTile>
      <Flex $direction='column' $flex='1' $gap={2}>
        <Caption size='SM'>{title}</Caption>
        <Paragraph size='XS'>{description}</Paragraph>
      </Flex>
      <Toggle
        checked={isChecked}
        name={`${type}-toggle`}
        onChange={() => {
          onClick(type, !isChecked)
        }}
        disabled={isDisabled}
      />
    </S.NotificationPreferenceTile>
  )
}

export const NotificationPreferences = (): JSX.Element => {
  const { data: userNotificationPreferences } = useUserNotificationPreferencesQuery()
  const { toggleUserNotificationPreference } = useToggleUserNotificationPreference()

  const updateUserNotificationPreference = useCallback((notificationType: NotificationType, isChecked: boolean) => {
    toggleUserNotificationPreference({
      notificationType,
      isChecked
    })
  }, [toggleUserNotificationPreference])

  return (
    <Flex $direction="column" $gap={24}>
      <S.NotificationSection>
        <Caption size='XS' $color='fgSecondary'>Team notifications</Caption>
        <NotificationPreferenceTile
          title='Your email used in outreach'
          description='When someone from your team adds your email to an outreach sequence.'
          type={NotificationType.EMAIL_ADDED_TO_SEQUENCE}
          onClick={updateUserNotificationPreference}
          isChecked={userNotificationPreferences?.[NotificationType.EMAIL_ADDED_TO_SEQUENCE] ?? false}
        />
        <NotificationPreferenceTile
          title='Join requests'
          description='When someone with a company email address asks to join the team.'
          type={NotificationType.USER_JOIN_REQUEST}
          onClick={updateUserNotificationPreference}
          isChecked={userNotificationPreferences?.[NotificationType.USER_JOIN_REQUEST] ?? false}
        />
        <NotificationPreferenceTile
          title='Join confirmations'
          description='When someone joins your team.'
          type={NotificationType.USER_JOINED}
          onClick={updateUserNotificationPreference}
          isChecked={userNotificationPreferences?.[NotificationType.USER_JOINED] ?? false}
        />
      </S.NotificationSection>
      <S.NotificationSection>
        <Caption size='XS' $color='fgSecondary'>RecruitCo updates</Caption>
        <NotificationPreferenceTile
          title='New features'
          description='When new product features and updates are available.'
          type={NotificationType.NEW_FEATURES}
          onClick={updateUserNotificationPreference}
          isChecked={userNotificationPreferences?.[NotificationType.NEW_FEATURES] ?? false}
        />
        <NotificationPreferenceTile
          title='Marketing emails'
          description='Other relevant notifications about RecruitCo.'
          type={NotificationType.MARKETING}
          onClick={updateUserNotificationPreference}
          isChecked={userNotificationPreferences?.[NotificationType.MARKETING] ?? false}
        />
      </S.NotificationSection>
    </Flex>
  )
}
