import { Button } from 'src/components/primitives/button'
import { Dropdown } from 'src/components/primitives/dropdown'
import { useRejectCandidate } from 'src/hooks/mutations/use-reject-candidate'
import { CandidateJobStatus, inboxRejectionReasons } from 'src/libs/api/backend/candidate_jobs'
import type {
  CandidateJobExpanded,
  CandidateJobRejectionReason
} from 'src/libs/api/backend/candidate_jobs'
import { map } from 'lodash'
import { useDialog } from 'src/hooks/use-dialog'
import { DialogId } from 'src/contexts/dialogs'
import { Icons } from 'src/components/primitives/icon'
import { usePauseCandidateSequence } from 'src/hooks/mutations/use-pause-candidate-job-sequence'
import { useUndoStageTransition } from 'src/hooks/mutations/use-undo-stage-transition'
import type { Job } from 'src/libs/api/backend/jobs'

interface BaseProps {
  selectedRows: Record<string, boolean>
}

interface PauseResumeSequenceButtonProps extends BaseProps {
  isPause?: boolean
  onResetSelectedRows?: () => void
}

export const PauseResumeSequenceButton = ({
  selectedRows,
  isPause = true,
  onResetSelectedRows
}: PauseResumeSequenceButtonProps): JSX.Element => {
  const { setCandidateSequencePause } = usePauseCandidateSequence()

  return (
    <Button
      $variant="outline"
      $colorTheme="tint"
      $height={24}
      $fontSize={12}
      leadingIcon={Icons.pauseCircle}
      onClick={() => {
        setCandidateSequencePause({
          candidateJobIds: Object.keys(selectedRows),
          pause: isPause,
          onSuccess: () => {
            if (onResetSelectedRows) {
              onResetSelectedRows()
            }
          }
        })
      }}
    >
      {isPause ? 'Pause' : 'Unpause'} outreach
    </Button>
  )
}

interface RejectButtonProps extends BaseProps {
  onResetSelectedRows?: () => void
}

export const RejectButton = ({
  selectedRows,
  onResetSelectedRows
}: RejectButtonProps): JSX.Element => {
  const { rejectCandidate } = useRejectCandidate()
  return (
    <Dropdown
      trigger={
        <Button
          nested
          $variant="outline"
          $colorTheme="negative"
          $height={24}
          $fontSize={12}
          leadingIcon={Icons.xOctagon}
          trailingIcon="chevron-down"
        >
          Archive candidate{Object.keys(selectedRows).length >= 2 && 's'}
        </Button>
      }
      items={map(inboxRejectionReasons, (reason, key) => ({
        id: key,
        title: reason,
        onSelect: () => {
          rejectCandidate({
            candidateJobIds: Object.keys(selectedRows),
            rejectionReason: key as CandidateJobRejectionReason
          })
          if (onResetSelectedRows) {
            onResetSelectedRows()
          }
          // setSelectedRows({})
        }
      }))}
    />
  )
}

interface WriteEmailButtonProps extends BaseProps {
  candidateJobs?: CandidateJobExpanded[]
}

export const WriteEmailButton = ({
  selectedRows,
  candidateJobs
}: WriteEmailButtonProps): JSX.Element => {
  const { openDialog } = useDialog()

  const areSelectedCandidatesInvalid = (): boolean => {
    const invalidArguments = [CandidateJobStatus.EMAIL_NOT_FOUND, CandidateJobStatus.BOUNCED]
    const selectedCandidateJobs = candidateJobs?.filter((candidateJob) =>
      Object.keys(selectedRows).includes(candidateJob.id)
    )
    return Boolean(
      selectedCandidateJobs?.every(
        (candidateJob) =>
          candidateJob.statusDisplay?.status &&
          invalidArguments.includes(candidateJob.statusDisplay.status)
      )
    )
  }

  return (
    <Button
      $variant="outline"
      $colorTheme="normal"
      $height={24}
      $fontSize={12}
      leadingIcon={Icons.mail}
      disabled={areSelectedCandidatesInvalid()}
      onClick={() => {
        const candidateJobIds = Object.keys(selectedRows)
        const payload = candidateJobs?.filter((candidate) => candidateJobIds.includes(candidate.id))
        openDialog(DialogId.WRITE_EMAIL, payload)
      }}
    >
      Write email{Object.keys(selectedRows).length >= 2 && 's'}
    </Button>
  )
}

interface UndoRejectButtonProps extends BaseProps {}

export const UndoRejectButton = ({ selectedRows }: UndoRejectButtonProps): JSX.Element => {
  const { undoStageTransition } = useUndoStageTransition({ isRestoringCandidates: true })

  return (
    <Button
      $variant="outline"
      $colorTheme="normal"
      $height={24}
      $fontSize={12}
      leadingIcon={Icons.undo}
      onClick={() => {
        undoStageTransition(Object.keys(selectedRows))
      }}
    >
      Restore candidate{Object.keys(selectedRows).length >= 2 && 's'}
    </Button>
  )
}

interface MoveToAnotherJobProps extends BaseProps {
  candidateJobs?: CandidateJobExpanded[]
}

export const MoveToAnotherJobButton = ({
  selectedRows,
  candidateJobs
}: MoveToAnotherJobProps): JSX.Element => {
  const { openDialog } = useDialog()

  return (
    <Button
      $variant="outline"
      $colorTheme="normal"
      $height={24}
      $fontSize={12}
      leadingIcon={Icons.arrowRightCircle}
      onClick={() => {
        const candidateJobIds = Object.keys(selectedRows)
        const payload = {
          candidateJobs: candidateJobs?.filter((candidate) =>
            candidateJobIds.includes(candidate.id)
          ),
          requiredReason: true
        }
        openDialog(DialogId.MOVE_TO_ANOTHER_JOB, payload)
      }}
    >
      Move to another job
    </Button>
  )
}

interface ExportToAtsButtonProps extends BaseProps {
  candidateJobs?: CandidateJobExpanded[]
  job: Job
}

export const ExportToAtsButton = ({
  selectedRows,
  candidateJobs,
  job
}: ExportToAtsButtonProps): JSX.Element => {
  const { openDialog } = useDialog()

  return (
    <Button
      $variant="outline"
      $colorTheme="normal"
      $height={24}
      $fontSize={12}
      leadingIcon={Icons.share}
      onClick={() => {
        const candidateJobIds = Object.keys(selectedRows)
        const payload = {
          job,
          candidateJobs: candidateJobs?.filter((candidate) => candidateJobIds.includes(candidate.id)),
          requiredReason: true
        }

        openDialog(DialogId.EXPORT_CANDIDATES, payload)
      }}
    >
      Export to ATS
    </Button>
  )
}
