import { setEmailAccountTeamSendAsPermission as setPermission } from 'src/libs/api/backend/me'
import { useMutation } from '@tanstack/react-query'
import type { DefaultError, UseMutationResult } from '@tanstack/react-query'
import { useSetSessionQueryCache } from '../use-session'
import { useNotifyErrorSuccess } from '../use-notification'
import Api from '../../libs/api'

interface MutateArgs {
  emailAccountId: string
  teamSendAsPermission: boolean
}
interface Res {
  setEmailAccountTeamSendAsPermission: ({ emailAccountId, teamSendAsPermission }: MutateArgs) => void
}

export const useSetEmailAccountTeamSendAsPermission = (): Res => {
  const setSessionQueryCache = useSetSessionQueryCache()
  const { notifyError } = useNotifyErrorSuccess()

  const mutation = useMutation({
    mutationFn: async ({ emailAccountId, teamSendAsPermission }: MutateArgs) => {
      return await setPermission(emailAccountId, teamSendAsPermission)
    },
    onSuccess: (data) => {
      setSessionQueryCache((old) => {
        const emailAccountAccessTokens = old.emailAccountAccessTokens.map((emailAccount) => {
          if (emailAccount.emailAccountId === data.emailAccountId) {
            return {
              ...emailAccount,
              teamSendAsPermission: data.teamSendAsPermission
            }
          }
          return emailAccount
        })
        return {
          ...old,
          emailAccountAccessTokens
        }
      })
    },
    onError: (error) => {
      console.error(error)
      notifyError('Failed to update team send permission')
    }
  })

  const setEmailAccountTeamSendAsPermission = ({ emailAccountId, teamSendAsPermission }: MutateArgs) => {
    mutation.mutate({ emailAccountId, teamSendAsPermission })
  }

  return { setEmailAccountTeamSendAsPermission }
}

export const useSetPrimaryOrgEmailAccount = (options: {
  onSuccess?: () => void
  onError?: () => void
}): UseMutationResult<void, DefaultError, { emailAccountId: string }, never> => {
  return useMutation({
    ...options,
    mutationFn: async ({ emailAccountId }: { emailAccountId: string }) => {
      await Api.post('/org/set_primary_sender_email_account', null, {
        emailAccountId
      })
    }
  })
}
