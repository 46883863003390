import { submitFeedbackApi } from 'src/libs/api/backend/feedback'
import type { SubmitFeedback } from 'src/libs/api/backend/feedback'
import { useNotification } from '../use-notification'
import { useMutation } from '@tanstack/react-query'

interface Args {
  feedback: SubmitFeedback
  onSuccess?: () => void
}

interface Res {
  submitFeedback: (args: Args) => void
}

export const useSubmitFeedback = (): Res => {
  const { notify } = useNotification()

  const mutation = useMutation({
    mutationFn: async ({ feedback }: Args) => {
      return await submitFeedbackApi(feedback)
    },
    onError: (err) => {
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: `An error occurred when submitting feedback: ${err.message}`
      })
    },
    onSuccess: async (_, variables) => {
      if (variables.onSuccess) {
        variables.onSuccess()
      } else {
        notify({
          type: 'toast',
          variant: 'positive',
          position: 'bottom-right',
          icon: 'mails',
          message: 'Successfully submitted feedback',
          autoClose: true
        })
      }
    }
  })

  const submitFeedback = (args: Args): void => {
    mutation.mutate(args)
  }

  return { submitFeedback }
}
