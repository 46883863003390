import { useAvatarQuery } from 'src/hooks/queries/use-avatar'
import BoringAvatar from 'boring-avatars'
import * as S from './profile-photo.styled'
import type { AvatarStyleProps } from 'src/components/primitives/avatar'
import { isNil } from 'lodash'
import type { Color } from 'src/styles/theme/types'
import { getInitials } from 'src/utils/get-initials'

export interface ProfilePhotoStyleProps extends Pick<AvatarStyleProps, '$shape' | '$size'> {}

interface ProfilePhotoProps extends ProfilePhotoStyleProps {
  url?: string | null
  initials?: string | null
  fallbackAvatar?: 'random'
}

export const ProfilePhoto = ({
  url,
  initials,
  $shape,
  $size,
  fallbackAvatar
}: ProfilePhotoProps): JSX.Element => {
  const { data, isLoading } = useAvatarQuery({ url })

  const getBgColor = (): Color => {
    if (!isNil(data) && !isLoading) {
      return 'bgPrimary'
    }

    if (fallbackAvatar === 'random') {
      return 'bgPrimary'
    }

    return 'fgPrimary'
  }

  const renderedInitials = ($size ?? 36) <= 16 ? initials?.charAt(0)?.toUpperCase() : getInitials(initials ?? '')

  return (
    <S.ProfilePhoto $shape={$shape} $size={$size}>
      {!isNil(data) && (
        <img
          src={data}
          alt={`Profile photo of ${initials}`}
          style={{ display: isLoading ? 'none' : 'block' }}
        />
      )}
      {/*
        <S.NoImage $backgroundColor={!isNil(data) && !isLoading ? 'bgPrimary' : 'fgPrimary'}><span>{initials?.charAt(0)?.toUpperCase()}</span></S.NoImage>
      */}
      <S.NoImage $backgroundColor={getBgColor()} $size={$size}>
        {fallbackAvatar === 'random' && (
          <BoringAvatar
            size={($size as number) * 1.25}
            square
            name={initials ?? ''}
            variant="marble"
            colors={['#92A1C6', '#146A7C', '#F0AB3D', '#C271B4', '#C20D90']}
          />
        )}
        <span>{renderedInitials}</span>
      </S.NoImage>
    </S.ProfilePhoto>
  )
}
