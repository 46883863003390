import { isNil } from 'lodash'
import { pluralize } from './pluralize'
import type { FormatDistanceFnOptions, FormatDistanceToken } from 'date-fns'

export const getTimespan = (periodStart: Date | string, periodEnd: Date | string | null): string => {
  const startDate = new Date(periodStart)
  // We're assuming that the candidate is still there
  // if no periodEnd was provided so we just use "today"
  const endDate = periodEnd !== null ? new Date(periodEnd) : new Date()

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]

  let years = endDate.getFullYear() - startDate.getFullYear()
  let months = endDate.getMonth() - startDate.getMonth()

  if (months < 0) {
    years -= 1
    months += 12
  }

  const formattedStartDate = `${monthNames[startDate.getMonth()]} ${startDate.getFullYear()}`
  const formattedEndDate = periodEnd !== null
    ? `${monthNames[endDate.getMonth()]} ${endDate.getFullYear()}`
    : 'Present'
  const calculatedYears = years > 0 ? pluralize(years, 'year') : ''
  const calculatedMonths = months > 0 ? pluralize(months, 'month') : ''
  const duration = `${calculatedYears} ${calculatedMonths}`.trim()
  const formattedDuration = formattedStartDate === formattedEndDate ? '(1 month)' : `(${duration})`

  return `${formattedStartDate} - ${formattedEndDate} ${formattedDuration}`
}

export const getYearTimespan = (periodStart: Date | string, periodEnd: Date | string | null): string => {
  const startYear = new Date(periodStart).getFullYear()

  let str = `${startYear}`

  if (periodEnd !== null) {
    const endYear = new Date(periodEnd).getFullYear()
    str += ` - ${endYear}`
  }

  return str
}

export const getDateRange = (
  start: Date | string | null,
  end: Date | string | null,
  format: 'years' | 'months' | 'days' = 'years'
): { time: number, format: string } => {
  // if cannot calculate a date range, return 1 year as the default value in the correct format
  if (isNil(start)) {
    if (format === 'years') {
      return { time: 1, format: 'years' }
    } else if (format === 'months') {
      return { time: 12, format: 'months' }
    } else {
      return { time: 365, format: 'days' }
    }
  }

  if (isNil(end)) {
    end = new Date(Date.now())
  }

  const startDate = new Date(start)
  const endDate = new Date(end)

  const diffTime = Math.abs(endDate.getTime() - startDate.getTime())
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

  if (format === 'years') {
    return {
      time: Math.floor(diffDays / 365),
      format: 'years'
    }
  } else if (format === 'months') {
    return {
      time: Math.floor(diffDays / 30),
      format: 'months'
    }
  }

  return {
    time: diffDays,
    format: 'days'
  }
}

export const formatShorthandDistanceLocale = {
  lessThanXSeconds: '{{count}}s',
  xSeconds: '{{count}}s',
  halfAMinute: '30s',
  lessThanXMinutes: '{{count}}m',
  xMinutes: '{{count}}m',
  aboutXHours: '{{count}}h',
  xHours: '{{count}}h',
  xDays: '{{count}}d',
  aboutXWeeks: '{{count}}w',
  xWeeks: '{{count}}w',
  aboutXMonths: '{{count}}m',
  xMonths: '{{count}}m',
  aboutXYears: '{{count}}y',
  xYears: '{{count}}y',
  overXYears: '{{count}}y',
  almostXYears: '{{count}}y'
}

export const formatShorthandDistance = (token: FormatDistanceToken, count: number, options?: FormatDistanceFnOptions): string => {
  const result = formatShorthandDistanceLocale[token].replace('{{count}}', count.toString())

  if (options?.addSuffix) {
    if (!isNil(options?.comparison) && options.comparison > 0) {
      return 'in ' + result
    } else {
      return result + ' ago'
    }
  }

  return result
}
