import { useParams, useSearchParams } from 'react-router-dom'
import { CandidatesPageHeader } from 'src/components/blocks/candidates-page-header'
import type { CandidatesPageHeaderProps } from 'src/components/blocks/candidates-page-header'
import { CandidatesInSequenceTable } from 'src/components/tables/candidates-in-sequence-table'
import { EmptyState } from 'src/components/blocks/empty-state'
import { useDialog } from 'src/hooks/use-dialog'
import { useJobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import { isNil, isString } from 'lodash'
import { useUpsertJobSequence } from 'src/hooks/mutations/use-upsert-job-sequence'
import { CandidateJobStage } from 'src/libs/api/backend/candidate_jobs'
import RouteBuilder from 'src/libs/route-builder'
import { Flex } from 'src/components/primitives/flex'
import { StatsCard } from 'src/components/charts/stats-card'
import { Spacer } from 'src/components/primitives/spacer'
import { useEffect, useMemo, useRef, useState } from 'react'
import { CandidatesTablePagesContentInner, CandidatesTablePagesHeader } from './candidates.styled'
import { When } from 'src/components/blocks/when'
import { useCandidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'
import { pluralize } from 'src/libs/pluralize'
import { Button } from 'src/components/primitives/button'
import type { ButtonProps } from 'src/components/primitives/button'
import { useCandidateJobCountsQuery } from 'src/hooks/queries/use-candidate-job-counts'
import { DialogId } from 'src/contexts/dialogs'
import { EmptyStateArchivedJob } from 'src/components/blocks/empty-state-archived-job'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { Banner } from 'src/components/blocks/banner'
// import { useGlobalError } from 'src/hooks/use-global-error'
// import { GlobalErrorId } from 'src/contexts/global-error'
import { isSequenceStepsEmpty } from 'src/libs/sequence'
import { SEO } from '../../../components/primitives/seo'
import ReadyToSendPage from './ready-to-send-page'
import { Icons } from 'src/components/primitives/icon'
import { useOrgUsersQuery } from 'src/hooks/queries/use-org-users'
import type { EmailAccount } from 'src/libs/api/backend/users'

const DEFAULT_TITLE = 'All In Outreach'

const makeSequencePageTitle = (title: string | null): string =>
  `Outreach · ${title ?? DEFAULT_TITLE}`

const JobCandidatesInSequencePage = (): JSX.Element => {
  const { openDialog } = useDialog()
  const { jobId } = useParams()
  const { data: job } = useJobQuery()
  const { data: emailSequence } = useJobSequenceQuery()
  const { upsertJobSequence } = useUpsertJobSequence()
  const [searchParams] = useSearchParams()
  const readyToSend = Boolean(searchParams.get('readyToSend'))
  const positionSearchParam = searchParams.get('position')
  const title = searchParams.get('title')
  const position: number | undefined = isString(positionSearchParam)
    ? parseInt(positionSearchParam)
    : undefined
  const { isPending, data: candidateJobs } = useCandidateJobsQuery({
    stage: CandidateJobStage.PROSPECTING,
    sequenceStepPosition: !isNil(position) && !isNaN(position) ? position : undefined
  })
  const { data: candidateJobCounts } = useCandidateJobCountsQuery()
  // const { globalError } = useGlobalError()

  const { data: orgUsers } = useOrgUsersQuery()

  const sequenceSenderEmailAccounts = useMemo(() => {
    if (orgUsers) {
      const emailAccounts = orgUsers.reduce<EmailAccount[]>((acc, user) => {
        if (user.emailAccounts) {
          return [...acc, ...user.emailAccounts]
        }
        return acc
      }, [])
      const sendingEmailAccountIds = emailSequence?.sequenceSteps?.map(step => step.sendingEmailAccountId)
      return emailAccounts.filter(account => sendingEmailAccountIds?.includes(account.id))
    }
    return []
  }, [orgUsers, emailSequence?.sequenceSteps])

  // TODO: We'll eventually want to support multiple email accounts in the dialog but this will have to do for now
  const deactivatedSendingEmailAccount = useMemo(() => sequenceSenderEmailAccounts.find(account => !account.hasAccessToken), [sequenceSenderEmailAccounts])

  const [pageHeaderHeight, setPageHeaderHeight] = useState<number>(158)

  const statsCardRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!isNil(statsCardRef.current?.offsetHeight)) {
      setPageHeaderHeight(statsCardRef.current?.offsetHeight ?? 158)
    }
  }, [statsCardRef.current?.offsetHeight])

  if (isNil(jobId) || isNil(emailSequence)) {
    return <></>
  }

  if (readyToSend) {
    return (
      <ReadyToSendPage jobId={jobId} />
    )
  }

  if (isSequenceStepsEmpty(emailSequence)) {
    return (
      <CandidatesTablePagesContentInner>
        <CandidatesPageHeader heading={makeSequencePageTitle(title)} />
        <EmptyState
          $padding={{ top: 0, bottom: 24, left: 0, right: 0 }}
          heading="Outreach sequence"
          description="Compose multiple outreach emails that will be send to the candidates automatically, to confirm their interest with the position."
          svg="emailSequence"
          actions={[
            {
              children: 'Create outreach sequence',
              onClick: () => {
                openDialog(DialogId.CREATE_SEQUENCE, 'OPTIONS')
              }
            }
          ]}
        />
      </CandidatesTablePagesContentInner>
    )
  }

  const pageHeaderActions: CandidatesPageHeaderProps['actions'] = [
    {
      children: `${emailSequence.active ? 'Pause' : 'Resume'} Outreach`,
      icon: emailSequence.active ? Icons.pauseCircle : Icons.playCircle,
      onClick: () => {
        const newState = !emailSequence.active
        upsertJobSequence({
          jobId,
          active: newState,
          toastMessage: newState ? 'Enabled sending outreach emails' : 'Paused sending outreach emails'
        })
      }
    },
    {
      children: 'Edit outreach',
      icon: Icons.settings2,
      href: RouteBuilder.build('SETTINGS_JOB_EMAIL_SEQUENCE', { jobId })
    },
    {
      children: 'Add candidate',
      icon: Icons.plus,
      // $variant: 'outline',
      $colorTheme: 'tint',
      onClick: () => {
        openDialog(DialogId.ADD_CANDIDATE)
      }
    }
  ]

  if (candidateJobCounts?.prospectingErrors && candidateJobCounts.prospectingErrors > 0) {
    const errorAction: ButtonProps = {
      children: `${pluralize(candidateJobCounts.prospectingErrors, 'candidate')} with errors`,
      icon: Icons.alertTriangle,
      $variant: 'outline',
      $colorTheme: 'negative',
      href: RouteBuilder.build('JOBS_CANDIDATES_IN_SEQUENCE_ERRORS', { jobId })
    }
    pageHeaderActions.unshift(errorAction)
  }

  const sequenceEmailsSentCount = candidateJobCounts?.sequenceEmailsSentByOrg?.totalSum ?? 0
  const sequenceEmailsSentChange = `${
    candidateJobCounts?.sequenceEmailsSentByOrg?.sumPastWeek ?? 0
  } this week`

  const { openedSum, totalSum } = candidateJobCounts?.openedEmails ?? { openedSum: 0, totalSum: 0 }
  const openedEmailsCount = openedSum
  const openRate = totalSum > 0 ? (openedSum / totalSum) * 100 : 0
  const openedEmailsChange = `${openRate.toFixed(0)}% open rate`

  const { respondedSum, totalSum: totalSum2 } = candidateJobCounts?.respondedEmails ?? {
    respondedSum: 0,
    totalSum: 0
  }
  const respondedEmailsCount = respondedSum
  const replyRate = totalSum2 > 0 ? (respondedSum / totalSum2) * 100 : 0
  const repliedEmailsChange = `${replyRate.toFixed(0)}% reply rate`

  return (
    <>
      <SEO title={position === -1 ? 'Not Yet Contacted' : 'All In Outreach'} />

      <CandidatesTablePagesContentInner>
        <div ref={statsCardRef}>
          <CandidatesTablePagesHeader>
            <CandidatesPageHeader
              heading={makeSequencePageTitle(title)}
              actions={pageHeaderActions}
            />
            <When condition={!isNil(deactivatedSendingEmailAccount) && emailSequence.active}>
              <>
                <Banner
                  $variant="negative"
                  icon="alert-triangle"
                  actions={
                    <Button
                      $variant="raised"
                      $colorTheme="tint"
                      $height={24}
                      $fontSize={12}
                      onClick={() => {
                        openDialog(DialogId.DISCONNECTED_EMAIL_ACCOUNT, deactivatedSendingEmailAccount)
                      }}
                    >
                      Review
                    </Button>
                  }
                >
                  <p>Outreach automatically paused by system. Unable to send emails using {deactivatedSendingEmailAccount?.email}.</p>
                </Banner>
                <Spacer $size={18} />
              </>
            </When>
            <When condition={!emailSequence.active}>
              <Banner
                icon="pause-circle"
                $variant="muted"
                actions={
                  <Button
                    $variant="fill"
                    $colorTheme="tint"
                    $height={24}
                    $fontSize={12}
                    leadingIcon="play-circle"
                    onClick={() => {
                      const newState = !emailSequence.active
                      upsertJobSequence({
                        jobId,
                        active: newState,
                        toastMessage: newState ? 'Enabled sending outreach emails' : 'Paused sending outreach emails'
                      })
                    }}
                  >
                    Enable outreach
                  </Button>
                }
              >
                Outreach is paused. Enable it to start sending emails.
              </Banner>
              <Spacer $size={18} />
            </When>
            <When condition={!isPending && (candidateJobs?.length ?? 0) > 0}>
              <Flex $align="center" $justify="space-between" $gap={16}>
                <StatsCard
                  icon="mail-arrow-right"
                  description="Outreach emails sent"
                  count={sequenceEmailsSentCount}
                  change={sequenceEmailsSentChange}
                />
                <StatsCard
                  icon="mail-open"
                  description="Opened emails"
                  count={openedEmailsCount}
                  change={openedEmailsChange}
                />
                <StatsCard
                  icon="mail-arrow-left"
                  description="Candidates replied"
                  count={respondedEmailsCount}
                  change={repliedEmailsChange}
                />
              </Flex>
              <Spacer $size={18} />
            </When>
          </CandidatesTablePagesHeader>
        </div>
        <When condition={Boolean(job?.deleted)}>
          <EmptyStateArchivedJob />
        </When>
        <When condition={!job?.deleted}>
          <CandidatesInSequenceTable pageHeaderHeight={pageHeaderHeight} />
        </When>
      </CandidatesTablePagesContentInner>
    </>
  )
}

export default JobCandidatesInSequencePage
