import { CandidatesPageHeader } from 'src/components/blocks/candidates-page-header'
import { CandidatesTablePagesContentInner } from './candidates.styled'
import { SearchRefinementForm } from 'src/components/blocks/search-refinement-form'
import { SearchRefinementRecommendations } from 'src/components/blocks/search-refinement-recommendations'
import styled from 'styled-components'
import { useCallback, useState } from 'react'
import { useCreateJobSearchRefinement } from 'src/hooks/mutations/use-create-job-search-refinement'
import { useNavigate, useParams } from 'react-router'
import RouteBuilder from 'src/libs/route-builder'
import { useJobQuery } from 'src/hooks/queries/use-job'

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  height: 100%;
  padding-bottom: 1.5rem;
`

const NewJobSearchRefinementPage = (): JSX.Element => {
  const { jobId } = useParams()
  const { data: job } = useJobQuery()
  const [isSaving, setIsSaving] = useState(false)
  const [isFocused, setIsFocused] = useState(true)
  const { createJobSearchRefinement } = useCreateJobSearchRefinement()
  const navigate = useNavigate()

  const onSubmit = useCallback((searchQuery: string) => {
    setIsSaving(true)
    createJobSearchRefinement({
      jobId: jobId ?? '',
      searchQuery,
      onSuccess: (jobSearchRefinement) => {
        navigate(RouteBuilder.build('JOBS_CANDIDATES_SOURCING', { jobId }, { jobSearchRefinementId: jobSearchRefinement.id }))
      }
    })
  }, [createJobSearchRefinement, jobId, navigate])

  return (
    <CandidatesTablePagesContentInner>
      <CandidatesPageHeader heading="New Pin Search" />
      <FormWrapper>
        <SearchRefinementForm
          onSubmit={onSubmit}
          isSearching={isSaving}
          newRefinement={true}
          setIsFocused={setIsFocused}
          isFocused={isFocused}
        />
        <SearchRefinementRecommendations
          suggestions={job?.jobSearchRefinementSuggestions ?? []}
          onSelect={(value) => {
            onSubmit(value)
          }}
        />
      </FormWrapper>
    </CandidatesTablePagesContentInner>
  )
}

export default NewJobSearchRefinementPage
