import * as Dialog from 'src/components/primitives/dialog'
import { useDialog } from 'src/hooks/use-dialog'
import { DialogId } from 'src/contexts/dialogs'
import * as S from './send-feedback-dialog.styled'
import { Caption } from 'src/components/primitives/typography'
import { Button, Flex } from 'src/components/primitives'
import { useCallback, useState } from 'react'
import { ButtonGroup } from 'src/components/primitives/button-group'
import { useTheme } from 'styled-components'
import { Editor } from 'src/components/blocks/editor'
import { FeedbackType } from 'src/libs/api/backend/feedback'
import { useSubmitFeedback } from 'src/hooks/mutations/use-submit-feedback'
import type { Editor as TiptapEditor } from '@tiptap/react'
import { MAX_INLINE_IMAGE_SIZE } from 'src/components/blocks/editor/types'
import { compressAndGenerateUrl, getCompressedQuality } from 'src/utils/compress-image'
import { UploadAttachments } from 'src/components/blocks/upload-attachments'

const FeedbackTypeButton = ({ label, onClick, selected }: { label: string, onClick: () => void, selected: boolean }): JSX.Element => {
  return (
    <Button
      ariaLabel={label}
      $variant={selected ? 'raised-light' : 'ghost'}
      $height={24}
      $fontSize={12}
      onClick={onClick}
    >
      <S.FeedbackLabel>{label}</S.FeedbackLabel>
    </Button>
  )
}

const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/webp']

export interface FeedbackDialogPayload {
  type?: FeedbackType
  defaultContent?: string
}

export const SendFeedbackDialog = ({ type, defaultContent }: FeedbackDialogPayload): JSX.Element => {
  const { colors } = useTheme()
  const { isDialogOpen, controlDialog } = useDialog()
  const [uploadingFile, setUploadingFile] = useState<string | null>(null)
  const { submitFeedback } = useSubmitFeedback()
  const [content, setContent] = useState<string>('')
  const [editor, setEditor] = useState<TiptapEditor | null>(null)
  const [feedbackType, setFeedbackType] = useState<FeedbackType>(type ?? FeedbackType.FEATURE_REQUEST)
  const handleUpload = useCallback(async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = event?.target?.files?.[0]
    if (!file) {
      return
    }
    setUploadingFile(file.name)
    const quality = getCompressedQuality(file.size, MAX_INLINE_IMAGE_SIZE)
    const src = await compressAndGenerateUrl({ file, quality })
    if (editor && src) {
      const { schema } = editor.view.state
      const node = schema.nodes.image.create({
        src: src.viewSignedUrl,
        alt: file.name
      })
      const transaction = editor.view.state.tr.insert(editor.view.state.selection.from, node)
      editor.view.dispatch(transaction)
    }
    setUploadingFile(null)
  }, [editor])

  return (
    <Dialog.Root
      id={DialogId.SEND_FEEDBACK}
      isOpen={isDialogOpen(DialogId.SEND_FEEDBACK)}
      onOpenChange={(value) => {
        controlDialog(DialogId.SEND_FEEDBACK, value)
      }}
      $width='640px'
      $maxWidth='100%'
      $innerPadding={{ top: 0, left: 0, right: 0, bottom: 0 }}
    >
      <Dialog.Portal>
        <Dialog.Header
          title="Send Feedback"
          onClose={() => {
            controlDialog(DialogId.SEND_FEEDBACK, false)
          }}
        />
        <Dialog.Content>
          <Flex $direction='column' $gap={0} $height='full'>
            <S.BannerText>
              <Caption size='XS' $fontWeight={400}>Thank you for being a part of our Beta testing. </Caption>
              <Caption size='XS' $fontWeight={400}>If you have a request, idea or bug to report, please send us feedback</Caption>
            </S.BannerText>
            <S.DialogInner>
              <S.FeedbackTitle>
                <ButtonGroup
                  $variant='ghost'
                  $direction='horizontal'
                  $padding={4}
                  $gap={4}
                  $backgroundColor={colors.bgTertiary}
                  $borderRadius={4}
                  $groupBorderRadius={8}
                >
                  <FeedbackTypeButton
                    label='Feature request'
                    onClick={() => {
                      setFeedbackType(FeedbackType.FEATURE_REQUEST)
                    }}
                    selected={feedbackType === FeedbackType.FEATURE_REQUEST}
                  />
                  <FeedbackTypeButton
                    label='Idea'
                    onClick={() => {
                      setFeedbackType(FeedbackType.IDEA)
                    }}
                    selected={feedbackType === FeedbackType.IDEA}
                  />
                  <FeedbackTypeButton
                    label='Bug report'
                    onClick={() => {
                      setFeedbackType(FeedbackType.BUG_REPORT)
                    }}
                    selected={feedbackType === FeedbackType.BUG_REPORT}
                  />
                  <FeedbackTypeButton
                    label='Other'
                    onClick={() => {
                      setFeedbackType(FeedbackType.OTHER)
                    }}
                    selected={feedbackType === FeedbackType.OTHER}
                  />
                </ButtonGroup>
              </S.FeedbackTitle>
              <S.FeedbackContent>
                <Editor
                  content={content}
                  initialContent={defaultContent}
                  onDataChanged={(data) => {
                    setContent(data)
                  }}
                  contentType="html"
                  placeholder="Write your feedback..."
                  setEditor={setEditor}
                  $minHeight='20rem'
                  $editorHeight='fit-content'
                  $maxHeight='calc(100vh - 20rem)'
                  useFileHandler={true}
                  toolbarActions={
                    <Flex $gap={24}>
                      <Button
                        $variant='flat'
                        $colorTheme='tint'
                        $height={24}
                        $fontSize={12}
                        disabled={editor?.isEmpty}
                        onClick={() => {
                          submitFeedback({
                            feedback: {
                              body: content,
                              type: feedbackType
                            }
                          })
                          controlDialog(DialogId.SEND_FEEDBACK, false)
                        }}
                      >
                        Send feedback
                      </Button>
                      { uploadingFile
                        ? (
                            <S.FeedbackUploading>
                              <Caption size='XS' $fontWeight={400}>Uploading {uploadingFile}...</Caption>
                            </S.FeedbackUploading>
                          )
                        : (
                            <UploadAttachments
                              accept={ALLOWED_FILE_TYPES.join(',')}
                              handleUpload={handleUpload}
                            />
                          )
                      }
                    </Flex>
                  }
                />
              </S.FeedbackContent>
            </S.DialogInner>
          </Flex>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
