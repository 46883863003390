import { useGlobalError } from 'src/hooks/use-global-error'
import * as RadixDialog from '@radix-ui/react-dialog'
import { Button, Flex } from 'src/components/primitives'
import { Icons } from 'src/components/primitives/icon'
import * as S from './notifications-dialog.styled'
import { Caption } from 'src/components/primitives/typography'
import { EmptyNotification } from 'src/components/blocks/notifications'
import { useUserNotificationsQuery } from 'src/hooks/queries/use-user-notifications'
import { NotificationTile } from 'src/components/blocks/notifications/notification-tile'
import { useMarkNotificationSeen } from 'src/hooks/mutations/use-mark-notification-seen'
import { useEffect } from 'react'
import { DialogId } from 'src/contexts/dialogs'
import { useDialog } from 'src/hooks/use-dialog'

export const NotificationsDialog = (): JSX.Element => {
  const { isGlobalErrorOpen = false } = useGlobalError()
  const { isPending, data: notifications } = useUserNotificationsQuery()
  const { isDialogOpen, controlDialog } = useDialog()

  const { markNotificationsSeen } = useMarkNotificationSeen()

  useEffect(() => {
    const unseenNotifications = (notifications ?? []).filter((notification) => !notification.notificationViews?.length)
    if (unseenNotifications.length > 0) {
      // Mark all notifications as seen when the dialog is opened for 3 seconds
      const timeout = setTimeout(() => {
        markNotificationsSeen({
          notificationIds: unseenNotifications.map((notification) => notification.id)
        })
      }, 3000)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [markNotificationsSeen, notifications])

  return (
    <RadixDialog.Root
      open={isDialogOpen(DialogId.NOTIFICATIONS)}
      onOpenChange={(open) => {
        controlDialog(DialogId.NOTIFICATIONS, open)
      }}
    >
      <RadixDialog.Portal>
        <S.DialogContent $isErrored={isGlobalErrorOpen}>
          <S.Header>
            <Caption size="MD">Notifications</Caption>
            <RadixDialog.Close asChild>
              <Button
                ariaLabel="Close"
                leadingIcon={Icons.x}
                $variant="ghost"
                $colorTheme="normal"
                $width={24}
                $height={24}
              />
            </RadixDialog.Close>
          </S.Header>
          {(!isPending && notifications?.length === 0)
            ? <EmptyNotification />
            : <Flex $direction="column" $gap={2}>
                {notifications?.map((notification) => (
                  <NotificationTile key={notification.id} notification={notification} />
                ))}
              </Flex>
          }
        </S.DialogContent>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  )
}
