import { Flex } from 'src/components/primitives/flex'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { LogoSymbol } from 'src/components/primitives/logo'
import * as S from './status-view.styled'
import type { Color } from 'src/styles/theme/types'

interface StatusViewProps {
  pin?: 'normal' | 'sad' | 'happy' | 'searching' | null
  animatePin?: boolean
  variant?: 'normal' | 'negative'
  heading?: string
  description?: string
  children?: React.ReactNode
}

export const StatusView = ({
  pin = null,
  animatePin = false,
  variant = 'normal',
  heading,
  description,
  children
}: StatusViewProps): JSX.Element => {
  const getCaptionColor = (): Color => {
    switch (variant) {
      case 'negative': {
        return 'negativeFg'
      }
      default: {
        return 'fgSecondary'
      }
    }
  }

  return (
    <S.StatusView>
      <S.Inner>
        {pin && <LogoSymbol variant={pin} width={40} height={43} animated={animatePin} />}
          <Flex $direction="column" $gap={8} $align="center">
            <Caption size="XS" $color={getCaptionColor()} $align="center">
              {heading}
            </Caption>
            <Paragraph size="XS" $color="fgSecondary" $align="center">
              {description}
            </Paragraph>
          {children}
          </Flex>
      </S.Inner>
    </S.StatusView>
  )
}
