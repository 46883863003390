import * as S from './search-refinement-form.styled'
import { Icon, Icons } from 'src/components/primitives/icon/icon'
import { Button } from 'src/components/primitives/button'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import type { JobSearchRefinement, RefinementHighlight } from 'src/libs/api/backend/jobs'
import { When } from '../when'
import { motion } from 'framer-motion'
import { Spinner } from 'src/components/primitives/spinner'
import { LogoSymbol } from 'src/components/primitives/logo'
import { SearchRefinementEditor } from '../search-refinement-editor'
import type { Editor } from '@tiptap/react'
import { isNil } from 'lodash'
import { Caption } from 'src/components/primitives/typography'
import { Flex } from 'src/components/primitives/flex'
import { Dropdown } from 'src/components/primitives/dropdown'
import { useOnClickOutside } from 'usehooks-ts'

interface SearchRefinementSuggestion {
  tag: string
  query: string
}

interface SearchRefinementFormProps {
  jobSearchRefinement?: JobSearchRefinement
  pickedRecommendation?: string | null
  isSearching?: boolean
  onSubmit: (searchQuery: string) => void
  suggestions?: SearchRefinementSuggestion[]
  highlights?: RefinementHighlight[] | null
  newRefinement?: boolean
  setIsFocused: (isFocused: boolean) => void
  isFocused: boolean
}

const DEFAULT_SUGGESTIONS = [
  { tag: 'Worked at a Series A company', query: 'Worked at a Series A company' },
  { tag: 'First 50 employees', query: 'First 50 employees' },
  { tag: 'Based in NYC', query: 'Based in NYC' },
  { tag: 'Aerospace Industry', query: 'Aerospace Industry' },
  { tag: '2+ years experience', query: '2+ years experience' },
  { tag: 'Top Tier University', query: 'Top Tier University' },
  { tag: 'Knows React', query: 'Knows React' }
]

const MOCK_SEARCH_HISTORY = [
  'Based in California with 3+ years of experience, engineering degree, knows React and NextJS.',
  'Former founder, experience in React. From New York. 5 years of experience.',
  'Former founder, experience in React. From East coast, preferably New York.',
  'Former founder, experience in Javascript.',
  'Worked in SaaS companies, with degree and at least 4 years of experience in tech.'
]

const removeUnnecessaryCharacters = (query?: string | null): string => {
  if (isNil(query)) {
    return ''
  }
  return query.replace(/(&nbsp;|\r?\n|\r)/g, '').trim()
}

export const SearchRefinementForm = ({
  pickedRecommendation,
  isSearching,
  onSubmit,
  suggestions = DEFAULT_SUGGESTIONS,
  newRefinement = false,
  setIsFocused,
  isFocused
}: SearchRefinementFormProps): JSX.Element => {
  const formWrapperEl = useRef<HTMLDivElement>(null)
  const [searchQuery, setSeachQuery] = useState<string | null>(null)
  const [selectedSearchHistory, setSelectedSearchHistory] = useState<string | null>(null)
  const [editor, setEditor] = useState<Editor | null>(null)

  useOnClickOutside(formWrapperEl, () => {
    if (!newRefinement) {
      setIsFocused(false)
    }
  })

  const searchHistoryItems = useMemo(() => {
    return MOCK_SEARCH_HISTORY.map(item => ({
      title: item,
      value: item,
      onSelect: () => {
        setSeachQuery(item)
        setSelectedSearchHistory(item)
      }
    }))
  }, [])

  const initialContent = useMemo(() => {
    if (!isNil(selectedSearchHistory)) {
      return selectedSearchHistory
    }
    const trimmedPickedRecommendation = removeUnnecessaryCharacters(pickedRecommendation)
    // TODO: This part causes a flushSync was called from inside a lifecycle method issue in Tiptap
    // if (!isNil(highlights) && highlights.length > 0) {
    //   return parseEditorContent(trimmedPickedRecommendation, highlights)
    // }
    return trimmedPickedRecommendation
  }, [pickedRecommendation, selectedSearchHistory])

  const disableSubmit = useMemo(() => {
    return (
      !newRefinement && (
        !!isSearching ||
        !searchQuery ||
        searchQuery.trim().toLowerCase() === removeUnnecessaryCharacters(pickedRecommendation).toLowerCase()
      )
    )
  }, [isSearching, newRefinement, pickedRecommendation, searchQuery])

  const handleSubmit = useCallback(async (searchQuery: string | null): Promise<void> => {
    if (onSubmit && searchQuery && !disableSubmit) {
      const trimmedQuery = searchQuery.trim()
      if (trimmedQuery === '') {
        return
      }
      onSubmit(trimmedQuery)
    }
  }, [disableSubmit, onSubmit])

  const handleAddSuggestion = useCallback((suggestion: string): void => {
    if (editor) {
      const metaCharacter = /\s/g
      const { $to } = editor.view.state.selection
      const lastCharacter = ($to.nodeBefore?.textContent ?? '').slice(-1)
      const isLastCharacterASpace = lastCharacter.match(metaCharacter)
      if (!lastCharacter || isLastCharacterASpace) {
        editor.chain().focus().insertContent(`${suggestion} `).run()
      } else {
        editor.chain().focus().insertContent(` ${suggestion} `).run()
      }
    }
  }, [editor])

  useEffect(() => {
    if (isSearching && !newRefinement) {
      setIsFocused(false)
    }
  }, [isSearching, newRefinement, setIsFocused])

  return (
    <div ref={formWrapperEl}>
      <S.SearchRefinementForm
        $isFocused={isFocused}
        animate={{ height: isFocused ? '100%' : '40px' }}
        transition={{ duration: 0.2, delay: isFocused ? 0 : 0, ease: 'circOut' }}
      >
        <S.Container>
          <S.Icon>
            <Icon name="file-search" color="fgSecondary" />
          </S.Icon>
          {searchQuery && !isSearching &&
            <S.InlineClear $show={isFocused}>
              <Button
                $variant="ghost"
                $colorTheme="normal"
                $height={20}
                $width={20}
                $fontSize={16}
                leadingIcon={Icons.x}
                $borderRadius={12}
                onClick={() => {
                  setSeachQuery(null)
                  editor?.chain().focus().clearContent().run()
                }}
              />
            </S.InlineClear>
          }
          <S.EditorWrapper
            $isFocused={isFocused}
            onClick={() => {
              if (!isSearching && !isFocused) {
                setIsFocused(true)
              }
            }}
          >
            <SearchRefinementEditor
              placeholder='Describe your requirements...'
              isEditable={!isSearching}
              initialContent={initialContent ?? undefined}
              onDataChanged={(data) => {
                setSeachQuery(removeUnnecessaryCharacters(data))
              }}
              isFocused={isFocused}
              forceEditorFocus={(!pickedRecommendation && newRefinement) || isFocused}
              onSubmit={(newQuery: string) => {
                void handleSubmit(newQuery)
              }}
              setEditor={setEditor}
            />
            <When condition={!isSearching && !initialContent && !isFocused}>
              <S.SuggestionsButton>
                <LogoSymbol colorTheme='tint' width={12} height={12} />
                Suggestions
              </S.SuggestionsButton>
            </When>
            <When condition={isSearching ?? false}>
              <S.InProgress>
                <Spinner />
              </S.InProgress>
            </When>
          </S.EditorWrapper>
        </S.Container>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{
            opacity: isFocused ? 1 : 0,
            y: isFocused ? 0 : -20
          }}
          transition={{ duration: isFocused ? 0.2 : 0.05, delay: 0, ease: 'circOut' }}
          style={{ pointerEvents: isFocused ? 'all' : 'none' }}
        >
          <When condition={isFocused}>
            <S.ActionButtons>
              <When condition={false}>
                <Dropdown
                  trigger={
                    <Button
                      nested
                      $variant="outline"
                      $colorTheme="muted"
                      $height={24}
                      leadingIcon="history"
                      trailingIcon="chevron-down"
                      $fontSize={12}
                      disabled={!!isSearching || !searchQuery}
                    >
                      History
                    </Button>
                  }
                  layout="block"
                  disabled={newRefinement}
                  items={searchHistoryItems}
                />
              </When>
              <Flex $gap={8} $align="center" $justify="flex-end">
                <When condition={!newRefinement}>
                  <Button
                    $variant="outline"
                    $colorTheme="muted"
                    $height={24}
                    $fontSize={12}
                    onClick={() => {
                      setSeachQuery(initialContent ?? null)
                      editor?.chain().focus().clearContent().insertContent(initialContent ?? null).run()
                      editor?.chain().focus().setTextSelection(0).run()
                      editor?.commands.blur()
                      setIsFocused(false)
                    }}
                  >
                    Cancel
                  </Button>
                </When>
                <Button
                  $variant="fill"
                  $colorTheme="tint"
                  $height={24}
                  $fontSize={12}
                  loading={isSearching ?? false}
                  disabled={disableSubmit}
                  onClick={() => {
                    void handleSubmit(searchQuery)
                    setIsFocused(false)
                  }}
                >
                  Search
                </Button>
              </Flex>
            </S.ActionButtons>
            {suggestions && suggestions?.length >= 1 && (
              <S.SuggestionsArea>
                <Caption size='2XS' $color='fgSecondary' $align='left'>PIN SUGGESTED</Caption>
                <S.Suggestions style={{ pointerEvents: isSearching ? 'none' : 'all' }}>
                  {suggestions.map((suggestion) => (
                    <S.Suggestion
                      key={suggestion.tag}
                      aria-label={`Add ${suggestion.tag} to search input for refinement`}
                      onClick={() => {
                        handleAddSuggestion(suggestion.query)
                      }}
                    >
                      {suggestion.tag}
                    </S.Suggestion>
                  ))}
                </S.Suggestions>
              </S.SuggestionsArea>
            )}
          </When>
        </motion.div>
      </S.SearchRefinementForm>
    </div>
  )
}
