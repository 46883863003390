import { z } from 'zod'
import Api from '..'
import type { CandidateExpanded } from './candidate_jobs'

export async function fetchDisconnectMergeIntegration (): Promise<void> {
  const { status } = await Api.get('/merge/disconnect')
  if (status !== 200) {
    throw new Error('Failed to disconnect merge integration')
  }
}

const mergeIntegrationParser = z.object({
  name: z.string(),
  slug: z.string(),
  image: z.string().url(),
  square_image: z.string().url(),
  color: z.string(),
  categories: z.array(z.string())
})

export type MergeIntegration = z.infer<typeof mergeIntegrationParser>

export async function fetchMergeIntegrationList (): Promise<MergeIntegration[]> {
  const { data } = await Api.get('/merge/integrations')
  return mergeIntegrationParser.array().parse(data)
}

const mergeAtsJobSerializer = z.object({
  id: z.string(),
  name: z.string()
})

export type MergeAtsJob = z.infer<typeof mergeAtsJobSerializer>

export async function fetchMergeJobs (): Promise<MergeAtsJob[]> {
  const { data } = await Api.get('/merge/jobs')

  return mergeAtsJobSerializer.array().parse(data)
}

interface LinkJobWithMergeJobParams {
  jobId: string
  mergeAtsJobId: string | null
}

export async function linkJobWithMergeJob ({
  jobId,
  mergeAtsJobId
}: LinkJobWithMergeJobParams): Promise<void> {
  const { status } = await Api.post(`/merge/${jobId}/link_job`, null, {
    mergeAtsJobId
  })

  if (status !== 200) {
    throw new Error('Failed to link job with merge job')
  }
}

interface ExportCandidateToAtsParams {
  jobId: string
  candidateIds: Array<CandidateExpanded['id']>
}

export async function exportCandidateToAts ({
  jobId,
  candidateIds
}: ExportCandidateToAtsParams): Promise<void> {
  const { status } = await Api.post(`/merge/${jobId}/export_candidates_to_ats`, null, {
    candidateIds
  })

  if (status !== 200) {
    throw new Error('Failed to export candidate to ATS')
  }
}
