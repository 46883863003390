import React, { useCallback, useEffect, useState } from 'react'
import { Notification, NotificationsContainer } from 'src/components/primitives/notification'
import { NotificationContext } from 'src/contexts/notifications'
import type { Notification as NotificationArgs } from 'src/contexts/notifications'
import { sleep } from 'src/libs/sleep'

export const NotificationProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [notifications, setNotifications] = useState<NotificationArgs[]>([])

  const notify = useCallback(async (notification: Omit<NotificationArgs, 'id'>) => {
    if (notification.delay) {
      await sleep(notification.delay)
    }

    const shouldClearBeforeAdding = (notifications: NotificationArgs[]): boolean => {
      if (notification.type === 'toast') {
        return notifications.some((n) => n.type !== 'toast')
      } else if (notification.type?.startsWith('candidate-')) {
        return notifications.some((n) => n.type === 'toast')
      }
      return false
    }

    setNotifications((prevNotifications) => {
      const shouldClear = shouldClearBeforeAdding(prevNotifications)
      return [
        ...(shouldClear ? [] : prevNotifications),
        {
          ...notification,
          id: crypto.randomUUID()
          // stackPosition: prevNotifications.length + 1
        }
      ]
    })
  }, [])

  const close = (id: NotificationArgs['id']): void => {
    setNotifications((prev) => prev.filter((n) => n.id !== id))
  }

  const clear = (): void => {
    setNotifications([])
  }

  useEffect(() => {
    if (notifications?.length >= 4) {
      const oldestNotificationId = notifications[0].id
      close(oldestNotificationId)
    }
  }, [notifications])

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        notify,
        close,
        clear
      }}
    >
      {children}
      <NotificationsContainer>
        {notifications?.map((notification, index) => (
          <Notification
            count={notifications.length}
            stackPosition={index}
            key={notification.id}
            {...notification}
          />
        ))}
      </NotificationsContainer>
    </NotificationContext.Provider>
  )
}
