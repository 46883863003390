import { useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { fetchCandidateJob } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { queryKeys } from 'src/libs/query-keys'
import queryClient from '../query-client'
import { isNil } from 'lodash'

interface CandidateJobQueryProps {
  candidateJobId?: string
}

export const useCandidateJobQuery = ({ candidateJobId }: CandidateJobQueryProps = {}): UseQueryResult<CandidateJobExpanded> => {
  const queriesData = queryClient.getQueriesData({ queryKey: [queryKeys.candidateJobs] })

  let candidateJob: CandidateJobExpanded | undefined
  for (const queryData of queriesData) {
    const candidateJobs = queryData[1] as CandidateJobExpanded[]
    candidateJob = candidateJobs?.find((candidateJob: CandidateJobExpanded) => candidateJob.id === candidateJobId)
    if (!isNil(candidateJob)) {
      break
    }
  }

  return useQuery({
    queryKey: [queryKeys.candidateJob, candidateJobId],
    queryFn: async () => {
      return await fetchCandidateJob(candidateJobId ?? '')
    },
    staleTime: 60 * 1000,
    placeholderData: candidateJob,
    enabled: !!candidateJobId
  })
}
