import type { FieldCommonStyleProps } from '../common'
import styled from 'styled-components'

export const FormElement = styled.div<FieldCommonStyleProps>`
  position: relative;
  flex: 1 1 100%;
  margin-bottom: ${({ theme, $marginBottom }) =>
    theme.spacing[typeof $marginBottom === 'number' ? $marginBottom : 16]};
  ${({ $width }) => $width && `
    width: ${$width};
  `}
`
