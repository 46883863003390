import { useRouteError } from 'react-router-dom'
import { ErrorLayout } from 'src/components/layouts/error'
import Sentry from 'src/utils/sentry'

const ErrorPage = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const error = useRouteError() as any
  console.error(error)
  Sentry.captureException(error, { level: 'fatal' })

  return (
    <ErrorLayout>
      <p>
        <i>{error?.statusText || error?.message}</i>
      </p>
    </ErrorLayout>
  )
}

export default ErrorPage
