import { useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { listOrgUsers } from 'src/libs/api/backend/users'
import type { OrgUser } from 'src/libs/api/backend/users'
import { queryKeys } from 'src/libs/query-keys'

export const useOrgUsersQuery = (): UseQueryResult<OrgUser[]> => {
  return useQuery({
    queryKey: [queryKeys.orgUsers],
    queryFn: async () => await listOrgUsers(),
    staleTime: 60 * 1000
  })
}
