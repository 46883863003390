import { Card, Flex, Spacer } from 'src/components/primitives'
import { MAX_ALLOWED_EMAILS } from '../automation-settings/settings'
import { useForm } from 'src/hooks/use-form'
import { z } from 'zod'
import * as S from './email-settings.styled'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { useCallback, useEffect } from 'react'
import { Form, Input } from 'src/components/forms'
import { Toggle } from 'src/components/primitives/toggle'
import { useJobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import { useUpsertJobSequence } from 'src/hooks/mutations/use-upsert-job-sequence'
import { isNil } from 'lodash'
import { useParams } from 'react-router'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { useUpdateJobPosition } from 'src/hooks/mutations/use-update-job-position'
import type { UpdateJob } from 'src/libs/api/backend/jobs'
import { WeekAvailabilityPicker } from '../week-availability-picker'

interface PreferenceRowProps {
  title: string
  subTitle: string
  action: React.ReactNode
}

const PreferenceRow = ({ title, subTitle, action }: PreferenceRowProps): JSX.Element => {
  return (
    <S.PreferenceRowWrapper>
      <S.PreferenceRowContainer>
        <Flex $gap={0} $direction='column' $width='auto' $flex="1 1 auto">
          <Caption size='SM' $ellipsis>{title}</Caption>
          <S.PreferenceRowSubTitle>
            <Caption size='XS' $color='fgSecondary' $ellipsis $fontWeight={400}>{subTitle}</Caption>
          </S.PreferenceRowSubTitle>
        </Flex>
        <S.Action>
          {action}
        </S.Action>
      </S.PreferenceRowContainer>
    </S.PreferenceRowWrapper>
  )
}

const emailsLimitForm = z.object({
  dailyEmailLimit: z.number().max(MAX_ALLOWED_EMAILS, 'Maximum number of daily emails is 100')
})

type EmailsLimitForm = z.infer<typeof emailsLimitForm>

export const EmailSettings = (): JSX.Element => {
  const { isPending, data: emailSequence } = useJobSequenceQuery()
  const { upsertJobSequence } = useUpsertJobSequence()
  const { jobId } = useParams<{ jobId: string }>()
  const { data: jobData } = useJobQuery()
  const { updateJobPosition } = useUpdateJobPosition()
  const { register, setValue, formData, submit } = useForm<EmailsLimitForm>({
    schema: emailsLimitForm
  })

  const updateEmailLimit = useCallback((): void => {
    if (!isNil(emailSequence) && formData?.dailyEmailLimit !== emailSequence.dailyEmailLimit) {
      if (typeof formData?.dailyEmailLimit === 'number' && formData?.dailyEmailLimit <= MAX_ALLOWED_EMAILS) {
        upsertJobSequence({ ...emailSequence, dailyEmailLimit: formData?.dailyEmailLimit })
      }
    }
  }, [emailSequence, formData?.dailyEmailLimit, upsertJobSequence])

  const handleSubmit = useCallback(async (): Promise<void> => {
    updateEmailLimit()
  }, [updateEmailLimit])

  useEffect(() => {
    setValue('dailyEmailLimit', emailSequence?.dailyEmailLimit)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailSequence?.dailyEmailLimit])

  const handleUpdateJob = useCallback((updatedFields: Partial<UpdateJob>, showToast = true) => {
    if (isNil(jobData)) {
      return
    }
    updateJobPosition({
      updatedJob: {
        id: jobId ?? '',
        ...updatedFields
      },
      showToast
    })
  }, [jobData, jobId, updateJobPosition])

  if (isPending) {
    return <></>
  }

  return (
    <Flex $direction='column' $gap={12}>
      <PreferenceRow
        title='Daily email limit'
        subTitle='How many emails Pin can send per day.'
        action={
          <Form onSubmit={submit(handleSubmit)}>
            <Input
              label="Max emails per day"
              hiddenLabel
              type="number"
              name="dailyEmailLimit"
              placeholder={emailSequence?.dailyEmailLimit.toString()}
              max={MAX_ALLOWED_EMAILS}
              register={register}
              onBlurEvent={() => {
                updateEmailLimit()
              }}
              $marginBottom={0}
              size='small'
            />
          </Form>
        }
      />
      <Card>
        <Flex $direction="column" $gap={2}>
          <Caption size="SM">When to send emails</Caption>
          <Paragraph size="XS" $color="fgSecondary">
            Choose weekdays and time of day when emails can be sent.
          </Paragraph>
        </Flex>
        <Spacer $size={16} />
        <WeekAvailabilityPicker
          defaultAvailability={jobData?.emailWhenToSend}
          onAvailabilityChange={(updated) => {
            handleUpdateJob({ emailWhenToSend: updated }, false)
          }}
        />
      </Card>
      <PreferenceRow
        title='Skip holidays'
        subTitle='Don’t send emails on holidays.'
        action={
          <Toggle
            name='skip-holidays'
            checked={jobData?.emailSkipHolidays ?? false}
            onChange={() => {
              handleUpdateJob({ emailSkipHolidays: !jobData?.emailSkipHolidays })
            }}
          />
        }
      />
    </Flex>
  )
}
