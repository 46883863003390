import { createGlobalStyle } from 'styled-components'

export const GlobalReset = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  *, html, body {
    padding: 0;
    margin: 0;
  }
  html, body {
    height: 100%;
    overflow: hidden;
  }
  h1, h2, h3, h4, h5, h6, a, li, p, span, label, input, textarea, button {
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  a, button {
    -moz-appearance: none;
    -webkit-appearance: none;
    /* color: currentColor; */
  }
`

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    background-color: #fff;
    color: #000;
  }
  ::selection {
    background: ${({ theme }) => theme.colors.tintBg};
    color: #fff;
    text-shadow: none;
  }
`
