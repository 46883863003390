import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { TrialExpired } from 'src/components/blocks/trial-expired'
import { useSession } from 'src/hooks/use-session'
import RouteBuilder from 'src/libs/route-builder'

const TrialExpiredPage = (): JSX.Element => {
  const { trialActive, subscribed } = useSession()
  const navigate = useNavigate()
  useEffect(() => {
    if (!!subscribed || !!trialActive) {
      navigate(RouteBuilder.build('ROOT'))
    }
  }, [navigate, subscribed, trialActive])

  return (
    <TrialExpired />
  )
}

export default TrialExpiredPage
