import 'src/utils/sentry'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import router from 'src/router'
import { initializeTracking } from './libs/initialize-tracking'

initializeTracking()

ReactDOM
  .createRoot(document.querySelector('slot')!)
  .render(<RouterProvider router={router}/>)
