import { Icon } from 'src/components/primitives/icon'
import type { IconName } from 'src/components/primitives/icon'
import * as S from './banner.styled'
import type { Color } from 'src/styles/theme/types'
import { isNil } from 'lodash'
import { Flex } from 'src/components/primitives/flex'

type Variant = 'neutral' | 'muted' | 'positive' | 'tint' | 'negative' | 'warning'

export interface BannerStyleProps {
  $variant?: Variant
}

interface BannerProps extends BannerStyleProps {
  icon?: IconName
  children: React.ReactNode | string
  actions?: React.ReactNode
}

export const Banner = ({
  $variant = 'neutral',
  icon,
  children,
  actions
}: BannerProps): JSX.Element => {
  const getIconColor = ($variant: Variant): Color => {
    switch ($variant) {
      case 'neutral': {
        return 'fgPrimary'
      }
      case 'muted': {
        return 'fgSecondary'
      }
      case 'positive': {
        return 'positiveBg'
      }
      case 'tint': {
        return 'tintBg'
      }
      case 'negative': {
        return 'negativeBg'
      }
      case 'warning': {
        return 'warningBg'
      }
    }
  }

  return (
    <S.Banner $variant={$variant}>
      <Flex $gap={16} $align="center">
        {!isNil(icon) && <Icon name={icon} color={getIconColor($variant)} />}
        {typeof children === 'string' ? <p>{children}</p> : children}
      </Flex>
      {actions && (
        <Flex $gap={8} $align="center" $justify="flex-end" $width="auto">
          {actions}
        </Flex>
      )}
    </S.Banner>
  )
}
