import { ChannelProvider } from 'ably/react'
import { useMemo } from 'react'
import { useSession } from 'src/hooks/use-session'
import { CHANNEL_TYPE, getChannelName } from 'src/libs/api/backend/websockets'

interface ChannelsProviderProps {
  children: React.ReactNode
}

export const ChannelsProvider = ({ children }: ChannelsProviderProps): JSX.Element => {
  const { org } = useSession()
  const orgId = useMemo(() => org?.id ?? '', [org])
  return (
    <ChannelProvider channelName={getChannelName(CHANNEL_TYPE.CANDIDATE_SEARCH, orgId)}>
      <ChannelProvider channelName={getChannelName(CHANNEL_TYPE.NOTIFICATIONS, orgId)}>
        <ChannelProvider channelName={getChannelName(CHANNEL_TYPE.CANDIDATE_DETAILS, orgId)}>
          {children}
        </ChannelProvider>
      </ChannelProvider>
    </ChannelProvider>
  )
}
