import { useEffect, useMemo, useState } from 'react'
import { StatusView } from '../status-view'
import { ProgressBar } from 'src/components/primitives/progress-bar'
import { SourceEnd } from '../source-end'
import { ProgressStepsList } from 'src/components/primitives/progress-steps-list'

interface SourcingViewProps {
  isRecommending: boolean
  isEmpty: boolean
  lastRequestedAt: Date
}

const TOTAL_TIME = 100 * 1000

export const SourcingView = ({
  isRecommending,
  isEmpty,
  lastRequestedAt
}: SourcingViewProps): JSX.Element | null => {
  const [tick, setTick] = useState(0)
  const [showEmpty, setShowEmpty] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShowEmpty(isEmpty)
    }, 1000)
    return () => {
      setShowEmpty(false)
    }
  }, [isEmpty])

  useEffect(() => {
    const interval = setInterval(() => {
      setTick((prevTick) => prevTick + 1)
    }, 2000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  const progress = useMemo((): number => {
    const elapsedTime = Date.now() - lastRequestedAt.getTime()
    const progressPercentage = Math.min(Math.max((elapsedTime / TOTAL_TIME) * 100, 0), 100)
    return progressPercentage
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastRequestedAt, tick])

  if (isRecommending) {
    return (
      <StatusView
        pin="searching"
        animatePin
        heading="Searching candidates…"
      >
        <ProgressBar progress={progress} />
        <ProgressStepsList progress={progress} />
      </StatusView>
    )
  }
  if (showEmpty) {
    return (
      <StatusView
        pin="happy"
        animatePin
        heading="Loading candidates…"
      >
        <ProgressBar progress={100} />
      </StatusView>
    )
  }
  return isEmpty ? null : <SourceEnd />
}
