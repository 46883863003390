import { useQuery } from '@tanstack/react-query'
import { ApiError } from 'src/libs/api'
import { fetchExternalJobListings } from 'src/libs/api/backend/external_job_listings'
import type { FetchExternalJobListingsInput } from 'src/libs/api/backend/external_job_listings'
import { queryKeys } from 'src/libs/query-keys'

export const jobListingsQuery = (query: FetchExternalJobListingsInput) => {
  return {
    queryKey: [queryKeys.jobListings, query],
    queryFn: async () => await fetchExternalJobListings(query),
    staleTime: 60 * 1000,
    retry: false,
    throwOnError: (err: any): boolean => { // eslint-disable-line @typescript-eslint/no-explicit-any
      if (err instanceof ApiError) {
        return false
      }

      return true
    }
  }
}

export const useJobListings = (query: FetchExternalJobListingsInput) => {
  return useQuery(jobListingsQuery(query))
}
