import styled from 'styled-components'

export const StatusView = styled.div`
  width: 100%;
  height: calc(100% - 1.5rem);
  border-radius: 6px;
  padding: 4rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
`

export const Inner = styled.div`
  width: 100%;
  max-width: 420px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`
