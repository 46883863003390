import { useEffect, useState } from 'react'
import { Button } from 'src/components/primitives/button'
import { Flex } from 'src/components/primitives/flex'
import { When } from '../when'
import type { CandidateExpanded, CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { Spinner } from 'src/components/primitives/spinner'
import {
  CandidateEducationTimeline,
  CandidateExperienceTimeline,
  CandidateProfile,
  CandidateQuickOverview,
  CandidateSummary
} from '../candidate-details'
import * as S from './candidate-details-card.styled'
import { ViewMode } from 'src/constants'

export interface CandidateDetailProps {
  stage?: CandidateJobExpanded['stage']
  candidate: CandidateExpanded
  candidateJob?: CandidateJobExpanded
  actions?: React.ReactNode
  viewMode?: ViewMode
}

export type CollapsedVariant = 'loading' | 'undo'

export const CandidateDetailsCard = ({
  candidate,
  candidateJob,
  stage,
  actions,
  viewMode = ViewMode.DEFAULT
}: CandidateDetailProps): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  useEffect(() => {
    if (stage === 'PROSPECTING' || candidate.profileLookupStage === 'IN_PROGRESS') {
      setIsCollapsed(true)
    } else {
      setIsCollapsed(false)
    }
  }, [stage, candidate.profileLookupStage])

  return (
    <S.CandidateDetailsCard
      $isCollapsed={isCollapsed}
    >
      <When condition={isCollapsed}>
        <S.Collapsed $variant={candidate.profileLookupStage === 'IN_PROGRESS' ? 'loading' : 'undo'}>
          <CandidateProfile candidate={candidate} subheading={candidate.location} />
          <When condition={candidate.profileLookupStage !== 'IN_PROGRESS'}>
            <Flex $width="auto" $gap={12} $align="center">
              {actions}
              <Button
                ariaLabel={isCollapsed ? 'Collapse' : 'Uncollapse'}
                $variant="ghost"
                $colorTheme="muted"
                $width={32}
                $height={32}
                leadingIcon={isCollapsed ? 'chevron-right' : 'chevron-down'}
                onClick={() => {
                  setIsCollapsed(!isCollapsed)
                }}
              />
            </Flex>
          </When>
          <When condition={candidate.profileLookupStage === 'IN_PROGRESS'}>
            <S.Loading>
              <Spinner />
            </S.Loading>
          </When>
        </S.Collapsed>
      </When>
      <When condition={!isCollapsed && viewMode === ViewMode.DEFAULT}>
        <>
          <S.Header>
            <CandidateProfile
              candidate={candidate}
              subheading={candidate.location}
              avatarSize={80}
              includeSocialLinks
              sourcingScores={candidateJob?.sourcingScores}
            />
            <When condition={Boolean(actions)}>
              <Flex $gap={12} $align="center" $justify="flex-end" $width="auto">
                {actions}
                {stage === 'PROSPECTING' && (
                  <Button
                    ariaLabel={isCollapsed ? 'Collapse' : 'Uncollapse'}
                    $variant="ghost"
                    $colorTheme="muted"
                    $width={32}
                    $height={32}
                    leadingIcon={isCollapsed ? 'chevron-right' : 'chevron-down'}
                    onClick={() => {
                      setIsCollapsed(!isCollapsed)
                    }}
                  />
                )}
              </Flex>
            </When>
          </S.Header>
          <S.Content>
            <CandidateSummary candidate={candidate} candidateJob={candidateJob} />
            <CandidateExperienceTimeline experiences={candidate?.experiences} />
            <CandidateEducationTimeline educations={candidate?.educations} />
          </S.Content>
        </>
      </When>

      <When condition={!isCollapsed && viewMode === ViewMode.COMPACT}>
        <>
          <S.Header>
            <CandidateProfile
              candidate={candidate}
              subheading={candidate.location}
              avatarSize={64}
              avatarShape="soft"
              includeSocialLinks
              socialLinksPosition='top'
              sourcingScores={candidateJob?.sourcingScores}
            />
            <When condition={Boolean(actions)}>
              <Flex $gap={12} $align="center" $justify="flex-end" $width="auto">
                {actions}
                {stage === 'PROSPECTING' && (
                  <Button
                    ariaLabel={isCollapsed ? 'Collapse' : 'Uncollapse'}
                    $variant="ghost"
                    $colorTheme="muted"
                    $width={32}
                    $height={32}
                    leadingIcon={isCollapsed ? 'chevron-right' : 'chevron-down'}
                    onClick={() => {
                      setIsCollapsed(!isCollapsed)
                    }}
                  />
                )}
              </Flex>
            </When>
          </S.Header>
          <S.Content>
            <CandidateQuickOverview candidateJob={candidateJob} />
          </S.Content>
        </>
      </When>
    </S.CandidateDetailsCard>
  )
}
