import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNotification } from '../use-notification'
import { useParams } from 'react-router'
import { batchAutoApproveJobSearchRefinements } from 'src/libs/api/backend/jobs'
import { queryKeys } from 'src/libs/query-keys'

interface Args {
  enabled: boolean
  jobSearchRefinementIds?: string[]
}

interface UseBatchAutoApproveJobSearchRefinements {
  batchAutoApproveRefinements: (args: Args) => void
}

export const useBatchAutoApproveJobSearchRefinements = (): UseBatchAutoApproveJobSearchRefinements => {
  const queryClient = useQueryClient()
  const { notify } = useNotification()
  const { jobId } = useParams()

  const mutation = useMutation({
    mutationFn: async ({ enabled, jobSearchRefinementIds }: Args) =>
      await batchAutoApproveJobSearchRefinements({ jobId: jobId ?? '', enabled, jobSearchRefinementIds }),
    onError: (error) => {
      console.error(error)
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: `An error occurred when updating your search refinements: ${error.message}`
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [queryKeys.jobSearchRefinements, jobId] })
      // const updatedRefinements = variables?.jobSearchRefinementIds?.length ?? 0
      // const toastMessage = updatedRefinements <= 0 ? 'No searches were updated' : `Updated ${pluralize(updatedRefinements, 'search', 'searches')}`
      // notify({
      //   type: 'toast',
      //   variant: 'positive',
      //   position: 'bottom-right',
      //   icon: 'x-octagon',
      //   message: toastMessage
      // })
    }
  })

  const batchAutoApproveRefinements = (args: Args): void => {
    mutation.mutate(args)
  }

  return { batchAutoApproveRefinements }
}
