import { useMutation } from '@tanstack/react-query'
import { sendManualEmail as sendManualEmailApi } from 'src/libs/api/backend/sequences'
import type { SendManualEmail } from 'src/libs/api/backend/sequences'
import { useNotification } from '../use-notification'
import { useDialog } from '../use-dialog'
import { pluralize } from 'src/libs/pluralize'
import { DialogId } from 'src/contexts/dialogs'

interface Args {
  manualEmail: SendManualEmail
  onSuccess?: () => void
}

interface Res {
  sendManualEmail: ({ manualEmail, onSuccess }: Args) => void
}

export const useSendManualEmail = (): Res => {
  const { notify } = useNotification()
  const { closeDialog } = useDialog()

  const mutation = useMutation({
    mutationFn: async ({ manualEmail }: Args) => {
      return await sendManualEmailApi({ manualEmail })
    },
    onError: (err) => {
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: `An error occurred when sending email(s): ${err.message}`
      })
    },
    onSuccess: async (data, variables) => {
      if (variables.onSuccess) {
        variables.onSuccess()
      } else {
        closeDialog(DialogId.WRITE_EMAIL)
        notify({
          type: 'toast',
          variant: 'positive',
          position: 'bottom-right',
          icon: 'mails',
          message: `Successfully sent ${pluralize(data.length, 'email')}`,
          autoClose: true
        })
      }
    }
  })

  const sendManualEmail = ({ manualEmail, onSuccess }: Args): void => {
    mutation.mutate({ manualEmail, onSuccess })
  }

  return { sendManualEmail }
}
