import { createContext, useContext } from 'react'

interface SettingsContextProps {
  currentJobId?: string | null
  setCurrentJobId: (jobId: string) => void
}

export const SettingsContext = createContext<SettingsContextProps>({
  currentJobId: null,
  setCurrentJobId: () => {}
})

export const useSettingsContext = () => {
  return useContext(SettingsContext)
}
