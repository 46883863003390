import * as S from './progress-steps-list.styled'
import { Flex } from '../flex'
import { Caption } from '../typography'
import { Icon } from '../icon'
import type { IconName } from '../icon'
import type { Color } from 'src/styles/theme/types'
import { Spinner } from '../spinner'

interface ProgressStep {
  text: string
  percentage: number
}

const STEPS: ProgressStep[] = [
  {
    text: 'Analyzing job description',
    percentage: 10
  },
  {
    text: 'Filtering through 100M+ profiles',
    percentage: 80
  },
  {
    text: 'Ranking expertise and fit',
    percentage: 95
  },
  {
    text: 'Preparing results',
    percentage: 101
  }
]

interface ProgressStepsListProps {
  progress: number
}

export const ProgressStepsList = ({ progress }: ProgressStepsListProps): JSX.Element => {
  const getStatus = (currentStep: ProgressStep, previousStep?: ProgressStep): { icon: IconName, color: Color } => {
    const currentStepProgress = currentStep.percentage
    const previousStepProgress = previousStep?.percentage ?? 0

    if (progress > currentStepProgress) {
      return {
        icon: 'check-circle-2',
        color: 'positiveFg'
      }
    }

    if (progress > previousStepProgress) {
      return {
        icon: 'loader',
        color: 'fgSecondary'
      }
    }

    return {
      icon: 'circle-dashed',
      color: 'fgSecondary'
    }
  }

  return (
    <S.Wrapper>
      <S.List>
        {STEPS.map((step, index) => (
          <Flex key={step.text} $align="center" $gap={8}>
            {getStatus(step, STEPS[index - 1]).icon === 'loader'
              ? (<Spinner color="fgSecondary" />)
              : (<Icon name={getStatus(step, STEPS[index - 1]).icon} color={getStatus(step, STEPS[index - 1]).color} />)}
            <Caption size="XS" $color={getStatus(step, STEPS[index - 1]).color}>
              {step.text}
            </Caption>
          </Flex>
        ))}
      </S.List>
    </S.Wrapper>
  )
}
