import { useQuery } from '@tanstack/react-query'
import { searchCompanies } from 'src/libs/api/backend/company_preferences'
import { queryKeys } from 'src/libs/query-keys'

interface Args {
  query?: string | null
}

export const companySuggestionQuery = ({ query }: Args) => {
  return {
    queryKey: [queryKeys.companySuggestions, query],
    queryFn: async () => await searchCompanies({ query }),
    staleTime: 60 * 1000,
    enabled: !!query
  }
}

export const useCompanySuggestion = ({ query }: Args) => {
  return useQuery(companySuggestionQuery({ query }))
}
