import { format, isWithinInterval, addMinutes, differenceInCalendarDays, isToday, isYesterday, isTomorrow, formatDistanceToNow, isFuture } from 'date-fns'
import { Flex } from 'src/components/primitives/flex'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { formatDate } from 'src/utils/format-date'
import { Button } from 'src/components/primitives/button'
import * as S from './elements.styled'
import { EmailMessageType } from 'src/libs/api/backend/candidate_activities'
import type { Attachment, CandidateNote, EmailMessage, EmailMessageClassification, MentionedTime } from 'src/libs/api/backend/candidate_activities'
import { groupBy, isNil } from 'lodash'
import { When } from '../when'
import type { ManualEmailMessage } from 'src/libs/api/backend/sequences'
import { useOrgUsersQuery } from 'src/hooks/queries/use-org-users'
import { UnsafeHTML } from 'src/components/primitives/unsafe-html/unsafe-html'
import { CalendarIcon } from '../calendar-icon'
import { Badge } from 'src/components/primitives/badge'
import type { CalendarEvent } from 'src/libs/api/backend/calendar_events'
import React, { useCallback, useMemo, useState } from 'react'
import type { PropsWithChildren } from 'react'
import { Icon, Icons } from '../../primitives/icon'
import { InboxCommentEditor } from '../inbox-comment-editor'
import { Tooltip } from 'src/components/primitives/tooltip'
import { useSession } from 'src/hooks/use-session'
import { FeatureFlags } from 'src/libs/api/backend/session'
import { DialogId } from 'src/contexts/dialogs'
import { useParams } from 'react-router'
import { useCandidateJobQuery } from 'src/hooks/queries/use-candidate-job'
import { downloadAttachment } from 'src/libs/api/backend/attachments'
import { useDialog } from 'src/hooks/use-dialog'
import { useDeleteCalendarEvent } from 'src/hooks/mutations/use-delete-calendar-event'
import { AttachmentList } from '../attachments-list'
import { useGetMentionedTimesAvailability } from 'src/hooks/queries/use-get-mentioned-times-availability'

interface EmailContentProps {
  subject?: string
  body?: string
  date?: string
  attachments?: Attachment[]
}

interface OutboundEmailProps {
  emailMessage: EmailMessage
  attachments?: Attachment[]
  onReply?: (emailMessageId: EmailMessage['id']) => void
  onForward?: (emailMessageId: EmailMessage['id']) => void
}

export interface MentionedTimesStyleProps {
  $variant: 'positive' | 'negative' | 'neutral'
}

export interface MentionedTimesProps {
  text: string
  mentionedTimes: MentionedTime[]
}

export const MentionedTimes = ({
  text,
  mentionedTimes
}: MentionedTimesProps): JSX.Element => {
  const { featureFlags } = useSession()
  const { openDialog } = useDialog()
  const { candidateJobId } = useParams()
  const { data: candidateJob } = useCandidateJobQuery({ candidateJobId })

  const { data, isLoading } = useGetMentionedTimesAvailability({
    startEndTimes: mentionedTimes.map(({ start, end }) => ({ start, end }))
  })

  if (isLoading) {
    return <>{text}</>
  }

  const $variant = isNil(data) ? 'negative' : 'positive'
  const tooltip = isNil(data) ? 'You are busy' : `You are available on ${format(data.start, 'EEEE, MMMM d, yyyy')} at ${format(data.start, 'h:mm a')}`

  return (
    <Tooltip
      trigger={
        <S.MentionedTimes
          $variant={$variant}
          disabled={!featureFlags?.includes(FeatureFlags.CALENDAR)}
          onClick={() => {
            if (featureFlags?.includes(FeatureFlags.CALENDAR) && mentionedTimes?.[0]?.start && mentionedTimes?.[0]?.end) {
              openDialog(DialogId.CALENDAR, {
                candidateJob,
                scheduleNewEvent: {
                  start: new Date(mentionedTimes?.[0]?.start),
                  end: new Date(mentionedTimes?.[0]?.end)
                }
              })
            }
          }}
        >
          {text}
        </S.MentionedTimes>
      }
    >
      {tooltip}
    </Tooltip>
  )
}

const EmailContent = ({
  subject,
  body,
  date,
  attachments
}: EmailContentProps): JSX.Element => {
  const triggerDownloadAttachment = useCallback(async (attachment: Attachment) => {
    const downloadUrl = await downloadAttachment(attachment.id)
    const link = document.createElement('a')
    link.href = downloadUrl
    link.setAttribute('download', attachment.fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }, [])

  return (
    <>
      <S.EmailHeader>
        <Flex $direction="column" $gap={4}>
          {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
          <Caption size="XS" $fontWeight={500}>{subject || '(No subject)'}</Caption>
        </Flex>
        {date && (
          <Paragraph size="XS" $whiteSpace="nowrap">
            {date}
          </Paragraph>
        )}
      </S.EmailHeader>
      <S.EmailBody>
        <Paragraph size="XS" $whiteSpace='pre-wrap' $color='fgPrimary' as='span'>
          <UnsafeHTML html={body ?? ''} />
        </Paragraph>
      </S.EmailBody>
      {attachments && attachments.length > 0 && (
        <AttachmentList
          attachments={attachments}
          onTriggerDownload={triggerDownloadAttachment}
        />
      )}
    </>
  )
}

const getEmailAddress = (addressString: string): string => {
  const emailAddressParts = addressString.match(/<(.*)>/)
  if (emailAddressParts) {
    return emailAddressParts[1]
  }
  return addressString
}

const EmailContainer = ({ children, isInbound, isSequenceEmail, ...message }: PropsWithChildren<{
  senderName?: string
  senderEmail?: string
  from?: string | null
  to?: string[] | null
  cc?: string[] | null
  bcc?: string[] | null
  isInbound?: boolean
  isSequenceEmail?: boolean
}>): JSX.Element => {
  const activityTitle = useMemo(() => {
    if (isInbound) {
      if (message.from) {
        return `Email received from ${message.from}`
      }
      return 'Email received'
    }

    if (isSequenceEmail) {
      if (message.senderName) {
        return `Sequence email sent from ${message.senderName} (${message.senderEmail})`
      }

      if (message.senderEmail) {
        return `Sequence email sent <${message.senderEmail}>`
      }

      return 'Sequence email sent'
    }

    if (message.senderName) {
      return `${message.senderName} sent an email <${message.senderEmail}>`
    }

    if (message.senderEmail) {
      return `Sent an email <${message.senderEmail}>`
    }

    return 'Sent an email'
  }, [isInbound, isSequenceEmail, message.senderName, message.senderEmail])

  const [isDetailsExpanded, setIsDetailsExpanded] = useState(false)

  return (
    <>
      <div style={{ paddingBottom: '0.75rem', paddingTop: '0.3rem' }}>
        <Description>
          {activityTitle}

          <S.EmailContainerExpandTitleButton onClick={() => { setIsDetailsExpanded(!isDetailsExpanded) }}>
            <Icon name="ellipsis" color="fgSecondary" size={12} />
          </S.EmailContainerExpandTitleButton>
        </Description>

        {isDetailsExpanded && (
          <>
            <Paragraph size="XS" $color="fgSecondary">From: {message.from}</Paragraph>
            <Paragraph size="XS" $color="fgSecondary">To: {message.to?.map(getEmailAddress).join(', ')}</Paragraph>
            {message.cc && message.cc.length > 0 && <Paragraph size="XS" $color="fgSecondary">Cc: {message.cc.map(getEmailAddress).join(', ')}</Paragraph>}
            {message.bcc && message.bcc.length > 0 && <Paragraph size="XS" $color="fgSecondary">Bcc: {message.bcc.map(getEmailAddress).join(', ')}</Paragraph>}
          </>
        )}
      </div>

      <S.Email>
        {children}
      </S.Email>
    </>
  )
}

interface ManualEmailProps {
  manualEmailMessage: ManualEmailMessage
  attachments?: Attachment[]
}

export const ManualEmail = ({
  manualEmailMessage,
  attachments
}: ManualEmailProps): JSX.Element => {
  const { data: orgUsers } = useOrgUsersQuery()
  const user = orgUsers?.find((orgUser) => orgUser.emailAccounts.find((emailAccount) => emailAccount.id === manualEmailMessage.sendingEmailAccountId))
  const sendingEmailAccount = user?.emailAccounts.find((emailAccount) => emailAccount.id === manualEmailMessage.sendingEmailAccountId)

  return (
    <EmailContainer
      senderName={user?.name}
      senderEmail={sendingEmailAccount?.email}
      cc={manualEmailMessage.cc}
      bcc={manualEmailMessage.bcc}
    >
      <EmailContent
        subject={manualEmailMessage.subject ?? undefined}
        body={manualEmailMessage.body}
        attachments={attachments}
        date="Sending"
      />
    </EmailContainer>
  )
}

export const OutboundEmail = ({ emailMessage, attachments, onReply }: OutboundEmailProps): JSX.Element => {
  const { data: orgUsers } = useOrgUsersQuery()
  const user = orgUsers?.find((orgUser) => orgUser.emailAccounts.find((emailAccount) => emailAccount.id === emailMessage.emailAccountId))
  const sendingEmailAccount = user?.emailAccounts.find((emailAccount) => emailAccount.id === emailMessage.emailAccountId)

  const mentionedTimes = emailMessage?.emailMessageClassifications?.[0]?.mentionedTimes
  const futureMentionedTimes = mentionedTimes?.filter(({ start, end }) => isFuture(start) && isFuture(end))
  const hasMentionedTimes = (futureMentionedTimes?.length ?? 0) >= 1

  const replaceMentionedTimes = (body: EmailMessage['strippedText'], mentionedTimes: EmailMessageClassification['mentionedTimes']): string | undefined => {
    if (!mentionedTimes) return body ?? undefined
    const groupedMentionedTimes = groupBy(mentionedTimes, 'matchingText')
    Object.keys(groupedMentionedTimes).forEach(matchingText => {
      const mentionedTimesComponent = `<mentionedtimes text="${matchingText}" mentionedTimes="${encodeURIComponent(JSON.stringify({ mentionedTimes: groupedMentionedTimes[matchingText] }))}"></mentionedtimes>`
      const regex = new RegExp(`(^|\\s)${matchingText}(?=\\s|$)`, 'g')
      body = body?.replace(regex, (match, p1) => {
        // Check if this occurrence has already been replaced
        if (match.includes('<mentionedtimes')) {
          return match // Skip replacement if already processed
        }
        return `${p1}${mentionedTimesComponent}`
      })
    })

    return body ?? undefined
  }

  const preparedBody = !hasMentionedTimes ? emailMessage.strippedText : replaceMentionedTimes(emailMessage.strippedText, futureMentionedTimes)

  return (
    <EmailContainer
      isInbound={emailMessage.type === EmailMessageType.RECEIVED}
      senderName={user?.name}
      senderEmail={sendingEmailAccount?.email}
      to={emailMessage.to}
      from={emailMessage.from}
      cc={emailMessage.cc}
      bcc={emailMessage.bcc}
    >
        <EmailContent
          subject={emailMessage.subject ?? undefined}
          body={preparedBody ?? undefined}
          date={formatDate(emailMessage.createdAt, { format: 'DaysPassed' })}
          attachments={attachments}
        />

        <When condition={!isNil(onReply)}>
          <S.InboundEmailActions>
            <Button
              $variant="raised"
              $colorTheme="muted"
              $height={24}
              $fontSize={12}
              leadingIcon="reply"
              onClick={() => {
                if (!isNil(onReply)) {
                  onReply(emailMessage.id)
                }
              }}
            >
              Reply
            </Button>
          </S.InboundEmailActions>
        </When>
    </EmailContainer>
  )
}

const InboundEmail = ({ emailMessage, onReply }: OutboundEmailProps): JSX.Element => {
  return (
    <EmailContainer
      isInbound={true}
      cc={emailMessage.cc}
      bcc={emailMessage.bcc}
    >
        <EmailContent
          subject={emailMessage.subject ?? undefined}
          body={emailMessage.strippedText ?? undefined}
          date={formatDate(emailMessage.createdAt, { format: 'DaysPassed' })}
        />

        <When condition={!isNil(onReply)}>
          <S.InboundEmailActions>
            <Button
              $variant="raised"
              $colorTheme="muted"
              $height={24}
              $fontSize={12}
              leadingIcon="reply"
              onClick={() => {
                if (!isNil(onReply)) {
                  onReply(emailMessage.id)
                }
              }}
            >
              Reply
            </Button>
          </S.InboundEmailActions>
        </When>
    </EmailContainer>
  )
}

export const EmailInboxActivity = ({ emailMessage, onReply }: OutboundEmailProps): JSX.Element => {
  if (emailMessage.candidateSequenceId != null || emailMessage.type === EmailMessageType.SENT) {
    return <OutboundEmail emailMessage={emailMessage} onReply={onReply} />
  }

  return <InboundEmail emailMessage={emailMessage} onReply={onReply} />
}

interface CommentProps {
  isEditable: boolean
  candidateNote: CandidateNote
  onCommentUpdate: (noteId: string, body: string) => void
}

export const Comment = ({ isEditable, candidateNote, onCommentUpdate }: CommentProps): JSX.Element => {
  const [isEditing, setIsEditing] = useState(false)
  const [newContent, setNewContent] = useState('')
  if (isEditing) {
    return (
      <InboxCommentEditor
        onDataChanged={(data) => {
          setNewContent(data)
        }}
        onCancel={() => {
          setIsEditing(false)
        }}
        onAddComment={() => {
          onCommentUpdate(candidateNote.id, newContent)
          setIsEditing(false)
        }}
        confirmText='Update comment'
        initialContent={candidateNote.body ?? ''}
      />
    )
  }

  return (
    <S.Comment $isEditable={isEditable}>
      <Paragraph size="XS" $whiteSpace='pre-wrap' $color="fgPrimary">
        <UnsafeHTML html={candidateNote.body ?? ''} />
      </Paragraph>
      <S.EditButton>
        <Button
          leadingIcon={Icons.pencil}
          $width={20}
          $height={20}
          $fontSize={12}
          $variant='raised-light'
          $colorTheme='muted'
          onClick={() => {
            setIsEditing(true)
          }}
        />
      </S.EditButton>
    </S.Comment>
  )
}

export const Description = ({ children }: { children: React.ReactNode }): JSX.Element => {
  return (
    <S.Description>
      <Paragraph size="XS">{children}</Paragraph>
    </S.Description>
  )
}

export const PositiveDescription = ({ children }: { children: React.ReactNode }): JSX.Element => {
  return (
    <S.Description>
      <Paragraph size="XS" $color='positiveFg'>{children}</Paragraph>
    </S.Description>
  )
}

export const ErrorDescription = ({ children }: { children: React.ReactNode }): JSX.Element => {
  return (
    <S.Description>
      <Paragraph size="XS" $color='negativeFg'>{children}</Paragraph>
    </S.Description>
  )
}

export interface CalendarEntryStyleProps {
  $variant?: 'card' | 'plain'
}

interface CalendarEntryProps extends CalendarEntryStyleProps {
  calendarEvent: CalendarEvent
  isSuggestedEvent?: boolean
}

export const CalendarEntry = ({
  calendarEvent,
  isSuggestedEvent = true,
  $variant = 'card'
}: CalendarEntryProps): JSX.Element => {
  const { openAlert } = useDialog()
  const { deleteEvent } = useDeleteCalendarEvent()

  const formatSlot = (slot: string | Date): string => {
    return format(slot, 'h.mma').toLowerCase()
  }

  const formatAttendee = (attendee: string): string => {
    const m = attendee.trim().match(/(.*)?<(.*)>/)
    return m ? m[1] ?? m[2] : ''
  }

  const getEventStatus = (): { type: 'UPCOMING' | 'PASSED' | 'TODAY' | 'FUTURE', text: string } | null => {
    const now = new Date()
    const thirtyMinutesFromNow = addMinutes(now, 30)
    const slotStartDate = calendarEvent.start
    const slotEndDate = calendarEvent.end

    // Upcoming events
    if (isWithinInterval(now, { start: slotStartDate, end: slotEndDate })) {
      return { type: 'UPCOMING', text: 'NOW' }
    } else if (isWithinInterval(slotStartDate, { start: now, end: thirtyMinutesFromNow })) {
      return { type: 'UPCOMING', text: 'STARTING SOON' }
    }

    // In the future
    if (slotStartDate > now) {
      if (isToday(slotStartDate)) {
        return { type: 'TODAY', text: 'Today' }
      } else if (isTomorrow(slotStartDate)) {
        return { type: 'FUTURE', text: 'Tomorrow' }
      } else {
        const description = formatDistanceToNow(slotStartDate, { addSuffix: true })
        return { type: 'FUTURE', text: description }
      }
    }

    // In the past
    if (now > slotEndDate) {
      if (isToday(slotEndDate)) {
        return { type: 'PASSED', text: 'Earlier today' }
      } else if (isYesterday(slotEndDate)) {
        return { type: 'PASSED', text: 'Yesterday' }
      } else {
        const daysAgo = differenceInCalendarDays(now, slotEndDate)
        return { type: 'PASSED', text: `${daysAgo} days ago` }
      }
    }
    return null
  }

  const eventStatus = getEventStatus()
  const hasBadge = eventStatus && eventStatus.type === 'UPCOMING'
  const isFutureEvent = eventStatus && eventStatus.type === 'FUTURE'
  const attendees = calendarEvent.attendees as string[]

  return (
    <S.CalendarEntry $isFutureEvent={Boolean(isFutureEvent)} $variant={$variant}>
      <CalendarIcon timestamp={calendarEvent.start} />
      <Flex $direction="column">
        <Flex $direction="row">
          <Flex $align="flex-start" $gap={12} $width="75%">
            <Flex $direction="column" $gap={0}>
              <Flex $gap={6}>
                <S.CalendarEntryTitle>
                  {calendarEvent.title ?? ''}
                </S.CalendarEntryTitle>
                {!isSuggestedEvent && hasBadge && (
                  <Badge $variant="positiveLight">{eventStatus.text}</Badge>
                )}
              </Flex>
              <Flex $align="center" $gap={12} $width="auto">
                <Flex $direction="column" $width="auto">
                  <S.CalendarEntryTime>
                    <span>{formatSlot(calendarEvent.start)} – {formatSlot(calendarEvent.end)}</span>
                  </S.CalendarEntryTime>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          {
            !isSuggestedEvent && (
              <Flex
                $align={hasBadge ? 'center' : 'flex-start'}
                $justify="flex-end"
                $width="25%"
                $height="full"
              >
                {hasBadge && calendarEvent.meetingLink
                  ? (
                    <Button
                      $variant="fill"
                      $colorTheme="tint"
                      $height={24}
                      $fontSize={12}
                      leadingIcon="video"
                      href={calendarEvent.meetingLink}
                    >
                      Join
                    </Button>
                  )
                  : (
                    <S.CalenderEntryTimestamp>
                      <Description>
                        {eventStatus?.text}
                      </Description>
                    </S.CalenderEntryTimestamp>
                  )
                }
              </Flex>
            )
          }
        </Flex>
        <Flex $justify="space-between">
          <S.CalendarEntryAttendees>
            {attendees?.map(attendee => (<span>{formatAttendee(attendee)}</span>))}
          </S.CalendarEntryAttendees>
          {
            !isSuggestedEvent && (
              <Button
                leadingIcon="trash"
                ariaLabel="Delete scheduled event"
                $width={16}
                $height={16}
                $variant="ghost"
                $colorTheme="muted"
                $fontSize={10}
                onClick={() => {
                  openAlert({
                    message: 'Are you sure you want to delete this event?',
                    description: 'This action can not be reversed',
                    cancelText: 'Cancel',
                    confirmText: 'Delete',
                    onConfirm: () => {
                      if (calendarEvent.id) {
                        deleteEvent({ id: calendarEvent.id })
                      }
                    }
                  })
                }}
              />
            )
          }
        </Flex>
      </Flex>
    </S.CalendarEntry>
  )
}
