import { useMutation } from '@tanstack/react-query'
import { rateCompanyPreference } from 'src/libs/api/backend/company_preferences'
import type { CompanyPreference, CompanyPreferenceType } from 'src/libs/api/backend/company_preferences'
import { useNotification } from '../use-notification'
import queryClient from '../query-client'
import { queryKeys } from 'src/libs/query-keys'
import { Icons } from 'src/components/primitives/icon'
import { useParams } from 'react-router'

interface Args {
  id?: string
  jobId: string
  departmentId?: string
  type: CompanyPreferenceType
  name: string
  linkedin: string
}

interface Res {
  setRateCompanyPreference: ({ jobId, departmentId, type, linkedin }: Args) => void
}

export const useRateCompanyPreference = (): Res => {
  const { notify } = useNotification()
  const { jobId } = useParams()

  const mutation = useMutation({
    mutationFn: async ({ jobId, departmentId, type, name, linkedin }: Args) => {
      return await rateCompanyPreference({ jobId, departmentId, type, name, linkedin })
    },
    onError: (err) => {
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-right',
        icon: Icons.xOctagon,
        message: `An error occurred when rating this company: ${err.message}`
      })
    },
    onSuccess: async (companyPreference: CompanyPreference, variables) => {
      queryClient.setQueriesData<CompanyPreference[]>(
        {
          queryKey: [queryKeys.companyPreferences, jobId]
        },
        (oldCompanyPreferences) => {
          if (!oldCompanyPreferences) {
            return oldCompanyPreferences
          }
          if (variables.id) {
            return oldCompanyPreferences.map((oldCompanyPreference) =>
              oldCompanyPreference.id === variables.id ? companyPreference : oldCompanyPreference
            )
          }
          return [...oldCompanyPreferences, companyPreference]
        }
      )
    }
  })

  const setRateCompanyPreference = (args: Args): void => {
    mutation.mutate(args)
  }

  return { setRateCompanyPreference }
}
