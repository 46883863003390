import { pluralize } from 'src/libs/pluralize'
import { differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns'

type Format = 'Month D, Year' | 'Year' | 'DaysPassed' | 'DaysPassedShort' | 'TimePassed'

interface Options {
  format?: Format
}

export const formatDate = (timestamp: string | Date, options: Options = { format: 'Month D, Year' }): string | undefined => {
  const MONTHS_FULL_NAME = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  if (options.format === 'Month D, Year') {
    const date = new Date(timestamp)
    const year = date.getFullYear()
    const month = MONTHS_FULL_NAME[date.getMonth()]
    const day = date.getDate()

    return `${month} ${day}, ${year}`
  }

  if (options.format === 'Year') {
    const date = new Date(timestamp)
    const year = date.getFullYear()
    return '' + year
  }

  if (options.format?.startsWith('DaysPassed')) {
    const today = new Date()
    const pastDate = new Date(timestamp)

    const days = differenceInDays(today, pastDate)

    if (options.format.endsWith('Short')) {
      return `${days}d`
    }

    if (days === 0) {
      return 'Today'
    }

    if (days <= -1) {
      if (days === -1) {
        return 'Tomorrow'
      }

      if (days > 5) {
        // return day of week
        const dayOfWeek = new Date(timestamp).toLocaleDateString('en-US', { weekday: 'long' })
        return `${dayOfWeek}`
      }

      return `${pluralize(-days, 'day')} from now`
    }

    return `${pluralize(days, 'day')} ago`
  }

  if (options.format?.startsWith('TimePassed')) {
    const today = new Date()
    const pastDate = new Date(timestamp)

    const hours = differenceInHours(today, pastDate)
    const minutes = differenceInMinutes(today, pastDate)
    const days = differenceInDays(today, pastDate)

    if (hours <= 0) {
      return `${pluralize(minutes, 'minute')} ago`
    }

    if (hours >= 24) {
      return `${pluralize(days, 'day')} ago`
    }

    return `${pluralize(hours, 'hour')} ago`
  }
}
