import { useEffect, useRef } from 'react'
import type { HTMLProps } from 'react'
import { Icon } from '../icon'
import * as S from './table.styled'

export const IndeterminateCheckbox = ({
  indeterminate, onClick, ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>): JSX.Element => {
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      if (ref.current) {
        ref.current.indeterminate = !rest.checked && indeterminate
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, indeterminate])

  return (
    <S.Checkbox onClick={onClick}>
      <input type="checkbox" ref={ref} {...rest} id="tr-checkbox" />
      <label htmlFor="tr-checkbox" />
      <S.CheckboxIcon>
        <Icon name="check" color="bgPrimary" size={12} />
        <Icon name="minus" color="bgPrimary" size={12} />
      </S.CheckboxIcon>
    </S.Checkbox>
  )
}
