import { isNil } from 'lodash'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router'
import { DialogId } from 'src/contexts/dialogs'
import { useArchiveJob } from 'src/hooks/mutations/use-archive-job'
import { useDialog } from 'src/hooks/use-dialog'
import { useNotification } from 'src/hooks/use-notification'
import type { Job } from 'src/libs/api/backend/jobs'
import RouteBuilder from 'src/libs/route-builder'
import * as Dialog from 'src/components/primitives/dialog'
import * as S from './archive-job-dialog.styled'
import { Icon, Icons } from 'src/components/primitives/icon'
import { Caption } from 'src/components/primitives/typography'
import { Button, Flex } from 'src/components/primitives'
import { Dropdown } from 'src/components/primitives/dropdown'

enum ArchiveReason {
  HIRED_SOMEONE_FROM_PIN = 'Hired someone using Pin',
  HIRED_SOMEONE_OUTSIDE_PIN = 'Hired someone outside Pin',
  NOT_ENOUGH_CANDIDATES = 'Not enough candidates',
  LOW_QUALITY_CANDIDATES = 'Low quality candidates',
  ECONOMIC_REASONS = 'Economic reasons',
  POSITION_NO_LONGER_NEEDED= 'Position is no longer needed',
  OTHER = 'Other'
}

export const ArchiveJobDialog = (): JSX.Element => {
  const { notify } = useNotification()
  const navigate = useNavigate()
  const { archiveJob } = useArchiveJob()
  const { getPayload, isDialogOpen, controlDialog } = useDialog()
  const [reason, setReason] = useState<ArchiveReason | null>(null)
  const job = getPayload(DialogId.ARCHIVE_JOB).job
  const onArchiveSuccess = useCallback((job: Job): void => {
    controlDialog(DialogId.ARCHIVE_JOB, false)
    notify({
      type: 'toast',
      variant: 'positive',
      position: 'bottom-right',
      icon: 'check-check',
      message: `${job.title} job archived`,
      autoClose: true
    })

    navigate(RouteBuilder.build('ROOT'))
  }, [controlDialog, navigate, notify])
  return (
    <Dialog.Root
      id={DialogId.ARCHIVE_JOB}
      isOpen={isDialogOpen(DialogId.ARCHIVE_JOB)}
      onOpenChange={(value) => {
        controlDialog(DialogId.ARCHIVE_JOB, value)
      }}
      $maxWidth='640px'
    >
      <Dialog.Portal>
        <Dialog.Header
          title='Archive Job Position'
          onClose={() => {
            controlDialog(DialogId.ARCHIVE_JOB, false)
          }}
        />
        <Dialog.Content>
          <Flex $direction='column' $gap={24}>
            <S.Banner>
              <Icon name={Icons.circleAlert} size={12} />
              <Caption size='SM'>Archiving this position will stop all sequence emails and archive all candidates.</Caption>
            </S.Banner>
            <Flex $direction='column' $gap={8}>
              <Caption size='SM'>Why are you archiving this position?</Caption>
              <Flex $align='center'>
                <Dropdown
                  triggerFullWidth
                  trigger={
                    <Button
                      nested
                      $variant='outline'
                      $colorTheme={reason ? 'normal' : 'muted'}
                      $align='space-between'
                      trailingIcon={Icons.chevronsUpDownSmall}
                      $width='full'
                    >
                      {reason ?? 'Choose option'}
                    </Button>
                  }
                  items={Object.values(ArchiveReason).map((reason) => ({
                    title: reason,
                    id: reason,
                    onSelect: () => {
                      setReason(reason)
                    }
                  }))}
                />
              </Flex>
            </Flex>
          </Flex>
        </Dialog.Content>
        <Dialog.Actions $justify='space-between'>
          <Button
            onClick={() => {
              controlDialog(DialogId.ARCHIVE_JOB, false)
            }}
            $height={40}
          >
            Don’t archive
          </Button>
          <Button
            $height={40}
            $variant='fill'
            $colorTheme='negative'
            disabled={isNil(reason)}
            onClick={() => {
              if (isNil(job) || isNil(reason)) {
                return
              }
              archiveJob({ jobId: job.id, reason, onSuccess: onArchiveSuccess })
            }}
          >
            Archive position
          </Button>
        </Dialog.Actions>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
