import { useParams, useSearchParams } from 'react-router-dom'
import { Item } from './item'
import { useCandidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'
import { CandidateJobStage } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { findIndex, isNil } from 'lodash'
import { formatDate } from 'src/utils/format-date'
import { Icon } from 'src/components/primitives/icon'
import { Caption } from 'src/components/primitives/typography'
import { useEffect, useMemo, useRef, useState } from 'react'
// import { LoadingSkeleton } from '../loading-skeleton'
import { When } from '../when'
import * as S from './inbox-sidebar.styled'
import { useVirtualizer } from '@tanstack/react-virtual'
import { SIDEBAR_PADDING, SIDEBAR_PADDING_COLLAPSED } from 'src/styles/constants'
import { useCandidateJobQuery } from 'src/hooks/queries/use-candidate-job'
import { Spacer } from 'src/components/primitives/spacer'
import { Flex } from 'src/components/primitives/flex'
import { Button } from 'src/components/primitives/button'

interface InboxSidebarProps {
  isExpanded: boolean
  toggleSidebarPinned: () => void
  isPinned: boolean
  setIsHovered: (value: boolean) => void
  isHovered: boolean
}

export const InboxSidebar = ({
  isExpanded = true,
  toggleSidebarPinned,
  isPinned,
  setIsHovered,
  isHovered
}: InboxSidebarProps): JSX.Element => {
  const { candidateJobId } = useParams()
  const [searchParams] = useSearchParams()
  const stage = searchParams.get('stage')
  const candidateJobStage = stage as CandidateJobStage
  const lastCommunicatedWithUserId = searchParams.get('lastCommunicatedWithUserId')

  const [candidateJobs, setCandidateJobs] = useState<CandidateJobExpanded[] | null>(null)
  const [firstScroll, setFirstScroll] = useState(false)

  const { data: candidateJobData } = useCandidateJobQuery({
    candidateJobId
  })

  const currentStage = useMemo(() => {
    if (stage === 'null') {
      return undefined
    }
    if (Object.values(CandidateJobStage).includes(candidateJobStage)) {
      return candidateJobStage
    } else {
      return CandidateJobStage.COMMUNICATING
    }
  }, [candidateJobStage, stage])

  const { isPending, data: candidateJobsData } = useCandidateJobsQuery({
    stage: currentStage,
    lastCommunicatedWithUserId: lastCommunicatedWithUserId ?? undefined
  })
  const parentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const sidebarElm = parentRef.current
    if (!sidebarElm) {
      return
    }

    const onMouseMove = (evt: MouseEvent): void => {
      const sidebarBoundingBox = sidebarElm.getBoundingClientRect()

      const isCurrentlyHovered =
        evt.pageX >= sidebarBoundingBox.left &&
        evt.pageX <= sidebarBoundingBox.right &&
        evt.pageY >= sidebarBoundingBox.top &&
        evt.pageY <= sidebarBoundingBox.bottom

      if (isCurrentlyHovered !== isHovered) {
        setIsHovered(isCurrentlyHovered)
      }
    }

    document.addEventListener('mousemove', onMouseMove)
    return () => {
      document.removeEventListener('mousemove', onMouseMove)
    }
  }, [isHovered, setIsHovered, isExpanded])

  useEffect(() => {
    if (!isNil(candidateJobsData)) {
      if (!isNil(candidateJobData)) {
        const index = findIndex(candidateJobsData, { id: candidateJobData.id })
        if (index < 0) {
          setCandidateJobs([candidateJobData, ...candidateJobsData])
          return
        }
      }
      setCandidateJobs(candidateJobsData)
    }
  }, [candidateJobsData, candidateJobData])

  const virtualizer = useVirtualizer({
    count: candidateJobs?.length ?? 0,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 70
  })

  // scrolls automatically to element when a direct link has been used like "view in inbox"
  useEffect(() => {
    if (!isNil(candidateJobs) && !firstScroll) {
      const index = findIndex(candidateJobs, { id: candidateJobId })
      if (index >= 0) {
        setTimeout(() => {
          virtualizer.scrollToIndex(index, {
            align: 'start'
          })
        })
        setFirstScroll(true)
      }
    }
  }, [candidateJobs, candidateJobId, virtualizer, firstScroll])

  // loading
  if (isPending) {
    // return <LoadingSkeleton $variant="InboxSidebar" />
    return <></>
  }

  // empty state
  if (!isPending && (isNil(candidateJobs) || !candidateJobs.length)) {
    return (
      <div
        style={{
          padding: isExpanded ? `0 ${SIDEBAR_PADDING}` : `0 ${SIDEBAR_PADDING_COLLAPSED}`,
          height: '100%'
        }}
      >
        <S.Empty>
          <Icon
            name={isExpanded ? 'messages-square' : 'x-octagon'}
            size={isExpanded ? 40 : 20}
            color="fgTranslucent10"
          />
          <When condition={isExpanded}>
            <Caption size="SM" $color="fgSecondary" $align="center">
              No conversations to display
            </Caption>
          </When>
        </S.Empty>
      </div>
    )
  }

  return (
    <S.InboxSidebar $isHovered={isHovered} $isPinned={isPinned} ref={parentRef}>
      <S.Items $isExpanded={isExpanded}>
        <Flex $align="center" $justify={isExpanded ? 'space-between' : 'center'}>
          <When condition={isExpanded}>
            <Caption size="MD">Inbox</Caption>
          </When>
          <Button
            ariaLabel="Collapse navigation bar"
            leadingIcon={isPinned ? 'chevrons-left-thin' : 'chevrons-right-thin'}
            $variant="ghost"
            $colorTheme="muted"
            $height={24}
            $width={24}
            onClick={toggleSidebarPinned}
            $borderRadius={9999}
          />
        </Flex>
        <Spacer $size={10} />
        <div
          style={{
            height: virtualizer.getTotalSize(),
            position: 'relative',
            width: '100%',
            scrollbarWidth: isExpanded ? 'thin' : 'none',
            overflowY: 'auto',
            overflowX: 'hidden'
          }}
        >
          {virtualizer.getVirtualItems().map((virtualRow) => {
            const candidateJob = candidateJobs?.[virtualRow.index]
            if (isNil(candidateJob)) {
              return null
            }
            let timePassed = '∞'
            if (!isNil(candidateJob.candidate.lastCommunicatedAt)) {
              timePassed =
                formatDate(candidateJob.candidate.lastCommunicatedAt, {
                  format: 'DaysPassedShort'
                }) ?? timePassed
            }

            const latestExperience = candidateJob.candidate.experiences?.[0]

            return (
              <div
                style={{
                  width: 'calc(100% - 2px)',
                  position: 'absolute',
                  top: `${virtualRow.start}px`
                }}
                key={virtualRow.key}
                data-index={virtualRow.index}
                ref={virtualizer.measureElement}
              >
                <Item
                  key={candidateJob.candidateId}
                  candidateJob={candidateJob}
                  timePassed={timePassed}
                  company={latestExperience?.company || ''}
                  companyWebsite={latestExperience?.companyInformation?.website}
                  companyLogoUrl={latestExperience?.logoUrl}
                  isExpanded={isExpanded}
                />
              </div>
            )
          })}
        </div>
      </S.Items>
    </S.InboxSidebar>
  )
}
