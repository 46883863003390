import { useMutation } from '@tanstack/react-query'
import { useNotification } from '../use-notification'
import { updateCandidateNote as updateCandidateNoteApi } from 'src/libs/api/backend/candidate_activities'

interface Args {
  candidateId: string
  noteId: string
  body: string
  onSuccess?: () => void
  onError?: () => void
}

interface Res {
  updateCandidateNote: (args: Args) => void
}

export const useUpdateCandidateNote = (): Res => {
  const { notify } = useNotification()

  const mutation = useMutation({
    mutationFn: async ({ candidateId, noteId, body }: Args) => await updateCandidateNoteApi(candidateId, noteId, body),
    onError: (err, variables) => {
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: `An error occurred while updating a note on your candidate: ${err.message}`
      })
      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (_, variables) => {
      if (variables.onSuccess) {
        variables.onSuccess()
      }
    }
  })

  const updateCandidateNote = (args: Args): void => {
    mutation.mutate(args)
  }

  return { updateCandidateNote }
}
