import { Logo } from 'src/components/primitives/logo'
import * as S from './trial-expired.styled'
import { Dropdown } from 'src/components/primitives/dropdown'
import { Avatar } from 'src/components/primitives/avatar'
import CONFIG from 'src/config'
import { Button } from 'src/components/primitives'
import { useMemo, useState } from 'react'
import { useSession } from 'src/hooks/queries/use-session'
import { isNil } from 'lodash'
import { SubscriptionSettings } from '../subscription-settings'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { ClosePinAccountFeedback } from '../close-pin-account-feedback'

export const TrialExpired = (): JSX.Element => {
  const { data: sessionContext } = useSession()
  const [closeAccount, setCloseAccount] = useState(false)
  const primaryEmail = useMemo(() => {
    const { emailAccountAccessTokens } = sessionContext ?? {}
    return emailAccountAccessTokens?.find(account => account.isPrimary) ?? emailAccountAccessTokens?.[0]
  }, [sessionContext])

  return (
    <S.Page>
      <S.Banner>
        <Logo variant="dark" size={64} />
        <Dropdown
          trigger={
            <S.UserProfileButton>
              <Avatar
                $shape="circle"
                $type="photo"
                $size={24}
                initials={sessionContext?.user?.name}
                photoUrl={sessionContext?.user?.profilePhoto}
                ariaLabel="Toggle account menu"
              />
            </S.UserProfileButton>
          }
          menuPosition="start"
          items={[
            {
              id: 'account',
              title: sessionContext?.user?.name ?? '',
              subTitle: primaryEmail?.email ?? '',
              isSelectable: false
            },
            {
              id: 'account-separator',
              title: 'AccountSeparator',
              type: 'separator'
            },
            {
              id: 'logout',
              title: 'Log out',
              variant: 'negative',
              icon: 'log-out',
              href: `${CONFIG.API_DOMAIN}/auth/logout?redirect=${CONFIG.APP_DOMAIN}`
            }
          ]}
        />
      </S.Banner>
      <S.Body>
        <S.TrialEndedNotificationContainer>
          { closeAccount
            ? (
                <S.TrialCloseAccountBox>
                  <ClosePinAccountFeedback
                    onClose={() => {
                      setCloseAccount(false)
                    }}
                  />
                </S.TrialCloseAccountBox>
              )
            : (
                <S.TrialEndedBox>
                  <SubscriptionSettings
                    notificationBanner={
                      <>
                        <Caption size='SM'>Your free trial period has ended.</Caption>
                        <Paragraph size='SM'>Subscribe below to continue using Pin.</Paragraph>
                      </>
                    }
                    onCloseAccount={setCloseAccount}
                />
              </S.TrialEndedBox>
              )
          }
        </S.TrialEndedNotificationContainer>
        <Button
          $variant='ghost'
          $colorTheme='tint'
          $height={40}
          $fontSize={14}
          onClick={() => {
            if (!isNil(sessionContext)) {
              // @ts-expect-error Intercom is loaded in the window
              window.Intercom('boot', {
                api_base: 'https://api-iam.intercom.io',
                app_id: 'f1g33stu',
                user_id: sessionContext.user.id,
                user_hash: sessionContext.intercomUserHash,
                name: sessionContext.user.name,
                email: primaryEmail?.email,
                company: {
                  company_id: sessionContext.org?.id,
                  name: sessionContext.org?.name,
                  website: sessionContext.org?.domain
                }
              })
            }
          }}
        >
          Contact Pin
        </Button>
      </S.Body>
    </S.Page>
  )
}
