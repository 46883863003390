import { z } from 'zod'
import Api, { ApiError } from '../'
import type { CandidateJob } from './candidate_jobs'

const buildCustomOutreachParser = z.object({
  emailMessageContent: z.object({
    subject: z.string(),
    body: z.object({
      html: z.string(),
      text: z.string()
    })
  }),
  sendingEmailAccountId: z.string().uuid()
})

export type BuildCustomOutreach = z.infer<typeof buildCustomOutreachParser>

export interface BuildCustomOutreachParams {
  candidateJobId: CandidateJob['id']
}

export async function buildCustomOutreach ({
  candidateJobId
}: BuildCustomOutreachParams): Promise<BuildCustomOutreach> {
  const { data } = await Api.post(`/candidate_jobs/${candidateJobId}/custom_outreach/build`)
  return buildCustomOutreachParser.parse(data)
}

const emailContentSerializer = z.object({
  subject: z.string(),
  body: z.string()
})

export type CustomOutreachEmail = z.infer<typeof emailContentSerializer>

const sendCustomOutreachBodySerializer = z.object({
  candidateJobId: z.string().uuid(),
  emailContent: emailContentSerializer
})

export type SendCustomOutreachBody = z.infer<typeof sendCustomOutreachBodySerializer>

export interface SendCustomOutreachParams {
  candidateJobId: CandidateJob['id']
  emailContent: CustomOutreachEmail
}

export async function sendCustomOutreach ({
  candidateJobId,
  emailContent
}: SendCustomOutreachParams): Promise<void> {
  const res = await Api.post(`/candidate_jobs/${candidateJobId}/custom_outreach/send`, null, {
    emailContent
  })
  if (res.status !== 200) {
    throw new ApiError(`${res.status} - Error while attempting to send custom outreach message`)
  }
}
