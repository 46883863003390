import * as Dialog from 'src/components/primitives/dialog'
import { AddCandidateForm } from 'src/components/blocks/add-candidate-form'
import { useDialog } from 'src/hooks/use-dialog'
import { DialogId } from 'src/contexts/dialogs'

export const AddCandidateDialog = (): JSX.Element => {
  const { isDialogOpen, controlDialog } = useDialog()

  return (
    <Dialog.Root
      id={DialogId.ADD_CANDIDATE}
      isOpen={isDialogOpen(DialogId.ADD_CANDIDATE)}
      onOpenChange={(value) => { controlDialog(DialogId.ADD_CANDIDATE, value) }}
      $maxWidth="600px"
    >
      <Dialog.Portal>
        <Dialog.Header
          title="Add New Candidates"
          onClose={() => { controlDialog(DialogId.ADD_CANDIDATE, false) }}
        />
        <Dialog.Content>
          <AddCandidateForm />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
