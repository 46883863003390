import { isNil } from 'lodash'
import { AvatarsList } from 'src/components/blocks/avatars-list'
import { Select } from 'src/components/forms/select'
import type { SelectItem } from 'src/components/forms/select'
import { Flex } from 'src/components/primitives'
import { Avatar } from 'src/components/primitives/avatar/avatar'
import { Button } from 'src/components/primitives/button/button'
import * as Dialog from 'src/components/primitives/dialog'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { DialogId } from 'src/contexts/dialogs'
import { useExportCandidateToAts } from 'src/hooks/mutations/use-export-candidate-to-ats'
import { useDialog } from 'src/hooks/use-dialog'
import { useForm } from 'src/hooks/use-form'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { z } from 'zod'
import * as S from './export-to-ats-dialog.styled'
import type { Job } from 'src/libs/api/backend/jobs'
import { useGetMergeJobs } from 'src/hooks/queries/use-get-merge-jobs'
import { useMemo } from 'react'
import { useLinkJobWithMergeJob } from 'src/hooks/mutations/use-link-job-with-merge-job'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { When } from 'src/components/blocks/when'

const exportToAtsScchema = z.object({
  mergeJobId: z.string().nullish()
})

export type ExportToAtsSchema = z.infer<typeof exportToAtsScchema>

interface ExportToAtsDialogProps {
  job?: Job
  candidateJobs: CandidateJobExpanded[]
}

export const ExportToAtsDialog = ({
  job = undefined,
  candidateJobs
}: ExportToAtsDialogProps): JSX.Element => {
  const { isDialogOpen, controlDialog } = useDialog()
  const { exportCandidates } = useExportCandidateToAts()
  const { linkJobWithMergeAtsJob } = useLinkJobWithMergeJob()

  const { data: jobData, refetch: refetchJob } = useJobQuery({ enabled: isNil(job) })
  if (isNil(job)) {
    job = jobData
  }

  const { mergeJobs } = useGetMergeJobs()
  const mergeJobsMap = useMemo(() => {
    const mergeJobMap = new Map<string, SelectItem>()
    for (const mergeJob of mergeJobs) {
      mergeJobMap.set(mergeJob.id, {
        value: mergeJob.id,
        title: mergeJob.name
      })
    }

    return mergeJobMap
  }, [mergeJobs])

  const mergeJobsList = useMemo(() => {
    return [...mergeJobsMap.values()]
  }, [mergeJobsMap])

  const defaultSelectedJob = useMemo(() => {
    return !isNil(job) && !isNil(job.mergeAtsJobId)
      ? mergeJobsMap.get(job.mergeAtsJobId)?.value
      : undefined
  }, [mergeJobsMap, job])

  const { register, formData } = useForm<ExportToAtsSchema>({
    schema: exportToAtsScchema
  })

  const candidateIds = candidateJobs.map((candidateJob) => candidateJob.candidate.id)
  const isPinJobLinkedToAts = !isNil(job?.mergeAtsJobId)

  const renderCandidates = (): JSX.Element => {
    return (
      <>
        <When condition={candidateJobs.length === 1}>
          <Flex
            $direction='row'
            $align='center'
            $gap={16}
          >
            <Avatar
              $size={48}
              $border={false}
              $type='photo'
              photoUrl={candidateJobs[0].candidate.profilePhotoUrl}
            />
            <Flex
              $gap={4}
              $direction='column'
            >
              <Caption $fontSize={32} $lineHeight={1.5}>{candidateJobs[0].candidate.name}</Caption>
              <Paragraph>
                {candidateJobs[0].candidate.experiences?.at(0)?.title} at {candidateJobs[0].candidate.experiences?.at(0)?.company}
              </Paragraph>
            </Flex>
          </Flex>
        </When>
        <When condition={candidateJobs.length > 1}>
          <Flex
            $direction='row'
            $justify='flex-start'
            $align='center'
            $gap={16}
          >
            <Flex
              $direction='row'
              $align='flex-start'
              $justify='flex-start'
              $width='none'
            >
              <AvatarsList
                avatars={candidateJobs.map((candidateJob) => ({
                  id: candidateJob.candidate.id,
                  name: candidateJob.candidate.name,
                  profilePhotoUrl: candidateJob.candidate.profilePhotoUrl
                }))}
                $border
                $size={20}
                $overlap={2}
                avatarDisplay={{ showPlus: false, count: candidateJobs.length }}
              />
            </Flex>
            <Paragraph>{candidateJobs.length} candidates selected</Paragraph>
          </Flex>
        </When>
      </>
    )
  }

  return (
    <Dialog.Root
      id={DialogId.EXPORT_CANDIDATES}
      isOpen={isDialogOpen(DialogId.EXPORT_CANDIDATES)}
      onOpenChange={() => {
        controlDialog(DialogId.EXPORT_CANDIDATES, false)
      }}
      $width='half'
      $maxWidth="640px"
    >
      <Dialog.Portal>
        <Dialog.Header
          title="Export candidate to ATS"
        />
        <Dialog.Content>
          <Flex
            $width='100%'
            $direction="column"
            $gap={24}
          >
            {renderCandidates()}
            <S.SelectiionForm>
              <Select
                label='ATS job position'
                name="mergeJobId"
                placeholder="Select a job"
                $marginBottom={0}
                defaultValue={defaultSelectedJob}
                items={mergeJobsList}
                register={register}
              />
            </S.SelectiionForm>
          </Flex>
        </Dialog.Content>
        <Dialog.Actions
          $justify={!isPinJobLinkedToAts ? 'space-between' : 'flex-start'}
          divider={!isPinJobLinkedToAts}
        >
          <Flex $justify="space-between">
            <Button
              $variant="raised"
              $colorTheme="normal"
              $height={48}
              onClick={() => {
                controlDialog(DialogId.EXPORT_CANDIDATES, false)
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={isNil(formData.mergeJobId) || isNil(job)}
              $variant="fill"
              $colorTheme="tint"
              $height={48}
              onClick={() => {
                if (isNil(job)) {
                  return
                }

                linkJobWithMergeAtsJob({
                  jobId: job.id,
                  mergeAtsJobId: formData.mergeJobId as string,
                  onSuccess: () => {
                    if (isNil(job)) {
                      return
                    }

                    exportCandidates({
                      jobId: job.id,
                      candidateIds,
                      onSuccess: () => {
                        void refetchJob()
                        controlDialog(DialogId.EXPORT_CANDIDATES, false)
                      }
                    })
                  }
                })
              }}
            >
              Export
            </Button>
          </Flex>
        </Dialog.Actions>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
