import { useState } from 'react'
import { Form, Input } from 'src/components/forms'
import { useForm } from 'src/hooks/use-form'
import { useDialog } from 'src/hooks/use-dialog'
import { z } from 'zod'
import { DialogId } from 'src/contexts/dialogs'
import { useCreateDepartment } from 'src/hooks/mutations/use-create-department'
import type { Department } from 'src/libs/api/backend/departments'
import { FormActionButtons } from '../form-action-buttons'
import { REGEX_DOMAIN } from 'src/libs/regex'
import { useUpdateDepartment } from 'src/hooks/mutations/use-update-department'

const createCompanyFormSchema = z.object({
  name: z.string(),
  domain: z.string().refine(value => REGEX_DOMAIN.test(value), {
    message: 'Please enter a valid domain'
  })
})

export type CreateCompanyFormForm = z.infer<typeof createCompanyFormSchema>

interface CreateCompanyFormProps {
  defaultName?: string
  defaultDomain?: string
  isUpdating?: boolean
}

export const CreateCompanyForm = ({ defaultName, defaultDomain, isUpdating }: CreateCompanyFormProps): JSX.Element => {
  const { createDepartmentByOrg } = useCreateDepartment()
  const { updateDepartmentByOrg } = useUpdateDepartment()

  const { closeDialog, getPayload } = useDialog()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const { submit, register, setValue, isValid, formData } = useForm<CreateCompanyFormForm>({
    schema: createCompanyFormSchema,
    initialValues: {
      name: defaultName,
      domain: defaultDomain
    }
  })

  const handleSubmit = async (formData: CreateCompanyFormForm): Promise<void> => {
    setIsSubmitting(true)
    if (isUpdating) {
      const id = getPayload(DialogId.EDIT_DEPARTMENT).departmentId
      updateDepartmentByOrg({
        id,
        name: formData.name,
        onSuccess: () => {
          setIsSubmitting(false)
        }
      })
      closeDialog(DialogId.EDIT_DEPARTMENT)
    } else {
      const { setDepartmentId } = getPayload(DialogId.CREATE_DEPARTMENT)
      createDepartmentByOrg({
        name: formData.name,
        domain: formData.domain,
        onSuccess: (newDept: Department) => {
          setDepartmentId(newDept.id)
          setIsSubmitting(false)
          closeDialog(DialogId.CREATE_DEPARTMENT)
        },
        onError: () => {
          setSubmitDisabled(true)
        }
      })
    }
  }

  return (
    <Form onSubmit={submit(handleSubmit)}>
      <Input name="name" placeholder="" label="Company name" register={register} />
      <Input
        name="domain"
        type='text'
        placeholder=""
        label="Company domain"
        register={register}
        $marginBottom={0}
        isDisabled={isUpdating}
        onBlurEvent={(event) => {
          const enteredDomain = event.target.value.toLowerCase()
          const matchedDomain = enteredDomain.match(REGEX_DOMAIN)
          if (matchedDomain && matchedDomain.length >= 3) {
            const extractedDomain = matchedDomain[1] + '.' + matchedDomain[2]
            setValue('domain', extractedDomain)
            setSubmitDisabled(false)
          }
        }}
      />
      <FormActionButtons
        saveText={isUpdating ? 'Update' : 'Create'}
        isSaving={isSubmitting}
        disabled={submitDisabled || !isValid || !formData.name || !formData.domain}
        cancelText="Cancel"
        onCancel={() => {
          if (isUpdating) {
            closeDialog(DialogId.EDIT_DEPARTMENT)
          } else {
            closeDialog(DialogId.CREATE_DEPARTMENT)
          }
        }}
      />
    </Form>
  )
}
