import { useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { queryKeys } from 'src/libs/query-keys'
import { fetchCandidateJobCounts } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobCounts } from 'src/libs/api/backend/candidate_jobs'
import { useParams } from 'react-router'
import { isNil } from 'lodash'

export const useCandidateJobCountsQuery = (): UseQueryResult<CandidateJobCounts> => {
  const { jobId } = useParams()

  return useQuery({
    queryKey: [queryKeys.candidateJobCounts, jobId],
    queryFn: async () => {
      if (isNil(jobId)) {
        return {}
      }
      return await fetchCandidateJobCounts(jobId)
    },
    staleTime: 60 * 1000,
    enabled: !isNil(jobId)
  })
}
