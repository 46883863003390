import { TimelineActivity } from './timeline-activity'
// import { Spacer } from 'src/components/primitives/spacer'
// import { InboxEmailEditor } from '../inbox-email-editor'
import * as S from './inbox-timeline.styled'
// import { useOrgUsersQuery } from 'src/hooks/queries/use-org-users'
import type { CandidateActivity } from 'src/libs/api/backend/candidate_activities'
import type { CandidateExpanded } from 'src/libs/api/backend/candidate_jobs'
import { findLast } from 'lodash'
import type { Spacing } from 'src/styles/theme/types'
import { useCallback } from 'react'
import { useUpdateCandidateNote } from 'src/hooks/mutations/use-update-candidate.note'

interface TimelineProps {
  activities?: CandidateActivity[]
  candidate: CandidateExpanded
  onReply?: (emailMessageId: string) => void
  $gap?: Spacing
  newActivityOpen?: boolean
}

export const InboxTimeline = ({
  activities,
  candidate,
  onReply,
  $gap,
  newActivityOpen
}: TimelineProps): JSX.Element => {
  // const { data: orgUsers } = useOrgUsersQuery()
  // const AVAILABLE_EMAIL_ACCOUNTS = orgUsers?.[0]?.emailAccounts ?? []
  const mostRecentEmailActivity = findLast(activities, (activity) => activity.type === 'EMAIL')
  const { updateCandidateNote } = useUpdateCandidateNote()
  const onCommentUpdate = useCallback((noteId: string, body: string) => {
    updateCandidateNote({ candidateId: candidate.id, noteId, body })
  }, [candidate.id, updateCandidateNote])

  return (
    <S.Timeline>
      <S.Activities $gap={$gap}>
        {activities?.map((activity) => (
          <TimelineActivity
            key={activity.id}
            activity={activity}
            candidate={candidate}
            onReply={activity.id === mostRecentEmailActivity?.id ? onReply : undefined}
            newActivityOpen={newActivityOpen}
            onCommentUpdate={onCommentUpdate}
          />
        ))}
      </S.Activities>
      {/* <InboxEmailEditor
        isAiGeneratedEmail
        sendingAccount={AVAILABLE_EMAIL_ACCOUNTS[0]}
        availableAccounts={AVAILABLE_EMAIL_ACCOUNTS}
        subject='Test'
        body='Hi Alex, Yes this is a remote position but you must be based in California. Cheers, Milun'
        onDataChanged={(data) => {
          console.log('updated: ', data)
        }}
      /> */}
      {/* <Spacer $size={24} /> */}
    </S.Timeline>
  )
}
