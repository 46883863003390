import { Flex } from 'src/components/primitives/flex'
import * as S from './candidates-page-header.styled'
import { Button } from 'src/components/primitives/button'
import type { ButtonProps } from 'src/components/primitives/button'
import { Caption } from 'src/components/primitives/typography'
import { ButtonGroup } from 'src/components/primitives/button-group'
import { useTheme } from 'styled-components'
import { When } from '../when'
import { ViewMode } from 'src/constants'
import { useEffect, useState } from 'react'
import { Form, Input } from 'src/components/forms'
import { z } from 'zod'
import { useForm } from 'src/hooks/use-form'
import { LoadingSkeleton } from '../loading-skeleton'

export interface CandidatesPageHeaderProps {
  heading?: string
  headingLoading?: boolean
  isEditable?: boolean
  onEdit?: (updated: string) => void
  actions?: ButtonProps[]
  context?: React.ReactNode
  currViewMode?: ViewMode
  setViewMode?: React.Dispatch<React.SetStateAction<ViewMode>>
}

const headingSchema = z.object({
  heading: z.string()
})

type HeadingSchema = z.infer<typeof headingSchema>

export const CandidatesPageHeader = ({
  heading = 'Candidates',
  headingLoading = false,
  isEditable = false,
  onEdit,
  actions,
  context,
  currViewMode,
  setViewMode
}: CandidatesPageHeaderProps): JSX.Element => {
  const { colors } = useTheme()
  const [isEditing, setIsEditing] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { submit, register, setValue, formData } = useForm<HeadingSchema>({
    schema: headingSchema
  })

  useEffect(() => {
    if (heading) {
      setValue('heading', heading)
      setIsEditing(false)
      setIsSubmitting(false)
    }
  }, [heading])

  const handleSubmit = (formData: HeadingSchema): void => {
    if (onEdit) {
      setIsSubmitting(true)
      onEdit(formData.heading)
    }
  }

  return (
    <S.CandidatesPageHeader>
      <Flex $align="center" $justify="space-between">
        <S.HeadingGroup>
          <When condition={!isEditing}>
            {headingLoading
              ? (
                <LoadingSkeleton $variant="Header" />
                )
              : (
                <Caption as="h1" size="MD" $whiteSpace="nowrap">
                  {heading}
                </Caption>
                )
            }
          </When>
          <When condition={isEditing}>
            <Form onSubmit={submit(handleSubmit)}>
              <Input
                name="heading"
                register={register}
                label="Edit title"
                hiddenLabel
                setFocus={isEditing}
                autoGrow
                $marginBottom={0}
                isDisabled={isSubmitting}
                maxLength={50}
              />
              <Button
                leadingIcon="check"
                ariaLabel="Save changes"
                $variant="ghost"
                $colorTheme="muted"
                $width={18}
                $height={18}
                $fontSize={12}
                disabled={isSubmitting || ((formData.heading ?? '') as string).trim() === heading.trim()}
                type="submit"
              />
            </Form>
            <Button
              leadingIcon="x"
              ariaLabel="Cancel changes"
              $variant="ghost"
              $colorTheme="muted"
              $width={18}
              $height={18}
              $fontSize={12}
              disabled={isSubmitting}
              onClick={() => {
                setIsEditing(false)
                setValue('heading', heading)
              }}
            />
          </When>
          <When condition={isEditable}>
            <When condition={!isEditing}>
              <Button
                leadingIcon="pen-line"
                ariaLabel="Edit title"
                $variant="ghost"
                $colorTheme="muted"
                $width={18}
                $height={18}
                $fontSize={12}
                onClick={() => {
                  setIsEditing(true)
                }}
              />
            </When>
          </When>
        </S.HeadingGroup>
        <Flex $gap={8} $justify="flex-end" $width="auto" $align="center">
          {actions?.map((action, index) => (
            <Button
              key={index}
              href={action.href}
              onClick={action.onClick}
              $variant={action.$variant ?? 'ghost'}
              $colorTheme={action.$colorTheme ?? 'muted'}
              leadingIcon={action.icon}
              trailingIcon={action.trailingIcon}
              $fontSize={12}
              $height={24}
            >
              {action.children}
            </Button>
          ))}
          <When condition={!!setViewMode && !!currViewMode}>
            <ButtonGroup
              $variant="ghost"
              $gap={2}
              $direction="horizontal"
              $padding={2}
              $backgroundColor={colors.bgTertiary}
              $borderRadius={4}
            >
              <Button
                ariaLabel="Enable compact mode for candidate cards"
                $variant={currViewMode === ViewMode.COMPACT ? 'raised-light' : 'ghost'}
                // $colorTheme="muted"
                $height={24}
                $fontSize={12}
                leadingIcon="layout-list"
                onClick={() => {
                  setViewMode?.(ViewMode.COMPACT)
                }}
                tooltip={{
                  text: 'Compact View'
                }}
              />
              <Button
                ariaLabel="Enable details mode for candidate cards"
                $variant={currViewMode === ViewMode.DEFAULT ? 'raised-light' : 'ghost'}
                // $colorTheme="muted"
                $height={24}
                $fontSize={12}
                leadingIcon="layout-details"
                onClick={() => {
                  setViewMode?.(ViewMode.DEFAULT)
                }}
                tooltip={{
                  text: 'Detailed View'
                }}
              />
            </ButtonGroup>
          </When>
          {context}
        </Flex>
      </Flex>
    </S.CandidatesPageHeader>
  )
}
