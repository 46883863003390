import styled from 'styled-components'
import { motion } from 'framer-motion'

export const SearchRefinementForm = styled(motion.div)<{
  $isFocused: boolean
}>`
  position: relative;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border: solid 1px;
  border-radius: 4px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  overflow: hidden;
  textarea {
    /* Optical alignment */
    transform: translateY(1px);
    padding: 0;
  }
  p {
    align-self: center;
    line-height: 1;
  }
  z-index: 3;
  ${({ $isFocused, theme }) => $isFocused && `
    box-shadow: ${theme.boxShadows.lg}
  `};
`

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[2]};
  padding: ${({ theme }) => theme.spacing[10]};
  padding-bottom: 0;
`

export const Icon = styled.div`
  display: flex;
  align-self: flex-start;
  height: 100%;
  padding: ${({ theme }) => theme.spacing[2]};
`

export const InProgress = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 120px;
  height: 38px;
  padding: 0 0.75rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: linear-gradient(90deg, hsla(240, 20%, 99%, 0) 0%, hsla(240, 20%, 99%, 1) 66%);
`

export const Suggestion = styled.button`
  border: none;
  height: 1.25rem;
  padding: 0.125rem 0.35rem;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border-radius: 4px;
  font-size: ${({ theme }) => theme.fontSizes[12]};
  letter-spacing: 0.12px;
  line-height: 1rem;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.fgTranslucent10};
    }
  }
`

export const InlineClear = styled.div<{ $show: boolean }>`
  display: ${({ $show }) => ($show ? 'flex' : 'none')};
  position: absolute;
  right: 0.7rem;
  top: 10px;
  z-index: 1;
`

export const SuggestionsArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing[6]};
  padding: 0.75rem 0.875rem;
  background: linear-gradient(267deg, rgba(170, 145, 240, 0.10)-0.11%, rgba(179, 193, 241, 0.10)99.81%);
  width: 100%;
  height: 4rem;
  p {
    align-self: flex-start;
    line-height: 14px;
  }
`

export const Suggestions = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  gap: ${({ theme }) => theme.spacing[4]};
`

export const SuggestionsButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.375rem;
  gap: ${({ theme }) => theme.spacing[4]};
  cursor: pointer;
  border-radius: ${({ theme }) => theme.spacing[4]};
  border: 1px dashed #AA91F0;
  color: #AA91F0;
  position: absolute;
  right: 10px;
  top: 8px;
  width: 105px;
  height: 24px;
  font-size: ${({ theme }) => theme.fontSizes[12]};
`

export const EditorWrapper = styled.div<{ $isFocused: boolean }>`
  width: ${({ $isFocused }) => $isFocused ? 'calc(100% - 1.5rem)' : '100%'};
`

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.875rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderTranslucent};
  gap: ${({ theme }) => theme.spacing[8]};
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.fgTranslucent25};
  z-index: 1;
  overflow-y: hidden;
`
