import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router'
import { queryKeys } from 'src/libs/query-keys'
import { fetchSequenceByJobId } from 'src/libs/api/backend/jobs'
import { isNil } from 'lodash'

export const jobSequenceQuery = (jobId?: string) => ({
  queryKey: [queryKeys.jobSequence, jobId],
  queryFn: async () => await fetchSequenceByJobId(jobId ?? ''),
  staleTime: 60 * 1000,
  enabled: !isNil(jobId)
})

export const useJobSequenceQuery = (providedJobId?: string) => {
  const { jobId } = useParams()

  const query = useQuery(jobSequenceQuery(providedJobId ?? jobId))

  // useEffect(() => {
  //   const refetch = async (): Promise<void> => {
  //     void queryClient.invalidateQueries({
  //       queryKey: [queryKeys.candidateJobs, jobId]
  //     })
  //   }
  //   return () => { void refetch() }
  // }, [pathname, jobId, queryClient])

  return query
}
