import { useEffect, useState } from 'react'

export const useContainerCoords = ({
  ref
}: {
  ref: React.RefObject<HTMLDivElement>
}): { coords: { left: number, top: number } } => {
  const [coords, setCoords] = useState<{ left: number, top: number }>({ left: 0, top: 0 })
  useEffect(() => {
    if (ref?.current?.parentElement) {
      const rect = ref.current.parentElement.getBoundingClientRect()
      setCoords({
        left: rect.x,
        top: rect.y + rect.height + window.scrollY // add scrollY offset, as soon as getBoundingClientRect takes on screen coords
      })
    }
  }, [ref])
  return { coords }
}
