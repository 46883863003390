import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNotification } from '../use-notification'
import { bulkReassignSequenceSender } from 'src/libs/api/backend/sequences'
import { queryKeys } from 'src/libs/query-keys'
import { useParams } from 'react-router-dom'

interface Args {
  originalAccountId: string
  updatedAccountId: string
  updatedEmailAddress: string
  onSuccess?: () => void
}

interface Res {
  reassignSequenceSender: (args: Args) => void
}

export const useBulkReassignSequenceSender = (): Res => {
  const { notify } = useNotification()
  const queryClient = useQueryClient()
  const { jobId } = useParams()

  const mutation = useMutation({
    mutationFn: async ({ originalAccountId, updatedAccountId }: Args) => {
      return await bulkReassignSequenceSender({ originalAccountId, updatedAccountId })
    },
    onError: (error) => {
      console.error(error)
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: `An error occurred while reassigning senders: ${error.message}`
      })
    },
    onSuccess: async (_, variables) => {
      notify({
        type: 'toast',
        variant: 'positive',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: `Sequence sender email address updated to ${variables.updatedEmailAddress}`
      })

      if (variables.onSuccess) {
        variables.onSuccess()
      }

      await queryClient.invalidateQueries({ queryKey: [queryKeys.jobSequence, jobId] })
    }
  })

  const reassignSequenceSender = (args: Args): void => {
    mutation.mutate(args)
  }

  return { reassignSequenceSender }
}
