import { Fragment, useRef, useEffect, useState, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'

// Components
import { Button } from 'src/components/primitives/button'
import type { ButtonProps } from 'src/components/primitives/button'
import { SequenceStep } from './sequence-step'
import { Paragraph } from 'src/components/primitives/typography'
import { Spinner } from 'src/components/primitives/spinner'
import { Flex } from 'src/components/primitives/flex'
import { When } from 'src/components/blocks/when'
import { Spacer } from 'src/components/primitives/spacer'

// Hooks, libs, styles
import type { EmailSequence, EmailSequenceStep } from 'src/models/sequence'
import type { SequenceInstructionsForm } from './instructions'
import { isNil } from 'lodash'
import { useDialog } from 'src/hooks/use-dialog'
import { useUpsertJobSequence } from 'src/hooks/mutations/use-upsert-job-sequence'
import {
  generateInitialSequence,
  useEmailSequenceEditor
} from 'src/hooks/use-email-sequence-editor'
import { DialogId } from 'src/contexts/dialogs'
import * as S from './email-sequence-editor.styled'
import { useAlertOnRouteChange } from 'src/hooks/use-alert-on-route-change'
import { IfElse } from '../if-else'
import { SequenceStepGenerationState } from 'src/libs/api/backend/sequences'
import { Icons } from 'src/components/primitives/icon'
import { useIsSafari } from 'src/hooks/use-is-browser'
import { EmailSequenceSuggestion } from '../email-sequence-suggestion/email-sequence-suggestion'
import { Editor } from '../editor'
import { LoadingSkeleton } from '../loading-skeleton'
import { Box } from 'src/components/primitives/box'
import { Dropdown } from 'src/components/primitives/dropdown'
import { AutoArchiveAfterDays } from 'src/libs/api/backend/jobs'

type Action = ButtonProps

const MAX_NUMBER_OF_STEPS = 10

export interface SequenceEditorProps {
  initialState?: EmailSequence
  sequenceInstructions?: SequenceInstructionsForm
  shouldAutoGenerateEmails?: boolean
  isEditable?: boolean
  onBackClick?: () => void
  actions?: {
    save?: Action
    skip?: Action
    toggleActive?: () => void
  }
}

export const SequenceEditor = ({
  initialState,
  shouldAutoGenerateEmails = true,
  // actions = {}
  isEditable = true,
  onBackClick
}: SequenceEditorProps): JSX.Element => {
  const { closeDialog } = useDialog()
  const [currentStep, setCurrentStep] = useState(0)
  const [animationDone, setAnimationDone] = useState(true)
  const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const { jobId } = useParams()
  const scrollableStepsSectionEl = useRef<HTMLDivElement>(null)
  const {
    isLoading,
    sequenceState,
    emailsBeingGenerated,
    generatedEmails,
    getStepSubjectAndPlaceholder,
    getNewPosition,
    handleAddStep,
    isReadyForNextStep,
    handleGenerateStepSuggestion,
    handleConfirmStepSuggestion,
    handleDataChange,
    handleRemoveStep,
    handleUpdateReplyType,
    handleUpdateSequencePreferences,
    validateSequenceState,
    hasEmptySequenceStep,
    hasUnsavedChanges,
    setHasUnsavedChanges
  } = useEmailSequenceEditor({
    initialState: initialState ?? generateInitialSequence(3),
    autoGenerateEmails: shouldAutoGenerateEmails,
    sequenceSize: initialState?.sequenceSteps?.length ?? 3
  })

  const isSafari = useIsSafari()

  const atLeastOneStepIsBeingGenerated = useMemo(
    () => sequenceState.sequenceSteps?.some((step) => step.generationState === SequenceStepGenerationState.IN_PROGRESS),
    [sequenceState.sequenceSteps]
  )

  useAlertOnRouteChange(hasUnsavedChanges)

  const { upsertJobSequence, isPending } = useUpsertJobSequence()

  const stepIsGenerating = useCallback(
    (stepPosition: number): boolean => {
      const isNewSequenceStepBeingGenerated = emailsBeingGenerated?.includes(stepPosition) ?? false
      const isInitialSequenceStepsBeingGenerated =
        sequenceState.sequenceSteps?.at(stepPosition)?.generationState === SequenceStepGenerationState.IN_PROGRESS
      return isNewSequenceStepBeingGenerated || isInitialSequenceStepsBeingGenerated
    },
    [emailsBeingGenerated, sequenceState.sequenceSteps]
  )

  const isSequenceGenerating = useMemo((): boolean => {
    // NOTE: we return true in this case because if `sequenceState.sequenceSteps` is null, then the initial sequence is being generated
    if (isNil(sequenceState.sequenceSteps)) {
      return true
    }

    if (isNil(emailsBeingGenerated)) {
      return false
    }

    return emailsBeingGenerated.length > 0
  }, [emailsBeingGenerated, sequenceState.sequenceSteps])

  const reachedMaxStepCount = useMemo(() => {
    return Array.isArray(sequenceState.sequenceSteps) && sequenceState.sequenceSteps.length === MAX_NUMBER_OF_STEPS
  }, [sequenceState])

  const saveSequence = useCallback(async () => {
    if (isNil(jobId)) {
      return
    }
    const validated = await validateSequenceState(sequenceState)
    upsertJobSequence({
      jobId,
      sequenceSteps: validated.sequenceSteps,
      active: validated.active ?? true,
      autoArchiveAfterDays: validated.autoArchiveAfterDays,
      dailyEmailLimit: validated.dailyEmailLimit,
      onSuccess: () => {
        closeDialog(DialogId.CREATE_SEQUENCE)
      }
    })
  }, [closeDialog, jobId, sequenceState, upsertJobSequence, validateSequenceState])

  const scrollToCurrentStep = useCallback((stepPosition: number) => {
    if (scrollableStepsSectionEl.current) {
      const stepElement = document.getElementById(`step-${stepPosition}`)
      if (stepElement) {
        scrollableStepsSectionEl.current.onscrollend = () => {
          setAnimationDone(true)
        }
        scrollableStepsSectionEl.current.scrollTo({
          top: stepElement.offsetTop,
          behavior: 'smooth'
        })
        if (isSafari) {
          if (scrollTimeoutRef.current) {
            clearTimeout(scrollTimeoutRef.current)
          }
          // THANKS SAFARI FOR NOT SUPPORTING scrollend event
          // This will break the smooth animation on Chrome so don't enable it for Chrome powered browser or FF
          // Remove this when Safari supports https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollend_event#browser_compatibility likes an adult
          scrollTimeoutRef.current = setTimeout(() => {
            setAnimationDone(true)
          }, 500)
        }
      }
    }
  }, [isSafari])

  useEffect(() => {
    scrollToCurrentStep(currentStep)
  }, [currentStep, scrollToCurrentStep])

  const onStepClick = useCallback((stepPosition: number) => {
    setAnimationDone(false)
    setCurrentStep(stepPosition)
  }, [])

  const renderEditorFooter = (stepIndex: number): JSX.Element | null => {
    if (isNil(sequenceState) || isNil(sequenceState.sequenceSteps)) {
      return null
    }

    const suggestion = sequenceState.sequenceSteps[stepIndex].emailBodySuggestion
    const body = sequenceState.sequenceSteps[stepIndex].body
    if (isNil(body)) {
      return null
    }

    const hasShortBody = body.length < 50
    const hasSuggestion = !isNil(suggestion)

    const showFooter = hasSuggestion || hasShortBody
    if (!showFooter) {
      return null
    }

    return (
      <EmailSequenceSuggestion
        isGeneratingSuggestion={isSequenceGenerating}
        startCollpased={!isSequenceGenerating}
        onOpenSuggestion={async () => {
          await handleGenerateStepSuggestion(stepIndex)
        }}
        onCloseSuggestion={async () => { }}
        onApplySuggestion={() => {
          handleConfirmStepSuggestion(true, stepIndex)
        }}
      >
        <When condition={isSequenceGenerating}>
          <Box $padding={{ top: 12, right: 12, bottom: 12, left: 12 }} $width='100%'>
            <LoadingSkeleton $variant="Text" delay={0} />
          </Box>
        </When>
        <When condition={!isSequenceGenerating}>
          <Editor
            isEditable={false}
            content=''
            initialContent={suggestion}
            onDataChanged={() => {}}
            $minHeight="3rem"
            $editorHeight='fit-content'
          />
        </When>
      </EmailSequenceSuggestion>
    )
  }

  if (isLoading) {
    return <></>
  }

  return (
    <S.SequenceEditor $isEditable={isEditable}>
      <S.Sidebar>
        <Flex $direction="column">
          <S.StepSelectors>
            {sequenceState.sequenceSteps?.map((step: EmailSequenceStep) => {
              const { subject, placeholder } = getStepSubjectAndPlaceholder(step.position)
              return (
                <Fragment key={`${step.position}-${step.subject}`}>
                  <S.StepSelector
                    $isGenerating={false}
                    $isCurrentStep={currentStep === step.position}
                    onClick={() => {
                      onStepClick(step.position)
                    }}
                    aria-label={`Select outreach step with the subject: ${step.subject} at position ${step.position}`}
                  >
                    <Flex $gap={4} $direction="column">
                      <IfElse
                        condition={stepIsGenerating(step.position)}
                        ifNode={<Paragraph size="XS">Generating...</Paragraph>}
                        elseNode={
                          <Paragraph size="XS">
                            {step.position === 0
                              ? 'Intro email'
                              : `${step.subject !== null ? 'New thread' : 'Reply'} after ${
                                  step.waitDays
                                } days`}
                          </Paragraph>
                        }
                      />
                      <IfElse
                        condition={subject === '' || placeholder === ''}
                        ifNode={
                          <Paragraph size="XS" $color="fgTertiary">
                            (no subject)
                          </Paragraph>
                        }
                        elseNode={
                          <Paragraph size="XS" $color="fgPrimary">
                            {subject ?? placeholder}
                          </Paragraph>
                        }
                      />
                    </Flex>
                    <When condition={stepIsGenerating(step.position)}>
                      <S.StepSelectorSpinner>
                        <Spinner />
                      </S.StepSelectorSpinner>
                    </When>
                  </S.StepSelector>
                </Fragment>
              )
            })}
          </S.StepSelectors>
          <Flex $align='center' $justify='center'>
            <S.AddStepButton>
              <Button
                $variant="outline"
                $colorTheme="tint"
                leadingIcon={Icons.plus}
                $height={24}
                $fontSize={12}
                disabled={isLoading || reachedMaxStepCount || !isReadyForNextStep() || atLeastOneStepIsBeingGenerated}
                onClick={async () => {
                  onStepClick(getNewPosition())

                  if (shouldAutoGenerateEmails) {
                    await handleGenerateStepSuggestion()
                  } else {
                    handleAddStep()
                  }
                }}
              >
                Add step
              </Button>
            </S.AddStepButton>
          </Flex>
          <S.AutoArchiveBlock>
            <S.AutoArchiveContent>
              <Paragraph size='XS'>Auto-archive if no reply</Paragraph>
              <Dropdown
                trigger={
                  <Button
                    nested
                    $variant='outline'
                    $colorTheme='normal'
                    $fontSize={12}
                    $height={24}
                    $width='full'
                    trailingIcon={Icons.chevronsUpDownSmall}
                    $align='space-between'
                  >
                    After {sequenceState.autoArchiveAfterDays} days
                  </Button>
                }
                items={AutoArchiveAfterDays.map((days) => ({
                  id: days.toString(),
                  title: `${days} days`,
                  type: 'item',
                  onSelect: () => {
                    handleUpdateSequencePreferences({ dailyEmailLimit: sequenceState.dailyEmailLimit ?? 100, autoArchiveAfterDays: days })
                  }
                }))}
                menuPosition='start'
                size='small'
              />
            </S.AutoArchiveContent>
          </S.AutoArchiveBlock>
        </Flex>
        <Flex $direction="column" $gap={16}>
          <Spacer $size={24} />
          <Paragraph size="XS" $color="fgTertiary">
            Candidates are auto-archived {sequenceState.autoArchiveAfterDays} days after the last email in the outreach sequence if no
            response.
          </Paragraph>
          <Flex $align="center" $gap={16}>
            {onBackClick && (
              <Button
                leadingIcon={Icons.chevronLeft}
                $variant="outline"
                $colorTheme="muted"
                $height={40}
                $width="84px"
                $minWidth="84px"
                onClick={onBackClick}
              >
                Back
              </Button>
            )}
            <Button
              disabled={isSequenceGenerating || isPending || Boolean(emailsBeingGenerated?.length) || !isReadyForNextStep() || hasEmptySequenceStep()}
              $variant="fill"
              $colorTheme="tint"
              $height={40}
              $width="full"
              $align="center"
              onClick={async () => {
                // if (pathname.includes('settings/job') && sequenceState.active) {
                //   notify({
                //     type: 'toast',
                //     variant: 'negative',
                //     position: 'bottom-right',
                //     icon: 'x-octagon',
                //     message: 'Active outreach sequences cannot be edited'
                //   })
                // } else {
                await saveSequence()
                // }
                setHasUnsavedChanges(false)
              }}
            >
              Save outreach sequence
            </Button>
          </Flex>
        </Flex>
      </S.Sidebar>
      <S.StepsSection ref={scrollableStepsSectionEl}>
        {sequenceState.sequenceSteps?.map((step: EmailSequenceStep, index: number) => {
          return (
            <SequenceStep
              key={`step-${step.position}`}
              step={step}
              getStepSubjectAndPlaceholder={getStepSubjectAndPlaceholder}
              initialStepBody={generatedEmails?.[step.position]?.body ?? undefined}
              totalSteps={
                Array.isArray(sequenceState.sequenceSteps)
                  ? sequenceState.sequenceSteps.length - 1
                  : 3
              }
              isGenerating={
                step.generationState === SequenceStepGenerationState.IN_PROGRESS || stepIsGenerating(step.position)
              }
              onDataChanged={(updatedStep) => {
                handleDataChange(updatedStep)
              }}
              onRemoveStep={() => {
                onStepClick(step.position - 1)
                handleRemoveStep(step)
              }}
              onReplyTypeUpdate={(value) => {
                handleUpdateReplyType(step, value)
              }}
              onReorder={(newPosition) => {
                console.log('reorder: ', newPosition)
              }}
              forceEditorFocus={step.position === currentStep && animationDone}
              emailSuggestionFooter={renderEditorFooter(index)}
            />
          )
        })}
      </S.StepsSection>
    </S.SequenceEditor>
  )
}
