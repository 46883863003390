import { useMutation } from '@tanstack/react-query'
import { useNotification } from '../use-notification'
import { deleteCompanyPreference } from 'src/libs/api/backend/company_preferences'
import type { CompanyPreference } from 'src/libs/api/backend/company_preferences'
import queryClient from '../query-client'
import { queryKeys } from 'src/libs/query-keys'
import { Icons } from 'src/components/primitives/icon'
import { useParams } from 'react-router'

interface Args {
  id: string
}

interface Res {
  setDeleteCompanyPreference: ({ id }: Args) => void
}

export const useDeleteCompanyPreference = (): Res => {
  const { notify } = useNotification()
  const { jobId } = useParams()

  const mutation = useMutation({
    mutationFn: async ({ id }: Args) => {
      await deleteCompanyPreference(id)
    },
    onError: (err) => {
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-right',
        icon: Icons.xOctagon,
        message: `An error occurred when delete company rating: ${err.message}`
      })
    },
    onSuccess: async (_, variables) => {
      queryClient.setQueriesData<CompanyPreference[]>(
        {
          queryKey: [queryKeys.companyPreferences, jobId]
        },
        (oldCompanyPreferences) => {
          if (!oldCompanyPreferences) {
            return oldCompanyPreferences
          }
          return oldCompanyPreferences.filter((oldCompanyPreference) => oldCompanyPreference.id !== variables.id)
        }
      )
    }
  })

  const setDeleteCompanyPreference = (args: Args): void => {
    mutation.mutate(args)
  }

  return { setDeleteCompanyPreference }
}
