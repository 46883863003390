import { useState } from 'react'
import { Button, Flex } from 'src/components/primitives'
import * as Dialog from 'src/components/primitives/dialog'
import { Caption } from 'src/components/primitives/typography'
import { DialogId } from 'src/contexts/dialogs'
import { useDialog } from 'src/hooks/use-dialog'
import * as S from './refinement-feedback-dialog.styled'
import { useSubmitFeedback } from 'src/hooks/mutations/use-submit-feedback'
import { FeedbackType } from 'src/libs/api/backend/feedback'
import { useQueryParams } from 'src/hooks/use-query-params'

export const RefinementFeedbackDialog = (): JSX.Element => {
  const { isDialogOpen, controlDialog } = useDialog()
  const [content, setContent] = useState<string>('')
  const { submitFeedback } = useSubmitFeedback()
  const { getParam } = useQueryParams()
  const jobSearchRefinementId = getParam('jobSearchRefinementId')

  return (
    <Dialog.Root
      id={DialogId.REFINEMENT_FEEDBACK}
      isOpen={isDialogOpen(DialogId.REFINEMENT_FEEDBACK)}
      onOpenChange={(value) => {
        controlDialog(DialogId.REFINEMENT_FEEDBACK, value)
      }}
      $width='640px'
    >
      <Dialog.Portal>
        <Dialog.Header
          title='Sourcing feedback'
          onClose={() => {
            controlDialog(DialogId.REFINEMENT_FEEDBACK, false)
          }}
        />
        <Dialog.Content>
          <Flex $direction='column' $gap={24}>
            <Flex $direction='column' $gap={8}>
              <Caption size='SM'>Please tell us what you didn’t like about the results.
              </Caption>
              <S.InputArea
                value={content}
                onChange={(event) => {
                  setContent(event.target.value)
                }}
                required
                placeholder='Write your feedback here (Required)'
              />
            </Flex>
            <Button
              $variant='fill'
              $colorTheme='tint'
              $height={40}
              disabled={!content}
              onClick={() => {
                submitFeedback({
                  feedback: {
                    body: content,
                    type: FeedbackType.BUG_REPORT,
                    jobSearchRefinementId
                  }
                })
                controlDialog(DialogId.REFINEMENT_FEEDBACK, false)
              }}
            >
              Send Feedback
            </Button>
          </Flex>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
