import { Button, Flex } from 'src/components/primitives'
import * as S from './hiring-for-settings-styled'
import { useDialog } from 'src/hooks/use-dialog'
import { DialogId } from 'src/contexts/dialogs'
import { Icons } from 'src/components/primitives/icon'
import { useGetDepartmentQuery } from 'src/hooks/queries/use-departments'
import { Avatar } from 'src/components/primitives/avatar'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { useJobByDepartmentsQuery } from 'src/hooks/queries/use-jobs-by-department'
import { useMemo } from 'react'
import { Tooltip } from 'src/components/primitives/tooltip'
import { LoadingSkeleton } from 'src/components/blocks/loading-skeleton'
import { useDeleteDepartment } from 'src/hooks/mutations/use-delete-department'

export const HiringForSettings = (): JSX.Element => {
  const { openDialog } = useDialog()
  const { departments = [] } = useGetDepartmentQuery()

  const { isPending, data: jobsByDepartment = {} } = useJobByDepartmentsQuery()
  const { deleteDepartmentByOrg } = useDeleteDepartment()

  const activeJobsCountByDepartmentId = useMemo(() => {
    return Object.keys(jobsByDepartment).reduce((acc: Record<string, number>, departmentId: string): Record<string, number> => {
      const jobs = jobsByDepartment[departmentId]
      const count = jobs.filter((job) => !job.deleted).length
      return {
        ...acc,
        [departmentId]: count
      }
    }, {})
  }, [jobsByDepartment])

  if (isPending) {
    return <LoadingSkeleton $variant='JobsList' />
  }

  return (
    <S.HiringForContainer>
      <Button
        leadingIcon={Icons.plus}
        $variant='raised'
        $colorTheme='tint'
        onClick={() => {
          openDialog(DialogId.CREATE_DEPARTMENT, {
            setDepartmentId (id: string) {
              void id
            }
          })
        }}
        $width={140}
        $height={32}
        $fontSize={14}
      >
        New company
      </Button>
      <S.DepartmentsList>
        {departments.map((department) => {
          const count = activeJobsCountByDepartmentId[department.id] || 0
          return (
            <S.DepartmentDetails key={department.id}>
              <Flex $gap={10} $align='center' $width='auto' $flex='1 1 auto'>
                <Avatar
                  $type="logo"
                  initials={department.name}
                  $size={32}
                  $border={false}
                  $innerPadding={0}
                  company={{ name: department.name, url: department.domain }}
                />
                <Flex $direction='column' $flex='1 1 auto'>
                  <Caption size='SM'>{department.name}</Caption>
                  <Flex $align='center'>
                    <Paragraph size='XS'>{department.domain}</Paragraph>
                    {count > 0 && (
                      <Paragraph size='XS'>&nbsp;· {count} job position{count > 1 ? 's' : ''}</Paragraph>
                    )}
                    {department.deleted ? <Paragraph size='XS'>&nbsp;· Archived</Paragraph> : null}
                  </Flex>
                </Flex>
              </Flex>
              <Flex $width='fit-content' $gap={12}>
                <Button
                  $width={24}
                  $height={24}
                  leadingIcon={Icons.penLine}
                  $variant='ghost'
                  $colorTheme='muted'
                  $fontSize={12}
                  onClick={() => {
                    openDialog(DialogId.EDIT_DEPARTMENT, {
                      departmentId: department.id,
                      name: department.name,
                      domain: department.domain
                    })
                  }}
                />
                <Tooltip
                  trigger={
                    <span>
                      <Button
                        $width={24}
                        $height={24}
                        leadingIcon={department.deleted ? Icons.archiveRestore : Icons.archive}
                        $variant='ghost'
                        $colorTheme='muted'
                        disabled={count > 0}
                        $fontSize={12}
                        onClick={() => {
                          deleteDepartmentByOrg({
                            id: department.id,
                            deleted: !department.deleted
                          })
                        }}
                      />
                    </span>
                  }
                  disabled={count === 0}
                  triggerDisabled={count > 0}
                >
                  All jobs for {department.name} must be archived before you can archive this company
                </Tooltip>
              </Flex>
            </S.DepartmentDetails>
          )
        })}
      </S.DepartmentsList>
    </S.HiringForContainer>
  )
}
