import { PageHeader } from 'src/components/blocks/page-header'
// import { Dropdown } from 'src/components/primitives/dropdown'
// import { Button } from 'src/components/primitives/button'
// import { BarChart } from 'src/components/charts/bar-chart'
import { AreaChart } from 'src/components/charts/area-chart'
import { ChartWrapper } from 'src/components/charts/chart-wrapper'
import { Flex } from 'src/components/primitives/flex'
import { TabularChart } from 'src/components/charts/tabular-chart'
import type { TabularChartSchema } from 'src/components/charts/tabular-chart'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Summary } from 'src/components/charts/summary'
import { useCandidateJobCountsQuery } from 'src/hooks/queries/use-candidate-job-counts'
import { useJobReportQuery } from 'src/hooks/queries/use-job-report'
import { useMemo } from 'react'
import { useParams } from 'react-router'
import RouteBuilder from 'src/libs/route-builder'
import { EmptyState } from 'src/components/blocks/empty-state'
import { LoadingSkeleton } from 'src/components/blocks/loading-skeleton'
import { TileChart } from 'src/components/charts/tile-chart'
import { SEO } from 'src/components/primitives/seo'
import { ReportFeedbackBanner } from 'src/components/blocks/report-feedback-banner'

interface BySequenceStepChart {
  sequenceStep: React.ReactNode
  sent: React.ReactNode
  opened: React.ReactNode
  // clicked: React.ReactNode
  replied: React.ReactNode
}

const mapSubjects = (input: Array<{ subject?: string | null | undefined }>): string[] => {
  let lastSubject = ''
  let repeatCount = 0
  return input.map((item) => {
    if (item.subject) {
      lastSubject = item.subject
      repeatCount = 0
      return item.subject
    } else {
      let prefix = ''
      for (let i = 0; i <= repeatCount; i++) {
        prefix += 'Re: '
      }
      repeatCount++
      return prefix + lastSubject
    }
  })
}

const ReportsCandidatesPage = (): JSX.Element => {
  const { jobId } = useParams()
  const { data: candidateJobCounts, isLoading } = useCandidateJobCountsQuery()
  const { openedEmails, /* clickedEmails, */ respondedEmails, sequenceEmailsSentByOrg } =
    candidateJobCounts ?? {}

  const { data: jobReport } = useJobReportQuery()

  const { emailMessageCounts, sequenceStepEngagementMetrics, bestTimeToSend } = jobReport ?? {}

  const subjects = mapSubjects(sequenceStepEngagementMetrics ?? [])

  const sequenceStepTableData =
    sequenceStepEngagementMetrics?.map((step, index) => {
      return {
        sequenceStep: (
          <Flex $direction="column" $gap={2}>
            <Caption size="XS">{subjects[index]}</Caption>
            <Paragraph size="XS" $color="fgTertiary">
              {step.email}
            </Paragraph>
          </Flex>
        ),
        sent: step.sent,
        opened: step.opened,
        // clicked: step.clicked,
        replied: step.replied
      }
    }) ?? []

  const emailsPerDayData =
    emailMessageCounts?.map((email) => {
      return {
        label: email.friendlyDate,
        Sent: email.sent,
        Received: email.received
      }
    }) ?? []

  const bySequenceStepSchema: TabularChartSchema<BySequenceStepChart> = {
    sequenceStep: 'By outreach step',
    sent: 'Sent',
    opened: 'Opened',
    // clicked: 'Clicked',
    replied: 'Replied'
  }

  const isEmpty = useMemo(
    () =>
      !sequenceEmailsSentByOrg?.totalSum &&
      !openedEmails?.totalSum &&
      // !clickedEmails?.clickedSum &&
      !respondedEmails?.totalSum &&
      !sequenceStepEngagementMetrics?.length &&
      !emailMessageCounts?.length &&
      (!bestTimeToSend?.data?.length || bestTimeToSend?.data.every((item) => item.count === 0)),
    [
      sequenceEmailsSentByOrg,
      openedEmails,
      /* clickedEmails, */ respondedEmails,
      sequenceStepEngagementMetrics,
      emailMessageCounts,
      bestTimeToSend
    ]
  )

  /* const demoData = [
   *   {
   *     category: 'All rejections',
   *     data: [
   *       {
   *         label: 'Foo',
   *         value: 8
   *       },
   *       {
   *         label: 'Bar',
   *         value: 40
   *       },
   *       {
   *         label: 'Lorem',
   *         value: 20
   *       },
   *       {
   *         label: 'ipsum',
   *         value: 12
   *       }
   *     ]
   *   }
   * ] */

  const header = useMemo(
    () => (
      <PageHeader
        heading="Reports · Communications"
        actions={
          undefined
          /* <>
            <Dropdown
              trigger={
                <Button
                  nested
                  leadingIcon="calendar"
                  trailingIcon="chevron-down"
                  $height={24}
                  $fontSize={12}
                  $variant="raised"
                  $colorTheme="muted"
                >
                  All time
                </Button>
              }
              items={[
                {
                  title: 'Option 1'
                },
                {
                  title: 'Option 2'
                },
                {
                  title: 'Option 3'
                }
              ]}
              menuPosition="end"
              size="small"
            />
            <Button
              leadingIcon="arrow-up-right"
              $height={24}
              $fontSize={12}
              $variant="outline"
              $colorTheme="muted"
            >
              Export PDF
            </Button>
            <Dropdown
              trigger={
                <Button
                  nested
                  ariaLabel="More"
                  $variant="ghost"
                  $colorTheme="muted"
                  leadingIcon="more-vertical"
                  $fontSize={12}
                  $height={24}
                />
              }
              items={[
                {
                  title: 'Option 1'
                },
                {
                  title: 'Option 2'
                },
                {
                  title: 'Option 3'
                }
              ]}
              menuPosition="end"
              size="small"
            />
          </> */
        }
      />
    ),
    []
  )

  if (isLoading) {
    return (<LoadingSkeleton $variant="CandidateDetailsCard" />)
  }

  if (isEmpty && !isLoading) {
    return (
      <Flex $direction="column" $height="full">
        {header}
        <EmptyState
          heading="No data to report yet"
          description="As you start communicating with candidates we'll generate a report for you"
          svg="report"
          $borderRadius="6px"
          $padding={{ top: 0, bottom: 0, left: 0, right: 0 }}
          actions={[
            {
              href: RouteBuilder.build('JOBS_CANDIDATES_SOURCING', { jobId }),
              children: 'Go to sourcing'
            }
          ]}
        />
      </Flex>
    )
  }

  return (
    <>
      <SEO title='Communications Reports' />
      {header}
      <Flex $direction="column" $gap={20}>
        <ReportFeedbackBanner />
        <ChartWrapper $variant="solid">
          <Summary
            data={[
              {
                icon: 'mail-arrow-right',
                label: 'Emails sent',
                value: sequenceEmailsSentByOrg?.totalSum ?? 0
              },
              {
                icon: 'mail-open',
                label: 'Opened',
                value: openedEmails?.openedSum ?? 0,
                total: openedEmails?.totalSum ?? 0
              },
              // {
              //   label: 'Clicked',
              //   value: clickedEmails?.clickedSum ?? 0,
              //   total: clickedEmails?.totalSum ?? 0
              // },
              {
                icon: 'mail-arrow-left',
                label: 'Replied',
                value: respondedEmails?.respondedSum ?? 0,
                total: respondedEmails?.totalSum ?? 0
              }
            ]}
          />
        </ChartWrapper>
        <ChartWrapper>
          <TabularChart<BySequenceStepChart>
            schema={bySequenceStepSchema}
            calculatePercentage
            tableData={sequenceStepTableData}
          />
        </ChartWrapper>
        <ChartWrapper>
          <AreaChart chartTitle="Emails per day" data={emailsPerDayData} />
        </ChartWrapper>
        <ChartWrapper>
          <TileChart
            chartTitle="Email opens by time sent"
            data={bestTimeToSend?.data ?? []}
          />
        </ChartWrapper>
        <div></div> {/* Hack to add padding to bottom of reports */}
      </Flex>
    </>
  )
}

export default ReportsCandidatesPage
