import React, { useCallback, useEffect } from 'react'
import * as S from './tag-input.styled'
import { Icon } from '../icon'
import type { Spacing } from 'src/styles/theme/types'
import { isNil } from 'lodash'
import { REGEX_DOMAIN, REGEX_EMAIL } from 'src/libs/regex'
import { Caption } from '../typography'
import { Flex } from '../flex'

interface TagInputProps {
  defaultTags?: string[]
  onTagsChange?: (tags: string[]) => void
  placeholder?: string
  containerPadding?: Spacing
  type?: 'email' | 'domain'
}

export const TagInput = ({
  defaultTags,
  onTagsChange,
  placeholder,
  containerPadding,
  type
}: TagInputProps): JSX.Element => {
  const [tags, setTags] = React.useState<string[] | undefined>(undefined)
  const [error, setError] = React.useState<string | undefined>(undefined)
  useEffect(() => {
    if (!isNil(defaultTags) && isNil(tags)) {
      setTags(defaultTags)
    }
  }, [defaultTags, tags])

  const tagInput = React.useRef<HTMLInputElement>(null)

  const updateTags = useCallback((newTags: string[]): void => {
    setTags(newTags)
    if (onTagsChange) {
      onTagsChange(newTags)
    }
  }, [onTagsChange])

  const validateInput = useCallback((value: string): void => {
    const trimmedValue = value.trim()
    if (trimmedValue.length === 0) {
      setError(undefined)
      return
    }
    if (tags?.find(tag => tag.toLowerCase() === trimmedValue.toLowerCase())) {
      return
    }
    switch (type) {
      case 'email':
        if (!REGEX_EMAIL.test(trimmedValue)) {
          setError('Invalid email address')
          return
        }
        break
      case 'domain':
        if (!REGEX_DOMAIN.test(trimmedValue)) {
          setError('Invalid domain')
          return
        }
        break
      default:
        break
    }
    updateTags([...(tags ?? []), trimmedValue])
    setError(undefined)
    if (tagInput.current) {
      tagInput.current.value = ''
    }
  }, [tags, type, updateTags])

  const removeTag = useCallback((i: number): void => {
    const newTags = (tags ?? []).filter((_, index) => index !== i)
    updateTags(newTags)
  }, [tags, updateTags])

  React.useEffect(() => {
    if (tagInput.current) {
      tagInput.current.focus()
    }
  }, [])

  const inputKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>): void => {
    const val = (e.target as HTMLInputElement).value
    if ((e.key === 'Enter' || e.key === ' ') && val) {
      validateInput(val)
    } else if (e.key === 'Backspace' && !val) {
      removeTag((tags ?? []).length - 1)
    }
  }, [removeTag, tags, validateInput])

  const onBlur = useCallback((e: React.FocusEvent<HTMLInputElement>): void => {
    const val = (e.target as HTMLInputElement).value
    validateInput(val)
  }, [validateInput])

  return (
    <Flex $direction='column' $gap={4}>
      <S.Container
        $containerPadding={containerPadding}
      >
        <S.TagList>
          {tags?.map((tag, i) => (
            <S.TagListItem key={tag}>
              {tag}
              <S.TagListItemRemoveButton type="button" onClick={() => { removeTag(i) }}>
                <Icon name="x" size={12} />
              </S.TagListItemRemoveButton>
            </S.TagListItem>
          ))}
          <S.TagListInput>
            <input
              type="text"
              onKeyDown={inputKeyDown}
              onBlur={onBlur}
              placeholder={placeholder}
              ref={tagInput}
            />
          </S.TagListInput>
        </S.TagList>
      </S.Container>
      {error && <Caption $color='negativeBg' size='XS'>{error}</Caption>}
    </Flex>
  )
}
