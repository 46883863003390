import { useQuery } from '@tanstack/react-query'
import { fetchCandidateSequenceStepMessageReviews } from 'src/libs/api/backend/candidate_sequence_step_message_review'
import { queryKeys } from 'src/libs/query-keys'

interface Args {
  jobId: string | undefined
}

export const candidateSequenceStepMessageReviewsQuery = ({ jobId }: Args) => {
  return {
    queryKey: [queryKeys.candidateSequenceStepMessageReviews, jobId],
    queryFn: async () => await fetchCandidateSequenceStepMessageReviews(jobId),
    staleTime: 60 * 1000,
    enabled: !!jobId
  }
}

export const useCandidateSequenceStepMessageReviews = ({ jobId }: Args) => {
  return useQuery(candidateSequenceStepMessageReviewsQuery({ jobId }))
}
