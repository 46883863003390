import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNotification } from '../use-notification'
import { queryKeys } from 'src/libs/query-keys'
import { updateOrg as updateOrgApi } from 'src/libs/api/backend/orgs'
import type { UpdateOrg } from 'src/libs/api/backend/orgs'
import type { Session } from 'src/libs/api/backend/session'
import { isNil } from 'lodash'

interface Args {
  orgUpdate: Partial<UpdateOrg>
}

interface Res {
  updateOrg: (orgUpdate: Partial<UpdateOrg>) => void
}

export const useUpdateOrg = (): Res => {
  const queryClient = useQueryClient()
  const { notify } = useNotification()

  const mutation = useMutation({
    mutationFn: async ({ orgUpdate }: Args) => {
      return await updateOrgApi(orgUpdate)
    },
    onError: (err) => {
      console.error(err)
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: `An error occurred when updating your organization: ${err.message}`,
        autoClose: false
      })
    },
    onSuccess: async (data) => {
      queryClient.setQueryData<Session>([queryKeys.session], (oldSession) => {
        if (isNil(oldSession)) {
          return
        }

        return {
          ...oldSession,
          org: {
            ...oldSession?.org,
            ...data
          },
          logoUrl: data.logoUrl
        }
      })
      notify({
        type: 'toast',
        variant: 'positive',
        position: 'bottom-right',
        icon: 'check-check',
        message: 'Successfully updated company details',
        autoClose: true
      })
    }
  })

  const updateOrg = (orgUpdate: Partial<UpdateOrg>): void => {
    mutation.mutate({ orgUpdate })
  }

  return { updateOrg }
}
