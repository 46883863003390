import { Spacer } from 'src/components/primitives/spacer'
import { JoinTeamCard } from 'src/components/blocks/join-team-card'
import { Flex } from 'src/components/primitives/flex'
import { Button } from 'src/components/primitives/button'
import { useExistingOrgsQuery } from 'src/hooks/queries/use-existing-orgs'
import { useEffect } from 'react'
import RouteBuilder from 'src/libs/route-builder'
import { useNavigate } from 'react-router'
import { useNotification, useNotifyErrorSuccess } from 'src/hooks/use-notification'
import { joinOrg as joinOrgApi } from 'src/libs/api/backend/orgs'
import { useSession } from 'src/hooks/use-session'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Divider } from 'src/components/primitives/divider'
import { useQueryClient } from '@tanstack/react-query'
import { queryKeys } from 'src/libs/query-keys'

const OnboardingJoinTeam = (): JSX.Element => {
  const { isPending, data } = useExistingOrgsQuery()
  const navigate = useNavigate()
  const { notify } = useNotification()
  const { notifySuccess, notifyError } = useNotifyErrorSuccess()
  const { emailAccountAccessTokens } = useSession()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (!isPending && data?.length === 0) {
      navigate(RouteBuilder.build('ONBOARDING_COMPANY_PROFILE'))
    }
  }, [isPending, data, navigate])

  // TODO: Add a loading indicator
  if (isPending) {
    return <div></div>
  }

  const orgs = data ?? []

  const joinOrg = async (orgId: string): Promise<void> => {
    try {
      await joinOrgApi(orgId)
      await queryClient.invalidateQueries({ queryKey: [queryKeys.session] })
    } catch (error) {
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-center',
        icon: 'x-octagon',
        message: 'An error occured joining the org, please try again.',
        autoClose: false
      })
      return
    }

    const hasEmailConnected = emailAccountAccessTokens?.some((token) => token.hasAccessToken)

    if (hasEmailConnected) {
      navigate(RouteBuilder.build('ROOT'))
    } else {
      navigate(`${RouteBuilder.build('ONBOARDING_CONNECTED_ACCOUNTS')}?skip_invite=1`)
    }
  }

  const askToJoin = async (orgId: string): Promise<void> => {
    try {
      await joinOrgApi(orgId)
    } catch (error) {
      notifyError('An error occured while requesting to join this org, please try again.')
      return
    }
    notifySuccess(
      'Successfully requested to join this org. You will receive an email once your request is approved.'
    )
  }

  const createOrg = (): void => {
    navigate(RouteBuilder.build('ONBOARDING_COMPANY_PROFILE'))
  }

  return (
    <>
      <Caption size="LG">We Found Your Team</Caption>
      <Spacer $size={4} />
      <Paragraph size="SM" $color="fgSecondary">
        Join an existing team.
      </Paragraph>
      <Spacer $size={24} />
      <Flex $direction="column" $gap={24}>
        {orgs.map((org) => (
          <JoinTeamCard
            key={org.id}
            org={org}
            onJoin={(orgId) => {
              void joinOrg(orgId)
            }}
            onAskToJoin={(orgId) => {
              void askToJoin(orgId)
            }}
          />
        ))}
      </Flex>
      <Divider $spacing={{ before: 40, after: 36 }}>OR</Divider>
      <Flex $align="center" $gap={16}>
        <Button
          $variant="raised"
          $colorTheme="tint"
          $height={40}
          $width="full"
          $align="center"
          onClick={createOrg}
        >
          Create new team
        </Button>
      </Flex>
    </>
  )
}

export default OnboardingJoinTeam
