import { useBlocker } from 'react-router-dom'
import { useDialog } from './use-dialog'

export const useAlertOnRouteChange = (shouldAlert: boolean): void => {
  const { openAlert } = useDialog()

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      shouldAlert && currentLocation.pathname !== nextLocation.pathname
  )

  if (blocker.state === 'blocked') {
    openAlert({
      message: 'Are you sure you want to leave this page?',
      description: 'All your changes will be lost.',
      cancelText: 'Stay on page',
      confirmText: 'Discard and leave',
      onCancel: () => {
        blocker.reset()
      },
      onConfirm: () => {
        blocker.proceed()
      }
    })
  }
}
