import { Caption } from 'src/components/primitives/typography'
import { CANDIDATES_PAGES_MAX_WIDTH, CONTENT_PADDING, HEADER_HEIGHT, HEADER_PADDING } from 'src/styles/constants'
import styled from 'styled-components'

export const ReadyToSendHeader = styled(Caption)`
  line-height: ${HEADER_HEIGHT};
`

export const ReadyToSendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: ${HEADER_PADDING} 0;
  padding-bottom: 32px;
  gap: ${HEADER_PADDING};
`

export const ReadyToSendPageInner = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 ${CONTENT_PADDING};
  overflow: auto;
  contain: strict;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
`

export const ReadyToSendList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[18]};
  max-width: calc(${CANDIDATES_PAGES_MAX_WIDTH} + 1rem);
  width: 100%;
  height: 100%;
`
