import { useParams } from 'react-router-dom'

const JobInterviewsPage = (): JSX.Element => {
  const params = useParams()
  return (
    <>
      <p>INTERVIEWS PAGE for job id: {params?.jobId}</p>
    </>
  )
}

export default JobInterviewsPage
