import * as Dialog from 'src/components/primitives/dialog'
import { useDialog } from 'src/hooks/use-dialog'
import { CreateJobForm } from 'src/components/blocks/create-job-form'
import * as S from './create-new-position-dialog.styled'
import { DialogId } from 'src/contexts/dialogs'
import { useMemo, useState } from 'react'
import { isNil } from 'lodash'
import { useJobByDepartmentsQuery } from 'src/hooks/queries/use-jobs-by-department'
import { useSession } from 'src/hooks/queries/use-session'
import { BlockCreateNewJobDialog } from '../create-new-job-dialog/block-create-new-job-dialog'

export const CreateNewPositionDialog = (): JSX.Element => {
  const { isDialogOpen, controlDialog, openDialog } = useDialog()
  const [isModal, setIsModal] = useState(true)
  const { data: jobsByDepartment } = useJobByDepartmentsQuery()
  const { data: session } = useSession()
  const numberOfActiveJobs = useMemo(() => {
    return jobsByDepartment ? Object.values(jobsByDepartment).flat().filter((job) => !job.deleted).length : 0
  }, [jobsByDepartment])

  const canAddMoreJobs = useMemo(() => {
    if (isNil(session?.jobsLimit)) {
      return false
    }
    return numberOfActiveJobs < session.jobsLimit
  }, [numberOfActiveJobs, session?.jobsLimit])

  if (!canAddMoreJobs) {
    return (
      <BlockCreateNewJobDialog id={DialogId.CREATE_JOB_POSITION} />
    )
  }

  return (
    <Dialog.Root
      id={DialogId.CREATE_JOB_POSITION}
      isOpen={isDialogOpen(DialogId.CREATE_JOB_POSITION)}
      modal={isModal}
      onOpenChange={(value) => {
        controlDialog(DialogId.CREATE_JOB_POSITION, value)
      }}
      $width="almostFull"
      $height="almostFull"
      $innerPadding={{ top: 0, right: 0, bottom: 0, left: 0 }}
    >
      <Dialog.Portal>
        <Dialog.Header
          title="New Job Position"
          onClose={() => {
            controlDialog(DialogId.CREATE_JOB_POSITION, false)
          }}
        />
        <Dialog.Content>
          <S.ContentWrapper>
            <S.MediaColumn>
              <img src="/onboarding-company-profile.png" />
            </S.MediaColumn>
            <S.FormColumn>
              <CreateJobForm
                redirectType="jobs"
                onCreateJob={() => {
                  controlDialog(DialogId.CREATE_JOB_POSITION, false)
                  openDialog(DialogId.CREATE_SEQUENCE, 'OPTIONS')
                }}
                onMergeLinkVisibility={(isVisible: boolean) => {
                  setIsModal(!isVisible)
                }}
              />
            </S.FormColumn>
          </S.ContentWrapper>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
