import { Icon } from 'src/components/primitives/icon'
import { Avatar } from 'src/components/primitives/avatar'
import { useSession } from 'src/hooks/queries/use-session'
import { useOrgUsersQuery } from 'src/hooks/queries/use-org-users'
import * as S from './action.styled'

interface ActionProps {
  actionType: 'COMMENT' | 'EMAIL'
  children: React.ReactNode
}

export const Action = ({ actionType, children }: ActionProps): JSX.Element => {
  const { data: sessionData } = useSession()
  const { data: orgUsers } = useOrgUsersQuery()

  const getIndicator = (): JSX.Element => {
    if (actionType === 'EMAIL') {
      return <Icon name="pen-square" size={14} color="fgSecondary" />
    }
    if (actionType === 'COMMENT') {
      const orgUser = orgUsers?.find((orgUser) => orgUser.id === sessionData?.user?.id)
      const avatar = { initials: orgUser?.name ?? 'Unknown', photoUrl: orgUser?.profilePhotoUrl }
      return (
        <Avatar
          $type="photo"
          key={avatar.photoUrl ?? avatar.initials}
          $size={16}
          $shape="soft"
          {...avatar}
        />
      )
    }
    return <></>
  }

  return (
    <S.Action>
      <S.Indicator>{getIndicator()}</S.Indicator>
      <S.Content>{children}</S.Content>
    </S.Action>
  )
}
