import { useMutation } from '@tanstack/react-query'
import { useNotification } from 'src/hooks/use-notification'
import { closePinAccount } from 'src/libs/api/backend/orgs'
import type { ClosePinAccountFeedbackArgs } from 'src/libs/api/backend/orgs'

type Args = ClosePinAccountFeedbackArgs & {
  onSuccess?: () => void
  onError?: () => void
}

interface Res {
  deleteOrg: (args: Args) => void
}

export const useDeleteOrg = (): Res => {
  const { notify } = useNotification()

  const mutation = useMutation({
    mutationFn: async ({ reason, reasonDetails, feedbackCall }: Args) => {
      await closePinAccount({ reason, reasonDetails, feedbackCall })
    },
    onError: (err, variables) => {
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: `An error occurred when disabling the user: ${err.message}`
      })
      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (_, variables) => {
      if (variables.onSuccess) {
        variables.onSuccess()
      }
    }
  })

  const deleteOrg = (args: Args): void => {
    mutation.mutate(args)
  }

  return { deleteOrg }
}
