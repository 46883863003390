import { useMutation } from '@tanstack/react-query'
import { useNotification } from '../use-notification'
import queryClient from '../query-client'
import { queryKeys } from 'src/libs/query-keys'
import { pauseCandidateJobSequence } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { isNil, keyBy } from 'lodash'
import { useParams } from 'react-router'
import type { CandidateSequenceStepMessageReviewExtended } from 'src/libs/api/backend/candidate_sequence_step_message_review'

interface Args {
  candidateJobIds: string[]
  pause: boolean
  onSuccess?: () => void
}

interface Res {
  setCandidateSequencePause: ({ candidateJobIds, pause }: Args) => void
}

export const usePauseCandidateSequence = (): Res => {
  const { notify } = useNotification()
  const { jobId } = useParams()

  const mutation = useMutation({
    mutationFn: async ({ candidateJobIds, pause }: Args) => {
      return await pauseCandidateJobSequence(candidateJobIds, pause)
    },
    onError: (err) => {
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: `An error occurred when sending email(s): ${err.message}`
      })
    },
    onSuccess: async (candidateJobs: CandidateJobExpanded[], variables) => {
      const candidateJobsById = keyBy(candidateJobs, 'id')

      queryClient.setQueriesData<CandidateJobExpanded[]>({ queryKey: [queryKeys.candidateJobs, jobId] }, (oldCandidateJobs) => {
        if (isNil(oldCandidateJobs)) {
          return oldCandidateJobs
        }

        return oldCandidateJobs.map((o) => {
          if (candidateJobsById[o.id]) {
            return {
              ...o,
              ...candidateJobsById[o.id]
            }
          }

          return o
        })
      })

      queryClient.setQueriesData<CandidateSequenceStepMessageReviewExtended[]>({
        queryKey: [queryKeys.candidateSequenceStepMessageReviews, jobId]
      }, (oldCandidateSequenceStepMessageReviews) => {
        if (isNil(oldCandidateSequenceStepMessageReviews)) {
          return oldCandidateSequenceStepMessageReviews
        }

        return oldCandidateSequenceStepMessageReviews.map((o) => {
          const candidateJobId = o.candidateJob.id
          if (candidateJobsById[candidateJobId]) {
            return {
              ...o,
              candidateJob: {
                ...o.candidateJob,
                ...candidateJobsById[o.candidateJob.id]
              }
            }
          }

          return o
        })
      })

      const pauseState = variables.pause ? 'paused' : 'unpaused'
      const candidateString = candidateJobs.length > 1 ? `${candidateJobs.length} candidates` : 'candidate'
      notify({
        type: 'toast',
        variant: 'positive',
        position: 'bottom-right',
        icon: 'mails',
        message: `Successfully ${pauseState} outreach for ${candidateString}`,
        autoClose: true
      })

      if (variables.onSuccess) {
        variables.onSuccess()
      }
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [queryKeys.candidateJobs, jobId, { errored: true }]
        }),
        queryClient.invalidateQueries({
          queryKey: [queryKeys.candidateJobCounts, jobId]
        })
      ])
    }
  })

  const setCandidateSequencePause = (args: Args): void => {
    mutation.mutate(args)
  }

  return { setCandidateSequencePause }
}
