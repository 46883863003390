import styled from 'styled-components'

export const ChangeBadge = styled.div<{ $bgThemeColor: string }>`
  min-width: 2rem;
  padding: 0.3rem 0.5rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, $bgThemeColor }) => theme.colors[$bgThemeColor]};
  position: relative;
  p {
    line-height: 1;
    z-index: 1;
  }
`
export const AbsoluteAndCentered = styled.span`
  svg {
    position: absolute;
    left: 50%;           /* Center horizontally */
    top: 50%;            /* Move the top to the center vertically */
    transform: translate(-50%, -50%); /* Offset by its own dimensions */
  }
`

export const Relative = styled.div`
  position: relative;

`
