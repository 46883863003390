import { Card, Spacer } from 'src/components/primitives'
import { Flex } from 'src/components/primitives/flex'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Toggle } from 'src/components/primitives/toggle'
import { useUpdateJobPosition } from 'src/hooks/mutations/use-update-job-position'
import { useParams } from 'react-router'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { useCallback } from 'react'
import type { UpdateJob } from 'src/libs/api/backend/jobs'
import { isNil } from 'lodash'
import { WeekAvailabilityPicker } from '../week-availability-picker'
import { TagInput } from 'src/components/primitives/tag-input'

export const CalendarSettings = (): JSX.Element => {
  const { updateJobPosition } = useUpdateJobPosition()
  const { jobId } = useParams<{ jobId: string }>()
  const { data: jobData } = useJobQuery()
  const handleUpdateJob = useCallback((updatedFields: Partial<UpdateJob>, showToast = true, toastMessage?: string) => {
    if (isNil(jobData)) {
      return
    }
    updateJobPosition({
      updatedJob: {
        id: jobId ?? '',
        ...updatedFields
      },
      showToast,
      toastMessage
    })
  }, [jobData, jobId, updateJobPosition])

  return (
    <Flex $direction="column" $gap={12}>
      <Card>
        <Flex $direction="column" $gap={2}>
          <Caption size="SM">Event attendees</Caption>
          <Paragraph size="XS" $color="fgSecondary">
            When Pin suggests or creates events, invite these people automatically
          </Paragraph>
        </Flex>
        <Spacer $size={16} />
        <TagInput
          placeholder='Email addresses'
          defaultTags={jobData?.calendarInviteeList}
          onTagsChange={(tags) => {
            const inviteesList = jobData?.calendarInviteeList ?? []
            if (tags.length !== inviteesList.length || inviteesList.some((invitee) => !tags.includes(invitee))) {
              handleUpdateJob({ calendarInviteeList: tags }, false)
            }
          }}
          containerPadding={10}
          type='email'
        />
      </Card>
      <Card>
        <Flex $direction="column" $gap={2}>
          <Caption size="SM">Availability</Caption>
          <Paragraph size="XS" $color="fgSecondary">
            Choose weekdays and time of day when meetings can be scheduled.
          </Paragraph>
        </Flex>
        <Spacer $size={16} />
        <WeekAvailabilityPicker
          defaultAvailability={jobData?.calendarWhenToSchedule}
          onAvailabilityChange={(updated) => {
            handleUpdateJob({ calendarWhenToSchedule: updated }, true, 'Successfully updated availability')
          }}
        />
      </Card>
      <Card>
        <Flex $align="center" $justify="space-between">
          <Flex $direction="column" $gap={2}>
            <Caption size="SM">Skip holidays</Caption>
            <Paragraph size="XS" $color="fgSecondary">
              Don&lsquo;t schedule on holidays.
            </Paragraph>
          </Flex>
          <Toggle
            name="skipHolidays"
            checked={jobData?.calendarSkipHolidays ?? false}
            onChange={() => {
              handleUpdateJob({ calendarSkipHolidays: !jobData?.calendarSkipHolidays })
            }}
          />
        </Flex>
      </Card>
    </Flex>
  )
}
