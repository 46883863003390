import { CONTENT_PADDING, INBOX_MAX_WIDTH } from 'src/styles/constants'
import styled from 'styled-components'

export const InboxActions = styled.div`
  border-top: solid 1px;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
  background-color: ${({ theme }) => theme.colors.bgSecondary};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  z-index: 10;
  bottom: 0;
  // max-width: calc(100% + 2rem);
`

export const InboxActionsInner = styled.div`
  display: flex;
  padding: 1rem ${CONTENT_PADDING};
  padding-left: calc(${CONTENT_PADDING} + 2rem);
  width: 100%;
`

export const InboxTimelineAction = styled.div`
  max-width: ${INBOX_MAX_WIDTH};
  padding: 0 ${CONTENT_PADDING};
`
