import { CandidatesPageHeader } from 'src/components/blocks/candidates-page-header'
import { EmptyState } from 'src/components/blocks/empty-state'
import RouteBuilder from 'src/libs/route-builder'

const JobCandidatesScheduledPage = (): JSX.Element => {
  return (
    <>
      <CandidatesPageHeader heading="Scheduled" />
      <EmptyState
        heading="No one scheduled"
        description="Looks like you have no candidates scheduled for meetings yet, you’ll start seeing them here once you start setting up a call with them."
        svg="scheduleCards"
        actions={[
          {
            href: RouteBuilder.build('JOBS_CANDIDATES_SOURCING'),
            children: 'Go to sourcing'
          }
        ]}
      />
    </>
  )
}

export default JobCandidatesScheduledPage
