import { isNil } from 'lodash'
import { useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { CandidatesPageHeader } from 'src/components/blocks/candidates-page-header'
import { EmptyState } from 'src/components/blocks/empty-state'
import { LoadingSkeleton } from 'src/components/blocks/loading-skeleton'
import { useCandidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'
import { CandidateJobStage } from 'src/libs/api/backend/candidate_jobs'
import RouteBuilder from 'src/libs/route-builder'
import { CandidatesTablePagesContentInner } from '../candidates/candidates.styled'
import { When } from 'src/components/blocks/when'
import { EmptyStateArchivedJob } from 'src/components/blocks/empty-state-archived-job'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { SEO } from '../../../components/primitives/seo'

const JobInboxPage = (): JSX.Element => {
  const { jobId } = useParams()

  const navigate = useNavigate()

  const { data: job } = useJobQuery()
  const [searchParams] = useSearchParams()
  const stage = searchParams.get('stage')
  const candidateJobStage = stage as CandidateJobStage
  const lastCommunicatedWithUserId = searchParams.get('lastCommunicatedWithUserId')

  const { isPending, data: candidateJobsData } = useCandidateJobsQuery({
    stage: stage === 'null'
      ? undefined
      : Object.values(CandidateJobStage).includes(candidateJobStage)
        ? candidateJobStage
        : CandidateJobStage.COMMUNICATING,
    lastCommunicatedWithUserId: lastCommunicatedWithUserId ?? undefined
  })

  // This helps to navigate to the first candidate job when the page is loaded
  useEffect(() => {
    if (!isPending && !isNil(candidateJobsData) && candidateJobsData.length) {
      const path = RouteBuilder.build('JOBS_INBOX_CANDIDATE_JOBS', {
        jobId,
        candidateJobId: candidateJobsData?.[0].id
      }, {
        stage: candidateJobStage,
        lastCommunicatedWithUserId
      })
      navigate(path)
    }
  }, [isPending, candidateJobsData, jobId, candidateJobStage, lastCommunicatedWithUserId, navigate])

  if (isPending || isNil(candidateJobsData) || candidateJobsData.length) {
    return (
      <LoadingSkeleton $variant="CandidatesTable" />
    )
  }

  if (searchParams.toString().length > 0) {
    return (
      <>
        <SEO title={lastCommunicatedWithUserId ? 'My Communications' : 'All In Inbox'} />

        <CandidatesTablePagesContentInner>
          <CandidatesPageHeader heading="Inbox" />
          <When condition={!job?.deleted}>
            <EmptyState
              $padding={{ top: 0, bottom: 24, left: 0, right: 0 }}
              svg="archiveDots"
              heading="It's empty here"
              description="Looks like there's nothing in this inbox. Try looking at a different inbox."
              actions={[
                {
                  href: RouteBuilder.build('JOBS_INBOX', { jobId }),
                  children: 'View entire inbox'
                }
              ]}
            />
          </When>
          <When condition={Boolean(job?.deleted)}>
            <EmptyStateArchivedJob />
          </When>
        </CandidatesTablePagesContentInner>
      </>
    )
  }

  return (
    <>
      <SEO title={lastCommunicatedWithUserId ? 'My inbox' : 'All in inbox'} />

      <CandidatesTablePagesContentInner>
        <CandidatesPageHeader heading="Inbox" />
        <When condition={!job?.deleted}>
          <EmptyState
            $padding={{ top: 0, bottom: 24, left: 0, right: 0 }}
            svg="archiveDots"
            heading="It's empty here"
            description="Looks like you have nothing in the inbox, start by sourcing and move candidates to next step to start viewing all their updates here."
            actions={[
              {
                href: RouteBuilder.build('JOBS_CANDIDATES_SOURCING', { jobId }),
                children: 'Go to candidates'
              }
            ]}
          />
        </When>
        <When condition={Boolean(job?.deleted)}>
          <EmptyStateArchivedJob />
        </When>
      </CandidatesTablePagesContentInner>
    </>
  )
}

export default JobInboxPage
