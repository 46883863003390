import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNotification } from 'src/hooks/use-notification'
import { createJobSearchRefinement as createJobSearchRefinementApi } from 'src/libs/api/backend/jobs'
import type { Job, JobSearchRefinement } from 'src/libs/api/backend/jobs'
import { queryKeys } from 'src/libs/query-keys'

interface Args {
  jobId: Job['id']
  searchQuery: JobSearchRefinement['searchQuery']
  onSuccess?: (jobSearchRefinement: JobSearchRefinement) => void
  onError?: () => void
}

interface Res {
  createJobSearchRefinement: ({ jobId, searchQuery, onSuccess, onError }: Args) => void
}

export const useCreateJobSearchRefinement = (): Res => {
  const queryClient = useQueryClient()
  const { notify } = useNotification()

  const mutation = useMutation({
    mutationFn: async ({ jobId, searchQuery }: Args) =>
      await createJobSearchRefinementApi(jobId, searchQuery),
    onError: (err, variables) => {
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: `An error occurred when creating your search: ${err.message}`
      })
      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (jobSearchRefinement, variables) => {
      queryClient.setQueriesData<JobSearchRefinement[]>(
        {
          queryKey: [queryKeys.jobSearchRefinements, variables.jobId]
        },
        (oldJobSearchRefinements) => {
          if (!oldJobSearchRefinements) {
            return [jobSearchRefinement]
          }
          return [...oldJobSearchRefinements, jobSearchRefinement]
        }
      )
      if (variables.onSuccess) {
        variables.onSuccess(jobSearchRefinement)
      }
    }
  })

  const createJobSearchRefinement = (args: Args): void => {
    mutation.mutate(args)
  }

  return { createJobSearchRefinement }
}
