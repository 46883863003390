import React from 'react'
import { QueryClientProvider } from '@tanstack/react-query'
import queryClient from 'src/hooks/query-client'
import { GlobalContext } from 'src/contexts/global'
import { ThemeProvider } from 'styled-components'
import { GlobalReset, GlobalStyle } from 'src/styles/global'
import { lightTheme } from 'src/styles/theme'
import { AuthProvider } from './auth'
import { NotificationProvider } from './notification'
import { SettingsProvider } from './settings'
import { DialogProvider } from './dialog'
import { AblyProvider } from 'ably/react'
import ablyClient from 'src/hooks/ably-client'
import { ChannelsProvider } from './ably-channels'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import PostHogPageviewTrackerProvider from './posthog-pageview-tracker'

type GlobalProviderProps = React.PropsWithChildren & {
  globalState: object
}

const GlobalProvider = ({ children, globalState }: GlobalProviderProps): JSX.Element => {
  return (
    <GlobalContext.Provider value={globalState}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <PostHogProvider client={posthog}>
            <PostHogPageviewTrackerProvider />
            <AblyProvider client={ablyClient}>
              <ThemeProvider theme={lightTheme}>
                <SettingsProvider>
                  <GlobalReset />
                  <GlobalStyle />
                  <ChannelsProvider>
                    <NotificationProvider>
                      <DialogProvider>{children}</DialogProvider>
                    </NotificationProvider>
                  </ChannelsProvider>
                </SettingsProvider>
              </ThemeProvider>
            </AblyProvider>
          </PostHogProvider>
        </AuthProvider>
      </QueryClientProvider>
    </GlobalContext.Provider>
  )
}

export default GlobalProvider
