import { createContext } from 'react'
import type { IconName } from 'src/components/primitives/icon'

export type NotificationType = 'toast' | 'candidate-added' | 'candidate-rejected' | 'candidate-moved' | 'candidate-restored' | 'failed-adding-candidates'

export const CUSTOM_NOTIFICATIONS: NotificationType[] = ['candidate-added', 'candidate-rejected', 'candidate-moved', 'candidate-restored']

export const CUSTOM_NOTIFICATION_MESSAGE: NotificationType[] = ['failed-adding-candidates']

export interface Notification<T = unknown> {
  id: string
  type?: NotificationType
  position?: 'bottom-center' | 'bottom-right'
  variant: 'neutral' | 'warning' | 'positive' | 'negative' | 'tint'
  icon?: IconName
  message?: string | React.ReactNode
  messageContext?: string
  delay?: number
  autoClose?: boolean
  payload?: T
}

export interface NotificationContextProps {
  notifications: Notification[]
  notify: (notification: Omit<Notification, 'id'>) => void
  close: (id: Notification['id']) => void
  clear: () => void
}

export const NotificationContext = createContext<NotificationContextProps>({
  notifications: [],
  notify: () => {},
  close: () => {},
  clear: () => {}
})
