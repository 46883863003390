import type { Spacing } from 'src/styles/theme/types'
import styled from 'styled-components'

export const Container = styled.div<{ $containerPadding?: Spacing }>`
  background: ${({ theme }) => theme.colors.bgPrimary};
  border: 1px solid ${({ theme }) => theme.colors.borderTranslucent};
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  padding: ${({ theme, $containerPadding }) => theme.spacing[$containerPadding ?? 4]};
  width: 100%;
`

export const TagList = styled.ul`
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 100%;
  background: none;
  flex-grow: 1;
  gap: ${({ theme }) => theme.spacing[4]};
  padding: 0;
`

export const TagListItem = styled.li`
  align-items: center;
  background: ${({ theme }) => theme.colors.bgTertiary};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.fgPrimary};
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes[12]};
  font-weight: ${({ theme }) => theme.fontWeights[400]};
  list-style: none;
  padding-left: ${({ theme }) => theme.spacing[6]};
`

export const TagListItemRemoveButton = styled.button`
  align-items: center;
  appearance: none;
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.fgSecondary};
  cursor: pointer;
  display: inline-flex;
  font-size: ${({ theme }) => theme.fontSizes[12]};
  height: 100%;
  justify-content: center;
  line-height: 0;
  margin-left: ${({ theme }) => theme.spacing[4]};
  padding: 0;
  width: 12px;
`

export const TagListInput = styled(TagListItem)`
  background: none;
  flex-grow: 1;
  padding-left: 0;

  input {
    font-size: ${({ theme }) => theme.fontSizes[12]};
    font-weight: ${({ theme }) => theme.fontWeights[400]};
    border: none;
    width: 100%;
    padding-left: 4px;

    &:focus {
      outline: none;
    }
  }
`
