import styled from 'styled-components'

export const TabularChart = styled.div``

export const Table = styled.table`
  width: 100%;
  border-spacing: 0 0.25rem;
`

export const Head = styled.thead`
  position: relative;
  tr {
    th {
      padding: ${({ theme }) => theme.spacing[10]} ${({ theme }) => theme.spacing[12]};
      padding-top: 0;
      text-align: left;
      font-size: ${({ theme }) => theme.fontSizes[12]};
      font-weight: ${({ theme }) => theme.fontWeights[500]};
      line-height: ${({ theme }) => theme.lineHeights['1.33']};
      letter-spacing: ${({ theme }) => theme.letterSpacings['0.12']};
      color: ${({ theme }) => theme.colors.fgPrimary};
      &:not(:first-of-type) {
        text-align: center;
      }

      &:first-child {
        padding-left: 0;
      }
    }
  }
`

export const Body = styled.tbody`
  tr {
    border-bottom: solid 0.25rem;
    border-color: ${({ theme }) => theme.colors.bgPrimary};
    &:nth-child(odd) {
      background-color: ${({ theme }) => theme.colors.bgSecondary};
    }
    @media (hover: hover) {
      &:hover {
        background-color: ${({ theme }) => theme.colors.bgTertiary};
      }
    }
    td {
      padding: ${({ theme }) => theme.spacing[10]} ${({ theme }) => theme.spacing[12]};
      font-size: ${({ theme }) => theme.fontSizes[12]};
      font-weight: ${({ theme }) => theme.fontWeights[400]};
      line-height: ${({ theme }) => theme.lineHeights['1.33']};
      &:not(:first-of-type) {
        color: ${({ theme }) => theme.colors.fgSecondary};
        text-align: center;
      }
      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
`
