import { sortBy } from 'lodash'
import { useMemo } from 'react'
import { Button } from 'src/components/primitives'
import { Avatar } from 'src/components/primitives/avatar'
import { Dropdown } from 'src/components/primitives/dropdown'
import { useSendableAddresses } from 'src/hooks/queries/use-sendable-addresses'
import type { EmailAccount } from 'src/libs/api/backend/users'

export interface EmailAccountWithProfilePhoto extends EmailAccount {
  profilePhotoUrl?: string | null
}

interface SenderSelectionProps {
  handleSenderChange: (emailAccount: EmailAccountWithProfilePhoto) => void
  userEmailAccounts: EmailAccountWithProfilePhoto[]
  selectedEmailAccount?: EmailAccountWithProfilePhoto
  disableEmailAccountSelection?: boolean
}

export const SenderSelection = ({
  handleSenderChange,
  userEmailAccounts,
  selectedEmailAccount,
  disableEmailAccountSelection = false
}: SenderSelectionProps): JSX.Element => {
  const sendableAddresses = useSendableAddresses()

  const emailDropdownItems = useMemo(() => {
    let items = userEmailAccounts.map((emailAccount) => {
      const sendable = sendableAddresses.find((sendableAddress) => sendableAddress?.id === emailAccount.id)
      const hasAccessToken = emailAccount.hasAccessToken

      let trailingIcon
      let trailingTooltip
      if (!sendable) {
        trailingIcon = 'lock'
        trailingTooltip = {
          text: "The current user doesn't have permission to send emails as this account. Please request permission and try again."
        }
      } else if (!hasAccessToken) {
        trailingIcon = 'negativeBg'
        trailingTooltip = {
          text: 'Email account not connected.'
        }
      } else {
        trailingIcon = undefined
        trailingTooltip = undefined
      }

      return {
        id: emailAccount.id,
        title: emailAccount.email,
        value: emailAccount.id,
        isDisabled: !sendable,
        trailingTooltip,
        trailingIcon,
        onSelect: () => {
          handleSenderChange(emailAccount)
        }
      }
    }).sort((a, b) => {
      // sort alphabetically
      return a.title.localeCompare(b.title)
    })

    items = sortBy(items, (item) => {
      if (item.isDisabled) {
        return 2
      }
      if (item.trailingIcon === 'negativeBg') {
        return 1
      }
      return 0
    })

    return items
  }, [userEmailAccounts, sendableAddresses, handleSenderChange])
  return (
    <Dropdown
      trigger={
        <Button
          disabled={disableEmailAccountSelection}
          nested
          $variant="raised-light"
          $height={24}
          $fontSize={12}
          leadingIcon={
            <Avatar
              $shape="circle"
              $border={false}
              $size={12}
              photoUrl={selectedEmailAccount?.profilePhotoUrl}
              initials={selectedEmailAccount?.email[0]}
            />
          }
          trailingIcon="chevrons-up-down-small"
        >
          {selectedEmailAccount?.email}
        </Button>
      }
      disabled={disableEmailAccountSelection}
      items={emailDropdownItems}
      size="small"
    />
  )
}
