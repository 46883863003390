import { useParams } from 'react-router'
import { useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { queryKeys } from 'src/libs/query-keys'
import { fetchCandidateJobs } from 'src/libs/api/backend/candidate_jobs'
import type {
  CandidateJobExpanded,
  FetchCandidatesSearchParams
} from 'src/libs/api/backend/candidate_jobs'
import { isNil } from 'lodash'

interface UseQueryOpts {
  refetchInterval?: number
}

export const candidateJobsQuery = (
  jobId: string,
  searchParams: FetchCandidatesSearchParams = {}
) => ({
  queryKey: [queryKeys.candidateJobs, jobId, searchParams],
  queryFn: async () => await fetchCandidateJobs(jobId, searchParams),
  staleTime: 60 * 1000,
  retry: 0
})

export const useCandidateJobsQuery = (
  searchParams: FetchCandidatesSearchParams = {},
  useQueryOpts: UseQueryOpts = {}
): UseQueryResult<CandidateJobExpanded[]> => {
  const { jobId } = useParams()
  if (isNil(jobId)) {
    throw new Error('Missing jobId')
  }

  const query = useQuery({
    ...candidateJobsQuery(jobId, searchParams),
    ...useQueryOpts
  })
  return query
}
