import { Button } from 'src/components/primitives/button'
import type { EmailSequenceStep } from 'src/models/sequence'
import { Flex } from 'src/components/primitives/flex'
import { When } from '../when'
import { WaitDays } from './waitdays'
import { Paragraph } from 'src/components/primitives/typography'
import * as S from './email-sequence-editor.styled'
import { SequenceReply, SequenceStepType } from 'src/libs/api/backend/sequences'
import type { SequenceReplyType } from 'src/libs/api/backend/sequences'
import { Dropdown } from 'src/components/primitives/dropdown'
import { isNil } from 'lodash'
import { useDialog } from 'src/hooks/use-dialog'
import { DialogId } from 'src/contexts/dialogs'
import { EmailComposer } from '../email-composer'
import { EmailSequenceStepTypeSelector } from './email-sequence-step-type-selector'
import { Icons } from 'src/components/primitives/icon'

interface SequenceStepProps {
  initialStepBody?: string
  step: EmailSequenceStep
  totalSteps: number
  isGenerating?: boolean
  onReplyTypeUpdate?: (replyType: SequenceReplyType) => void
  onRemoveStep: () => void
  onReorder: (newPosition: number) => void
  onDataChanged: (updated: EmailSequenceStep) => void
  onCancelGeneratingEmail?: () => void
  getStepSubjectAndPlaceholder: (stepPosition: number) => {
    subject: string | null
    placeholder: string | null
  }
  forceEditorFocus: boolean
  emailSuggestionFooter?: JSX.Element | null
}

export const SequenceStep = ({
  initialStepBody,
  step,
  // totalSteps,
  isGenerating = false,
  onDataChanged,
  onReplyTypeUpdate,
  onRemoveStep,
  // onReorder,
  onCancelGeneratingEmail,
  getStepSubjectAndPlaceholder,
  forceEditorFocus,
  emailSuggestionFooter,
  ...emailComposerProps
}: SequenceStepProps): JSX.Element => {
  const { controlDialog, openAlert } = useDialog()
  const { subject, placeholder: subjectPlaceholder } = getStepSubjectAndPlaceholder(step.position)
  return (
    <>
      <S.SequenceStep id={`step-${step.position}`}>
        <S.StepHeader>
          <When condition={step.position === 0}>
            <Paragraph size="XS" $whiteSpace="nowrap">
              When added to outreach
            </Paragraph>
          </When>
          <When condition={step.position !== 0}>
            <Flex $width="auto" $align="center" $gap={10} $justify="flex-start">
              <WaitDays
                waitDays={step.waitDays ?? 0}
                onWaitDaysUpdate={(waitDays: number) => {
                  const updatedStep = {
                    ...step,
                    waitDays
                  }
                  onDataChanged(updatedStep)
                }}
                isGenerating={isGenerating}
              />
              <Dropdown
                disabled={isGenerating}
                trigger={
                  <Button
                    nested
                    trailingIcon={Icons.chevronsUpDownSmall}
                    $height={24}
                    $fontSize={12}
                    $variant="raised"
                    $colorTheme="normal"
                    disabled={isGenerating}
                  >
                    {isNil(step.subject) ? 'Reply on previous thread' : 'Start new thread'}
                  </Button>
                }
                selectedValue={step.subject === null ? SequenceReply.REPLY_TO_PREVIOUS_THREAD : SequenceReply.NEW_THREAD}
                showActiveIcon={true}
                items={[
                  {
                    id: SequenceReply.NEW_THREAD,
                    title: 'Start new thread',
                    onSelect: () => {
                      onReplyTypeUpdate?.(SequenceReply.NEW_THREAD)
                    }
                  },
                  {
                    id: SequenceReply.REPLY_TO_PREVIOUS_THREAD,
                    title: 'Reply on previous thread',
                    onSelect: () => {
                      onReplyTypeUpdate?.(SequenceReply.REPLY_TO_PREVIOUS_THREAD)
                    }
                  }
                ]}
                menuPosition="end"
                size="small"
              />
            </Flex>
          </When>
          <EmailSequenceStepTypeSelector
              type={step.type ?? SequenceStepType.AUTOMATED_EMAIL}
              isGenerating={isGenerating}
              onSequenceStepTypeUpdate={(type) => {
                const updatedStep = {
                  ...step,
                  type
                }
                onDataChanged(updatedStep)
              }}
            />
          <Flex $gap={4} $justify="flex-end">
            <When condition={step.waitDays !== 0}>
              <>
                {/*
                <Button
                  $variant="ghost"
                  $colorTheme="muted"
                  leadingIcon="arrow-up"
                  $width={24}
                  $height={24}
                  $fontSize={12}
                  disabled={step.position === 1}
                  onClick={() => {
                    onReorder(step.position - 1)
                  }}
                />
                <Button
                  $variant="ghost"
                  $colorTheme="muted"
                  leadingIcon="arrow-down"
                  $width={24}
                  $height={24}
                  $fontSize={12}
                  disabled={step.position >= totalSteps}
                  onClick={() => {
                    onReorder(step.position + 1)
                  }}
                />
                */}
                <Button
                  $variant="ghost"
                  $colorTheme="negative"
                  leadingIcon={Icons.trash}
                  $width={24}
                  $height={24}
                  $fontSize={12}
                  onClick={() => {
                    openAlert({
                      message: 'Delete outreach step',
                      description: 'Any scheduled candidates will be moved to the next available step.',
                      confirmText: 'Delete step',
                      onConfirm: () => {
                        onRemoveStep()
                        controlDialog(DialogId.ALERT, false)
                      }
                    })
                  }}
                />
              </>
            </When>
          </Flex>
        </S.StepHeader>
        <S.StepContent>
          <EmailComposer
            forceEditorFocus={forceEditorFocus}
            isGenerating={isGenerating}
            initialEmailBody={initialStepBody}
            subject={subject}
            subjectPlaceholder={subjectPlaceholder === '' ? '(no subject)' : subjectPlaceholder}
            body={step.body?.replace(/\n/g, '<br />')}
            sendingEmailAccountId={step.sendingEmailAccountId}
            emailSuggestionFooter={emailSuggestionFooter}
            sendingUserId={step.sendingUserId}
            onDataChanged={(updated) => {
              onDataChanged({
                ...step,
                subject: updated.subject,
                body: updated.body,
                sendingEmailAccountId: updated.sendingEmailAccountId,
                sendingUserId: updated.sendingUserId
              })
            }}
            onCancelGeneratingEmail={onCancelGeneratingEmail}
            useVariables={true}
            {...emailComposerProps}
          />
        </S.StepContent>
      </S.SequenceStep>
    </>
  )
}
