import styled from 'styled-components'
import type { EmptyStateStylingProps } from './empty-state'
// import { CONTENT_PADDING } from 'src/styles/constants'

export const EmptyState = styled.div<EmptyStateStylingProps>`
  height: ${({ $height }) => {
    if ($height === 'full') {
      return 'calc(100vh)'
    }

    if ($height === 'full-with-header') {
      return '100%'
    }

    return $height
  }};
  width: 100%;

  padding-top: ${({ $padding, theme }) => theme.spacing[$padding?.top ?? 24]};
  padding-right: ${({ $padding, theme }) => theme.spacing[$padding?.right ?? 24]};
  padding-bottom: ${({ $padding, theme }) => theme.spacing[$padding?.bottom ?? 24]};
  padding-left: ${({ $padding, theme }) => theme.spacing[$padding?.left ?? 24]};

  background: ${({ $background }) => $background};
  border-radius: ${({ $borderRadius }) => $borderRadius};
`

export const EmptyStateInner = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4.5rem;
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.bgTertiary};
`

const Column = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const Media = styled(Column)`
  flex: 0 1 260px;
`

export const Text = styled(Column)`
  flex: 0 1 320px;
  align-items: flex-start;
  gap: 1.5rem;
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`
