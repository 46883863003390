import { useState } from 'react'
import { SettingsContext } from 'src/contexts/settings'

export const SettingsProvider = ({ children }: React.PropsWithChildren): JSX.Element => {
  const [currentJobId, setCurrentJobId] = useState<string | null>(null)

  return (
    <SettingsContext.Provider value={{ currentJobId, setCurrentJobId }}>
      {children}
    </SettingsContext.Provider>
  )
}
