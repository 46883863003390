import { useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { LocalStorageKey } from 'src/constants'
import { useLocalStorage } from 'usehooks-ts'

export const JobLayout = (): JSX.Element => {
  const { jobId } = useParams()
  const [_lastViewedJobId, setLastViewedJobId] = useLocalStorage<string | null | undefined>(LocalStorageKey.LAST_VIEWED_JOB, null)

  useEffect(() => {
    if (jobId) {
      setLastViewedJobId(jobId)
    }
  }, [jobId, setLastViewedJobId])

  return (
    <>
      <Outlet />
    </>
  )
}
