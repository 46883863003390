import { useEffect, useState } from 'react'
import { EmptyState } from 'src/components/blocks/empty-state'
import { CandidatesTable } from '../candidates-table'
import { useCandidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'
import { RejectButton } from '../candidate-table-actions'
import { useQueryParams } from 'src/hooks/use-query-params'
import { DialogId } from 'src/contexts/dialogs'
import { useDialog } from 'src/hooks/use-dialog'
import { isNil } from 'lodash'
import RouteBuilder from 'src/libs/route-builder'
import { useParams } from 'react-router'
import { COLUMN } from '../candidates-table/table-builder'

export const CandidatesShortlistedTable = (): JSX.Element => {
  const { getParam } = useQueryParams()
  const { jobId } = useParams()
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({})
  const { isPending, data: candidateJobs } = useCandidateJobsQuery({
    favorite: true
  })

  // For extension to open dialog with write email
  const writeManualEmailParams = getParam('writeManualEmail')
  const { openDialog, isDialogOpen } = useDialog()

  useEffect(() => {
    if (writeManualEmailParams && !isDialogOpen(DialogId.WRITE_EMAIL) && !isNil(candidateJobs)) {
      openDialog(DialogId.WRITE_EMAIL, candidateJobs.filter((candidateJob) => candidateJob.candidateId === writeManualEmailParams))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [writeManualEmailParams, candidateJobs])

  if (!candidateJobs) {
    return <></>
  }
  return (
    <CandidatesTable
      visibleColumns={[COLUMN.FAVORITE, COLUMN.NAME, COLUMN.STATUS, COLUMN.SOURCE, COLUMN.CREATED_AT]}
      isLoading={isPending}
      candidateJobs={candidateJobs}
      setRowSelection={setRowSelection}
      rowSelection={rowSelection}
      emptyState={
        <EmptyState
          heading="Shortlisted"
          description="Shortlist candidates from Sourcing to create a list of favorite candidates to share with teammates."
          svg="shortlistedCandidates"
          $height="100%"
          actions={[
            {
              href: RouteBuilder.build('JOBS_CANDIDATES_SOURCING', { jobId }),
              children: 'Go to sourcing'
            }
          ]}
        />
      }
      selectedRowsActions={
        <>
          <RejectButton
            selectedRows={rowSelection}
            onResetSelectedRows={() => {
              setRowSelection({})
            }}
          />
        </>
      }
    />
  )
}
