import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useLocation, useParams } from 'react-router-dom'
import { useNotification } from 'src/hooks/use-notification'
import { queryKeys } from 'src/libs/query-keys'
import { candidateJobStageDisplay, createCandidatesByLinkedInUrl } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobStage } from 'src/libs/api/backend/candidate_jobs'

interface Args {
  linkedins: string[]
  stage: CandidateJobStage
  onSuccess?: () => void
  onError?: () => void
}

interface Res {
  createCandidatesByLinkedIn: ({ linkedins, stage, onSuccess, onError }: Args) => void
}

export const useCreateCandidateByLinkedIn = (): Res => {
  const queryClient = useQueryClient()
  const { notify } = useNotification()
  const { jobId } = useParams()
  const location = useLocation()

  const favorite = location.pathname.includes('/shortlisted')

  const mutation = useMutation({
    mutationFn: async ({ linkedins, stage }: Args) => await createCandidatesByLinkedInUrl(linkedins, stage, jobId, favorite),
    onError: (err, variables) => {
      console.error(err)
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: `An error occurred while creating your candidate: ${err.message}`
      })
      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateJobs, jobId]
      })

      notify({
        type: 'toast',
        variant: 'positive',
        position: 'bottom-right',
        icon: 'check-check',
        // message: 'Candidate successfully created',
        message: `${variables.linkedins?.length === 1 ? 'Candidate' : 'Candidates'} added to ${candidateJobStageDisplay[variables.stage]}`
      })
      if (variables.onSuccess) {
        variables.onSuccess()
      }
    }
  })

  const createCandidatesByLinkedIn = ({ linkedins, stage, onSuccess, onError }: Args): void => {
    mutation.mutate({ linkedins, stage, onSuccess, onError })
  }

  return { createCandidatesByLinkedIn }
}
