import { CANDIDATES_PAGES_MAX_WIDTH } from 'src/styles/constants'
import { EmptyStateArchivedJob } from '../empty-state-archived-job'
import { useMemo } from 'react'
import { StatusView } from '../status-view'
import { SourcingView } from './sourcing-view'
import { EmptyState } from '../empty-state'
import { useDialog } from 'src/hooks/use-dialog'
import { DialogId } from 'src/contexts/dialogs'
import { JobSourcingState } from 'src/libs/api/backend/jobs'
import { useParams } from 'react-router'
import { LoadingSkeleton } from '../loading-skeleton'
import { When } from '../when'
import { PinExtensionChromeStoreUrl } from '../../../constants'
import { usePinExtensionVersion } from '../../../hooks/use-pin-extension'
import { useIsChrome } from '../../../hooks/use-is-browser'

interface SourcingStatusProps {
  isSourcingView: boolean
  sourcingState?: JobSourcingState | null
  isManualSourceView: boolean
  isRecommending: boolean
  isEmpty: boolean
  lastRequestedAt: Date
  isArchived: boolean
  isLoading: boolean
}

export const SourcingStatus = ({
  isSourcingView,
  sourcingState,
  isManualSourceView,
  isRecommending,
  isEmpty,
  lastRequestedAt,
  isArchived,
  isLoading
}: SourcingStatusProps): JSX.Element | null => {
  const { openDialog } = useDialog()

  const { jobId } = useParams()

  const isSourcingErrorView = useMemo(() => {
    const sourcingErrorStates = [
      JobSourcingState.NO_CANDIDATES_ERROR,
      JobSourcingState.INTERNAL_ERROR
    ]
    return sourcingErrorStates.some((errorState) => sourcingState === errorState)
  }, [sourcingState])

  const pinExtensionVersion = usePinExtensionVersion()
  const isChrome = useIsChrome()

  const content = useMemo(() => {
    if (isArchived) {
      return <EmptyStateArchivedJob />
    }
    if (isSourcingErrorView) {
      return (
        <StatusView
          pin="sad"
          animatePin
          heading="Something went wrong"
          description="Can’t find candidates at this moment."
        />
      )
    }
    if (isLoading) {
      return <LoadingSkeleton $variant="CandidateDetailsCard" />
    }
    if (isSourcingView) {
      return (
        <SourcingView
          isRecommending={isRecommending}
          isEmpty={isEmpty}
          lastRequestedAt={lastRequestedAt}
        />
      )
    }
    if (isManualSourceView) {
      return (
        <EmptyState
          heading="Manually Added"
          description="Add candidates by their LinkedIn URL to manually add them for consideration."
          svg="userCards"
          actions={[
            {
              children: 'Add candidate',
              onClick: () => {
                openDialog(DialogId.ADD_CANDIDATE, { jobId })
              }
            },
            ...(pinExtensionVersion != null || !isChrome
              ? []
              : [
                  {
                    children: 'Install Chrome Extension',
                    href: PinExtensionChromeStoreUrl,
                    leadingIcon: 'chrome'
                  }])
          ]}
        />
      )
    }
    return null
  }, [
    isArchived,
    isEmpty,
    isLoading,
    isManualSourceView,
    isRecommending,
    isSourcingErrorView,
    isSourcingView,
    jobId,
    lastRequestedAt,
    openDialog,
    pinExtensionVersion
  ])

  return (
    <When condition={isSourcingView || isEmpty || isSourcingErrorView || isLoading || isArchived}>
      <div
        style={{
          // maxWidth: renderedCandidates?.length ? CANDIDATES_PAGES_MAX_WIDTH : '100%',
          maxWidth: CANDIDATES_PAGES_MAX_WIDTH,
          flex: 1
          // paddingBottom: spacing[48]
        }}
      >
        {content}
      </div>
    </When>
  )
}
