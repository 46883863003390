import { NavLink } from 'react-router-dom'
import * as StyledButton from 'src/components/primitives/button/button.styled'
import type { Spacing } from 'src/styles/theme/types'
import styled, { css } from 'styled-components'

export const Navigation = styled.div<{ $isCollapsed: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${({ $isCollapsed }) => ($isCollapsed ? 'center' : 'flex-start')};
  justify-content: space-between;
  gap: 1rem;
  z-index: ${({ theme }) => theme.zindeces.navigation};
  padding: ${({ $isCollapsed }) => ($isCollapsed ? '1rem 0' : '1rem')};
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
`

export const MainContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
`

export const NavigationItems = styled.div<{ $isCollapsed: boolean }>`
  display: flex;
  flex-direction: column;
  // gap: 0.5rem;
  width: 100%;
  ${({ $isCollapsed }) => $isCollapsed && `
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`

export const NavigationItem = styled.div<{ $isActive: boolean }>`
  position: relative;
  padding: ${({ theme }) => theme.spacing[4]};
  color: ${({ theme }) => theme.colors.fgPrimary};

  ${({ $isActive, theme }) => $isActive && `
    // padding: ${theme.spacing[4]};
    background-color: ${theme.colors.bgTertiary};
    border-radius: 4px;
  `}
`

export const Subitems = styled.div<{ $isCollapsed: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  padding-top: 0.5rem;
  ${({ $isCollapsed }) =>
    $isCollapsed &&
    `
    align-items: center;
  `}
  &:after {
    position: absolute;
    content: '';
    width: 2px;
    height: calc(100% - 14px);
    top: 0;
    left: ${({ $isCollapsed }) => $isCollapsed ? '18px' : '17px'};
    background-color: ${({ theme }) => theme.colors.borderOpaque};
    z-index: 2;
  }
`

export const SubitemIcon = styled.span<{ $isActiveItem: boolean, $isActiveSubItem: boolean, $isCollapsed: boolean }>`
  position: relative;
  flex: 0 0 1rem;
  top: 0;
  width: 1rem;
  height: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transform: ${({ $isCollapsed }) => ($isCollapsed ? 'translate(8px, 0)' : 'translate(10px, 0)')};
  background-color: ${({ $isActiveItem, $isActiveSubItem, theme }) => $isActiveSubItem ? theme.colors.borderOpaque : $isActiveItem ? theme.colors.bgTertiary : 'transparent'};
  cursor: pointer;
  z-index: 2;
`

export const SubitemIndicator = styled.span<{ $isActiveSubItem: boolean, $isCollapsed: boolean }>`
  position: relative;
  flex: 0 0 6px;
  top: 0;
  width: 6px;
  height: 6px;
  border-radius: 999px;
  transform: ${({ $isCollapsed }) => ($isCollapsed ? 'translate(12px, 0)' : 'translate(15px, 0)')};
  background-color: ${({ $isActiveSubItem, theme }) =>
    theme.colors[$isActiveSubItem ? 'fgPrimary' : 'fgTertiary']};
  z-index: 2;
`

const subitemStyles = css<{ $isActiveSubItem: boolean, $isCollapsed: boolean, $hasIcon: boolean }>`
  position: relative;
  width: ${({ $isCollapsed }) => ($isCollapsed ? '2rem' : '100%')};
  height: 1.75rem;
  display: flex;
  align-items: center;
  gap: 0.625rem;
  color: ${({ $isActiveSubItem, theme }) =>
    $isActiveSubItem ? theme.colors.fgPrimary : theme.colors.fgSecondary};
  background-color: ${({ $isActiveSubItem, theme }) =>
    $isActiveSubItem ? theme.colors.borderOpaque : 'transparent'};
  border-radius: 4px;
  padding-right: 0.2rem;
  z-index: 3;

  &:hover {
    background-color: ${({ theme }) => theme.colors.borderOpaque};

    ${SubitemIcon} {
      background-color: ${({ theme }) => theme.colors.borderOpaque};
    }
  }

  ${StyledButton.Button} {
    background-color: transparent;
    padding-left: ${({ $hasIcon }) => $hasIcon ? '0.38rem' : '1rem'};
    @media (hover: hover) {
      &:hover {
        background-color: transparent;
      }
    }
  }
  ${StyledButton.Inner} {
    max-width: 100%;
  }
  ${StyledButton.Content} {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    text-align: left;
  }
`

export const Subitem = styled(NavLink)<{ $isActiveSubItem: boolean, $isCollapsed: boolean, $hasIcon: boolean }>`
  ${subitemStyles}
`

export const SubitemSeparator = styled.div`
  position: relative;
  padding: 0.5rem 0.875rem 0.25rem 0.9rem;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  z-index: 3;
`

export const SubitemButton = styled.button<{ $isActiveSubItem: boolean, $isCollapsed: boolean, $hasIcon: boolean }>`
  ${subitemStyles}
  outline: none;
  border: none;
`

export const Top = styled.div<{ $isCollapsed: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  justify-content: ${({ $isCollapsed }) => ($isCollapsed ? 'center' : 'space-between')};
  padding-left: ${({ $isCollapsed, theme }) => $isCollapsed ? '0' : theme.spacing[8]};
`

export const Bottom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const UserProfileButton = styled.div<{ $impersonating?: boolean }>`
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[6]};
  padding: ${({ theme }) => theme.spacing[6]};
  border-radius: 6px;
  ${({ $impersonating, theme }) => $impersonating && `
    border: 1px solid ${theme.colors.warningBg};
    background-color: ${theme.colors.warningTranslucent10};
  `}
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme, $impersonating }) => $impersonating ? theme.colors.warningTranslucent10 : theme.colors.fgTranslucent10};
    }
  }
`

export const FeatureButton = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.spacing[4]};
  cursor: pointer;
  height: 32px;
  width: 32px;
  color: ${({ theme }) => theme.colors.fgSecondary};
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.fgTranslucent10};
    }
  }
`

export const Badge = styled.span`
  background-color: ${({ theme }) => theme.colors.tintBg};
  border-radius: ${({ theme }) => theme.spacing[9999]};
  color: ${({ theme }) => theme.colors.bgPrimary};

  padding: 2px 4.5px;
  font-size: 8px;
  font-weight: 500;
  position: absolute;
  top: 2px;
  right: 2px;
`

export const FeatureBanner = styled.div<{ $showArrow?: boolean, $gap?: Spacing }>`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  gap: ${({ theme, $gap }) => theme.spacing[$gap ?? 12]};
  padding: ${({ theme }) => theme.spacing[8]};
  padding-left: ${({ theme }) => theme.spacing[12]};
  border-radius: ${({ theme }) => theme.spacing[6]};
  background-color: ${({ theme }) => theme.colors.tintFaded};
  color: ${({ theme }) => theme.colors.fgPrimary};
  ${({ $showArrow }) => $showArrow &&
    `
      &::after {
        content: '';
        position: absolute;
        right: 43.515px;
        bottom: -4.971px;
        width: 12px;
        height: 12px;
        transform: rotate(45deg);
        background-color: inherit;
      }
    `
  }
`
