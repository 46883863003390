import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { updateJob } from 'src/libs/api/backend/jobs'
import type { UpdateJob } from 'src/libs/api/backend/jobs'
import { useNotification } from '../use-notification'
import { queryKeys } from 'src/libs/query-keys'

interface Args {
  updatedJob: UpdateJob
  showToast?: boolean
  toastMessage?: string
}

interface Res {
  updateJobPosition: ({ updatedJob }: Args) => void
}

export const useUpdateJobPosition = (): Res => {
  const queryClient = useQueryClient()
  const { jobId } = useParams()
  const { notify } = useNotification()

  const mutation = useMutation({
    mutationFn: async ({ updatedJob }: Args) => {
      await updateJob(jobId ?? updatedJob.id, updatedJob)
    },
    onError: (err) => {
      console.error(err)
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: `An error occurred when updating your job: ${err.message}`,
        autoClose: false
      })
    },
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.jobs]
      })
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.jobByDepartments]
      })
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.job, jobId]
      })
      if (variables.showToast) {
        notify({
          type: 'toast',
          variant: 'positive',
          position: 'bottom-right',
          icon: 'check-check',
          message: variables?.toastMessage ?? 'Successfully updated job position',
          autoClose: true
        })
      }
    }
  })

  const updateJobPosition = ({ updatedJob, showToast = true, toastMessage }: Args): void => {
    mutation.mutate({ updatedJob, showToast, toastMessage })
  }

  return { updateJobPosition }
}
