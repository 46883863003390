import { ChartLegend } from '../chart-legend'
import type { ChartLegendProps } from '../chart-legend'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Flex } from 'src/components/primitives/flex'
import * as S from './chart-header.styled'
import { Icon } from 'src/components/primitives/icon'

interface ChartHeaderProps {
  title: string
  subtitle?: string
  context?: string
  legend?: ChartLegendProps['data']
  customLegend?: React.ReactNode
}

export const ChartHeader = ({
  title,
  subtitle,
  legend,
  customLegend,
  context
}: ChartHeaderProps): JSX.Element => {
  return (
    <S.ChartHeader>
      <Flex $direction="column" $gap={8}>
        <Caption $color="fgPrimary" size="XS" as="h4">
          {title}
        </Caption>
        {subtitle && (
          <Caption size="LG" as="h5">
            {subtitle}
          </Caption>
        )}
      </Flex>
      {legend && <ChartLegend data={legend} />}
      {customLegend && customLegend}
      {context && (
        <S.Context>
          <Icon name="info" size={16} color="fgTertiary" />
          <Paragraph size="XS" $color="fgSecondary">
            {context}
          </Paragraph>
        </S.Context>
      )}
    </S.ChartHeader>
  )
}
