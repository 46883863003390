import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { queryKeys } from 'src/libs/query-keys'
import { updateCandidate } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobExpanded, UpdateCandidate } from 'src/libs/api/backend/candidate_jobs'
import { isNil } from 'lodash'
import { useNotification } from '../use-notification'
import { useDialog } from '../use-dialog'
import { DialogId } from 'src/contexts/dialogs'

interface Args {
  updatedCandidate: UpdateCandidate
}

interface ReturnType {
  updateCandidateJob: (args: Args) => void
}

export const useUpdateCandidate = (candidateJobId: string): ReturnType => {
  const queryClient = useQueryClient()
  const { jobId } = useParams()
  const { notify } = useNotification()
  const { closeDialog } = useDialog()

  const mutation = useMutation({
    mutationFn: async ({ updatedCandidate }: Args) => {
      if (isNil(jobId)) {
        throw new Error('No update data')
      }

      return await updateCandidate(updatedCandidate)
    },
    onSuccess: async (data) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [queryKeys.candidateJobs, candidateJobId]
        }),
        queryClient.invalidateQueries({
          queryKey: [queryKeys.candidate, data.id]
        }),
        queryClient.invalidateQueries({
          queryKey: [queryKeys.candidateJobs, jobId, { errored: true }]
        }),
        queryClient.invalidateQueries({
          queryKey: [queryKeys.candidateJobCounts, jobId]
        })
      ])
      const queriesData = queryClient.getQueriesData({ queryKey: [queryKeys.candidateJobs, candidateJobId] })

      let candidateJob: CandidateJobExpanded | undefined
      for (const queryData of queriesData) {
        const candidateJobs = queryData[1] as CandidateJobExpanded[]
        candidateJob = candidateJobs?.find(
          (candidateJob: CandidateJobExpanded) => candidateJob.candidate.id === data.id
        )
        if (!isNil(candidateJob)) {
          break
        }
      }
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateJob, candidateJob?.id]
      })
      closeDialog(DialogId.EDIT_CANDIDATE)
    },
    onError: (err) => {
      console.error(err)
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: `An error occurred when updating your candidate: ${err.message}`,
        autoClose: false
      })
    }
  })

  const updateCandidateJob = ({ updatedCandidate }: Args): void => {
    mutation.mutate({ updatedCandidate })
  }

  return { updateCandidateJob }
}
