import { Icon } from 'src/components/primitives/icon'
import type { IconName } from 'src/components/primitives/icon'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Flex } from 'src/components/primitives/flex'
import * as S from './benefits-list.styled'

export interface Benefit {
  title: string
  description?: string
  icon: IconName
}

export interface BenefitsListStyleProps {
  $colorTheme?: 'dark' | 'light'
  $itemSize?: 'normal' | 'small'
}

interface BenefitsListProps extends BenefitsListStyleProps {
  benefits: Benefit[]
}

export const BenefitsList = ({ benefits, $colorTheme = 'dark', $itemSize = 'normal' }: BenefitsListProps): JSX.Element => {
  return (
    <S.BenefitsList $itemSize={$itemSize}>
      {benefits.map((benefit) => (
        <S.Benefit key={benefit.title} $itemSize={$itemSize}>
          <S.Icon $colorTheme={$colorTheme} $itemSize={$itemSize}>
            <Icon
              name={benefit.icon}
              size={$itemSize === 'small' ? 16 : 20}
              color={$colorTheme === 'light' ? 'bgPrimary' : 'fgSecondary'}
            />
          </S.Icon>
          <Flex $gap={4} $direction="column">
            <Caption $color={$colorTheme === 'light' ? 'bgPrimary' : 'fgPrimary'} size={$itemSize === 'small' ? 'XS' : 'MD'}>
              {benefit.title}
            </Caption>
            <Paragraph $color={$colorTheme === 'light' ? 'fgTertiary' : 'fgSecondary'} size={$itemSize === 'small' ? 'XS' : 'SM'}>
              {benefit.description}
            </Paragraph>
          </Flex>
        </S.Benefit>
      ))}
    </S.BenefitsList>
  )
}
