import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { DialogId } from 'src/contexts/dialogs'
import { useDialog } from 'src/hooks/use-dialog'
import { useLoginRedirectPath } from 'src/hooks/use-login-redirect-path'
import { useQueryParams } from 'src/hooks/use-query-params'
import RouteBuilder from 'src/libs/route-builder'

const OpenSettingsDialogPage = ({ settings: pageSettings }: { settings?: string }): null => {
  const navigate = useNavigate()
  const { jobId } = useLoginRedirectPath()
  const { settings } = useParams()
  const { openDialog } = useDialog()
  const { setParam } = useQueryParams()

  useEffect(() => {
    if (pageSettings && jobId) {
      navigate(RouteBuilder.build('SETTINGS_JOB_DETAILS', { jobId }))
      setParam('settings', pageSettings)
      openDialog(DialogId.DEFAULT_SETTINGS)
    }
    if (settings && jobId) {
      navigate(RouteBuilder.build('SETTINGS_JOB_DETAILS', { jobId }))
      setParam('settings', settings)
      openDialog(DialogId.DEFAULT_SETTINGS)
    }
  }, [jobId, pageSettings, settings])

  return null
}

export default OpenSettingsDialogPage
