import { useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { fetchJob } from 'src/libs/api/backend/jobs'
import type { Job } from 'src/libs/api/backend/jobs'
import { queryKeys } from 'src/libs/query-keys'

interface UseJobQueryOpts {
  enabled?: boolean
  refetchInterval?: number | false | ((query: unknown) => number | false | undefined)
}

export const useJobQuery = (opts: UseJobQueryOpts = {}): UseQueryResult<Job> => {
  const { jobId } = useParams()
  return useQuery({
    queryKey: [queryKeys.job, jobId ?? ''],
    queryFn: async () => await fetchJob(jobId ?? ''),
    staleTime: 60 * 1000,
    ...opts
  })
}
