import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { CalendarEvent, NewCalendarEvent } from 'src/libs/api/backend/calendar_events'
import { createCalendarEvent } from 'src/libs/api/backend/calendar_events'
import { queryKeys } from 'src/libs/query-keys'
import { useSession } from '../queries/use-session'
import { useQueryParams } from '../use-query-params'
import { useParams } from 'react-router'
import { useCandidateJobQuery } from '../queries/use-candidate-job'

interface Args {
  newEvent: NewCalendarEvent
  onSuccess?: () => void
}

interface UseCreateCalendarEventReturnType {
  createEvent: (args: Args) => void
}

export const useCreateCalendarEvent = (): UseCreateCalendarEventReturnType => {
  const { data: sessionData } = useSession()
  const queryClient = useQueryClient()
  const { getParam } = useQueryParams()
  const startDateTime = getParam('startDateTime')
  const endDateTime = getParam('endDateTime')
  const { candidateJobId } = useParams()
  const { data: candidateJob } = useCandidateJobQuery({ candidateJobId })

  const mutation = useMutation({
    mutationFn: async ({ newEvent }: Args) => {
      return await createCalendarEvent(newEvent)
    },
    onMutate: async ({ newEvent }: Args) => {
      await queryClient.cancelQueries({ queryKey: [queryKeys.calendar, sessionData?.user?.id, { startDateTime, endDateTime }] })

      const snapshot = queryClient.getQueryData<CalendarEvent[]>([queryKeys.calendar, sessionData?.user?.id, { startDateTime, endDateTime }])

      queryClient.setQueryData<CalendarEvent[]>([queryKeys.calendar, sessionData?.user?.id, { startDateTime, endDateTime }], old => [
        ...(old ?? []),
        {
          ...newEvent,
          eventId: crypto.randomUUID(),
          attendees: newEvent?.attendees
        }
      ])

      return { snapshot }
    },
    onError: (error, _, context) => {
      console.log('onError: ', error)
      if (context?.snapshot) {
        queryClient.setQueryData([queryKeys.calendar, sessionData?.user?.id, { startDateTime, endDateTime }], context.snapshot)
      }
    },
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({ queryKey: [queryKeys.calendar, sessionData?.user?.id, { startDateTime, endDateTime }] })
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateActivities, candidateJob?.candidate.id]
      })
      variables?.onSuccess?.()
    }
  })

  const createEvent = (args: Args): void => {
    mutation.mutate(args)
  }

  return { createEvent }
}
