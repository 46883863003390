import * as Dialog from 'src/components/primitives/dialog'
import { useDialog } from 'src/hooks/use-dialog'
import { DialogId } from 'src/contexts/dialogs'
import { StripePricingTable } from 'src/components/blocks/stripe-pricing-table'

export const BillingSubscribeDialog = (): JSX.Element => {
  const { isDialogOpen, controlDialog } = useDialog()

  return (
    <Dialog.Root
      id={DialogId.BILLING_SUBSCRIBE}
      isOpen={isDialogOpen(DialogId.BILLING_SUBSCRIBE)}
      onOpenChange={(value) => { controlDialog(DialogId.BILLING_SUBSCRIBE, value) }}
      $width="large"
      $maxWidth="600px"
      // $innerPadding={{ top: 0, left: 0, right: 0, bottom: 0 }}
    >
      <Dialog.Portal>
        <Dialog.Header
          title="Subscribe to Pin"
          onClose={() => { controlDialog(DialogId.BILLING_SUBSCRIBE, false) }}
        />
        <Dialog.Content>
          <StripePricingTable />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
