import styled from 'styled-components'

export const HiringForContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[24]};
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing[24]};
`

export const DepartmentDetails = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacing[12]};
  justify-content: space-between;
  padding: 0.5rem 0.75rem;
`

export const DepartmentsList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  box-shadow: ${({ theme }) => theme.boxShadows.sm};
  ${DepartmentDetails} {
    &:nth-child(even) {
      background-color: ${({ theme }) => theme.colors.bgSecondary};
    }
  }
`
