import { useNavigate } from 'react-router-dom'
import RouteBuilder from 'src/libs/route-builder'
import { OnboardingHeader } from 'src/components/blocks/onboarding-header'
import { CompanyProfileForm } from 'src/components/blocks/company-profile-form'
import type { CompanyProfileFormData } from 'src/components/blocks/company-profile-form'
import { useCreateOrg } from 'src/hooks/mutations/use-create-org'
import { useCallback, useState } from 'react'
import { useNotifyErrorSuccess } from 'src/hooks/use-notification'
import { MAX_PROFILE_FILE_SIZE, PROFILE_MAX_WIDTH, PNG_MIME_TYPE, compressAndGenerateUrl } from 'src/utils/compress-image'

const OnboardingCompanyProfile = (): JSX.Element => {
  const navigate = useNavigate()
  const { notifyError } = useNotifyErrorSuccess()
  const { createOrg } = useCreateOrg()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleSubmit = useCallback(async (data: CompanyProfileFormData): Promise<void> => {
    try {
      setIsSubmitting(true)
      let newLogo // undefined means unchanged
      if (data.companyLogo instanceof File) {
        if (data.companyLogo.size > MAX_PROFILE_FILE_SIZE) {
          throw new Error('File is too large, please choose an image smaller than 1MB')
        }
        const uploadedTempProfilePhoto = await compressAndGenerateUrl({ file: data.companyLogo, fileType: PNG_MIME_TYPE, maxWidth: PROFILE_MAX_WIDTH })
        if (uploadedTempProfilePhoto?.key) {
          newLogo = {
            sourceKey: uploadedTempProfilePhoto.key
          }
        }
      }
      createOrg({
        org: {
          name: data.companyName,
          domain: data.companyUrl,
          mission: data.companyMission,
          country: data.companyLocation ?? '',
          city: data.companyLocation ?? '',
          industries: data.companyIndustries?.map((industry) => industry.title) ?? [],
          newLogo
        },
        onSuccess: () => {
          setIsSubmitting(false) // Not really needed as it should go to the next step but just to be safe
          navigate(RouteBuilder.build('ONBOARDING_JOB_POSITION'))
        }
      })
    } catch (e) {
      console.error('[onboarding-company-profile] Error creating org', e)
      notifyError((e as Error)?.message)
      setIsSubmitting(false)
    }
  }, [createOrg, navigate, notifyError])

  return (
    <>
      <OnboardingHeader
        heading="Tell Us About Your Company"
        subheading="Build your team on Pin."
      />
      <CompanyProfileForm
        onSubmit={(data) => { void handleSubmit(data) }}
        disableSubmit={isSubmitting}
      />
    </>
  )
}

export default OnboardingCompanyProfile
