import { CandidateProfile } from 'src/components/blocks/candidate-details'
import { EditCandidateForm } from 'src/components/blocks/edit-candidate-form'
import { Spacer } from 'src/components/primitives/spacer'
import { useDialog } from 'src/hooks/use-dialog'
import * as Dialog from 'src/components/primitives/dialog'
import { useCandidateJobQuery } from 'src/hooks/queries/use-candidate-job'
import { DialogId } from 'src/contexts/dialogs'

interface EditCandidateDialogProps {
  candidateJobId: string
}

export const EditCandidateDialog = ({ candidateJobId }: EditCandidateDialogProps): JSX.Element => {
  const { isDialogOpen, controlDialog } = useDialog()
  const { data: candidateJob } = useCandidateJobQuery({ candidateJobId })

  if (!candidateJob) {
    return <></>
  }

  return (
    <Dialog.Root
      id={DialogId.EDIT_CANDIDATE}
      isOpen={isDialogOpen(DialogId.EDIT_CANDIDATE)}
      onOpenChange={(value) => {
        controlDialog(DialogId.EDIT_CANDIDATE, value)
      }}
      $width="half"
      $maxWidth="640px"
    >
      <Dialog.Portal>
        <Dialog.Header
          title="Edit Candidate"
          onClose={() => {
            controlDialog(DialogId.EDIT_CANDIDATE, false)
          }}
        />
        <Dialog.Content>
          <CandidateProfile
            candidate={candidateJob.candidate}
            avatarSize={40}
          />
          <Spacer $size={24} />
          <EditCandidateForm candidateJob={candidateJob} />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
