import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useNotification } from 'src/hooks/use-notification'
import { queryKeys } from 'src/libs/query-keys'
import { isNil } from 'lodash'
import { addCandidateJobsToSequence, getActiveCandidateSequences } from 'src/libs/api/backend/candidate_jobs'
import { Icons } from 'src/components/primitives/icon'

interface Res {
  addCandidateToSequence: (candidateJobIds: string[]) => void
}

export const useAddCandidateToSequence = (): Res => {
  const queryClient = useQueryClient()
  const { notify } = useNotification()
  const { jobId } = useParams()

  if (isNil(jobId)) {
    throw new Error('Missing jobId')
  }

  const mutation = useMutation({
    mutationFn: async (candidateJobIds: string[]) => {
      return await addCandidateJobsToSequence(jobId, candidateJobIds)
    },
    onError: async (error, candidateJobIds: string[]) => {
      const activeCandidateSequences = await getActiveCandidateSequences(jobId, candidateJobIds)
      notify({
        type: 'failed-adding-candidates',
        variant: 'warning',
        position: 'bottom-right',
        icon: Icons.alertTriangle,
        payload: activeCandidateSequences
      })
      console.error('Error adding candidate to sequence', error.message)
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateJobs, jobId]
      })
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateJobCounts, jobId]
      })
      notify({
        type: 'candidate-added',
        variant: 'neutral',
        payload: data
        // type: 'toast',
        // variant: 'positive',
        // payload: data,
        // position: 'bottom-right',
        // icon: 'check-check',
        // message: 'Candidate added to outreach'
      })
    }
  })

  const addCandidateToSequence = (candidateJobIds: string[]): void => {
    mutation.mutate(candidateJobIds)
  }

  return { addCandidateToSequence }
}
