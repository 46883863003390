import { InboxTimeline } from 'src/components/blocks/inbox-timeline'
import { CandidateDetailsCard } from 'src/components/blocks/candidate-details-card'
import { Spacer } from 'src/components/primitives/spacer'
import { InboxActions } from 'src/components/blocks/inbox-actions'
// import type { TimelineActivityProps } from 'src/components/blocks/inbox-timeline/timeline-activity'
import { useCandidateJobQuery } from 'src/hooks/queries/use-candidate-job'
import { useParams } from 'react-router'
import { useCandidateActivitiesQuery } from 'src/hooks/queries/use-candidate-activities'
import { isNil } from 'lodash'
import { LoadingSkeleton } from 'src/components/blocks/loading-skeleton'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { MaxWidthContent } from 'src/components/layouts/inbox/inbox.styled'
import type { EmailMessage } from 'src/libs/api/backend/candidate_activities'
import { CompaniesPreferencesProvider } from 'src/providers/companies-preferences'
import { CandidateJobStatusDropdown } from 'src/components/blocks/candidate-job-status'
import { SEO } from 'src/components/primitives/seo'
import { useSuggestedResponse } from 'src/hooks/queries/use-suggested-response'
import { useSession } from 'src/hooks/use-session'
import { FeatureFlags } from 'src/libs/api/backend/session'
import type { ActionType } from 'src/components/blocks/inbox-actions/action-type'
import { useChannel } from 'ably/react'
import { CHANNEL_TYPE, EVENT_TYPE, getChannelName } from 'src/libs/api/backend/websockets'
import queryClient from 'src/hooks/query-client'
import { queryKeys } from 'src/libs/query-keys'

const JobInboxCandidatePage = (): JSX.Element => {
  // const { isPending, data: candidates } = useCandidateJobsQuery()
  // const MOCK_CANDIDATE = candidates?.[0]?.candidate
  const { candidateJobId } = useParams()
  const { featureFlags, org } = useSession()
  const [selectedAction, setSelectedAction] = useState<ActionType | null>(null)
  const { isPending: isCandidateJobPending, data: candidateJob } = useCandidateJobQuery({
    candidateJobId
  })
  const { isPending: isCandidateActivitiesPending, data: candidateActivities } =
    useCandidateActivitiesQuery({
      candidateId: candidateJob?.candidate.id
    })

  useChannel(getChannelName(CHANNEL_TYPE.CANDIDATE_DETAILS, org?.id ?? ''), (message) => {
    const { name, data } = message

    if (name === EVENT_TYPE.CANDIDATES_UPDATE) {
      const idsSet = new Set((data.ids as string[]) ?? [])
      if (candidateJob?.candidate.id && idsSet.has(candidateJob.candidate.id)) {
        void queryClient.invalidateQueries({
          queryKey: [queryKeys.candidateActivities, candidateJob.candidate.id]
        })
      }
    }
  })

  const [referenceEmailMessage, setReferenceEmailMessage] = useState<EmailMessage | null>(null)
  const { data: suggestedResponse } = useSuggestedResponse({
    candidateJobId
  })

  const onReply = (emailMessageId: string): void => {
    const emailMessage = candidateActivities?.find(
      (activity) => activity.emailMessage?.id === emailMessageId
    )
    setReferenceEmailMessage(emailMessage?.emailMessage ?? null)
  }

  useEffect(() => {
    setReferenceEmailMessage(null)
  }, [candidateJobId])

  const ref = useRef<HTMLDivElement>(null)

  const scrollToBottom = (): void => {
    if (
      !isNil(ref.current) &&
      !isNil(ref.current.parentElement) &&
      !isNil(ref.current.parentElement.parentElement)
    ) {
      // console.log(ref.current.parentElement.parentElement)
      // console.log(ref.current.scrollHeight)
      ref.current.parentElement.parentElement.scrollTop = ref.current.scrollHeight
    }
  }

  useLayoutEffect(scrollToBottom, [candidateActivities, candidateJob])

  if (isCandidateJobPending || isCandidateActivitiesPending) {
    return <LoadingSkeleton $variant="Inbox" />
  }

  if (isNil(candidateJob)) {
    return <></>
  }

  return (
    <>
    <SEO title='Inbox' />
    <CompaniesPreferencesProvider>
      <div ref={ref} style={{}}>
        <MaxWidthContent>
          <CandidateDetailsCard
            key={candidateJob.id}
            candidate={candidateJob.candidate}
            candidateJob={candidateJob}
            actions={
              <CandidateJobStatusDropdown $variant="flat" candidateJob={candidateJob} />
            }
          />
          <Spacer $size={32} />
          <InboxTimeline
            activities={candidateActivities}
            // activities={[
            //   ...candidateActivitiesResponse?.activities,
            //   {
            //     id: '123',
            //     type: 'CALENDAR',
            //     date: '2024-05-02T13:40:00.000Z',
            //     userId: '123'
            //   }
            // ]}
            candidate={candidateJob.candidate}
            onReply={onReply}
            newActivityOpen={!isNil(selectedAction)}
          />
        </MaxWidthContent>
        <InboxActions
          key={candidateJob.id}
          candidateJob={candidateJob}
          referenceEmailMessage={referenceEmailMessage}
          suggestedResponse={featureFlags?.includes(FeatureFlags.SUGGESTED_RESPONSES) ? suggestedResponse ?? null : null}
          onSelectedActionChange={() => {
            scrollToBottom()
          }}
          onEditorHeightChange={() => {
            scrollToBottom()
          }}
          onClose={() => {
            setReferenceEmailMessage(null)
          }}
          setSelectedAction={setSelectedAction}
          selectedAction={selectedAction}
        />
      </div>
    </CompaniesPreferencesProvider>
    </>
  )
}

export default JobInboxCandidatePage
