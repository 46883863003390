import type { FieldCommonStyleProps } from '../common'
import * as S from './form-element.styled'

interface FormElementProps extends FieldCommonStyleProps {
  children: React.ReactNode
}

export const FormElement = ({ children, $marginBottom = 16, $width }: FormElementProps): JSX.Element => {
  return <S.FormElement $marginBottom={$marginBottom} $width={$width}>{children}</S.FormElement>
}
