import { Flex } from 'src/components/primitives/flex'
import { Dropdown } from 'src/components/primitives/dropdown'
import type { MenuItemProps } from 'src/components/primitives/dropdown'
import { Button } from 'src/components/primitives/button'
import { Paragraph } from 'src/components/primitives/typography'
import { pluralize } from 'src/libs/pluralize'
import { useMemo } from 'react'
import { Icons } from 'src/components/primitives/icon'

interface WaitDaysProps {
  text?: string
  waitDays: number
  onWaitDaysUpdate: (update: number) => void
  isGenerating: boolean
}

export const WaitDays = ({
  text = 'If no response',
  waitDays,
  onWaitDaysUpdate,
  isGenerating
}: WaitDaysProps): JSX.Element => {
  const DEFAULT_TIMESPAN_OPTIONS: MenuItemProps[] = useMemo(() => [
    {
      id: '1',
      type: 'item',
      title: '1 day',
      onSelect: () => { onWaitDaysUpdate(1) }
    },
    {
      id: '2',
      type: 'item',
      title: '2 days',
      onSelect: () => { onWaitDaysUpdate(2) }
    },
    {
      id: '3',
      type: 'item',
      title: '3 days',
      onSelect: () => { onWaitDaysUpdate(3) }
    },
    {
      id: '5',
      type: 'item',
      title: '5 days',
      onSelect: () => { onWaitDaysUpdate(5) }
    },
    {
      id: '7',
      type: 'item',
      title: '7 days',
      onSelect: () => { onWaitDaysUpdate(7) }
    },
    {
      id: '10',
      type: 'item',
      title: '10 days',
      onSelect: () => { onWaitDaysUpdate(10) }
    }
  ], [onWaitDaysUpdate])

  return (
    <>
      <Flex $gap={12} $align="center">
        <Paragraph size="XS" $color="fgSecondary" $whiteSpace="nowrap">
          {text}
        </Paragraph>
        <Dropdown
          disabled={isGenerating}
          trigger={
            <Button
              nested
              disabled={isGenerating}
              $disabled={isGenerating}
              $variant="raised"
              $colorTheme="normal"
              trailingIcon={Icons.chevronsUpDownSmall}
              $height={24}
              $fontSize={12}
            >
              After {pluralize(waitDays, 'day')}
            </Button>
          }
          showActiveIcon={true}
          selectedValue={waitDays.toString()}
          items={DEFAULT_TIMESPAN_OPTIONS}
          size="small"
        />
      </Flex>
    </>
  )
}
