import { useEffect, useState } from 'react'

function getPinExtensionVersion (): string | undefined {
  const meta = document.querySelector('meta[name="pin-extension-version"]')
  const version = meta?.getAttribute('content') ?? undefined

  return version
}

/**
 * Returns the PinAI global object if it exists. This is created by the chrome extension.
 */
export function usePinExtensionVersion (): string | undefined {
  const [pinVersion, setPinVersion] = useState<string | undefined>(getPinExtensionVersion)

  useEffect(() => {
    if (pinVersion) {
      return
    }

    const interval = setInterval(() => {
      const version = getPinExtensionVersion()

      if (version) {
        setPinVersion(version)
      }
    }, 2e3)

    return () => {
      clearInterval(interval)
    }
  }, [pinVersion])

  return pinVersion
}
