import { createBrowserRouter, Navigate } from 'react-router-dom'
import RouteBuilder from 'src/libs/route-builder'
import Sentry from './utils/sentry'

import {
  Root,
  ErrorPage,
  Login,
  LoginRedirect,
  Invite,
  InviteRedirect,
  OnboardingCompanyProfile,
  OnboardingJoinTeam,
  OnboardingJobPosition,
  OnboardingConnectedAccounts,
  OnboardingInviteTeam,
  JobCandidatesPage,
  JobCandidatesSourcingPage,
  JobCandidatesInSequencePage,
  JobCandidatesInCommunicationPage,
  JobCandidatesScheduledPage,
  JobCandidatesArchivedPage,
  JobInboxPage,
  JobInboxCandidatePage,
  JobInterviewsPage,
  ReportsCandidatesPage,
  ReportsCommunicationsPage,
  JobSettingsSequencePage,
  // SettingsProfilePage,
  // SettingsTeamDetailsPage,
  // SettingsTeamMembersPage,
  // SettingsConnectedAccountsPage,
  // SettingsUserNotifications,
  JobSettingsDetailsPage,
  JobCandidatesShortlistedPage,
  JobSettingsCompaniesRatingsPage,
  AutomationSettingsPage,
  NewJobSearchRefinementPage,
  OnboardingExtension,
  GenerateErrorPage,
  JobCandidatesOutreachErrorsPage,
  OpenSettingsDialogPage,
  JobSettingsCalendarPreferencesPage,
  JobSettingsEmailPreferencesPage,
  TrialExpiredPage
} from 'src/pages/index'
import {
  OnboardingLayout,
  DashboardLayout,
  SettingsLayout,
  InboxLayout,
  DefaultLayout,
  JobLayout
} from 'src/components/layouts'

import { rootLoader, dashboardLoader } from 'src/libs/loaders'
import LoginRedirectClose from './pages/login-redirect-close'
import JobCandidatesShortlistedTablePage from './pages/job/candidates/shortlisted-table'
import { SubscriptionStatus } from './components/blocks/subscription-status/subscription-status'
import { SubscriptionReturnPage } from './components/layouts/subscription-return-page'

const routes = [
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    loader: rootLoader,
    children: [
      { path: '/login', element: <Login />, index: true },
      { path: '/login/redirect', element: <LoginRedirect /> },
      { path: '/login/redirect/close', element: <LoginRedirectClose /> },
      { path: '/invite/:inviteId/:token', element: <Invite /> },
      { path: '/invite-redirect/:inviteId/:token', element: <InviteRedirect /> },
      {
        path: '/onboarding',
        element: <OnboardingLayout />,
        children: [
          { path: 'company-profile', element: <OnboardingCompanyProfile />, index: true },
          { path: 'join-team', element: <OnboardingJoinTeam />, index: true },
          { path: 'job-position', element: <OnboardingJobPosition /> },
          { path: 'connected-accounts', element: <OnboardingConnectedAccounts /> },
          { path: 'invite-team', element: <OnboardingInviteTeam /> },
          { path: 'browser-extension', element: <OnboardingExtension /> }
        ]
      },
      {
        path: '/generate_error',
        element: <GenerateErrorPage />
      },
      {
        path: '/expired_trial',
        element: <TrialExpiredPage />
      },
      {
        path: '/jobs',
        loader: dashboardLoader,
        element: <DashboardLayout />,
        children: [
          {
            path: ':jobId',
            element: <JobLayout />,
            children: [
              {
                path: '',
                index: true,
                element: <Navigate to="candidates" />
              },
              {
                path: 'candidates',
                children: [
                  { path: '', index: true, element: <Navigate to="sourcing" /> },
                  {
                    path: 'sourcing',
                    children: [
                      {
                        path: 'manual',
                        index: true,
                        element: <JobCandidatesSourcingPage isManualSourceView={true} />
                      },
                      {
                        path: 'shortlisted',
                        element: <JobCandidatesShortlistedPage />
                      },
                      {
                        path: 'search',
                        children: [
                          {
                            path: '',
                            index: true,
                            element: <NewJobSearchRefinementPage />
                          }
                        ]
                      },
                      {
                        path: '',
                        index: true,
                        element: <JobCandidatesSourcingPage />
                      }
                    ]
                  },
                  { path: 'shortlisted', element: <JobCandidatesShortlistedPage /> },
                  {
                    path: 'in-sequence',
                    children: [
                      {
                        path: 'errors',
                        index: true,
                        element: <JobCandidatesOutreachErrorsPage />
                      },
                      {
                        path: '',
                        index: true,
                        element: <JobCandidatesInSequencePage />
                      }
                    ]
                  },
                  { path: 'in-communication', element: <JobCandidatesInCommunicationPage /> },
                  { path: 'scheduled', element: <JobCandidatesScheduledPage /> },
                  { path: 'all', element: <JobCandidatesPage heading="All Candidates" /> },
                  {
                    path: 'all',
                    children: [
                      {
                        path: '',
                        index: true,
                        element: <JobCandidatesPage heading="All Candidates" />
                      },
                      {
                        path: 'shortlisted',
                        element: <JobCandidatesShortlistedTablePage />
                      },
                      {
                        path: 'archived',
                        element: <JobCandidatesArchivedPage />
                      }
                    ]
                  },
                  { path: 'archived', element: <JobCandidatesArchivedPage /> }
                ]
              },
              {
                path: 'inbox',
                children: [
                  {
                    path: '',
                    index: true,
                    element: <JobInboxPage />
                  },
                  {
                    path: 'candidate_jobs',
                    children: [
                      {
                        path: '',
                        // index: true,
                        element: <JobInboxPage />
                      },
                      {
                        path: ':candidateJobId',
                        element: <InboxLayout />,
                        children: [
                          {
                            path: '',
                            index: true,
                            element: <JobInboxCandidatePage />
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                path: 'interviews',
                element: <JobInterviewsPage />
              },
              {
                path: 'reports',
                element: <DefaultLayout />,
                children: [
                  {
                    path: '',
                    index: true,
                    element: <Navigate to="candidates" />
                  },
                  {
                    path: 'candidates',
                    element: <ReportsCandidatesPage />
                  },
                  {
                    path: 'communications',
                    element: <ReportsCommunicationsPage />
                  }
                ]
              },
              {
                path: 'automation',
                element: <DefaultLayout />,
                children: [
                  {
                    path: '',
                    index: true,
                    element: <AutomationSettingsPage />
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: '/settings',
        loader: dashboardLoader,
        element: <DashboardLayout />,
        children: [
          {
            path: 'job',
            children: [
              {
                path: ':jobId',
                element: <SettingsLayout />,
                children: [
                  {
                    path: 'details',
                    element: <JobSettingsDetailsPage />
                  },
                  {
                    path: 'email-sequence',
                    element: <JobSettingsSequencePage />
                  },
                  {
                    path: 'companies-ratings',
                    element: <JobSettingsCompaniesRatingsPage />
                  },
                  {
                    path: 'email-preferences',
                    element: <JobSettingsEmailPreferencesPage />
                  },
                  {
                    path: 'calendar-preferences',
                    element: <JobSettingsCalendarPreferencesPage />
                  }
                ]
              }
            ]
          },
          {
            path: 'account/:settings',
            element: <OpenSettingsDialogPage />
          },
          {
            path: 'team/members', // this is a legacy route that is sent via emails
            element: <OpenSettingsDialogPage settings="team_members" />
          }
        ]
      },
      {
        path: 'subscribe',
        element: <SubscriptionReturnPage />,
        children: [
          {
            path: 'success',
            element: <SubscriptionStatus variant="success" />
          }
        ]
      }
    ]
  }
]

RouteBuilder.create(routes)
console.log('[Route Table]', RouteBuilder.routeTable)

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
  createBrowserRouter
)

const router = sentryCreateBrowserRouter(routes, {
  future: {
    // Normalize `useNavigation()`/`useFetcher()` `formMethod` to uppercase
    v7_normalizeFormMethod: true
  }
})

export default router
