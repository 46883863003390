import * as Dialog from 'src/components/primitives/dialog'
import { useDialog } from 'src/hooks/use-dialog'
import * as S from './new-job.styled'
import { DialogId } from 'src/contexts/dialogs'
import { CreateNewJobForm } from 'src/components/blocks/create-new-job-form'
import { useMemo } from 'react'
import { isNil } from 'lodash'
import { useJobByDepartmentsQuery } from 'src/hooks/queries/use-jobs-by-department'
import { useSession } from 'src/hooks/queries/use-session'
import { BlockCreateNewJobDialog } from './block-create-new-job-dialog'
import { useQueryParams } from 'src/hooks/use-query-params'

export const CreateNewJobDialog = (): JSX.Element => {
  const { isDialogOpen, controlDialog } = useDialog()
  const { resetParam } = useQueryParams()
  const { data: jobsByDepartment } = useJobByDepartmentsQuery()
  const { data: session } = useSession()
  const numberOfActiveJobs = useMemo(() => {
    return jobsByDepartment ? Object.values(jobsByDepartment).flat().filter((job) => !job.deleted).length : 0
  }, [jobsByDepartment])

  const canAddMoreJobs = useMemo(() => {
    if (isNil(session?.jobsLimit)) {
      return false
    }
    return numberOfActiveJobs < session.jobsLimit
  }, [numberOfActiveJobs, session?.jobsLimit])

  if (!canAddMoreJobs) {
    return (
      <BlockCreateNewJobDialog id={DialogId.CREATE_NEW_JOB} />
    )
  }

  return (
    <Dialog.Root
      id={DialogId.CREATE_NEW_JOB}
      isOpen={isDialogOpen(DialogId.CREATE_NEW_JOB)}
      modal
      onOpenChange={(value) => {
        if (!value) {
          resetParam('createNewJob')
        }
        controlDialog(DialogId.CREATE_NEW_JOB, value)
      }}
      $width="almostFull"
      $height="almostFull"
      $innerPadding={{ top: 0, right: 0, bottom: 0, left: 0 }}
    >
      <Dialog.Portal>
        <Dialog.Header
          title="New Job Position"
          onClose={() => {
            resetParam('createNewJob')
            controlDialog(DialogId.CREATE_NEW_JOB, false)
          }}
        />
        <Dialog.Content>
          <S.NewJobWrapper>
            <S.ImageWrapper>
              <S.ImageWrapperInner>
                <img src="/onboarding/company-profile.jpg" />
              </S.ImageWrapperInner>
            </S.ImageWrapper>
            <S.FormWrapper>
              <CreateNewJobForm />
            </S.FormWrapper>
          </S.NewJobWrapper>
              {/*
              <CreateJobForm
                redirectType="jobs"
                onCreateJob={() => {
                  controlDialog(DialogId.CREATE_NEW_JOB, false)
                  openDialog(DialogId.CREATE_SEQUENCE, 'OPTIONS')
                }}
                onMergeLinkVisibility={(isVisible: boolean) => {
                  setIsModal(!isVisible)
                }}
              />
              */}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
