import React, { useEffect, useState } from 'react'
import { Button } from 'src/components/primitives/button'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Form, Input, Textarea } from 'src/components/forms'
import { useForm } from 'src/hooks/use-form'
import { Flex } from 'src/components/primitives/flex'
import { Banner } from 'src/components/blocks/banner'
import { Spacer } from 'src/components/primitives/spacer'
import { When } from 'src/components/blocks/when'
import { LoadingBox } from 'src/components/blocks/loading-box'
import { pluralize } from 'src/libs/pluralize'
import { pasteLinkFormSchema } from './types/paste-link-form'
import type { PasteLinkForm } from './types/paste-link-form'
import type { JobBoardListing } from 'src/libs/api/backend/external_job_listings'
import { isNil } from 'lodash'
import * as S from './start.styled'
import { useJobListings } from 'src/hooks/queries/use-job-listings'
import { useMergeLink } from '@mergeapi/react-merge-link'
import { useMergeLinkToken } from 'src/hooks/use-merge-link-token'
import { submitMergePublicToken } from 'src/libs/api/backend/merge_link_token'
import { Card } from 'src/components/primitives/card'
import { FormActionButtons } from '../form-action-buttons'
import { Avatar } from 'src/components/primitives/avatar'
import { pasteJobDescriptionFormSchema } from './types/paste-job-description-form'
import type { PasteJobDescriptionForm } from './types/paste-job-description-form'
import type { ExtractJobRequirementsInput } from 'src/libs/api/backend/gpt'
import { Searchbar } from './searchbar'
import { Icon } from 'src/components/primitives/icon'

interface StartProps {
  onNewJobClick: (event: React.MouseEvent<Element, MouseEvent>, job?: JobBoardListing) => void
  onCreateNewJobFromImportClick: (
    job?: ExtractJobRequirementsInput
  ) => void
  onMergeLinkVisibility?: (isVisible: boolean) => void
}

type View = 'SELECT' | 'IMPORT_FROM_LISTING' | 'PASTE_JOB_DESCRIPTION'

export const Start = ({
  onNewJobClick,
  onCreateNewJobFromImportClick,
  onMergeLinkVisibility
}: StartProps): JSX.Element => {
  const [formWasSubmitted, setFormWasSubmitted] = useState(false)
  const [jobPostLink, setJobPostLink] = useState<string | null>(null)
  const { submit, register, reset } = useForm<PasteLinkForm>({
    schema: pasteLinkFormSchema
  })
  const {
    submit: submitPasteJobDescription,
    register: registerPasteJobDescription,
    reset: resetPasteJobDescription
  } = useForm<PasteJobDescriptionForm>({
    schema: pasteJobDescriptionFormSchema
  })
  const [view, setView] = useState<View>('SELECT')

  const {
    data: importedJobs,
    isFetching: isFetchingJobListings,
    isRefetching: isRefetchingJobListings,
    refetch: refetchJobListings,
    error: jobListingsError
  } = useJobListings({
    url: jobPostLink
  })

  const [importedJobsByName, setImportedJobsByName] = useState<Record<string, JobBoardListing[]>>({})
  const [filteredJobsByName, setFilteredJobsByName] = useState<Record<string, JobBoardListing[]>>({})

  useEffect(() => {
    if (importedJobs) {
      const jobsByName = importedJobs.reduce<Record<string, JobBoardListing[]>>((acc, job) => {
        const name = job.name ?? ''
        acc[name] = acc[name] ? [...acc[name], job] : [job]
        return acc
      }, {})
      setImportedJobsByName(jobsByName)
      setFilteredJobsByName(jobsByName)
    } else {
      setImportedJobsByName({})
      setFilteredJobsByName({})
    }
  }, [importedJobs])

  const { data: linkToken } = useMergeLinkToken()

  const { open: openMergeLink } = useMergeLink({
    linkToken,
    onSuccess: (publicToken): void => {
      async function submitAndFetch (): Promise<void> {
        await submitMergePublicToken(publicToken)
        await refetchJobListings()
      }
      void submitAndFetch()
    },
    onExit: (): void => {
      if (onMergeLinkVisibility) {
        onMergeLinkVisibility(false)
      }
    }
  })

  const handleSubmit = async (formData: { jobPostLink?: string }): Promise<void> => {
    setJobPostLink(formData.jobPostLink ?? null)
    setFormWasSubmitted(true)
  }

  const handleSubmitPasteJobDescription = async (formData: { jobDescription: string }): Promise<void> => {
    setFormWasSubmitted(true)
    onCreateNewJobFromImportClick({
      content: formData.jobDescription
    })
  }

  return (
    <>
      <S.StartSection>
        <When condition={view === 'SELECT'}>
          <S.SelectOptions>
            <Button
              $variant="raised"
              $colorTheme="tint"
              $height={48}
              $width="full"
              leadingIcon="link"
              onClick={() => {
                setView('IMPORT_FROM_LISTING')
              }}
            >
              Import from an existing job listing
            </Button>
            <Button
              $variant="raised"
              $colorTheme="tint"
              $height={48}
              $width="full"
              leadingIcon="import"
              onClick={() => {
                openMergeLink()
                if (onMergeLinkVisibility) {
                  onMergeLinkVisibility(true)
                }
              }}
            >
              Import from another ATS
            </Button>
            <Button
              $variant="raised"
              $colorTheme="tint"
              $height={48}
              $width="full"
              leadingIcon="clipboard"
              onClick={() => {
                setView('PASTE_JOB_DESCRIPTION')
              }}
            >
              Paste a job description
            </Button>
            <Button
              $variant="raised"
              $colorTheme="tint"
              $height={48}
              $width="full"
              leadingIcon="pen-square"
              onClick={onNewJobClick}
            >
              Create new job position
            </Button>
          </S.SelectOptions>
        </When>
        <When condition={view === 'IMPORT_FROM_LISTING'}>
          <Card>
            <Caption size="MD">Import from an existing job listing</Caption>
            <Spacer $size={16} />
            <Form onSubmit={submit(handleSubmit)}>
              <Input
                label="Job listing link"
                hiddenLabel
                name="jobPostLink"
                placeholder="Job listing link"
                icon="link"
                register={register}
                isDisabled={isFetchingJobListings}
                $marginBottom={0}
              />
              <When condition={formWasSubmitted && jobListingsError}>
                <>
                  <Spacer $size={16} />
                  <Banner $variant="warning">
                    <p>
                      {jobListingsError?.message ??
                        'We could not find any job listings or the job board is currently not yet supported.'}
                    </p>
                  </Banner>
                </>
              </When>
              <FormActionButtons
                saveText="Import"
                cancelText="Cancel"
                onCancel={() => {
                  setFormWasSubmitted(false)
                  setJobPostLink(null)
                  reset()
                  setView('SELECT')
                }}
                isSaving={isFetchingJobListings}
              />
            </Form>
          </Card>
        </When>
        <When condition={view === 'PASTE_JOB_DESCRIPTION'}>
          <Card>
            <Caption size="MD">Paste a job description</Caption>
            <Form onSubmit={submitPasteJobDescription(handleSubmitPasteJobDescription)}>
              <Textarea
                rows={4}
                label='Paste the description of your job'
                name="jobDescription"
                register={registerPasteJobDescription}
                $marginBottom={0}
                autoGrow
              />
              <FormActionButtons
                saveText="Import"
                cancelText="Cancel"
                onCancel={() => {
                  setFormWasSubmitted(false)
                  resetPasteJobDescription()
                  setView('SELECT')
                }}
                isSaving={isFetchingJobListings}
              />
            </Form>
          </Card>
        </When>
      </S.StartSection>

      <When condition={isFetchingJobListings}>
        <>
          <Spacer $size={24} />
          {isRefetchingJobListings
            ? (
                <LoadingBox loadingDelayMS={0}>Importing...</LoadingBox>
              )
            : (
                <LoadingBox loadingDelayMS={0}>Looking up your prior job positions...</LoadingBox>
              )
          }
        </>
      </When>

      <When condition={!isNil(importedJobs) && importedJobs.length > 0 && !isRefetchingJobListings}>
        <Spacer $size={12} />
        <Searchbar
          importedJobsByName={importedJobsByName}
          onSearch={(result) => { setFilteredJobsByName(result) }}
          onReset={() => { setFilteredJobsByName(importedJobsByName) }}
        />
        <When condition={Object.keys(filteredJobsByName).length <= 0}>
          <Spacer $size={40} />
          <Flex $direction="column" $align="center" $gap={12}>
            <Icon name="binoculars" size={24} color="fgFaded10" />
            <Caption size="SM" $color="fgSecondary">No jobs found</Caption>
          </Flex>
        </When>
        {Object.keys(filteredJobsByName).map((jobName) => (
          <S.ExistingJobsGroup>
            {jobName !== '' && <S.ExistingJobsGroupHeader>
              <Flex $direction="row" $width="100%" $align="center">
                <Flex $flex="1" $align="center" $gap={6}>
                  <Avatar
                    $type="logo"
                    $size={24}
                    $border={false}
                    company={{ name: jobName, url: filteredJobsByName[jobName][0].domain }}
                  />
                  <Caption size="SM">{jobName}</Caption>
                </Flex>
                <Caption size="XS" $color="fgSecondary">
                  {pluralize(filteredJobsByName[jobName].length, 'job position')} found
                </Caption>
              </Flex>
            </S.ExistingJobsGroupHeader>}
            <S.ExistingJobsGroupScrollContainer>
              {filteredJobsByName[jobName].map((job, index) => (
                <S.ExistingJob
                  key={index}
                  // onClick={(event: React.MouseEvent<Element, MouseEvent>) => {
                  //   onCreateNewJobFromImportClick(event, job)
                  // }}
                >
                  <Flex $gap={4} $direction="column">
                    <Caption>{job.title}</Caption>
                    <Paragraph>{job.location}</Paragraph>
                  </Flex>
                  <Flex $flex="0">
                    <Button
                      $variant="raised"
                      $colorTheme="tint"
                      $fontSize={12}
                      $height={24}
                      onClick={() => {
                        onCreateNewJobFromImportClick(job)
                      }}
                    >
                      Import
                    </Button>
                  </Flex>
                </S.ExistingJob>
              ))}
            </S.ExistingJobsGroupScrollContainer>
          </S.ExistingJobsGroup>
        ))}
      </When>
    </>
  )
}
