import * as Dialog from 'src/components/primitives/dialog'
import { UpgradeYourPlanFromProfessional } from 'src/components/blocks/upgrade-your-plan-from-professional'
import { UpgradeYourPlanFromTrial } from 'src/components/blocks/upgrade-your-plan-from-trial'
import type { DialogId } from 'src/contexts/dialogs'
import { useDialog } from 'src/hooks/use-dialog'
import { useQueryParams } from 'src/hooks/use-query-params'
import { useSession } from 'src/hooks/queries/use-session'

interface BlockCreateNewJobDialogProps {
  id: DialogId
}

export const BlockCreateNewJobDialog = ({ id }: BlockCreateNewJobDialogProps): JSX.Element => {
  const { resetParam } = useQueryParams()
  const { isDialogOpen, controlDialog } = useDialog()
  const { data: session } = useSession()
  return (
    <Dialog.Root
      id={id}
      isOpen={isDialogOpen(id)}
      onOpenChange={(value) => {
        if (!value) {
          resetParam('createNewJob')
        }
        controlDialog(id, value)
      }}
      $maxWidth='640px'
      $innerPadding={{
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }}
    >
      <Dialog.Portal>
        <Dialog.Header
          title="Upgrade your plan"
          onClose={() => {
            resetParam('createNewJob')
            controlDialog(id, false)
          }}
        />
        <Dialog.Content>
          {session?.subscribed
            ? <UpgradeYourPlanFromProfessional />
            : <UpgradeYourPlanFromTrial
                onClick={() => {
                  controlDialog(id, false)
                }}
              />
          }
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
