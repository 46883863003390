import Api, { ApiError } from '..'
import { z } from 'zod'
import type { EmailSequenceStep } from 'src/models/sequence'
import { sequenceStepParser } from './sequences'
import { departmentParser } from './departments'

export enum JobSourcingState {
  REQUESTED = 'REQUESTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  NO_CANDIDATES_ERROR = 'NO_CANDIDATES_ERROR'
}

export enum JobSequenceState {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  ERROR_DISCONNECTED_EMAIL = 'ERROR_DISCONNECTED_EMAIL'
}

export enum AutoExportCandidateType {
  DISABLED = 'DISABLED',
  ON_MOVED_TO_OUTREACH = 'ON_MOVED_TO_OUTREACH',
  ON_EMAIL_REPLY = 'ON_EMAIL_REPLY'
}

export const jobParser = z.object({
  id: z.string().uuid(),
  orgId: z.string().uuid(),
  title: z.string(),
  description: z.string().nullish(),
  salaryMin: z.number().nullish(),
  salaryMax: z.number().nullish(),
  locations: z.array(z.string()).nullish(),
  // country: z.string().nullish(),
  workspace: z.enum(['REMOTE', 'OFFICE', 'HYBRID']).nullish(),
  createdAt: z.coerce.date(),
  sequenceState: z.nativeEnum(JobSequenceState).nullish(),
  communicating: z.object({
    totalAwaitingResponse: z.number()
  }).nullish(),
  updatedAt: z.coerce.date(),
  departmentId: z.string().uuid().nullish(),
  deleted: z.boolean(),
  deletedAt: z.coerce.date().nullish(),
  deletedReason: z.string().nullish(),
  deletedByUserId: z.string().uuid().nullish(),
  jobSearchRefinementSuggestions: z.array(z.string()).nullish(),
  automateAutoApproveCandidates: z.boolean().optional(),
  automateAutoApproveCandidatesDaily: z.number().optional(),
  automateAutoArchiveCandidates: z.boolean().optional(),
  automateNudgeCandidates: z.boolean().optional(),
  automateSendArchiveEmail: z.boolean().optional(),
  autoExportCandidate: z.nativeEnum(AutoExportCandidateType).nullable(),
  automateScheduling: z.boolean().optional(),
  createdByUserId: z.string().uuid().nullish(),
  mergeAtsJobId: z.string().nullish(),
  emailSkipHolidays: z.boolean().optional(),
  emailWhenToSend: z.array(z.number().min(0).max(167)).optional(),
  calendarWhenToSchedule: z.array(z.number().min(0).max(167)).optional(),
  calendarSkipHolidays: z.boolean().optional(),
  calendarInviteeList: z.array(z.string()).optional()
})

export type Job = z.infer<typeof jobParser>

export async function fetchJobs (): Promise<Job[]> {
  const { data } = await Api.get('/jobs')
  return z.array(jobParser).parse(data)
}

export async function fetchJob (jobId: Job['id']): Promise<Job> {
  const { data } = await Api.get(`/jobs/${jobId}`)
  return jobParser.parse(data)
}

export const newJobParser = z.object({
  title: z.string(),
  description: z.string().nullish(),
  jobDescriptionRefinement: z.string().nullish(),
  salaryMin: z.number().nullish(),
  salaryMax: z.number().nullish(),
  locations: z.array(z.string()).nullish(),
  diverseHire: z.boolean().nullish(),
  workspace: z.enum(['REMOTE', 'OFFICE', 'HYBRID']).nullish(),
  departmentId: z.string().nullish(),
  jobBoardDescription: z.string().nullish(),
  automateAutoApproveCandidates: z.boolean().optional(),
  automateAutoApproveCandidatesDaily: z.number().optional(),
  automateAutoArchiveCandidates: z.boolean().optional(),
  automateNudgeCandidates: z.boolean().optional(),
  automateSendArchiveEmail: z.boolean().optional(),
  automateScheduling: z.boolean().optional(),
  autoExportCandidate: z.nativeEnum(AutoExportCandidateType).optional(),
  mergeAtsJobId: z.string().nullish()
})

export type NewJob = z.infer<typeof newJobParser>

export async function createJob (job: NewJob): Promise<Job> {
  const { data } = await Api.post('/jobs', { generateSequence: true }, job)
  return jobParser.parse(data)
}

export const updateJobParser = newJobParser.extend({
  id: z.string().uuid(),
  title: z.string().optional(),
  emailSkipHolidays: z.boolean().optional(),
  emailWhenToSend: z.array(z.number().min(0).max(167)).optional(),
  calendarWhenToSchedule: z.array(z.number().min(0).max(167)).optional(),
  calendarSkipHolidays: z.boolean().optional(),
  calendarInviteeList: z.array(z.string()).optional()
})
export type UpdateJob = z.infer<typeof updateJobParser>

export async function updateJob (jobId: string, updatedJob: UpdateJob): Promise<Job> {
  const { data } = await Api.put(`/jobs/${jobId}`, null, updatedJob)
  return jobParser.parse(data)
}

export const AutoArchiveAfterDays = [10, 15, 20, 25, 30]
export const DailyEmailLimit = [50, 100, 200, 300, 400, 500]

const sequenceParser = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  orgId: z.string(),
  active: z.boolean(),
  jobId: z.string(),
  autoArchiveAfterDays: z.number(),
  dailyEmailLimit: z.number()
})

export type Sequence = z.infer<typeof sequenceParser>

const sequenceWithStepsParser = sequenceParser.extend({
  sequenceSteps: z.array(sequenceStepParser).optional()
})

export type SequenceWithSteps = z.infer<typeof sequenceWithStepsParser>

export async function fetchSequenceByJobId (jobId: Job['id']): Promise<SequenceWithSteps | null> {
  try {
    const { data } = await Api.get(`/jobs/${jobId}/sequence?include[]=steps`)
    return sequenceWithStepsParser.parse(data)
  } catch (e) {
    if (e instanceof ApiError) {
      if (e.status === 404) {
        return null
      }
    }

    throw e
  }
}

export async function upsertJobSequence (
  jobId: Job['id'],
  body: {
    active?: boolean
    sequenceSteps?: EmailSequenceStep[]
    autoArchiveAfterDays?: number
    dailyEmailLimit?: number
  }
): Promise<Sequence> {
  const { data } = await Api.put(`/jobs/${jobId}/sequence`, null, body)
  return sequenceWithStepsParser.parse(data)
}

const jobAndDepartment = jobParser.extend({
  department: departmentParser.nullish()
})

export type JobWithDepartment = z.infer<typeof jobAndDepartment>

const jobGroupedByDepartmentParser = z.record(z.array(jobAndDepartment))

export type JobAndDepartment = z.infer<typeof jobAndDepartment>

export type JobGroupedByDepartment = z.infer<typeof jobGroupedByDepartmentParser>

const refinementHighlightParser = z.object({
  name: z.string(),
  value: z.any(),
  supported: z.boolean(),
  originalWords: z.array(z.string()),
  position: z.array(z.number()).nullish()
})

export type RefinementHighlight = z.infer<typeof refinementHighlightParser>

export async function fetchJobsGroupedByDepartment (): Promise<JobGroupedByDepartment> {
  const { data } = await Api.get('/jobs/group_by_department')
  const jobsByDept = jobGroupedByDepartmentParser.parse(data)
  return jobsByDept
}

export async function archiveJob (jobId: Job['id'], reason: string): Promise<Job> {
  const { data } = await Api.delete(`/jobs/${jobId}`, { reason })
  const job = jobParser.parse(data)
  return job
}

export const jobSearchRefinementParser = z.object({
  id: z.string(),
  jobId: z.string(),
  sourcingState: z.nativeEnum(JobSourcingState).nullable(),
  highlights: z.array(refinementHighlightParser).nullable(),
  lastSourcingRequestedAt: z.coerce.date().nullable(),
  title: z.string().nullable(),
  searchQuery: z.string(),
  automateAutoApproveCandidates: z.boolean().nullish(),
  candidateMatchCount: z.number().nullish()
})

export type JobSearchRefinement = z.infer<typeof jobSearchRefinementParser>

export async function listJobSearchRefinements (jobId: Job['id']): Promise<JobSearchRefinement[]> {
  const { data } = await Api.get(`/jobs/${jobId}/search_refinements`)
  return jobSearchRefinementParser.array().parse(data)
}

export async function createJobSearchRefinement (jobId: Job['id'], searchQuery: string): Promise<JobSearchRefinement> {
  const { data } = await Api.post(`/jobs/${jobId}/search_refinements`, null, { searchQuery, automateAutoApproveCandidates: false })
  return jobSearchRefinementParser.parse(data)
}

export async function updateJobSearchRefinement (
  jobId: Job['id'],
  jobSearchRefinementId: JobSearchRefinement['id'],
  searchQuery: string,
  title?: string | null,
  automateAutoApproveCandidates?: boolean | null
): Promise<JobSearchRefinement> {
  const { data } = await Api.put(`/jobs/${jobId}/search_refinements/${jobSearchRefinementId}`, null, { searchQuery, title, automateAutoApproveCandidates })
  return jobSearchRefinementParser.parse(data)
}

export const updatedAutoApproveJobSearchRefinements = z.object({
  job: jobParser,
  jobSearchRefinements: z.array(jobSearchRefinementParser)
})

export type UpdatedAutoApproveJobSearchRefinements = z.infer<typeof updatedAutoApproveJobSearchRefinements>

interface BatchAutoApproveJobSearchRefinementsArgs {
  jobId: Job['id']
  enabled: boolean
  jobSearchRefinementIds?: Array<JobSearchRefinement['id']>
}

export async function batchAutoApproveJobSearchRefinements ({
  jobId,
  enabled,
  jobSearchRefinementIds
}: BatchAutoApproveJobSearchRefinementsArgs): Promise<UpdatedAutoApproveJobSearchRefinements> {
  const { data } = await Api.put(`/jobs/${jobId}/auto_approve`, null, { enabled, jobSearchRefinementIds })
  return updatedAutoApproveJobSearchRefinements.parse(data)
}

export interface DeleteJobSearchRefinementArgs {
  jobId: Job['id']
  jobSearchRefinementId: JobSearchRefinement['id']
}

export async function deleteJobSearchRefinement ({ jobId, jobSearchRefinementId }: DeleteJobSearchRefinementArgs): Promise<void> {
  const { status } = await Api.delete(`/jobs/${jobId}/search_refinements/${jobSearchRefinementId}`)
  if (status !== 204) {
    throw new Error('Failed to delete search refinement')
  }
}

const jobStats = z.record(z.string().uuid(), z.object({
  numCandidatesAwaitingResponse: z.number(),
  hasPausedSequence: z.boolean(),
  hasDeactivatedSenders: z.boolean()
}))

export type JobStats = z.infer<typeof jobStats>

export async function fetchJobStats (): Promise<JobStats> {
  const { data } = await Api.get('/jobs/stats')
  return jobStats.parse(data)
}
