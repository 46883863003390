import * as Dialog from 'src/components/primitives/dialog'
import { useDialog } from 'src/hooks/use-dialog'
import { DialogId } from 'src/contexts/dialogs'

export const JobSettingsDialog = (): JSX.Element => {
  const { isDialogOpen, controlDialog } = useDialog()

  return (
    <Dialog.Root
      id={DialogId.JOB_SETTINGS}
      isOpen={isDialogOpen(DialogId.JOB_SETTINGS)}
      onOpenChange={(value) => {
        controlDialog(DialogId.JOB_SETTINGS, value)
      }}
      $width="almostFull"
      $height="almostFull"
    >
      <Dialog.Portal>
        <Dialog.Header
          title="Settings"
          onClose={() => {
            controlDialog(DialogId.JOB_SETTINGS, false)
          }}
        />
        <Dialog.Content>
          <p>Job settings</p>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
