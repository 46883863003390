import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateDepartment } from 'src/libs/api/backend/departments'
import type { Department } from 'src/libs/api/backend/departments'
import { useNotification } from '../use-notification'
import { queryKeys } from 'src/libs/query-keys'
import { isNil } from 'lodash'

interface Args {
  id: string
  name: string
  onSuccess?: (updatedDept: Department) => void
  onError?: () => void
}

interface Res {
  updateDepartmentByOrg: (args: Args) => void
}

export const useUpdateDepartment = (): Res => {
  const queryClient = useQueryClient()
  const { notify } = useNotification()

  const mutation = useMutation({
    mutationFn: async ({ id, name }: Args) => await updateDepartment(id, name),
    onError: (err, variables) => {
      console.error(err)
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: `An error occurred while updating your department: ${err.message}`
      })

      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (updatedDept: Department, variables) => {
      queryClient.setQueryData<Department[]>([queryKeys.departments], (oldDepartments) => {
        if (isNil(oldDepartments)) {
          return
        }

        return oldDepartments.map((dept) => {
          if (dept.id === updatedDept.id) {
            return updatedDept
          }
          return dept
        })
      })

      notify({
        type: 'toast',
        variant: 'positive',
        position: 'bottom-right',
        icon: 'check-check',
        message: 'Department successfully updated'
      })
      if (variables.onSuccess) {
        variables.onSuccess(updatedDept)
      }
    }
  })
  const updateDepartmentByOrg = (args: Args): void => {
    mutation.mutate(args)
  }
  return { updateDepartmentByOrg }
}
