import { isNil } from 'lodash'
import type { ButtonStyleProps, Variant } from 'src/components/primitives/button'
import { Button } from 'src/components/primitives/button'
import { Dropdown } from 'src/components/primitives/dropdown'
import type { MenuItemProps } from 'src/components/primitives/dropdown'
import { Icons } from 'src/components/primitives/icon'
import type { IconName } from 'src/components/primitives/icon'
import { DialogId } from 'src/contexts/dialogs'
import { useAddCandidateToSequence } from 'src/hooks/mutations/use-add-candidate-to-sequence'
import { useRejectCandidate } from 'src/hooks/mutations/use-reject-candidate'
import { usePauseCandidateSequence } from 'src/hooks/mutations/use-pause-candidate-job-sequence'
import { useDialog } from 'src/hooks/use-dialog'
import type { CandidateJobExpanded, CandidateJobStatusDisplay } from 'src/libs/api/backend/candidate_jobs'
import { CandidateJobRejectionReason, CandidateJobStage, CandidateJobStatus, CandidateJobStatusType } from 'src/libs/api/backend/candidate_jobs'
import RouteBuilder from 'src/libs/route-builder'
import { useLocation } from 'react-router-dom'
import { useHireCandidate } from 'src/hooks/mutations/use-hired-candidate'
import { useUndoStageTransition } from 'src/hooks/mutations/use-undo-stage-transition'
import { useSession } from 'src/hooks/use-session'
import { FeatureFlags } from 'src/libs/api/backend/session'

interface GetStatusReturnType {
  icon: IconName
  triggerTitle: string
  colorTheme?: ButtonStyleProps['$colorTheme']
  actions?: MenuItemProps[]
}

const getColorThemeForStatus = (statusDisplay: CandidateJobStatusDisplay | null): ButtonStyleProps['$colorTheme'] => {
  switch (statusDisplay?.type) {
    case CandidateJobStatusType.SUCCESS:
      return 'positive'
    case CandidateJobStatusType.WARNING:
      return 'warning'
    case CandidateJobStatusType.INFO:
      return 'tint'
    case CandidateJobStatusType.PENDING:
      return 'muted'
    case CandidateJobStatusType.ERROR:
      return 'negative'
    default:
      return 'muted'
  }
}

const getStatus = (candidateJob: CandidateJobExpanded): GetStatusReturnType => {
  const status: CandidateJobStatusDisplay | null | undefined = candidateJob.statusDisplay
  switch (status?.status) {
    case CandidateJobStatus.HIRED: {
      return {
        icon: Icons.heartHandshake,
        triggerTitle: status.title,
        colorTheme: getColorThemeForStatus(status)
      }
    }
    case CandidateJobStatus.REJECTED: {
      return {
        icon: Icons.xOctagon,
        triggerTitle: status.title,
        colorTheme: getColorThemeForStatus(status)
      }
    }
    case CandidateJobStatus.SEARCHING_FOR_EMAIL: {
      return {
        icon: Icons.mailQuestion,
        triggerTitle: status.title,
        colorTheme: getColorThemeForStatus(status)
      }
    }
    case CandidateJobStatus.BOUNCED: {
      return {
        icon: Icons.mailQuestion,
        triggerTitle: status.title,
        colorTheme: getColorThemeForStatus(status),
        actions: [
          {
            title: 'Edit email',
            icon: Icons.mailPlus
          }
        ]
      }
    }
    case CandidateJobStatus.EMAIL_NOT_FOUND: {
      return {
        icon: Icons.mailQuestion,
        triggerTitle: status.title,
        colorTheme: getColorThemeForStatus(status)
      }
    }
    case CandidateJobStatus.IN_SEQUENCE: {
      return {
        icon: Icons.mail,
        triggerTitle: status.subtitle ?? status.title,
        colorTheme: getColorThemeForStatus(status)
      }
    }
    case CandidateJobStatus.RESPONDED: {
      return {
        icon: Icons.mails,
        triggerTitle: status.title,
        colorTheme: getColorThemeForStatus(status)
      }
    }
    case CandidateJobStatus.WAITING_ON_CANDIDATE_TO_RESPOND: {
      return {
        icon: Icons.replyThick,
        triggerTitle: status.title,
        colorTheme: getColorThemeForStatus(status)
      }
    }
    case CandidateJobStatus.CANDIDATE_WAITING_FOR_RESPONSE: {
      return {
        icon: Icons.bellRing,
        triggerTitle: status.title,
        colorTheme: getColorThemeForStatus(status)
      }
    }
    case CandidateJobStatus.SEQUENCE_PAUSED: {
      return {
        icon: Icons.pauseCircle,
        triggerTitle: status.title,
        colorTheme: getColorThemeForStatus(status)
      }
    }
    case CandidateJobStatus.SHORTLISTED: {
      return {
        icon: Icons.star,
        triggerTitle: status.title,
        colorTheme: getColorThemeForStatus(status)
      }
    }
    case CandidateJobStatus.SOURCED: {
      return {
        icon: Icons.sparkles,
        triggerTitle: status.title,
        colorTheme: getColorThemeForStatus(status)
      }
    }
    default: {
      return {
        icon: Icons.info,
        triggerTitle: status?.title ?? '',
        colorTheme: getColorThemeForStatus(status)
      }
    }
  }
}

interface SourcedStatusDropdownProps {
  candidateJob?: CandidateJobExpanded
  $variant?: Variant
}

export const SourcedStatusDropdown = ({
  candidateJob,
  $variant = 'ghost'
}: SourcedStatusDropdownProps): JSX.Element => {
  const { rejectCandidate } = useRejectCandidate()
  const { addCandidateToSequence } = useAddCandidateToSequence()

  const { openDialog } = useDialog()
  const handleReject = (rejectionReason?: CandidateJobRejectionReason): void => {
    if (!candidateJob?.id) {
      return
    }
    rejectCandidate({ candidateJobIds: [candidateJob.id], rejectionReason })
  }

  const { org, featureFlags } = useSession()

  return (
    <Dropdown
      trigger={
        <Button
          nested
          $variant={$variant}
          $colorTheme="muted"
          $fontSize={12}
          $height={24}
          leadingIcon="sparkles"
          trailingIcon="chevron-down"
        >
          Sourced
        </Button>
      }
      items={[
        {
          id: 'add-to-sequence',
          title: 'Add to outreach',
          icon: Icons.mailPlus,
          onSelect: () => {
            if (!candidateJob?.id) {
              return
            }
            addCandidateToSequence([candidateJob.id])
          }
        },
        {
          id: 'archive-separator',
          title: 'ArchiveSeparator',
          type: 'separator'
        },
        {
          id: 'archive-candidate',
          title: 'Archive candidate',
          icon: Icons.trash,
          variant: 'negative',
          onSelect: () => {
            handleReject()
          }
        },
        ...(!featureFlags?.includes(FeatureFlags.ATS_INTEGRATION))
          ? []
          : [{
              id: 'archive-separator',
              title: 'ArchiveSeparator',
              type: 'separator'
            },
            {
              id: 'export-to-ats',
              title: 'Export to ATS',
              icon: Icons.share,
              isDisabled: isNil(candidateJob?.mergeApplicationId) || isNil(org?.mergeAtsIntegration),
              onSelect: () => {
                openDialog(DialogId.EXPORT_CANDIDATES, { candidateJobs: [candidateJob] })
              }
            }]
      ]}
      menuPosition="end"
      size="small"
    />
  )
}

interface SequenceStatusDropdownProps {
  candidateJob?: CandidateJobExpanded
  $variant?: Variant
  enabledSendEmail?: boolean
}

export const SequenceStatusDropdown = ({
  candidateJob,
  $variant = 'ghost',
  enabledSendEmail = true
}: SequenceStatusDropdownProps): JSX.Element => {
  const { openDialog } = useDialog()
  const { rejectCandidate } = useRejectCandidate()
  const { setCandidateSequencePause } = usePauseCandidateSequence()
  const { org, featureFlags } = useSession()

  const handleReject = (rejectionReason?: CandidateJobRejectionReason): void => {
    if (!candidateJob?.id) {
      return
    }
    rejectCandidate({ candidateJobIds: [candidateJob.id], rejectionReason })
  }

  const status = candidateJob?.statusDisplay
  if (status && [CandidateJobStatus.IN_SEQUENCE, CandidateJobStatus.SEQUENCE_PAUSED].includes(status?.status)) {
    const statusPayload = getStatus(candidateJob)
    const variant = status.status === CandidateJobStatus.SEQUENCE_PAUSED
      ? 'ghost'
      : $variant

    const items = []
    if (enabledSendEmail) {
      items.push({
        id: 'write-email',
        title: 'Write email',
        icon: Icons.mail,
        onSelect: () => {
          openDialog(DialogId.WRITE_EMAIL, [candidateJob])
        }
      })
    }

    return (
      <Dropdown
        trigger={
          <Button
            nested
            $variant={variant}
            $colorTheme={statusPayload.colorTheme}
            $fontSize={12}
            $height={24}
            leadingIcon={statusPayload.icon}
            trailingIcon="chevron-down"
          >
            {statusPayload.triggerTitle}
          </Button>
        }
        items={[
          ...items,
          {
            id: 'resume-pause-sequence',
            title: candidateJob.candidateSequence?.paused
              ? 'Resume outreach'
              : 'Pause outreach',
            icon: candidateJob.candidateSequence?.paused
              ? Icons.playCircle
              : Icons.pauseCircle,
            variant: candidateJob.candidateSequence?.paused
              ? 'tint'
              : 'warning',
            onSelect: () => {
              const candidateJobId = candidateJob.id
              if (isNil(candidateJobId)) {
                return
              }
              setCandidateSequencePause({ candidateJobIds: [candidateJobId], pause: !candidateJob.candidateSequence?.paused })
            }
          },
          {
            id: 'archive-separator',
            title: 'ArchiveSeparator',
            type: 'separator'
          },
          {
            id: 'archive-candidate',
            title: 'Archive candidate',
            icon: Icons.trash,
            variant: 'negative',
            onSelect: () => {
              handleReject()
            }
          },
          ...(!featureFlags?.includes(FeatureFlags.ATS_INTEGRATION))
            ? []
            : [{
                id: 'archive-separator',
                title: 'ArchiveSeparator',
                type: 'separator'
              },
              {
                id: 'export-to-ats',
                title: 'Export to ATS',
                icon: Icons.share,
                isDisabled: isNil(candidateJob?.mergeApplicationId) || isNil(org?.mergeAtsIntegration),
                onSelect: () => {
                  openDialog(DialogId.EXPORT_CANDIDATES, { candidateJobs: [candidateJob] })
                }
              }]
        ]}
        menuPosition="end"
        size="small" />
    )
  }

  if (status && ['EMAIL_NOT_FOUND', 'BOUNCED', 'SEARCHING_FOR_EMAIL'].includes(status?.status)) {
    const statusPayload = getStatus(candidateJob)
    let actions: MenuItemProps[] = []
    let rejectionReason = CandidateJobRejectionReason.OTHER

    if (status?.status === CandidateJobStatus.EMAIL_NOT_FOUND ||
      status?.status === CandidateJobStatus.SEARCHING_FOR_EMAIL) {
      actions = [
        {
          id: 'add-email',
          title: 'Add email',
          icon: Icons.mailPlus,
          onSelect: () => {
            openDialog(DialogId.EDIT_CANDIDATE, candidateJob)
          }
        }
      ]
      rejectionReason = CandidateJobRejectionReason.MISSING_EMAIL
    }

    if (status?.status === CandidateJobStatus.BOUNCED) {
      actions = [
        {
          id: 'edit-email',
          title: 'Edit email',
          icon: Icons.mail,
          onSelect: () => {
            openDialog(DialogId.EDIT_CANDIDATE, candidateJob)
          }
        }
      ]
      rejectionReason = CandidateJobRejectionReason.UNREACHABLE
    }

    return (
      <Dropdown
        trigger={
          <Button
            nested
            $variant={$variant}
            $colorTheme="negative"
            $fontSize={12}
            $height={24}
            leadingIcon={statusPayload.icon}
            trailingIcon="chevron-down"
          >
            {statusPayload.triggerTitle}
          </Button>
        }
        items={[
          ...actions,
          {
            id: 'archive-separator',
            title: 'ArchiveSeparator',
            type: 'separator'
          },
          {
            id: 'archive-candidate',
            title: 'Archive candidate',
            icon: Icons.trash,
            variant: 'negative',
            onSelect: () => {
              handleReject(rejectionReason)
            }
          }
        ]}
        menuPosition="end"
        size="small"
      />
    )
  }

  return <></>
}

interface CommunicatingStatusDropdownProps {
  candidateJob?: CandidateJobExpanded
  $variant?: Variant
}

export const CommunicatingStatusDropdown = ({
  candidateJob,
  $variant = 'ghost'
}: CommunicatingStatusDropdownProps): JSX.Element => {
  const { pathname } = useLocation()
  const { rejectCandidate } = useRejectCandidate()
  const { hireCandidate } = useHireCandidate()
  const { org, featureFlags } = useSession()
  const { openDialog } = useDialog()

  if (!candidateJob) {
    return <></>
  }

  const handleReject = (rejectionReason?: CandidateJobRejectionReason): void => {
    if (!candidateJob?.id) {
      return
    }
    rejectCandidate({ candidateJobIds: [candidateJob.id], rejectionReason })
  }

  const handleHire = (): void => {
    if (!candidateJob?.id) {
      return
    }
    hireCandidate({ candidateJobIds: [candidateJob.id] })
  }

  const hasInboxLink = !pathname.includes('inbox')

  let dropdownItems: MenuItemProps[] = []

  if (hasInboxLink) {
    dropdownItems = [{
      id: 'open-in-inbox',
      title: 'Open in inbox',
      icon: Icons.inbox,
      href: RouteBuilder.build('JOBS_INBOX_CANDIDATE_JOBS', {
        jobId: candidateJob.jobId,
        candidateJobId: candidateJob?.id
      })
    },
    {
      id: 'archive-separator',
      title: 'ArchiveSeparator',
      type: 'separator'
    }]
  }

  return (
    <Dropdown
      trigger={<Button
        nested
        $variant={$variant}
        $colorTheme={getStatus(candidateJob).colorTheme}
        $fontSize={12}
        $height={24}
        leadingIcon={getStatus(candidateJob).icon}
        trailingIcon="chevron-down"
      >
        {getStatus(candidateJob).triggerTitle}
      </Button>}
      items={[
        ...dropdownItems,
        {
          id: 'archive-candidate',
          title: 'Archive candidate',
          icon: Icons.trash,
          variant: 'negative',
          onSelect: () => {
            handleReject()
          }
        },
        {
          id: 'hired-candidate',
          title: 'Hired candidate',
          icon: Icons.heartHandshake,
          variant: 'positive',
          onSelect: () => {
            handleHire()
          }
        },
        ...(!featureFlags?.includes(FeatureFlags.ATS_INTEGRATION))
          ? []
          : [{
              id: 'archive-separator',
              title: 'ArchiveSeparator',
              type: 'separator'
            },
            {
              id: 'export-to-ats',
              title: 'Export to ATS',
              icon: Icons.share,
              isDisabled: isNil(candidateJob?.mergeApplicationId) || isNil(org?.mergeAtsIntegration),
              onSelect: () => {
                openDialog(DialogId.EXPORT_CANDIDATES, { candidateJobs: [candidateJob] })
              }
            }]
      ]}
      menuPosition="end"
      size="small"
    />
  )
}

interface RejectedStatusDropdownProps {
  candidateJob?: CandidateJobExpanded
  $variant?: Variant
}

export const RejectedStatusDropdown = ({
  candidateJob,
  $variant = 'ghost'
}: RejectedStatusDropdownProps): JSX.Element => {
  const { pathname } = useLocation()
  const { undoStageTransition } = useUndoStageTransition({ isRestoringCandidates: true })
  const { org, featureFlags } = useSession()
  const { openDialog } = useDialog()

  if (!candidateJob) {
    return <></>
  }

  const handleUndo = (): void => {
    if (!candidateJob?.id) {
      return
    }
    undoStageTransition([candidateJob.id])
  }

  const hasInboxLink = !pathname.includes('inbox')

  let dropdownItems: MenuItemProps[] = []

  if (hasInboxLink) {
    dropdownItems = [{
      id: 'open-in-inbox',
      title: 'Open in inbox',
      icon: Icons.inbox,
      href: RouteBuilder.build('JOBS_INBOX_CANDIDATE_JOBS', {
        jobId: candidateJob.jobId,
        candidateJobId: candidateJob?.id
      })
    },
    {
      id: 'archive-separator',
      title: 'ArchiveSeparator',
      type: 'separator'
    }]
  }

  return (
    <Dropdown
      trigger={<Button
        nested
        $variant={$variant}
        $colorTheme={getStatus(candidateJob).colorTheme}
        $fontSize={12}
        $height={24}
        leadingIcon={getStatus(candidateJob).icon}
        trailingIcon="chevron-down"
      >
        {getStatus(candidateJob).triggerTitle}
      </Button>}
      items={[
        ...dropdownItems,
        {
          id: 'archive-candidate',
          title: 'Undo rejection',
          icon: Icons.undo,
          onSelect: () => {
            handleUndo()
          }
        },
        ...(!featureFlags?.includes(FeatureFlags.ATS_INTEGRATION))
          ? []
          : [{
              id: 'archive-separator',
              title: 'ArchiveSeparator',
              type: 'separator'
            },
            {
              id: 'export-to-ats',
              title: 'Export to ATS',
              icon: Icons.share,
              isDisabled: isNil(candidateJob?.mergeApplicationId) || isNil(org?.mergeAtsIntegration),
              onSelect: () => {
                openDialog(DialogId.EXPORT_CANDIDATES, { candidateJobs: [candidateJob] })
              }
            }]
      ]}
      menuPosition="end"
      size="small"
    />
  )
}

interface CandidateJobStatusDropdownProps {
  candidateJob?: CandidateJobExpanded
  $variant?: Variant
}

export const CandidateJobStatusDropdown = ({
  candidateJob,
  $variant = 'ghost'
}: CandidateJobStatusDropdownProps): JSX.Element => {
  if (!candidateJob) {
    return <></>
  }

  const statusPayload = getStatus(candidateJob)

  if (candidateJob.stage === CandidateJobStage.SOURCED) {
    return <SourcedStatusDropdown candidateJob={candidateJob} $variant={$variant} />
  } else if (candidateJob.stage === CandidateJobStage.PROSPECTING) {
    return <SequenceStatusDropdown candidateJob={candidateJob} $variant={$variant} />
  } else if (candidateJob.stage === CandidateJobStage.COMMUNICATING) {
    return <CommunicatingStatusDropdown candidateJob={candidateJob} $variant={$variant} />
  } else if (candidateJob.stage === CandidateJobStage.REJECTED) {
    return <RejectedStatusDropdown candidateJob={candidateJob} $variant={$variant} />
  } else {
    return (
      <Button
        $variant={$variant}
        $colorTheme={statusPayload.colorTheme}
        $fontSize={12}
        $height={24}
        leadingIcon={statusPayload.icon}
      >
        {statusPayload.triggerTitle}
      </Button>
    )
  }
}
