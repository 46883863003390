import { useEffect, useMemo, useState } from 'react'
import { Button } from 'src/components/primitives/button'
import { Flex } from 'src/components/primitives/flex'
import { When } from '../when'
import { Action } from './action'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { InboxEmailEditor } from '../inbox-email-editor'
import { InboxCommentEditor } from '../inbox-comment-editor'
import { isNil } from 'lodash'
import type {
  CandidateJobExpanded,
  SuggestedResponse
} from 'src/libs/api/backend/candidate_jobs'
import * as S from './inbox-actions.styled'
import { useCreateCandidateNote } from 'src/hooks/mutations/use-create-candidate-note'
import type { SendManualEmail } from 'src/libs/api/backend/sequences'
import { useSendManualEmail } from 'src/hooks/mutations/use-send-manual-email'
import type { EmailMessage } from 'src/libs/api/backend/candidate_activities'
import { CandidateJobStatusDropdown } from '../candidate-job-status'
import { ActionType } from './action-type'
import { DialogId } from 'src/contexts/dialogs'
import { useDialog } from 'src/hooks/use-dialog'
import { useSession } from 'src/hooks/use-session'
import { useSession as useSessionQuery } from 'src/hooks/queries/use-session'
import { FeatureFlags } from 'src/libs/api/backend/session'
import { CalendarIcon } from '../calendar-icon'
import { format } from 'date-fns'
import { useCreateCalendarEvent } from 'src/hooks/mutations/use-create-calendar-event'
import type { NewCalendarEvent } from 'src/libs/api/backend/calendar_events'
import { useOrgUsersQuery } from 'src/hooks/queries/use-org-users'

interface InboxActionsProps {
  candidateJob: CandidateJobExpanded
  referenceEmailMessage: EmailMessage | null
  suggestedResponse: SuggestedResponse | null
  onSelectedActionChange?: (action: ActionType | null) => void
  onEditorHeightChange?: (height: number) => void
  onClose?: () => void
  selectedAction: ActionType | null
  setSelectedAction: (action: ActionType | null) => void
}

export const InboxActions = ({
  candidateJob,
  referenceEmailMessage,
  suggestedResponse,
  onSelectedActionChange,
  onEditorHeightChange,
  onClose,
  selectedAction,
  setSelectedAction
}: InboxActionsProps): JSX.Element => {
  const [manualEmail, setManualEmail] = useState<Partial<SendManualEmail>>({
    subject: '',
    body: ''
  })

  const [commentContent, setCommentContent] = useState<string>()
  const { createCandidateNote } = useCreateCandidateNote()
  const { sendManualEmail } = useSendManualEmail()
  const { openDialog } = useDialog()
  const { featureFlags } = useSession()
  const { createEvent } = useCreateCalendarEvent()
  const { data: sessionData } = useSessionQuery()
  const { data: orgUsers } = useOrgUsersQuery()
  const sessionEmailAccount = useMemo(() => {
    if (isNil(sessionData) || isNil(orgUsers)) {
      return null
    }

    const emailAccount = sessionData.emailAccountAccessTokens.find(token => token.isPrimary) ?? sessionData.emailAccountAccessTokens[0]
    const orgUser = orgUsers.find(user => user.emailAccounts.some(account => account.email === emailAccount.email))
    return orgUser?.emailAccounts?.find(account => account.email === emailAccount.email)
  }, [orgUsers, sessionData])

  const handleSendEmail = (): void => {
    if (isNil(manualEmail.subject)) {
      return
    }
    if (isNil(manualEmail.body)) {
      return
    }
    if (isNil(manualEmail.sendingEmailAccountId)) {
      return
    }

    let referenceEmailMessageId: string | undefined
    if (selectedAction === ActionType.EMAIL_REPLY) {
      referenceEmailMessageId = referenceEmailMessage?.id
    } else if (selectedAction === ActionType.SUGGESTED_RESPONSE) {
      referenceEmailMessageId = suggestedResponse?.latestEmailMessageId
    }

    sendManualEmail({
      manualEmail: {
        subject: manualEmail.subject,
        body: manualEmail.body,
        cc: manualEmail.cc,
        bcc: manualEmail.bcc,
        candidateIds: [candidateJob.candidateId],
        sendingEmailAccountId: manualEmail.sendingEmailAccountId,
        referenceEmailMessageId,
        attachmentUploads: manualEmail.attachmentUploads
      }
    })

    if (selectedAction === ActionType.SUGGESTED_RESPONSE && suggestedResponse?.calEventStart && suggestedResponse?.calEventEnd) {
      const newEvent: NewCalendarEvent = {
        title: suggestedResponse?.calEventTitle ?? 'Intro call',
        isPinEvent: true,
        startPosition: 0,
        endPosition: 0,
        start: suggestedResponse?.calEventStart,
        end: suggestedResponse?.calEventEnd,
        candidateJobId: candidateJob?.id,
        attendees: [
          {
            address: sessionEmailAccount?.email ?? '',
            name: sessionData?.user?.name ?? sessionEmailAccount?.email ?? ''
          },
          {
            address: candidateJob.candidate.emails?.[0] ?? '',
            name: candidateJob?.candidate?.name
          }
        ]
      }
      createEvent({ newEvent })
    }

    setSelectedAction(null)
  }

  useEffect(() => {
    if (!isNil(referenceEmailMessage)) {
      setSelectedAction(ActionType.EMAIL_REPLY)
    }
  }, [referenceEmailMessage, setSelectedAction])

  useEffect(() => {
    if (!isNil(suggestedResponse)) {
      setManualEmail({
        subject: suggestedResponse.subject,
        body: suggestedResponse.bodyHtml,
        candidateIds: [candidateJob.candidateId],
        sendingEmailAccountId: suggestedResponse.sendingEmailAccountId
      })
      setSelectedAction(ActionType.SUGGESTED_RESPONSE)
    }
  }, [candidateJob.candidateId, setSelectedAction, suggestedResponse])

  const handleAddComment = (): void => {
    if (isNil(commentContent)) {
      return
    }
    createCandidateNote({
      candidateId: candidateJob.candidateId,
      body: commentContent,
      onSuccess: () => {
        setSelectedAction(null)
      }
    })
  }

  useEffect(() => {
    if (!isNil(onSelectedActionChange)) {
      onSelectedActionChange(selectedAction)
    }
  }, [onSelectedActionChange, selectedAction])

  return (
    <>
      <S.InboxTimelineAction>
        {selectedAction === 'SUGGESTED_RESPONSE' && suggestedResponse && (
          <Action actionType="EMAIL">
            <InboxEmailEditor
              onDataChanged={(data) => {
                setManualEmail({
                  subject: data.subject ?? '',
                  body: data.body ?? '',
                  candidateIds: [candidateJob.candidateId],
                  sendingEmailAccountId: data.sendingEmailAccountId,
                  cc: data.cc ?? undefined,
                  bcc: data.bcc ?? undefined,
                  attachmentUploads: data.attachmentUploads ?? undefined
                })
              }}
              isAiGeneratedEmail
              onSendEmail={handleSendEmail}
              onClose={() => {
                setSelectedAction(null)
                if (!isNil(onClose)) {
                  onClose()
                }
              }}
              onEditorHeightChange={onEditorHeightChange}
              body={suggestedResponse?.bodyHtml}
              subject={suggestedResponse?.subject}
              minHeight='150px'
              sendingUserId={suggestedResponse?.sendingUserId}
              sendingEmailAccountId={suggestedResponse?.sendingEmailAccountId}
              editorFooterContent={
                <>
                  {suggestedResponse?.calEventStart && suggestedResponse?.calEventEnd && (
                    <Flex $gap={12}>
                      <CalendarIcon timestamp={suggestedResponse?.calEventStart} />
                      <Flex $direction="column">
                        <Paragraph size="XS" $color="fgSecondary">
                          {`${format(suggestedResponse?.calEventStart, 'h:mma').toLowerCase()} - ${format(suggestedResponse?.calEventEnd, 'h:mma').toLowerCase()}`}{' '}
                          {' '}{suggestedResponse?.calEventStart.toLocaleString('en-US', { timeZoneName: 'short' }).split(' ').pop()}
                        </Paragraph>
                        <Caption size="XS" $color="fgPrimary">{suggestedResponse?.calEventTitle}</Caption>
                      </Flex>
                    </Flex>
                  )}
                </>
              }
            />
          </Action>
        )}

        <When condition={selectedAction === ActionType.EMAIL || selectedAction === ActionType.EMAIL_REPLY}>
          <Action actionType="EMAIL">
            <InboxEmailEditor
              onDataChanged={(data) => {
                setManualEmail({
                  subject: data.subject ?? '',
                  body: data.body ?? '',
                  candidateIds: [candidateJob.candidateId],
                  sendingEmailAccountId: data.sendingEmailAccountId,
                  cc: data.cc ?? undefined,
                  bcc: data.bcc ?? undefined,
                  attachmentUploads: data.attachmentUploads ?? undefined
                })
              }}
              onSendEmail={handleSendEmail}
              onClose={() => {
                setSelectedAction(null)
                if (!isNil(onClose)) {
                  onClose()
                }
              }}
              onEditorHeightChange={onEditorHeightChange}
              body=""
              subject={selectedAction === 'EMAIL_REPLY' ? referenceEmailMessage?.subject : ''}
              minHeight='150px'
              sendingUserId={referenceEmailMessage?.userId}
              sendingEmailAccountId={referenceEmailMessage?.emailAccountId}
            />
          </Action>
        </When>
        <When condition={selectedAction === ActionType.COMMENT}>
          <Action actionType="COMMENT">
            <Flex $align="center" $justify="space-between">
              <Paragraph size="XS" $color="fgSecondary">
                New comment
              </Paragraph>
            </Flex>
            <InboxCommentEditor
              onCancel={() => {
                setSelectedAction(null)
              }}
              onDataChanged={(data) => {
                setCommentContent(data)
              }}
              onEditorHeightChange={onEditorHeightChange}
              onAddComment={handleAddComment}
            />
          </Action>
        </When>
      </S.InboxTimelineAction>
      <S.InboxActions>
        <S.InboxActionsInner>
          <Flex $gap={8}>
            <Button
              $variant="raised"
              $colorTheme="tint"
              leadingIcon="mail"
              $fontSize={12}
              $height={24}
              onClick={() => {
                setSelectedAction(ActionType.EMAIL)
              }}
            >
              Write email
            </Button>
            <Button
              $variant="raised"
              $colorTheme="muted"
              leadingIcon="message-circle"
              $fontSize={12}
              $height={24}
              onClick={() => {
                setSelectedAction(ActionType.COMMENT)
              }}
            >
              Comment
            </Button>
            <When condition={!!featureFlags && featureFlags?.includes(FeatureFlags.CALENDAR)}>
              <Button
                $variant="raised"
                $colorTheme="muted"
                leadingIcon="calendar"
                $fontSize={12}
                $height={24}
                onClick={() => {
                  openDialog(DialogId.CALENDAR, { candidateJob })
                }}
              >
                Schedule meeting
              </Button>
            </When>
          </Flex>
          <Flex $justify="flex-end">
            <CandidateJobStatusDropdown candidateJob={candidateJob} $variant="flat" />
          </Flex>
        </S.InboxActionsInner>
      </S.InboxActions>
    </>
  )
}
