import { useVirtualizer } from '@tanstack/react-virtual'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { CandidateActions } from 'src/components/blocks/candidate-actions'
import { CandidateDetailsCard } from 'src/components/blocks/candidate-details-card'
import { EmptyState } from 'src/components/blocks/empty-state'
import { EmptyStateArchivedJob } from 'src/components/blocks/empty-state-archived-job'
import { LoadingSkeleton } from 'src/components/blocks/loading-skeleton'
import { SourcingPageHeader } from 'src/components/blocks/sourcing'
import { LocalStorageKey, ViewMode } from 'src/constants'
import { DialogId } from 'src/contexts/dialogs'
import { useAddCandidateToSequence } from 'src/hooks/mutations/use-add-candidate-to-sequence'
import { useCandidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { useJobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import { useDialog } from 'src/hooks/use-dialog'
import { CandidateJobStage } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import RouteBuilder from 'src/libs/route-builder'
import { isSequenceStepsEmpty } from 'src/libs/sequence'
import { CompaniesPreferencesProvider } from 'src/providers/companies-preferences'
import { CANDIDATES_PAGES_MAX_WIDTH, CONTENT_PADDING } from 'src/styles/constants'
import { useLocalStorage } from 'usehooks-ts'

const JobCandidatesShortlistedPage = (): JSX.Element => {
  const { openDialog } = useDialog()
  const { jobId } = useParams()
  const [currViewMode, setViewMode] = useLocalStorage(LocalStorageKey.VIEW_MODE, ViewMode.DEFAULT)

  const [renderedCandidates, setRenderedCandidates] = useState<CandidateJobExpanded[]>([])

  const { data: job } = useJobQuery()
  const { isPending, data: candidateJobs } = useCandidateJobsQuery({
    stage: CandidateJobStage.SOURCED,
    source: undefined,
    favorite: true
  })

  const { isPending: isSequenceLoading, data: sequence } = useJobSequenceQuery()
  const { addCandidateToSequence } = useAddCandidateToSequence()

  const parentRef = useRef<HTMLDivElement>(null)

  const virtualizer = useVirtualizer({
    count: renderedCandidates?.length ?? 0,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 300
  })

  useEffect(() => {
    if (candidateJobs) {
      const sourcedCandidates = candidateJobs?.filter((c) => c.stage === 'SOURCED')

      setRenderedCandidates(sourcedCandidates)
    }
  }, [candidateJobs])

  const candidatesPageHeader = useMemo(
    (): JSX.Element => {
      return (
        <SourcingPageHeader
          title='Shortlisted'
          setViewMode={setViewMode}
          currViewMode={currViewMode}
          jobId={jobId}
          candidateJobIds={renderedCandidates?.map((c) => c.id) ?? []}
          isSequenceEmpty={isSequenceStepsEmpty(sequence)}
        />
      )
    },
    [currViewMode, jobId, renderedCandidates, sequence, setViewMode]
  )

  const emptyState = useMemo(() => {
    if (renderedCandidates?.length) {
      return null
    }

    if (job?.deleted) {
      return (
        <EmptyStateArchivedJob />
      )
    }

    return (
      <EmptyState
        heading="Shortlisted"
        description="Shortlist candidates from Sourcing to create a list of favorite candidates to share with teammates."
        svg="shortlistedCandidates"
        $height="100%"
        actions={[
          {
            href: RouteBuilder.build('JOBS_CANDIDATES_SOURCING', { jobId }),
            children: 'Go to sourcing'
          }
        ]}
      />
    )
  }, [jobId, renderedCandidates?.length, job?.deleted])

  if (isPending || isSequenceLoading || !job) {
    return (
      <div
        style={{
          padding: `0 ${CONTENT_PADDING}`
        }}
      >
        {candidatesPageHeader}
        <LoadingSkeleton $variant="CandidateDetailsCard" />
      </div>
    )
  }

  return (
    <CompaniesPreferencesProvider>
      <div
        ref={parentRef}
        style={{
          height: '100%',
          padding: `0 ${CONTENT_PADDING}`,
          overflow: 'auto',
          contain: 'strict',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {candidatesPageHeader}
        <div
          style={{
            height: virtualizer.getTotalSize(),
            position: 'relative'
          }}
        >
          {virtualizer.getVirtualItems().map((virtualRow) => {
            const candidateJob = renderedCandidates?.[virtualRow.index]
            if (!candidateJob) {
              return <></>
            }
            return (
              <div
                style={{
                  width: '100%',
                  maxWidth: CANDIDATES_PAGES_MAX_WIDTH,
                  position: 'absolute',
                  top: `${virtualRow.start}px`
                }}
                key={virtualRow.key}
                data-index={virtualRow.index}
                ref={virtualizer.measureElement}
              >
                <CandidateDetailsCard
                  key={candidateJob.id}
                  stage={candidateJob.stage}
                  candidate={candidateJob.candidate}
                  candidateJob={candidateJob}
                  actions={
                    <CandidateActions
                      candidateJob={candidateJob}
                      onAddToSequenceClick={() => {
                        if (isSequenceStepsEmpty(sequence)) {
                          openDialog(DialogId.CREATE_SEQUENCE)
                        } else {
                          addCandidateToSequence([candidateJob.id])
                        }
                      }}
                    />
                  }
                  viewMode={currViewMode}
                />
              </div>
            )
          })}
        </div>
        <div
          style={{
            // maxWidth: renderedCandidates?.length ? CANDIDATES_PAGES_MAX_WIDTH : '100%',
            maxWidth: CANDIDATES_PAGES_MAX_WIDTH,
            flex: 1
            // paddingBottom: spacing[48]
          }}
        >
          {emptyState}
        </div>
      </div>
    </CompaniesPreferencesProvider>
  )
}

export default JobCandidatesShortlistedPage
