import * as S from './progress-bar.styled'

interface ProgressBarProps {
  progress?: number
}

export const ProgressBar = ({ progress = 0 }: ProgressBarProps): JSX.Element => {
  return (
    <S.ProgressBar>
      <S.Inner>
        <S.Bar>
          <S.Progress $progress={progress} />
        </S.Bar>
      </S.Inner>
    </S.ProgressBar>
  )
}
