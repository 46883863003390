import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNotification } from 'src/hooks/use-notification'
import { sendCandidateSequenceStepMessageReview } from 'src/libs/api/backend/candidate_sequence_step_message_review'
import type { CandidateSequenceStepMessageReviewExtended } from 'src/libs/api/backend/candidate_sequence_step_message_review'
import { queryKeys } from 'src/libs/query-keys'

interface Args {
  jobId: string
  candidateSequenceStepMessageReviewId: string
  subject: string
  body: string
  onSuccess?: () => void
  onError?: () => void
}

interface Res {
  sendCandidateSequenceStepMessage: (args: Args) => void
}

export const useSendCandidateSequenceStepMessage = (): Res => {
  const queryClient = useQueryClient()
  const { notify } = useNotification()

  const mutation = useMutation({
    mutationFn: async ({ jobId, candidateSequenceStepMessageReviewId, subject, body }: Args) => {
      return await sendCandidateSequenceStepMessageReview(jobId, candidateSequenceStepMessageReviewId, subject, body)
    },
    onError: (err, variables) => {
      console.error(err)
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: `An error occurred while sending email to candidate: ${err.message}`
      })
      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateJobs, variables.jobId]
      })
      notify({
        type: 'toast',
        variant: 'positive',
        position: 'bottom-right',
        icon: 'check-check',
        message: 'Successfully sent email to candidate'
      })
      if (variables.onSuccess) {
        variables.onSuccess()
      }

      queryClient.setQueriesData<CandidateSequenceStepMessageReviewExtended[]>({
        queryKey: [queryKeys.candidateSequenceStepMessageReviews, variables.jobId]
      }, (oldCandidateSequenceStepMessageReviews) => {
        if (!oldCandidateSequenceStepMessageReviews) {
          return oldCandidateSequenceStepMessageReviews
        }

        return oldCandidateSequenceStepMessageReviews.filter((review) => review.id !== variables.candidateSequenceStepMessageReviewId)
      })
    }
  })

  const sendCandidateSequenceStepMessage = (args: Args): void => {
    mutation.mutate(args)
  }

  return { sendCandidateSequenceStepMessage }
}
