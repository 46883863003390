import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router'
import { getCompanyPreferences } from 'src/libs/api/backend/company_preferences'
import { queryKeys } from 'src/libs/query-keys'

export const companiesPreferencesQuery = (jobId: string) => {
  return {
    queryKey: [queryKeys.companyPreferences, jobId],
    queryFn: async () => await getCompanyPreferences(jobId),
    staleTime: 60 * 1000 * 15, // 15 minutes
    enabled: !!jobId
  }
}

export const useGetCompaniesPreferences = () => {
  const { jobId } = useParams()
  return useQuery(companiesPreferencesQuery(jobId ?? ''))
}
