interface DataPoint {
  label: string | number
  [key: string]: string | number
}

export const adjustChartDataWithMinimumPoints = (
  data: DataPoint[],
  minimumDataPoints: number = 7
): DataPoint[] => {
  if (data.length === 0) {
    const sampleData = {
      label: '',
      placeholder1: 0,
      placeholder2: 0

    }
    return Array(minimumDataPoints).fill(null).map(() => ({ ...sampleData }))
  }

  const emptyDataPointTemplate: DataPoint = Object.keys(data[0]).reduce<DataPoint>(
    (template, key) => {
      if (key !== 'label') {
        template[key] = 0
      }
      return template
    },
    { label: '' }
  )

  const emptyDataPoints: DataPoint[] = []
  for (let i = 0; i < minimumDataPoints - data.length; i++) {
    const emptyDataPoint: DataPoint = { ...emptyDataPointTemplate, label: '' }
    emptyDataPoints.push(emptyDataPoint)
  }

  return [...emptyDataPoints, ...data]
}
