import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNotification } from 'src/hooks/use-notification'
import { queryKeys } from 'src/libs/query-keys'
import { createJob } from 'src/libs/api/backend/jobs'
import type { Job, NewJob } from 'src/libs/api/backend/jobs'

interface Args {
  job: NewJob
  onSuccess?: (data: Job) => void
  onError?: () => void
}

interface Res {
  createNewJob: ({ job, onSuccess, onError }: Args) => void
}

export const useCreateNewJob = (): Res => {
  const queryClient = useQueryClient()
  const { notify } = useNotification()

  const mutation = useMutation({
    mutationFn: async ({ job }: Args) => await createJob(job),
    onError: (err, variables) => {
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: `An error occurred when creating your job: ${err.message}`
      })
      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.jobs]
      })
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.jobByDepartments]
      })
      if (variables.onSuccess) {
        variables.onSuccess(data)
      }
    }
  })

  const createNewJob = (args: Args): void => {
    mutation.mutate(args)
  }

  return { createNewJob }
}
