import { Caption, Paragraph } from 'src/components/primitives/typography'
import * as S from './automate-info-banner.styled'
import { Flex } from 'src/components/primitives/flex'
import { AutomateIcon } from 'src/components/primitives/automate-icon'
import { Icon, Icons } from 'src/components/primitives/icon'
import { useCallback, useMemo, useState } from 'react'
import { useListJobSearchRefinementsQuery } from 'src/hooks/queries/use-job-search-refinements'
import { useParams } from 'react-router'
import { useQueryParams } from 'src/hooks/use-query-params'
import { useUpdateJobSearchRefinement } from 'src/hooks/mutations/use-update-job-search-refinement'
import { Button } from 'src/components/primitives'
import { useDialog } from 'src/hooks/use-dialog'
import { DialogId } from 'src/contexts/dialogs'
import { useJobQuery } from 'src/hooks/queries/use-job'

// const refinementMatchCount = (count: number): string => {
//   if (count === 1) {
//     return '1 candidate'
//   }

//   return `${count > 1000 ? `${Math.round(count / 1000)}k` : count} candidates`
// }

// const SearchRefinementCount = ({ count }: { count: number | undefined | null }): JSX.Element => {
//   if (!count) {
//     return <>&nbsp;</>
//   }
//   return (
//     <Paragraph size='XS'>
//       About {refinementMatchCount(count)}
//     </Paragraph>
//   )
// }

const RateThisSearch = (): JSX.Element => {
  const [thumbsUpClicked, setThumbsUpClicked] = useState(false)
  const [thumbsDownClicked, setThumbsDownClicked] = useState(false)
  const { openDialog } = useDialog()
  return (
    <Flex $align='center' $gap={8} $width='fit-content'>
      <Paragraph size='XS' $fontWeight={500}>
        Rate this search
      </Paragraph>
      <Flex $align='center' $gap={4} $width='fit-content'>
        <Button
          leadingIcon={thumbsUpClicked ? Icons.thumbsUpFill : Icons.thumbsUp}
          $variant='flat'
          $colorTheme={thumbsUpClicked ? 'positive' : 'muted'}
          $height={24}
          $width={24}
          $fontSize={12}
          disabled={thumbsDownClicked || thumbsUpClicked}
          onClick={() => {
            setThumbsUpClicked(true)
          }}
        />
         <Button
          leadingIcon={thumbsDownClicked ? Icons.thumbsDownFill : Icons.thumbsDown}
          $variant='flat'
          $colorTheme={thumbsDownClicked ? 'negative' : 'muted'}
          $height={24}
          $width={24}
          $fontSize={12}
          disabled={thumbsDownClicked || thumbsUpClicked}
          onClick={() => {
            setThumbsDownClicked(true)
            openDialog(DialogId.REFINEMENT_FEEDBACK)
          }}
        />
      </Flex>
    </Flex>
  )
}

export const AutomateInfoBanner = (): JSX.Element => {
  const { jobId } = useParams()
  const { data: jobData } = useJobQuery()
  const { data: jobSearchRefinements } = useListJobSearchRefinementsQuery(jobId)
  const { getParam } = useQueryParams()
  const jobSearchRefinementId = getParam('jobSearchRefinementId')
  const { updateJobSearchRefinement } = useUpdateJobSearchRefinement()
  const { openDialog } = useDialog()

  const jobSearchRefinement = useMemo(() => {
    return jobSearchRefinements?.find((refinement) => refinement.id === jobSearchRefinementId)
  }, [jobSearchRefinements, jobSearchRefinementId])

  const autoApproveIsEnabled = jobSearchRefinement?.automateAutoApproveCandidates ?? false

  const handleUpdateJobRefinement = useCallback((isEnabled: boolean): void => {
    updateJobSearchRefinement({
      jobId: jobId ?? '',
      jobSearchRefinementId: jobSearchRefinementId ?? '',
      searchQuery: jobSearchRefinement?.searchQuery ?? '',
      automateAutoApproveCandidates: isEnabled
    })
  }, [jobSearchRefinement, jobId, jobSearchRefinementId])

  if (jobData?.deleted) {
    return <></>
  }

  if (!autoApproveIsEnabled) {
    return (
      <S.Wrapper>
        <Flex $align='center' $gap={20} $justify='space-between'>
          <div>&nbsp;</div>
          {/* <SearchRefinementCount count={jobSearchRefinement?.candidateMatchCount} /> */}
          <Flex $align='center' $gap={20} $justify='flex-end' $width='auto'>
            <RateThisSearch />
            <Button
              $variant="outline"
              $colorTheme="muted"
              $height={24}
              $fontSize={12}
              leadingIcon="sparkles"
              onClick={() => {
                openDialog(DialogId.ENABLE_AUTO_OUTREACH)
              }}
            >
              Auto-outreach
            </Button>
          </Flex>
        </Flex>
      </S.Wrapper>
    )
  }

  return (
    <S.Wrapper>
      <Flex $align='center' $gap={20} $justify='space-between'>
        <div>&nbsp;</div>
        {/* <SearchRefinementCount count={jobSearchRefinement?.candidateMatchCount} /> */}
        <Flex $align='center' $gap={20} $justify='flex-end' $width='auto'>
          <RateThisSearch />
          <S.ToggleWrapper
            onClick={() => {
              handleUpdateJobRefinement(!autoApproveIsEnabled)
            }}
          >
            <Icon name="sparkles" size={12} />
            <Caption size="XS">Auto-outreach</Caption>
            <S.ToggleButton $isChecked={autoApproveIsEnabled}>
              <span>{autoApproveIsEnabled ? 'ON' : 'OFF'}</span>
            </S.ToggleButton>
          </S.ToggleWrapper>
        </Flex>
      </Flex>
      {autoApproveIsEnabled && <S.Banner $isEnabled={autoApproveIsEnabled}>
        <S.Arrow>
          <S.ArrowShape />
        </S.Arrow>
        <AutomateIcon isAnimating={autoApproveIsEnabled} />
        <Flex $direction="column" $gap={4}>
          <Caption size="XS" $color={autoApproveIsEnabled ? 'fgPrimary' : 'fgSecondary'}>
            Auto-outreach {autoApproveIsEnabled ? 'enabled' : 'disabled'}
          </Caption>
          <Paragraph size="XS" $color="fgSecondary">
            Pin will automatically reach out to new candidates from this search daily.
          </Paragraph>
        </Flex>
      </S.Banner>}
    </S.Wrapper>
  )
}
