import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNotification } from 'src/hooks/use-notification'
import { queryKeys } from 'src/libs/query-keys'
import { setCandidatesHired } from 'src/libs/api/backend/candidate_jobs'
import type {
  CandidateJobExpanded
} from 'src/libs/api/backend/candidate_jobs'
import { isNil, keyBy } from 'lodash'

interface Args {
  candidateJobIds: string[]
}

export const useHireCandidate = () => {
  const queryClient = useQueryClient()
  const { notify } = useNotification()

  const mutation = useMutation({
    mutationFn: async ({ candidateJobIds }: Args) => {
      return await setCandidatesHired(candidateJobIds)
    },
    onError: (err) => {
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: `An error occurred when marking your candidate as hired: ${err.message}`
      })
    },
    onSuccess: async (dataFromApi) => {
      // await queryClient.invalidateQueries({
      //   queryKey: [queryKeys.candidateJobs, jobId]
      // })
      const candidateJobs = keyBy(dataFromApi, 'id')
      queryClient.setQueriesData<CandidateJobExpanded[]>(
        { queryKey: [queryKeys.candidateJobs] },
        (oldCandidateJobs) => {
          if (isNil(oldCandidateJobs)) {
            return oldCandidateJobs
          }

          return oldCandidateJobs.map((o) => {
            if (candidateJobs[o.id]) {
              return {
                ...o,
                ...candidateJobs[o.id]
              }
            }

            return o
          })
        }
      )

      await queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateJobCounts]
      })

      const cachedCandidateJobs = queryClient.getQueriesData({
        queryKey: [queryKeys.candidateJobs]
      })

      const modifiedCandidateJobIds = dataFromApi.map((candidateJob) => candidateJob.id)
      let notificationPayload: CandidateJobExpanded[] = []
      for (const queryData of cachedCandidateJobs) {
        const candidateJobs = queryData[1] as CandidateJobExpanded[]
        const matches: CandidateJobExpanded[] = candidateJobs?.filter(
          (candidateJob: CandidateJobExpanded) => modifiedCandidateJobIds.includes(candidateJob.id)) ?? []
        notificationPayload = [...notificationPayload, ...matches]
      }

      notify({
        type: 'toast',
        variant: 'positive',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: 'Candidate marked as hired.'
      })
    }
  })

  const hireCandidate = (args: Args) => {
    mutation.mutate(args)
  }

  return { hireCandidate }
}
