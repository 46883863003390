import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { JobSearchRefinement } from 'src/libs/api/backend/jobs'
import { deleteJobSearchRefinement } from 'src/libs/api/backend/jobs'
import { useNotification } from '../use-notification'
import { useNavigate, useParams } from 'react-router'
import { queryKeys } from 'src/libs/query-keys'
import RouteBuilder from 'src/libs/route-builder'
import { isNil } from 'lodash'
import { generateUrlWithParams } from 'src/utils/format-url'

interface Args {
  jobSearchRefinementId: JobSearchRefinement['id']
}

interface Res {
  deleteSearchRefinement: ({ jobSearchRefinementId }: Args) => void
}

export const useDeleteJobSearchRefinement = (): Res => {
  const { notify } = useNotification()
  const { jobId } = useParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const mutation = useMutation({
    mutationFn: async ({ jobSearchRefinementId }: Args) => {
      await deleteJobSearchRefinement({ jobId: jobId ?? '', jobSearchRefinementId })
    },
    onError: (err) => {
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: `An error occurred when deleting your search: ${err.message}`
      })
    },
    onSuccess: async (_, variables) => {
      const queryData = queryClient.getQueriesData<JobSearchRefinement[]>({
        queryKey: [queryKeys.jobSearchRefinements, jobId]
      })
      const existingRefinements = queryData.flatMap(([_, data]) => data ?? [])
      await queryClient.invalidateQueries({ queryKey: [queryKeys.jobSearchRefinements, jobId] })

      const getTargetId = (): string | null => {
        const index = existingRefinements.findIndex(
          (refinement) => refinement.id === variables.jobSearchRefinementId
        )
        return index !== -1 && index + 1 < existingRefinements.length
          ? existingRefinements[index + 1].id
          : null
      }

      const targetId = getTargetId()
      if (!isNil(targetId)) {
        const targetHref = generateUrlWithParams(
          RouteBuilder.build('JOBS_CANDIDATES_SOURCING', {
            jobId: jobId ?? ''
          }), {
            jobSearchRefinementId: targetId
          }
        )
        navigate(targetHref)
      } else {
        navigate(RouteBuilder.build('JOBS_CANDIDATES_SOURCING_SEARCH', { jobId: jobId ?? '' }))
      }
    }
  })

  const deleteSearchRefinement = (args: Args): void => {
    mutation.mutate(args)
  }

  return { deleteSearchRefinement }
}
