import styled, { keyframes } from 'styled-components'

export const Recommendations = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  border-radius: 6px;
  padding: 4rem;
  height: calc(100% - 20px);
`

export const Inner = styled.div`
  max-width: 648px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 80px 1fr;
  grid-row-gap: 0.5rem;
`

export const Searching = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-items: center;
  justify-content: center;
`

export const Top = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr;
  grid-columns: 1 / -1;
  p {
    grid-column: 2;
    align-self: center;
  }
`

const show = keyframes`
  from { opacity: 0; transform: translateY(8px); }
  to { opacity: 1; transform: translateY(0); }
`

export const Suggestions = styled.div`
  grid-row: 2;
  grid-column: 2;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`

export const Suggestion = styled.div<{ $step: number }>`
  opacity: 0;
  transform: translateY(8px);
  animation: ${show} 0.45s ease-in-out forwards;
  animation-delay: ${({ $step }) => `${$step * 0.1}s`};
`

export const Card = styled.div`
  padding: 0.75rem 1.25rem;
  border-radius: 0.75rem;
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  transition: all 0.15s ease-in-out;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  p {
    line-height: 1.6;
  }
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.bgPrimary};
      box-shadow: ${({ theme }) => theme.boxShadows.lg};
    }
  }
`
