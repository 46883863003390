import { useContext } from 'react'
import { NotificationContext } from 'src/contexts/notifications'
import type { NotificationContextProps } from 'src/contexts/notifications'

export const useNotification = (): NotificationContextProps => {
  return useContext(NotificationContext)
}

interface NotifyErrorSuccess {
  notifySuccess: (msg: string, overrides?: Record<string, unknown>) => void
  notifyError: (msg: string, overrides?: Record<string, unknown>) => void
}
export const useNotifyErrorSuccess = (): NotifyErrorSuccess => {
  const { notify } = useNotification()
  function notifySuccess (message: string, overrides: Record<string, unknown> = {}): void {
    notify({
      type: 'toast',
      variant: 'positive',
      position: 'bottom-right',
      icon: 'check-check',
      autoClose: true,
      message,
      ...overrides
    })
  }
  function notifyError (message: string, overrides: Record<string, unknown> = {}): void {
    notify({
      type: 'toast',
      variant: 'negative',
      position: 'bottom-right',
      icon: 'x-octagon',
      autoClose: false,
      message,
      ...overrides
    })
  }
  return { notifySuccess, notifyError }
}
