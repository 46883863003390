import { AnimatePresence } from 'framer-motion'
import { LogoSymbol } from 'src/components/primitives/logo'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { DivAsButton } from 'src/components/primitives/div-as-button'
import * as S from './search-refinement-recommendations.styled'

interface SearchRefinementRecommendationsProps {
  onSelect: (description: string) => void
  suggestions?: string[]
}

interface SuggestionProps {
  onSelect: (description: string) => void
  children: React.ReactNode
  refinement: string
  step: number
}

const Suggestion = ({ children, refinement, onSelect, step }: SuggestionProps): JSX.Element => {
  return (
    <S.Suggestion $step={step}>
      <DivAsButton
        ariaLabel="Use this recommendation"
        onClick={() => {
          onSelect(refinement)
        }}
      >
        <S.Card>{children}</S.Card>
      </DivAsButton>
    </S.Suggestion>
  )
}

export const SearchRefinementRecommendations = ({
  onSelect,
  suggestions = [
    'Senior candidate, based in New York City with a strong track record of leading development teams.',
    'Junior candidate, fully remote, CS degree. Familiar with modern JavaScript frameworks like React',
    'Ecommerce focused, with experience in scalable and secure e-commerce platforms.'
  ]
}: SearchRefinementRecommendationsProps): JSX.Element => {
  return (
    <S.Recommendations>
      <AnimatePresence initial={false}>
        <S.Inner>
          <S.Top>
            <LogoSymbol width={40} height={43} />
            <Caption size="2XS" $color="fgTertiary" $whiteSpace="nowrap">
              PIN SUGGESTS
            </Caption>
          </S.Top>
          <S.Suggestions>
            {suggestions.map((suggestion, index) => (
              <Suggestion
                key={index}
                step={index}
                onSelect={(value) => {
                  onSelect(value)
                }}
                refinement={suggestion}
              >
                <Paragraph $color="fgSecondary" size="XS">
                  {suggestion}
                </Paragraph>
              </Suggestion>
            ))}
          </S.Suggestions>
        </S.Inner>
      </AnimatePresence>
    </S.Recommendations>
  )
}
