import { useMutation } from '@tanstack/react-query'
import { useNotification } from '../use-notification'
import { markNotificationsSeenApi } from 'src/libs/api/backend/notifications'
import queryClient from '../query-client'
import { queryKeys } from 'src/libs/query-keys'

interface Args {
  notificationIds: string[]
  onSuccess?: () => void
  onError?: () => void
}

interface Res {
  markNotificationsSeen: ({ notificationIds, onSuccess, onError }: Args) => void
}

export const useMarkNotificationSeen = (): Res => {
  const { notify } = useNotification()

  const mutation = useMutation({
    mutationFn: async ({ notificationIds }: Args) => {
      await markNotificationsSeenApi(notificationIds)
    },
    onError: (err, variables) => {
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: `An error occurred when viewing your notifications: ${err.message}`
      })
      variables.onError?.()
    },
    onSuccess: async (_, variables) => {
      // notifications IS NOT invalidated because we want to show new notifications status
      variables.onSuccess?.()

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [queryKeys.notifications]
        }),
        queryClient.invalidateQueries({
          queryKey: [queryKeys.notificationsCount]
        })
      ])
    }
  })

  const markNotificationsSeen = (args: Args): void => {
    mutation.mutate(args)
  }

  return { markNotificationsSeen }
}
