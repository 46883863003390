import Api from '..'
import { z } from 'zod'

const jobReportParser = z.object({
  sequenceStepEngagementMetrics: z.array(z.object({
    subject: z.string().nullish(),
    email: z.string().nullish(),
    position: z.number(),
    sent: z.number(),
    opened: z.number(),
    clicked: z.number(),
    replied: z.number()
  })),
  candidateApprovalOverTimeCounts: z.array(z.object({
    date: z.string(),
    friendlyDate: z.string(),
    approved: z.number(),
    rejected: z.number()
  })),
  openedOverTimeCounts: z.array(z.object({
    date: z.string(),
    friendlyDate: z.string(),
    opened: z.number()
  })),
  respondedOverTimeCounts: z.array(z.object({
    date: z.string(),
    friendlyDate: z.string(),
    responded: z.number()
  })),
  sourcedOverTimeCounts: z.array(z.object({
    date: z.string(),
    manual: z.number(),
    ai: z.number(),
    friendlyDate: z.string()
  })),
  emailMessageCounts: z.array(z.object({
    date: z.string(),
    sent: z.number(),
    received: z.number(),
    friendlyDate: z.string()
  })),
  rejectionReasonCounts: z.array(z.object({
    rejectionReason: z.string().nullish().transform((rejectionReason) => rejectionReason ?? 'NULL'),
    fromStage: z.string(),
    rejectionCount: z.number()
  })),
  approvalStatusCounts: z.array(z.object({
    jobStageGroup: z.string().transform((jobStageGroup) => {
      const friendlyNames: Record<string, string> = {
        APPROVED: 'Approved',
        REJECTED: 'Rejected'
      }
      return friendlyNames[jobStageGroup] ?? jobStageGroup
    }),
    count: z.number()
  })),
  teamActivitySourcedCounts: z.array(z.object({
    count: z.number(),
    userName: z.string(),
    userId: z.string().uuid(),
    jobId: z.string()
  })),
  teamActivityRejectedCounts: z.array(z.object({
    userName: z.string(),
    userId: z.string().uuid(),
    count: z.number()
  })),
  teamActivityEmailCounts: z.array(z.object({
    userName: z.string(),
    userId: z.string().uuid(),
    opened: z.number(),
    responded: z.number()
  })),
  teamActivitySourcedByCounts: z.array(z.object({
    source: z.string(),
    sourced: z.number(),
    sequenced: z.number(),
    communicating: z.number()
  })),
  totalApproved: z.object({
    approved: z.number()
  }),
  totalOpened: z.object({
    opened: z.number()
  }),
  totalResponded: z.object({
    responded: z.number()
  }),
  bestTimeToSend: z.object({
    data: z.array(z.object({
      hour: z.number(),
      dayOfWeek: z.number(),
      friendlyDayOfWeek: z.string(),
      friendlyHour: z.string(),
      count: z.number(),
      percentage: z.number()
    })),
    timezone: z.string()
    // longestStreaks: z.array(z.object({
    //   start: z.number(),
    //   end: z.number(),
    //   totalPercentage: z.number(),
    //   friendlyStart: z.string(),
    //   friendlyEnd: z.string()
    // }))
  })
})

export type JobReport = z.infer<typeof jobReportParser>

export async function fetchJobReport (jobId: string): Promise<JobReport> {
  const { data } = await Api.get(`/reports/job_report/${jobId}`)
  return jobReportParser.parse(data)
}
