import { Button, Flex } from 'src/components/primitives'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import * as S from './upgrade-your-plan-from-professional.styled'
import { Icon, Icons } from 'src/components/primitives/icon'
import { useSession } from 'src/hooks/queries/use-session'

export const UpgradeYourPlanFromProfessional = (): JSX.Element => {
  const { data: session } = useSession()

  return (
    <S.Container>
      <Flex $direction='column'>
        <Paragraph size='SM'>You’ve reached your limit of {session?.jobsLimit ?? 0} open job positions on the Professional plan.</Paragraph>
        <Paragraph size='SM'>Get unlimited open job positions with a custom plan.</Paragraph>
      </Flex>
      <S.Details>
        <Flex $direction='column' $flex='1 1 auto' $gap={16} $width='auto'>
          <Caption size='MD'>Custom</Caption>
          <Flex $direction='column' $gap={8}>
            <S.DetailsBulletPoint>
              <Icon name={Icons.checkFill} size={12} color='fgTertiary' />
              <Paragraph size='SM' $color='fgPrimary'>Unlimited users</Paragraph>
            </S.DetailsBulletPoint>
            <S.DetailsBulletPoint>
              <Icon name={Icons.checkFill} size={12} color='fgTertiary' />
              <Paragraph size='SM' $color='fgPrimary'>Unlimited qualified candidates</Paragraph>
              <Icon name={Icons.helpCircle} size={12} color='fgTertiary' />
            </S.DetailsBulletPoint>
            <S.DetailsBulletPoint>
              <Icon name={Icons.checkFill} size={12} color='fgTertiary' />
              <Paragraph size='SM' $color='fgPrimary'>Unlimited open job positions</Paragraph>
            </S.DetailsBulletPoint>
          </Flex>
        </Flex>
        <S.VerticalDivider />
        <Flex $direction='column' $align='center' $width='200px' $gap={16}>
          <Caption $fontWeight={400} size='XS'>We’ll work with you to build a custom plan that fits your needs.</Caption>
          <Button $variant='fill' $colorTheme='tint' $width={200} $height={40} $fontSize={14} $align='center' href='mailto:hi@pin.com'>
            Contact us
          </Button>
        </Flex>
      </S.Details>
    </S.Container>
  )
}
