import { CandidatesInCommunicationTable } from 'src/components/tables/candidates-in-communication-table'
import { CandidatesPageHeader } from 'src/components/blocks/candidates-page-header'
import { CandidatesTablePagesContentInner, CandidatesTablePagesHeader } from './candidates.styled'

const JobCandidatesInCommunicationPage = (): JSX.Element => {
  return (
    <CandidatesTablePagesContentInner>
      <CandidatesTablePagesHeader>
        <CandidatesPageHeader heading="In Communication" />
      </CandidatesTablePagesHeader>
      <CandidatesInCommunicationTable />
    </CandidatesTablePagesContentInner>
  )
}

export default JobCandidatesInCommunicationPage
