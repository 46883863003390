import { useEffect, useState } from 'react'
import * as S from './loading-skeleton.styled'
import { Flex } from 'src/components/primitives'
import { Icon } from 'src/components/primitives/icon'

type Variant =
  | 'CandidateDetailsCard'
  | 'CandidatesTable'
  | 'EmailSequenceEditor'
  | 'Inbox'
  | 'InboxSidebar'
  | 'JobsList'
  | 'Text'
  | 'NavigationTitle'
  | 'Header'
  | 'Select'

export interface LoadingSkeletonStyleProps {
  $variant: Variant
  $marginTop?: number
  $marginBottom?: number
  delay?: number
}

interface LoadingSkeletonProps extends LoadingSkeletonStyleProps {}

export const LoadingSkeleton = ({
  $variant,
  $marginTop,
  $marginBottom,
  delay = 1000
}: LoadingSkeletonProps): JSX.Element | null => {
  const [loadingDelay, setLoadingDelay] = useState(true)

  useEffect(() => {
    if (delay > 1000) {
      setTimeout(() => {
        setLoadingDelay(false)
      }, delay)
    } else {
      setLoadingDelay(false)
    }
  }, [delay])

  if (loadingDelay) {
    return <></>
  }

  if ($variant === 'CandidateDetailsCard') {
    return (
      <>
        <S.CandidateDetailsCard />
        <S.CandidateDetailsCard />
        <S.CandidateDetailsCard />
        <S.CandidateDetailsCard />
        <S.CandidateDetailsCard />
      </>
    )
  }

  if ($variant === 'CandidatesTable') {
    return (
      <>
        <S.Table>
          <S.TableHead />
          {Array.from({ length: 20 }).map((_, index) => (
            <S.TableRow key={index} />
          ))}
        </S.Table>
      </>
    )
  }

  if ($variant === 'EmailSequenceEditor') {
    return (
      <S.EmailSequenceEditor>
        <S.EmailSequenceSteps>
          <S.EmailSequenceStep />
          <S.EmailSequenceStep />
          <S.EmailSequenceStep />
        </S.EmailSequenceSteps>
        <S.EmailSequenceWindow />
      </S.EmailSequenceEditor>
    )
  }

  if ($variant === 'Inbox') {
    return (
      <S.Inbox>
        {Array.from({ length: 50 }, (_, index) => (
          <S.InboxItem key={index} />
        ))}
      </S.Inbox>
    )
  }

  if ($variant === 'InboxSidebar') {
    return (
      <S.InboxSidebar>
        {Array.from({ length: 50 }, (_, index) => (
          <S.InboxSidebarItem key={index} />
        ))}
      </S.InboxSidebar>
    )
  }

  if ($variant === 'JobsList') {
    return (
      <S.JobsList>
        {Array.from({ length: 5 }, (_, index) => (
          <S.JobCard key={index} />
        ))}
      </S.JobsList>
    )
  }

  if ($variant === 'Text') {
    return (
      <Flex $direction="column" $gap={4}>
        <S.Text />
        <S.Text />
      </Flex>
    )
  }

  if ($variant === 'NavigationTitle') {
    return <S.NavigationItem />
  }

  if ($variant === 'Header') {
    return <S.Header />
  }

  if ($variant === 'Select') {
    return (
      <S.SelectContainer $marginTop={$marginTop} $marginBottom={$marginBottom}>
        <Flex $gap={16} $direction='row' $align='center' $justify='space-between' $width='100%'>
          <S.Select />
          <Icon name='chevron-down' size={16} />
        </Flex>
      </S.SelectContainer>
    )
  }

  return null
}
