import * as S from './error.styled'

export const ErrorLayout = ({ children }: { children: React.ReactNode }): JSX.Element => {
  return (
    <S.ErrorLayout id="error-page">
      <div>
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
      </div>
      {process.env.NODE_ENV === 'development' && children}
    </S.ErrorLayout>
  )
}
