import React, { useCallback, useMemo } from 'react'
import { Table } from 'src/components/primitives/table'
import type { TableSchema } from 'src/components/primitives/table'
import {
  FavoriteCell,
  CandidateNameCell,
  CandidateSourceCell,
  CandidateLastContactDateCell,
  CandidateStatusCell,
  CandidateCreatedDateCell,
  CandidateRejectionReasonCell,
  CandidateRejectedDateCell,
  CandidateNextContactDateCell,
  CandidateErrorStatusCell,
  CandidateErrorActionCell
} from '../candidate-table-cells'
import type { CandidateJobExpanded, CandidateJobRejectionReason } from 'src/libs/api/backend/candidate_jobs'
import { useDialog } from 'src/hooks/use-dialog'
import type { Row } from '@tanstack/react-table'
import { Flex } from 'src/components/primitives/flex'
import { DialogId } from 'src/contexts/dialogs'
import { useGlobalError } from 'src/hooks/use-global-error'
import { AllSizes, COLUMN, sortByCandidateDateField, sortByCandidateJobDateField, sortByCandidateSequenceDateField } from './table-builder'
import type { ColumnSizes, DefaultTableSchema } from './table-builder'
import { usePauseCandidateSequence } from 'src/hooks/mutations/use-pause-candidate-job-sequence'
import { useRejectCandidate } from 'src/hooks/mutations/use-reject-candidate'

interface CandidatesTableProps {
  isLoading: boolean
  pageHeaderHeight?: number
  candidateJobs?: CandidateJobExpanded[]
  emptyState: React.ReactNode
  selectedRowsActions?: React.ReactNode
  setRowSelection: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
  rowSelection: Record<string, boolean>
  visibleColumns: COLUMN[]
}

export const CandidatesTable = ({
  isLoading,
  candidateJobs,
  emptyState,
  pageHeaderHeight = 0,
  setRowSelection,
  rowSelection,
  selectedRowsActions,
  visibleColumns
}: CandidatesTableProps): JSX.Element => {
  const { openDialog } = useDialog()
  const { isGlobalErrorOpen } = useGlobalError()
  const { setCandidateSequencePause } = usePauseCandidateSequence()
  const { rejectCandidate } = useRejectCandidate()

  const handlePauseSequence = useCallback((candidateJobIds: string[], isPaused: boolean): void => {
    setCandidateSequencePause({
      candidateJobIds,
      pause: isPaused
    })
  }, [setCandidateSequencePause])

  const rejectCandidates = useCallback((candidateJobIds: string[], rejectionReason: CandidateJobRejectionReason): void => {
    rejectCandidate({ candidateJobIds, rejectionReason })
  }, [rejectCandidate])

  const tableSchema: TableSchema<DefaultTableSchema> = useMemo(() => {
    const schema: Partial<TableSchema<DefaultTableSchema>> = {}

    visibleColumns.forEach((column) => {
      switch (column) {
        case COLUMN.FAVORITE:
          schema.favorite = 'Favorite'
          break
        case COLUMN.NAME:
          schema.name = 'Name'
          break
        case COLUMN.STATUS:
          schema.status = 'Status'
          break
        case COLUMN.SOURCE:
          schema.source = 'Source'
          break
        case COLUMN.REJECTION_REASON:
          schema.rejectionReason = 'Reason'
          break
        case COLUMN.LAST_CONTACT:
          schema.lastContact = 'Last email'
          break
        case COLUMN.NEXT_CONTACT:
          schema.nextContact = 'Next email'
          break
        case COLUMN.CREATED_AT:
          schema.createdAt = 'Date added'
          break
        case COLUMN.REJECTED_AT:
          schema.rejectedAt = 'Archived at'
          break
        case COLUMN.ERROR:
          schema.error = 'Error'
          break
        case COLUMN.ERROR_ACTIONS:
          schema.errorActions = null
          break
        default:
          break
      }
    })

    return schema as TableSchema<DefaultTableSchema>
  }, [visibleColumns])

  const columnSizes = useMemo(() => {
    const sizes: ColumnSizes = {}
    const columns = visibleColumns as Array<keyof DefaultTableSchema>

    columns.forEach((column) => {
      if (column in AllSizes) {
        sizes[column] = AllSizes[column]
      }
    })

    return sizes
  }, [visibleColumns])

  const handleRowClick = useCallback((row: Row<DefaultTableSchema>): void => {
    openDialog(DialogId.CANDIDATE_DETAILS, row.original.candidateJob?.id)
  }, [openDialog])

  const mapCandidatesToSchema = useCallback((candidateJobs: CandidateJobExpanded[]): DefaultTableSchema[] => {
    return candidateJobs.map((candidateJob) => {
      const mappedData: Partial<DefaultTableSchema> = {
        id: candidateJob.id ?? '',
        candidateJob,
        favorite: <FavoriteCell candidateJob={candidateJob} />,
        name: <CandidateNameCell candidateJob={candidateJob} />
      }

      visibleColumns.forEach((column) => {
        switch (column) {
          case COLUMN.STATUS:
            mappedData.status = <CandidateStatusCell candidateJob={candidateJob} />
            break
          case COLUMN.SOURCE:
            mappedData.source = <CandidateSourceCell candidateJob={candidateJob} />
            break
          case COLUMN.REJECTION_REASON:
            mappedData.rejectionReason = (
              <CandidateRejectionReasonCell candidateJob={candidateJob} />
            )
            break
          case COLUMN.LAST_CONTACT:
            mappedData.lastContact = <CandidateLastContactDateCell candidateJob={candidateJob} />
            break
          case COLUMN.NEXT_CONTACT:
            mappedData.nextContact = <CandidateNextContactDateCell candidateJob={candidateJob} />
            break
          case COLUMN.CREATED_AT:
            mappedData.createdAt = <CandidateCreatedDateCell candidateJob={candidateJob} />
            break
          case COLUMN.REJECTED_AT:
            mappedData.rejectedAt = <CandidateRejectedDateCell candidateJob={candidateJob} />
            break
          case COLUMN.ERROR:
            mappedData.error = <CandidateErrorStatusCell candidateJob={candidateJob} />
            break
          case COLUMN.ERROR_ACTIONS:
            mappedData.errorActions = (
              <CandidateErrorActionCell
                candidateJob={candidateJob}
                openDialog={openDialog}
                handlePauseSequence={handlePauseSequence}
                rejectCandidates={rejectCandidates}
              />
            )
            break
          default:
            break
        }
      })
      return mappedData as DefaultTableSchema
    })
  }, [handlePauseSequence, openDialog, rejectCandidates, visibleColumns])

  const tableData = useMemo(() => {
    return mapCandidatesToSchema(candidateJobs ?? [])
  // DO NOT REMOVE THIS ESLINT FLAG, or it will keep on refreshing on row selection
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateJobs])

  return (
    <>
      <Table<DefaultTableSchema>
        isLoading={isLoading}
        isGlobalErrorOpen={isGlobalErrorOpen}
        pageHeaderHeight={pageHeaderHeight}
        columnSorting={{
          favorite: {
            sortingFn: (rowA: Row<DefaultTableSchema>, rowB: Row<DefaultTableSchema>) => {
              const a = rowA.original.candidateJob?.favorite ?? false
              const b = rowB.original.candidateJob?.favorite ?? false

              if (a < b) return -1
              if (a > b) return 1
              return 0
            }
          },
          name: {
            sortingFn: (rowA: Row<DefaultTableSchema>, rowB: Row<DefaultTableSchema>) => {
              const nameA = rowA.original.candidateJob?.candidate?.name?.toLowerCase() ?? ''
              const nameB = rowB.original.candidateJob?.candidate?.name?.toLowerCase() ?? ''

              if (nameA < nameB) return 1
              if (nameA > nameB) return -1
              return 0
            }
          },
          createdAt: {
            sortingFn: sortByCandidateJobDateField('createdAt')
          },
          lastContact: {
            sortingFn: sortByCandidateDateField('lastCommunicatedAt')
          },
          nextContact: {
            sortingFn: sortByCandidateSequenceDateField('nextSequenceStepDate')
          },
          rejectedAt: {
            sortingFn: sortByCandidateJobDateField('updatedAt')
          },
          rejectionReason: {
            sortingFn: (rowA: Row<DefaultTableSchema>, rowB: Row<DefaultTableSchema>) => {
              const reasonA = rowA.original.candidateJob?.rejectionReason?.toLowerCase() ?? ''
              const reasonB = rowB.original.candidateJob?.rejectionReason?.toLowerCase() ?? ''

              if (reasonA < reasonB) return -1
              if (reasonA > reasonB) return 1
              return 0
            }
          },
          status: {
            sortingFn: (rowA: Row<DefaultTableSchema>, rowB: Row<DefaultTableSchema>) => {
              const statusA =
                `${rowA.original.candidateJob?.statusDisplay?.title?.toLowerCase()}${rowA.original.candidateJob?.statusDisplay?.subtitle?.toLowerCase()}` ??
                ''
              const statusB =
                `${rowB.original.candidateJob?.statusDisplay?.title?.toLowerCase()}${rowB.original.candidateJob?.statusDisplay?.subtitle?.toLowerCase()}` ??
                ''

              if (statusA < statusB) return -1
              if (statusA > statusB) return 1
              return 0
            }
          },
          // TODO: The actual values that's displayed in the table are not the
          // same as what where sorting by, this will need to be fixed
          source: {
            sortingFn: (rowA: Row<DefaultTableSchema>, rowB: Row<DefaultTableSchema>) => {
              const sourceA =
                rowA.original.candidateJob?.source ??
                rowA.original.candidateJob?.creatorUserId ??
                ''
              const sourceB =
                rowB.original.candidateJob?.source ??
                rowB.original.candidateJob?.creatorUserId ??
                ''

              if (sourceA < sourceB) return -1
              if (sourceA > sourceB) return 1
              return 0
            }
          }
        }}
        columnSizes={columnSizes}
        schema={tableSchema}
        tableData={tableData}
        onRowClick={(row) => {
          handleRowClick(row)
        }}
        setRowSelection={setRowSelection}
        rowSelection={rowSelection}
        ignoredCellsOnRowClick={[COLUMN.FAVORITE, COLUMN.STATUS]}
        emptyState={emptyState}
        selectionActions={<Flex $gap={6}>{selectedRowsActions}</Flex>}
      />
    </>
  )
}
