import { useMutation } from '@tanstack/react-query'
import { useNotification } from '../use-notification'
import queryClient from '../query-client'
import { queryKeys } from 'src/libs/query-keys'
import { exportCandidateToAts } from 'src/libs/api/backend/merge'
import type { CandidateExpanded } from 'src/libs/api/backend/candidate_jobs'
import { pluralize } from 'src/libs/pluralize'

interface Args {
  jobId: string
  candidateIds: Array<CandidateExpanded['id']>
  onSuccess?: () => void
  onError?: () => void
}

interface Res {
  exportCandidates: ({ jobId, candidateIds, onSuccess, onError }: Args) => void
}

export const useExportCandidateToAts = (): Res => {
  const { notify } = useNotification()

  const mutation = useMutation({
    mutationFn: async ({ jobId, candidateIds }: Args) => {
      await exportCandidateToAts({ jobId, candidateIds })
    },
    onError: (err, variables) => {
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: `${err.message}`,
        autoClose: false
      })
      variables.onError?.()
    },
    onSuccess: async (_, variables) => {
      variables.onSuccess?.()

      notify({
        type: 'toast',
        variant: 'positive',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: `Successfully exported ${pluralize(variables.candidateIds.length, 'candidate')} to ATS`
      })

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [queryKeys.mergeLinkJobs]
        })
      ])
    }
  })

  const exportCandidates = (args: Args): void => {
    mutation.mutate(args)
  }

  return { exportCandidates }
}
