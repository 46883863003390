import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.colors.bgSecondary};
`

export const Details = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.25rem 1.5rem;
  gap: 1.5rem;
  align-self: stretch;
  border-radius: ${({ theme }) => theme.spacing[12]};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  box-shadow: ${({ theme }) => theme.boxShadows.sm};
`

export const DetailsBulletPoint = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[8]};
  padding: 0 2px;
`

export const VerticalDivider = styled.div`
  width: 1px;
  height: 112px;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
`
