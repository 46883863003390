import styled from 'styled-components'

export const ErrorLayout = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 400;
  div {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  * {
    text-align: center;
  }
  h1 {
    font-size: 16px;
    margin: 4px 0;
  }
  p {
    font-size: 14px;
    opacity: 0.65;
    margin: 4px 0;
  }
`
