import { CANDIDATES_PAGES_MAX_WIDTH, HEADER_PADDING, SIDEBAR_PADDING } from 'src/styles/constants'
import styled from 'styled-components'

export const ContentInner = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: ${HEADER_PADDING} ${SIDEBAR_PADDING};
`

export const MaxWidth = styled.div`
  max-width: ${CANDIDATES_PAGES_MAX_WIDTH};
  height: 100%;
`
