import type { Row } from '@tanstack/react-table'
import { ColumnWidthType } from 'src/components/primitives/table'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'

export enum COLUMN {
  FAVORITE = 'favorite',
  NAME = 'name',
  STATUS = 'status',
  SOURCE = 'source',
  CREATED_AT = 'createdAt',
  REJECTION_REASON = 'rejectionReason',
  LAST_CONTACT = 'lastContact',
  NEXT_CONTACT = 'nextContact',
  REJECTED_AT = 'rejectedAt',
  ERROR = 'error',
  ERROR_ACTIONS = 'errorActions'
}

export interface DefaultTableSchema {
  id?: string
  candidateJob?: CandidateJobExpanded
  favorite: React.ReactNode
  name: React.ReactNode
  source: React.ReactNode
  status: React.ReactNode
  rejectionReason: React.ReactNode
  lastContact: React.ReactNode
  nextContact: React.ReactNode
  createdAt: React.ReactNode
  rejectedAt: React.ReactNode
  error: React.ReactNode
  errorActions: React.ReactNode
}

export const AllSizes: ColumnSizes = {
  favorite: { type: ColumnWidthType.FIXED, value: 32 },
  name: { type: ColumnWidthType.RATIO, value: 1.5 }
}

export type ColumnSizes = {
  [key in keyof DefaultTableSchema]?: {
    type: ColumnWidthType
    value: number
  }
}

type SortableCandidateJobDateFields = 'createdAt' | 'updatedAt'

export const sortByCandidateJobDateField = (field: SortableCandidateJobDateFields) => {
  return (rowA: Row<DefaultTableSchema>, rowB: Row<DefaultTableSchema>) => {
    const dateA = rowA.original.candidateJob?.[field] ?? ''
    const dateB = rowB.original.candidateJob?.[field] ?? ''

    if (dateA < dateB) return -1
    if (dateA > dateB) return 1
    return 0
  }
}

export type SortableCandidateDateFields = 'lastCommunicatedAt'

export const sortByCandidateDateField = (field: SortableCandidateDateFields) => {
  return (rowA: Row<DefaultTableSchema>, rowB: Row<DefaultTableSchema>) => {
    const nameA = rowA.original.candidateJob?.candidate?.[field] ?? ''
    const nameB = rowB.original.candidateJob?.candidate?.[field] ?? ''

    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    return 0
  }
}

export type SortableCandidateSequenceDateFields = 'nextSequenceStepDate'

export const sortByCandidateSequenceDateField = (field: SortableCandidateSequenceDateFields) => {
  return (rowA: Row<DefaultTableSchema>, rowB: Row<DefaultTableSchema>) => {
    const dateA = rowA.original.candidateJob?.candidateSequence?.[field] ?? ''
    const dateB = rowB.original.candidateJob?.candidateSequence?.[field] ?? ''

    if (dateA < dateB) return -1
    if (dateA > dateB) return 1
    return 0
  }
}
