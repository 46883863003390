import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { isNil } from 'lodash'
import * as Dialog from 'src/components/primitives/dialog'
import { EmailSequenceEditor } from 'src/components/blocks/email-sequence-editor'
import { useDialog } from 'src/hooks/use-dialog'
import { When } from 'src/components/blocks/when'
import type { Spacing } from 'src/styles/theme/types'
import { useJobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import { SequenceOptions } from './sequence-options'
import type { EmailSequence } from 'src/models/sequence'
import { Intro } from './intro'
import { useJobsQuery } from 'src/hooks/queries/use-jobs'
import * as S from './create-sequence-dialog.styled'
import { DialogId } from 'src/contexts/dialogs'
import { SequenceStepGenerationState, SequenceStepType } from 'src/libs/api/backend/sequences'
import { isSequenceStepsEmpty } from 'src/libs/sequence'

enum VIEW {
  INTRO = 'INTRO',
  OPTIONS = 'OPTIONS',
  CREATE_MANUALLY = 'CREATE_MANUALLY',
  CREATE_WITH_AI = 'CREATE_WITH_AI'
}

export const CreateSequenceDialog = (): JSX.Element => {
  const { jobId } = useParams()
  const { getPayload, openAlert } = useDialog()
  const payload = getPayload<VIEW>(DialogId.CREATE_SEQUENCE)
  const { data: jobs } = useJobsQuery()
  const [jobIdToCopySequenceFrom, setJobIdToCopySequenceFrom] = useState<string | null>(null)
  const { isDialogOpen, controlDialog, closeDialog } = useDialog()
  const { data: sequence } = useJobSequenceQuery()
  const { isPending, data: sequenceToCopy } = useJobSequenceQuery(jobIdToCopySequenceFrom ?? undefined)
  const isEmptySequence = isSequenceStepsEmpty(sequence)

  const inCompletedSequenceStepsIds = useMemo(() => {
    return (
      sequence?.sequenceSteps?.filter((step) =>
        step.generationState === SequenceStepGenerationState.IN_PROGRESS
      ).map((step) => step.id).join('')
    )
  }, [sequence?.sequenceSteps])

  const getInitialView = (): VIEW => {
    if (!isNil(payload)) {
      return payload
    } else if (isEmptySequence) {
      return VIEW.INTRO
    } else {
      return VIEW.OPTIONS
    }
  }

  const [view, setView] = useState<VIEW>(getInitialView())

  const defaultPadding = {
    top: 0 as Spacing,
    right: 0 as Spacing,
    bottom: 0 as Spacing,
    left: 0 as Spacing
  }

  const DIALOG_PADDING = {
    INTRO: {
      top: 24 as Spacing,
      right: 24 as Spacing,
      bottom: 24 as Spacing,
      left: 24 as Spacing
    },
    OPTIONS: defaultPadding,
    CREATE_MANUALLY: defaultPadding,
    CREATE_WITH_AI: defaultPadding
  }

  const getSequence = useCallback(
    (jobId: string | null): EmailSequence => {
      if (isNil(jobId)) {
        return {
          id: sequence?.id ?? null,
          sequenceSteps: [
            {
              position: 0,
              type: SequenceStepType.AUTOMATED_EMAIL,
              waitDays: 0,
              subject: 'Email 1',
              body: ''
            }
          ]
        }
      } else {
        return {
          id: sequence?.id ?? null,
          autoArchiveAfterDays: sequenceToCopy?.autoArchiveAfterDays,
          dailyEmailLimit: sequenceToCopy?.dailyEmailLimit,
          sequenceSteps:
            sequenceToCopy?.sequenceSteps?.map((step) => {
              return {
                ...step,
                id: undefined,
                generationState: null
              }
            }) ?? []
        }
      }
    },
    [sequence?.id, sequenceToCopy?.autoArchiveAfterDays, sequenceToCopy?.dailyEmailLimit, sequenceToCopy?.sequenceSteps]
  )

  const handleNotify = useCallback(
    (onConfirmAction: () => void): void => {
      openAlert({
        message: 'Are you sure you want to leave this page?',
        description: 'All your changes will be lost.',
        cancelText: 'Stay on page',
        confirmText: 'Discard and leave',
        onConfirm: () => {
          onConfirmAction()
        }
      })
    },
    [openAlert]
  )

  return (
    <Dialog.Root
      id={DialogId.CREATE_SEQUENCE}
      isOpen={isDialogOpen(DialogId.CREATE_SEQUENCE)}
      onOpenChange={(value) => {
        if (view === VIEW.CREATE_MANUALLY || view === VIEW.CREATE_WITH_AI) {
          handleNotify(() => {
            controlDialog(DialogId.CREATE_SEQUENCE, value)
          })
        } else {
          controlDialog(DialogId.CREATE_SEQUENCE, value)
        }
      }}
      $width={view === VIEW.INTRO ? '500px' : 'large'}
      $height={view === VIEW.INTRO ? 'auto' : 'large'}
      $innerPadding={DIALOG_PADDING[view]}
    >
      <Dialog.Portal>
        <Dialog.Header title="Outreach Sequence" />
        <Dialog.Content>
          <When condition={view === VIEW.INTRO}>
            <Intro
              onContinue={() => {
                setView(
                  isEmptySequence &&
                    Array.isArray(jobs) &&
                    jobs.filter((job) => job.id !== jobId)?.length >= 1
                    ? VIEW.OPTIONS
                    : VIEW.CREATE_WITH_AI
                )
              }}
            />
          </When>
          <When condition={view === VIEW.OPTIONS}>
            <SequenceOptions
              onSelectGenerationType={(generationType, jobIdToCopy) => {
                setView(generationType === 'AI' ? VIEW.CREATE_WITH_AI : VIEW.CREATE_MANUALLY)
                if (jobIdToCopy) {
                  setJobIdToCopySequenceFrom(jobIdToCopy)
                } else {
                  setJobIdToCopySequenceFrom(null)
                }
              }}
              onSkip={() => {
                closeDialog(DialogId.CREATE_SEQUENCE)
              }}
            />
          </When>
          <When condition={view === VIEW.CREATE_WITH_AI}>
            <S.SequenceEditorWrapper>
              <EmailSequenceEditor
                key={inCompletedSequenceStepsIds} // Force re-render till all steps are completed
                initialState={sequence ?? undefined}
                shouldAutoGenerateEmails={true}
                onBackClick={() => {
                  handleNotify(() => {
                    setView(VIEW.OPTIONS)
                  })
                }}
              />
            </S.SequenceEditorWrapper>
          </When>
          <When condition={view === VIEW.CREATE_MANUALLY}>
            <S.SequenceEditorWrapper>
              <When condition={!isPending}>
                <EmailSequenceEditor
                  initialState={getSequence(jobIdToCopySequenceFrom)}
                  shouldAutoGenerateEmails={true}
                  onBackClick={() => {
                    handleNotify(() => {
                      setView(VIEW.OPTIONS)
                    })
                  }}
                />
              </When>
            </S.SequenceEditorWrapper>
          </When>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
