import { useLocation } from 'react-router'
import { ConnectedEmailAccounts } from 'src/components/blocks/connected-email-accounts'
import { OnboardingHeader } from 'src/components/blocks/onboarding-header'
import { Flex, Spacer } from 'src/components/primitives'
import { Button } from 'src/components/primitives/button'
import { Paragraph } from 'src/components/primitives/typography'
import { useSession } from 'src/hooks/use-session'
import RouteBuilder from 'src/libs/route-builder'

const OnboardingConnectedAccounts = (): JSX.Element => {
  const { emailAccountAccessTokens } = useSession()
  const hasConnectedAccounts = emailAccountAccessTokens?.some((token) => token.hasAccessToken)
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const skipInvite = !!params.get('skip_invite')

  return (
    <>
      <OnboardingHeader
        heading="Connect Your Accounts"
        subheading="Approve ending emails to candidates through your account."
      />
      <ConnectedEmailAccounts />
      <Spacer $size={40} />
      <Paragraph size="XS">
        You can connect multiple accounts to sync contacts and calendars to help with meeting
        scheduling and avoid emailing past candidates.
      </Paragraph>
      <Spacer $size={40} />
      <Flex $justify="flex-end">
        <Button
          $variant={hasConnectedAccounts ? 'fill' : 'outline'}
          $colorTheme={hasConnectedAccounts ? 'tint' : 'muted'}
          $width="auto"
          $height={40}
          href={RouteBuilder.build(skipInvite ? 'ROOT' : 'ONBOARDING_INVITE_TEAM')}
        >
          {hasConnectedAccounts ? 'Continue' : 'Continue without connecting accounts'}
        </Button>
      </Flex>
    </>
  )
}

export default OnboardingConnectedAccounts
