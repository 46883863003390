import * as Dialog from 'src/components/primitives/dialog'
import { useDialog } from 'src/hooks/use-dialog'
import { DialogId } from 'src/contexts/dialogs'
import { Calendar } from 'src/components/blocks/calendar'
import type { CalendarProps } from 'src/components/blocks/calendar'
import { useQueryParams } from 'src/hooks/use-query-params'

interface CalendarDialogProps extends CalendarProps {}

export const CalendarDialog = ({ candidateJob, scheduleNewEvent }: CalendarDialogProps): JSX.Element => {
  const { isDialogOpen, controlDialog } = useDialog()
  const { resetParam } = useQueryParams()

  return (
    <Dialog.Root
      id={DialogId.CALENDAR}
      isOpen={isDialogOpen(DialogId.CALENDAR)}
      onOpenChange={(value) => {
        if (!value) {
          resetParam('startDateTime')
          resetParam('endDateTime')
        }
        controlDialog(DialogId.CALENDAR, value)
      }}
      $width="almostFull"
      $height="almostFull"
      $innerPadding={{ top: 0, left: 0, bottom: 0, right: 0 }}
    >
      <Dialog.Portal>
        <Dialog.Content hasHeader={false}>
          <Calendar
            candidateJob={candidateJob}
            scheduleNewEvent={scheduleNewEvent}
          />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
