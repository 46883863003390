import {
  BarChart as ReBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip
} from 'recharts'
import { useTheme } from 'styled-components'
import { ChartHeader } from '../chart-header'
import { EmptyChart } from '../empty-chart'
import { getChartDataMaxValue } from 'src/utils/get-chart-data-max-value'
import { adjustChartDataWithMinimumPoints } from 'src/utils/adjust-chart-data'
import { When } from 'src/components/blocks/when'
import { ChartTooltip } from '../chart-tooltip'
import { ChartCursor } from '../chart-cursor'

export interface DataPoint {
  label: string | number
  [key: string]: string | number
}

interface DataColor {
  name?: string
  fill: string
}

export interface BarChartProps {
  chartTitle: string
  chartSubtitle?: string
  chartContext?: string
  data: DataPoint[]
  dataFormat?: 'number' | 'percent'
  dataColors?: DataColor[]
  showLegend?: boolean
}

interface MinHeightBarProps {
  width: number
  x: number
  y: number
}

const MinHeightBar = ({ width, x, y }: MinHeightBarProps): JSX.Element => {
  const { colors } = useTheme()
  const minHeight = 4
  return (
    <rect
      x={x}
      y={y - minHeight / 2}
      width={width}
      height={minHeight}
      fill={colors.borderTranslucent}
      style={{ transform: 'translateY(-3px)' }}
    />
  )
}

const CustomShape = (props: any): JSX.Element => {
  const { width, x, y, height, value } = props
  if (value === 0) {
    return <MinHeightBar width={width} x={x} y={y} />
  } else {
    return <rect x={x} y={y} width={width} height={height} fill={props.fill} />
  }
}

export const BarChart = ({
  chartTitle,
  chartSubtitle,
  chartContext,
  data,
  dataFormat = 'number',
  dataColors,
  showLegend = true
}: BarChartProps): JSX.Element => {
  const { colors } = useTheme()

  const barColors = dataColors ?? [
    {
      fill: colors.chart1
    },
    {
      fill: colors.chart2
    },
    {
      fill: colors.chart3
    },
    {
      fill: colors.chart4
    },
    {
      fill: colors.chart5
    },
    {
      fill: colors.chart6
    }
  ]

  const preparedData = adjustChartDataWithMinimumPoints(data)

  const allKeys = new Set<string>()
  preparedData.forEach(dataPoint => {
    Object.keys(dataPoint).forEach(key => {
      if (key !== 'label') {
        allKeys.add(key)
      }
    })
  })

  const areaKeys = Array.from(allKeys)

  const legendData = areaKeys.map((key, index) => ({
    name: key,
    color: barColors[index % barColors.length].fill
  }))

  return (
    <>
      <ChartHeader
        title={chartTitle}
        subtitle={chartSubtitle}
        context={chartContext}
        legend={showLegend && data?.length ? legendData : undefined}
      />
      <div
        style={{ width: '100%' }}
      >
        <When condition={!data.length}>
          <EmptyChart />
        </When>
        <When condition={data.length >= 1}>
          <ResponsiveContainer width="100%" height={300}>
            <ReBarChart
              data={preparedData}
              margin={{
                top: 10,
                right: 0,
                left: dataFormat === 'percent' ? -18 : -28,
                bottom: 20
              }}
            >
              <Tooltip
                content={<ChartTooltip />}
                cursor={<ChartCursor $fill={colors.bgTertiary} />}
              />
              <CartesianGrid vertical={false} stroke={colors.borderTranslucent} />
              <XAxis
                axisLine={false}
                tickLine={false}
                tick={{ fill: colors.fgSecondary, fontSize: 12 }}
                tickMargin={16}
                padding={{ left: 24, right: 24 }}
                dataKey="label"
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tickMargin={10}
                tick={{ fill: colors.fgSecondary, fontSize: 12 }}
                minTickGap={1}
                ticks={getChartDataMaxValue(data) <= 2 ? [0, 1, 2, 3, 4] : undefined}
                tickFormatter={(value) => (dataFormat === 'percent' ? `${value}%` : value)}
              />
              {areaKeys.map((key, index) => (
                <Bar
                  key={key}
                  // barSize={36}
                  maxBarSize={48}
                  dataKey={key}
                  fill={barColors[index % barColors.length].fill}
                  shape={<CustomShape fill={barColors[index % barColors.length].fill} />}
                  stackId={areaKeys?.length >= 2 ? 'a' : undefined}
                />
              ))}
            </ReBarChart>
          </ResponsiveContainer>
        </When>
      </div>
    </>
  )
}
