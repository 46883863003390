import { useMutation } from '@tanstack/react-query'
import { fetchDisconnectMergeIntegration } from 'src/libs/api/backend/merge'
import { useNotification } from '../use-notification'

interface Args {
  onSuccess?: () => void
}

interface Res {
  disconnectAtsIntegration: ({ onSuccess }: Args) => void
}

export const useDisconnectMergeIntegration = (): Res => {
  const { notify } = useNotification()

  const mutation = useMutation({
    mutationFn: async () => {
      await fetchDisconnectMergeIntegration()
    },
    onError: (err: Error) => {
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: `An error occurred when disconnecting from ATS - ${err.message}`
      })
    },
    onSuccess: (_data, variables: Args) => {
      notify({
        type: 'toast',
        variant: 'positive',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: 'Successfully disconnected from ATS'
      })
      if (variables.onSuccess) {
        variables.onSuccess()
      }
    }
  })

  const disconnectAtsIntegration = ({ onSuccess }: Args): void => {
    mutation.mutate({ onSuccess })
  }

  return { disconnectAtsIntegration }
}
