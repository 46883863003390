import { CreateCompanyForm } from 'src/components/blocks/create-company-form'
import * as Dialog from 'src/components/primitives/dialog'
import { useDialog } from 'src/hooks/use-dialog'
import { DialogId } from 'src/contexts/dialogs'

export const EditCompanyDialog = (): JSX.Element => {
  const { isDialogOpen, controlDialog, getPayload } = useDialog()
  const defaultName = getPayload(DialogId.EDIT_DEPARTMENT).name
  const defaultDomain = getPayload(DialogId.EDIT_DEPARTMENT).domain
  return (
    <Dialog.Root
      id={DialogId.EDIT_DEPARTMENT}
      isOpen={isDialogOpen(DialogId.EDIT_DEPARTMENT)}
      onOpenChange={(value) => {
        controlDialog(DialogId.EDIT_DEPARTMENT, value)
      }}
      $width="480px"
    >
      <Dialog.Portal>
        <Dialog.Header
          title="Edit Company"
          onClose={() => {
            controlDialog(DialogId.EDIT_DEPARTMENT, false)
          }}
        />
        <Dialog.Content>
          <CreateCompanyForm defaultName={defaultName} defaultDomain={defaultDomain} isUpdating={true} />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
