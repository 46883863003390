import styled from 'styled-components'

export const SequenceStatusCell = styled.div`
  button {
    margin-top: 0.25rem;
    display: inline-flex;
    transform: translateX(-0.375rem);
  }
`

export const CandidateStatusCell = styled.div`
  button {
    margin-top: 0.25rem;
    display: inline-flex;
    transform: translateX(-0.375rem);
  }
`

export const FavoriteHeader = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`
