import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { z } from 'zod'
import { queryKeys } from 'src/libs/query-keys'

interface Args {
  url: string | null | undefined
}

interface Response {
  success: boolean
  companyName: string
  companyUrl: string
  logoUrl: string
  alt: string
}

const fetchCompanyData = async ({ url }: Args): Promise<Response> => {
  if (!url) {
    throw new Error('Company url is required')
  }

  const response = await axios.get(
    `https://autocomplete.clearbit.com/v1/companies/suggest?query=${url}`
  )

  if (response.status !== 200) {
    throw new Error(`Clearbit API error - ${response.status} ${response.data}`)
  }

  const clearBitSchema = z
    .object({
      name: z.string(),
      domain: z.string(),
      logo: z.string()
    })
    .array()
  const items = clearBitSchema.parse(response.data)

  for (const { name, domain, logo } of items) {
    if (domain === url) {
      return {
        success: true,
        companyName: name,
        companyUrl: domain,
        logoUrl: logo,
        alt: `Logo of company ${domain}`
      }
    }
  }

  return {
    success: true,
    companyName: '',
    companyUrl: url,
    logoUrl: '',
    alt: ''
  }
}

export const useClearbit = ({ url }: Args) => {
  return useQuery({
    queryKey: [queryKeys.companyLogo, url],
    queryFn: () => fetchCompanyData({ url }),
    staleTime: 1 * 60 * 60 * 60 * 1000, // 1 day
    enabled: !!url,
    retry: false
  })
}
