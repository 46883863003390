import { useSettingsContext } from 'src/contexts/settings'

export const useSettings = () => {
  const { currentJobId, setCurrentJobId } = useSettingsContext()

  return {
    currentJobId,
    setCurrentJobId
  }
}
