import { isNil } from 'lodash'
import CONFIG from 'src/config'

export const ABLY_AUTH_PATH = `${CONFIG.API_DOMAIN}/ably/auth`

export enum CHANNEL_TYPE {
  NOTIFICATIONS = 'notifications',
  CANDIDATE_SEARCH = 'candidateSearch',
  CANDIDATE_DETAILS = 'candidateDetails',
}

export enum EVENT_TYPE {
  ORG_NOTIFICATION = 'orgNotification',
  MANUAL_OUTREACH = 'manualOutreach',
  PROSPECTING_ERROR = 'prospectingError',
  CANDIDATES_UPDATE = 'candidatesUpdate',
}

export const getChannelName = (type: CHANNEL_TYPE, orgId: string, id?: string): string => {
  return `${type}:${orgId}${isNil(id) ? '' : `:${id}`}`
}
