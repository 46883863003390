import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button } from 'src/components/primitives/button'
import { Editor } from 'src/components/blocks/editor'
import { Flex } from 'src/components/primitives/flex'
import { When } from '../when'
import { Icon } from 'src/components/primitives/icon'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { isEqual, isNil } from 'lodash'
import * as S from './email-composer.styled'
import { Badge } from 'src/components/primitives/badge'
import { useSession } from 'src/hooks/queries/use-session'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { Spacer } from 'src/components/primitives/spacer'
import { EmailRecipients } from '../email-recipients'
import { useOrgUsersQuery } from 'src/hooks/queries/use-org-users'
import { getEmailAccountAuthUrl } from 'src/libs/auth-urls'
import { TagInput } from 'src/components/primitives/tag-input'
import { invalidateEmailAccounts } from 'src/hooks/invalidate-email-accounts'
import { SenderSelection } from '../sender-selection'
import type { EmailAccount } from 'src/libs/api/backend/users'
import type { Editor as TiptapEditor } from '@tiptap/react'
import { AttachmentList, AttachmentUploadStatus } from '../attachments-list'
import type { AttachmentUpload } from '../attachments-list'
import { generateUploadUrlApi } from 'src/libs/api/backend/url'
import { UploadAttachments } from '../upload-attachments'
import axios from 'axios'

const MAXIMUM_ATTACHMENT_SIZE = 25 * 1024 * 1024 // 25MB

const escapeFileName = (fileName: string): string => {
  return fileName.replace(/[^a-z0-9.]/gi, '_').toLowerCase()
}

export interface EmailData {
  subject?: string | null
  subjectPlaceholder?: string | null
  body?: string | null
  sendingEmailAccountId?: string
  sendingUserId?: string
  recipients?: CandidateJobExpanded[] | null
  cc?: string[] | null
  bcc?: string[] | null
  attachmentUploads?: Array<{ s3Key: string, fileName: string }> | null
}

export interface EmailComposerProps extends EmailData {
  isGenerating?: boolean
  initialEmailBody?: string
  onDataChanged: (updated: EmailData) => void
  onEditorHeightChange?: (height: number) => void
  leftActions?: React.ReactNode
  rightActions?: React.ReactNode
  useVariables?: boolean
  useCcFields?: boolean
  onCancelGeneratingEmail?: () => void
  forceEditorFocus?: boolean
  minHeight?: string
  onClose?: () => void
  onSendableStateChange?: (isSendable: boolean) => void
  disableEmailAccountSelection?: boolean
  emailSuggestionFooter?: React.ReactNode | null
  useAttachments?: boolean
  editorFooterContent?: React.ReactNode | null
  $editorHeight?: string
  $maxHeight?: string
}

export interface EmailToolbarProps {
  children: React.ReactNode
}

export const EmailComposer = ({
  isGenerating = false,
  initialEmailBody,
  subject,
  subjectPlaceholder,
  body,
  sendingEmailAccountId,
  useVariables = false,
  useCcFields = true,
  sendingUserId,
  recipients,
  onDataChanged,
  onEditorHeightChange,
  forceEditorFocus,
  minHeight,
  // onCancelGeneratingEmail,
  leftActions,
  rightActions,
  onClose,
  onSendableStateChange,
  disableEmailAccountSelection = false,
  emailSuggestionFooter,
  useAttachments,
  editorFooterContent,
  $editorHeight,
  $maxHeight
}: EmailComposerProps): JSX.Element => {
  const { data: sessionData } = useSession()
  // ignore the first update since the editor will modify the content on mount
  const [initialized, setInitialized] = useState(false)
  const { data: orgUsers, refetch: refetchOrgUsers } = useOrgUsersQuery()
  const [titleFocused, setTitleFocused] = useState(true)
  const [_mainEditor, setMainEditor] = useState<TiptapEditor | null>(null)
  const [attachments, setAttachments] = useState<AttachmentUpload[]>([])
  const [attachmentError, setAttachmentError] = useState<string | null>(null)

  const subjectLineEl = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setInitialized(false)
  }, [initialEmailBody])

  useEffect(() => {
    if (!!subject || !!subjectPlaceholder) {
      setTitleFocused(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (titleFocused) {
      setTimeout(() => {
        if (subjectLineEl.current) {
          subjectLineEl.current.focus()
        }
      }, 100)
    }
  }, [titleFocused])

  const userEmailAccounts = useMemo(() => {
    return orgUsers?.flatMap((user) => {
      return user.emailAccounts.map((emailAccount) => ({
        ...emailAccount,
        profilePhotoUrl: user.profilePhotoUrl
      }))
    }) ?? []
  }, [orgUsers])

  const sessionUserEmailAccount = useMemo(() => {
    return userEmailAccounts.find((account) => account.userId === sessionData?.user.id)
  }, [sessionData, userEmailAccounts])

  const [currentData, setCurrentData] = useState<EmailData>({
    subject,
    body: body?.replace(/\n/g, '<br />'),
    sendingEmailAccountId: sendingEmailAccountId ?? sessionUserEmailAccount?.id,
    sendingUserId: sendingUserId ?? sessionUserEmailAccount?.userId
  })

  useEffect(() => {
    const currentAttachmentKeys = (currentData.attachmentUploads ?? []).map((attachment) => attachment.s3Key)
    if (attachments.some((attachment) => !currentAttachmentKeys.includes(attachment.s3Key))) {
      const updatedData = {
        ...currentData,
        attachmentUploads: attachments.map((attachment) => ({
          s3Key: attachment.s3Key,
          fileName: attachment.fileName
        }))
      }
      setCurrentData(updatedData)
      onDataChanged(updatedData)
    }
  }, [attachments, currentData, onDataChanged])

  const uploadAttachments = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    setAttachmentError('')
    const files = e?.target?.files
    if (!files) {
      return
    }
    const uploadedFiles = Array.from(files)
    const totalFilesSize = uploadedFiles.reduce((acc, file) => acc + file.size, 0) + attachments.reduce((acc, attachment) => acc + attachment.size, 0)
    if (totalFilesSize > MAXIMUM_ATTACHMENT_SIZE) {
      setAttachmentError('Attachments size exceeds 25MB')
      return
    }
    setAttachments([
      ...attachments,
      ...uploadedFiles.map((file: File) => ({
        fileName: escapeFileName(file.name),
        contentType: file.type,
        size: file.size,
        s3Key: '',
        id: escapeFileName(file.name),
        status: AttachmentUploadStatus.PENDING
      }))
    ])
    const uploadLinks = await Promise.all(
      uploadedFiles.map(async (file: File) => {
        const response = await generateUploadUrlApi(file.name)

        setAttachments((prevAttachments) => {
          return prevAttachments.map((attachment) => {
            if (attachment.fileName === escapeFileName(file.name)) {
              return {
                ...attachment,
                s3Key: response.key,
                status: AttachmentUploadStatus.UPLOADING
              }
            }
            return attachment
          })
        })
        return { ...response, file }
      })
    )
    console.log('upload links: ', uploadLinks)

    await Promise.all(
      uploadLinks.map(async ({ uploadSignedUrl, key, file }) => {
        try {
          await axios.put(uploadSignedUrl, file, {
            headers: {
              'Content-Type': file.type
            },
            onUploadProgress: (progressEvent) => {
              setAttachments((prevAttachments) => {
                return prevAttachments.map((attachment) => {
                  if (attachment.s3Key === key) {
                    const progress = Math.round((progressEvent.loaded * 100) / (progressEvent.total ?? file.size))
                    return {
                      ...attachment,
                      status: AttachmentUploadStatus.UPLOADING,
                      progress
                    }
                  }
                  return attachment
                })
              })
            }
          }).then(() => {
            setAttachments((prevAttachments) => {
              return prevAttachments.map((attachment) => {
                if (attachment.s3Key === key) {
                  return {
                    ...attachment,
                    status: AttachmentUploadStatus.UPLOADED
                  }
                }
                return attachment
              })
            })
          })
        } catch (error) {
          setAttachments((prevAttachments) => {
            return prevAttachments.map((attachment) => {
              if (attachment.s3Key === key) {
                return {
                  ...attachment,
                  status: AttachmentUploadStatus.ERROR
                }
              }
              return attachment
            })
          })
        }
      })
    )
  }, [attachments])

  const removeAttachment = useCallback((s3Key: string) => {
    setAttachmentError('')
    setAttachments((prevAttachments) => {
      return prevAttachments.filter((attachment) => attachment.s3Key !== s3Key)
    })
  }, [])

  useEffect(() => {
    if (isNil(currentData.sendingEmailAccountId) && sessionUserEmailAccount?.id) {
      setCurrentData({
        ...currentData,
        sendingEmailAccountId: sessionUserEmailAccount.id
      })
    }
    if (isNil(currentData.sendingUserId) && sessionUserEmailAccount?.userId) {
      setCurrentData({
        ...currentData,
        sendingUserId: sessionUserEmailAccount.userId
      })
    }
  }, [currentData, sessionUserEmailAccount?.id, sessionUserEmailAccount?.userId])

  const [showCC, setShowCC] = useState(false)
  const [showBCC, setShowBCC] = useState(false)

  const selectedEmailAccount = useMemo(() => {
    if (isNil(sessionData)) {
      return undefined
    }
    return userEmailAccounts.find((account) => account.id === currentData.sendingEmailAccountId)
  }, [sessionData, userEmailAccounts, currentData.sendingEmailAccountId])

  onSendableStateChange?.((selectedEmailAccount?.hasAccessToken && selectedEmailAccount?.currentUserHasSendAsPermission) ?? false)

  useEffect(() => {
    if (!isEqual(currentData.subject, subject)) {
      const updated = {
        ...currentData,
        subject
      }
      setCurrentData(updated)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject])

  const handleSubjectChange = useCallback(
    (data: string): void => {
      const updatedEmailData = {
        ...currentData,
        subject: data
      }
      setCurrentData(updatedEmailData)
      onDataChanged(updatedEmailData)
    },
    [currentData, onDataChanged]
  )

  const handleBodyChange = useCallback(
    (data: string): void => {
      if (!initialized) {
        setInitialized(true)
        return
      }
      const updatedContent = {
        ...currentData,
        body: data
      }
      setCurrentData(updatedContent)
      onDataChanged(updatedContent)
    },
    [currentData, initialized, onDataChanged]
  )

  const handleSenderChange = useCallback(
    (sender: EmailAccount): void => {
      const updatedContent = {
        ...currentData,
        sendingEmailAccountId: sender.id,
        sendingUserId: sender.userId
      }
      setCurrentData(updatedContent)
      onDataChanged(updatedContent)
      onSendableStateChange?.(sender.hasAccessToken && sender.currentUserHasSendAsPermission)
    },
    [currentData, onDataChanged, onSendableStateChange]
  )

  const handleCCChange = useCallback(
    (cc: string[]): void => {
      const updatedContent = {
        ...currentData,
        cc
      }
      setCurrentData(updatedContent)
      onDataChanged(updatedContent)
    },
    [currentData, onDataChanged]
  )

  const handleBCCChange = useCallback(
    (bcc: string[]): void => {
      const updatedContent = {
        ...currentData,
        bcc
      }
      setCurrentData(updatedContent)
      onDataChanged(updatedContent)
    },
    [currentData, onDataChanged]
  )

  const reconnect = useCallback((): void => {
    if (!isNil(selectedEmailAccount)) {
      const redirectUrl = `${window.location.origin}/login/redirect/close`
      const authUrl = getEmailAccountAuthUrl(selectedEmailAccount, redirectUrl)
      const loginWindow = window.open(authUrl, '_blank', 'popup=1,height=600,width=600')
      const timer = setInterval(() => {
        if (loginWindow?.closed) {
          void invalidateEmailAccounts()
          void refetchOrgUsers()
          clearInterval(timer)
        }
      }, 500)
    }
  }, [refetchOrgUsers, selectedEmailAccount])

  return (
    <S.EmailComposer>
      <S.EmailFields>
        <S.Addressbar>
          <S.AddressRow>
            <Paragraph size="XS" $color="fgTertiary">
              From
            </Paragraph>
            <Flex $align="center" $justify="space-between">
              <SenderSelection
                handleSenderChange={handleSenderChange}
                selectedEmailAccount={selectedEmailAccount}
                userEmailAccounts={userEmailAccounts}
                disableEmailAccountSelection={disableEmailAccountSelection}
              />
              <Flex $align="center" $justify="flex-end" $gap={8}>
                <When condition={!selectedEmailAccount?.hasAccessToken}>
                  <Badge
                    $variant="negativeLight"
                    leadingIcon="alert-triangle"
                    $transform="none"
                    $fontSize={12}
                    $height={24}
                    $padding="casual"
                  >
                    Email account not connected
                  </Badge>
                  <Button
                    $height={24}
                    $variant="fill"
                    $colorTheme="tint"
                    leadingIcon="refresh-cw"
                    $fontSize={12}
                    ariaLabel='Reconnect email account'
                    onClick={reconnect}
                    disabled={selectedEmailAccount?.userId !== sessionData?.user.id}
                    tooltip={{
                      text: selectedEmailAccount?.userId === sessionData?.user.id ? undefined : 'Have your teammate reconnect this email account or switch to a different sender',
                      position: 'top'
                    }}
                  >
                    Reconnect
                  </Button>
                </When>
                <When condition={!isNil(onClose)}>
                  <Button
                    ariaLabel="Close"
                    $variant="ghost"
                    leadingIcon="x"
                    $height={16}
                    $width={16}
                    $fontSize={12}
                    onClick={() => {
                      if (onClose) {
                        onClose()
                      }
                    }}
                  />
                </When>
              </Flex>
            </Flex>
          </S.AddressRow>
          {recipients && (
            <>
              <Spacer $size={2} />
              <S.AddressRow>
                <Paragraph size="XS" $color="fgTertiary">
                  To
                </Paragraph>
                <Flex>
                  <Flex $flex="1" $align="center">
                    <EmailRecipients candidateJobs={recipients} />
                  </Flex>
                  {useCcFields && !showCC &&
                    <Button
                      $variant="ghost"
                      $colorTheme="muted"
                      $align="center"
                      $height={20}
                      $fontSize={12}
                      onClick={() => {
                        setShowCC(!showCC)
                      }}
                    >
                      Cc
                    </Button>
                  }
                  {useCcFields && !showBCC &&
                    <Button
                      $variant="ghost"
                      $colorTheme="muted"
                      $align="center"
                      $height={20}
                      $fontSize={12}
                      onClick={() => {
                        setShowBCC(!showBCC)
                      }}
                    >
                      Bcc
                    </Button>
                  }
                </Flex>
              </S.AddressRow>
            </>
          )}
          {showCC && (
            <>
              <Spacer $size={2} />
              <S.AddressRow>
                <Paragraph size="XS" $color="fgTertiary">
                  Cc
                </Paragraph>
                <TagInput onTagsChange={handleCCChange} />
              </S.AddressRow>
            </>
          )}
          {showBCC && (
            <>
              <Spacer $size={2} />
              <S.AddressRow>
                <Paragraph size="XS" $color="fgTertiary">
                  Bcc
                </Paragraph>
                <TagInput onTagsChange={handleBCCChange} />
              </S.AddressRow>
            </>
          )}
        </S.Addressbar>
        <S.Subjectline>
          <input
            ref={subjectLineEl}
            name="subject"
            type="text"
            disabled={!isNil(subjectPlaceholder)}
            value={currentData.subject ?? ''}
            placeholder={subjectPlaceholder ?? 'Your subject line...'}
            autoComplete="off"
            onChange={(event) => {
              handleSubjectChange(event.target.value)
            }}
            autoFocus={titleFocused}
          />
        </S.Subjectline>
        <Editor
          isEditable={!isGenerating}
          initialContent={initialEmailBody}
          customActions={
            <Flex $direction='column' $gap={8}>
              {emailSuggestionFooter}
              {useAttachments && (
                <AttachmentList
                  attachments={attachments}
                  onDelete={removeAttachment}
                  error={attachmentError}
                />
              )}
            </Flex>
          }
          setEditor={setMainEditor}
          content={isGenerating ? '' : body ?? null}
          placeholder="Compose a email message..."
          onDataChanged={(data) => {
            handleBodyChange(data)
          }}
          forceEditorFocus={titleFocused ? false : forceEditorFocus}
          onEditorHeightChange={onEditorHeightChange}
          $minHeight={minHeight}
          $maxHeight={$maxHeight}
          useVariables={!isGenerating && useVariables}
          $editorHeight={$editorHeight}
          customToolbar={
            isGenerating
              ? <S.Toolbar>
                  <S.Generating>
                    <Flex $gap={12} $align="center">
                      <Icon name="sparkles-sm" color="tintBg" size={12} />
                      <Caption size="XS" $hasGradient>
                        Generating email&hellip;
                      </Caption>
                    </Flex>
                    {/*
                    <Button
                      ariaLabel="Stop generating email body"
                      leadingIcon="stop-circle"
                      $variant="ghost"
                      $colorTheme="muted"
                      $height={24}
                      $width={24}
                      $fontSize={12}
                      onClick={onCancelGeneratingEmail}
                    />
                    */}
                  </S.Generating>
                </S.Toolbar>
              : null
          }
          toolbarActions={
            <When condition={!isGenerating}>
              <Flex $align='center' $gap={16} $width='fit-content'>
                {leftActions}
                {useAttachments && (
                  <UploadAttachments
                    handleUpload={uploadAttachments}
                    multiple={true}
                  />
                )}
              </Flex>
              {rightActions}
            </When>
          }
          editorFooterContent={editorFooterContent}
        />
      </S.EmailFields>
    </S.EmailComposer>
  )
}
