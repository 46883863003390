// import { useCompanyLogoQuery } from 'src/hooks/queries/use-company-logo'
import * as S from './company-logo.styled'
import { useAvatarQuery } from 'src/hooks/queries/use-avatar'
import { Icon, Icons } from '../icon'
import type { IconName } from '../icon'
import { isNil } from 'lodash'
import { useCompanyLogoQuery } from 'src/hooks/queries/use-company-logo'
import { useState } from 'react'

export interface CompanyLogoProps extends S.CompanyLogoStyleProps {
  name?: string
  url?: string | null
  logoUrl?: string | null
  externalLogoUrl?: string | null
  fallbackIcon?: IconName
}

export const CompanyLogo = ({
  name,
  url,
  logoUrl,
  externalLogoUrl,
  fallbackIcon = Icons.building2,
  $shape
}: CompanyLogoProps): JSX.Element => {
  const [isClearbitLogoError, setIsClearbitLogoError] = useState(false)
  const {
    isLoading,
    data
  } = useAvatarQuery({ url: logoUrl })
  const {
    // isPending: isCompanyLogoPending,
    data: companyLogoData
  } = useCompanyLogoQuery(!isLoading && isNil(data) ? { name, url } : {})
  // const {
  //   isPending: isCompanyLogoAvatarPending,
  //   isError: isCompanyLogoAvatarError,
  //   data: companyLogoAvatarData
  // } = useAvatarQuery({ url: companyLogoData?.logoUrl })

  if (!isNil(externalLogoUrl)) {
    return (
      <S.CompanyLogo $shape={$shape}>
        <S.Image src={externalLogoUrl} alt={name} />
      </S.CompanyLogo>
    )
  }

  if (!isNil(data)) {
    return (
      <S.CompanyLogo $shape={$shape}>
        <S.Image src={data} alt={name} />
      </S.CompanyLogo>
    )
  }

  if (!isClearbitLogoError && !isNil(companyLogoData)) {
    return (
      <S.CompanyLogo $shape={$shape}>
        <S.Image src={companyLogoData.logoUrl} alt={name} onError={() => { setIsClearbitLogoError(true) }} />
      </S.CompanyLogo>
    )
  }

  return (
    <S.CompanyLogo $shape={$shape}>
      <S.Icon>
        <Icon name={fallbackIcon} color="fgTertiary" />
      </S.Icon>
    </S.CompanyLogo>
  )
}
