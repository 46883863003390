import { Caption, Paragraph } from 'src/components/primitives/typography'
import * as S from './timeline-entry-subtitle.styled'
import { When } from '../when'

interface Detail {
  title?: string | null
  timespan?: string | null
  description?: string | null
}

interface TimelineEntrySubtitleProps {
  details: Detail[]
  isCollapsed?: boolean
}

interface ListItemHeadingProps {
  title?: string | null
  timespan?: string | null
}

const ListItemHeading = ({ title, timespan }: ListItemHeadingProps): JSX.Element => {
  return (
    <S.ListItemHeading data-name="ListItemHeading">
      {
        (title?.length ?? 0) >= 1 && (
          <Caption
            as="span"
            size="XS"
            $color="fgPrimary"
            $lineHeight={1.33}
            $fontWeight={400}
            $whiteSpace="nowrap"
            $ellipsis={true}
          >
            {title}
          </Caption>
        )
      }
      <When condition={Boolean(timespan)}>
        <Paragraph as="span" size="XS" $color="fgTertiary" $whiteSpace="nowrap">
          {timespan}
        </Paragraph>
      </When>
    </S.ListItemHeading>
  )
}

export const TimelineEntrySubtitle = ({
  details,
  isCollapsed
}: TimelineEntrySubtitleProps): JSX.Element => {
  return (
    <S.TimelineEntrySubtitle data-name="TimelineEntrySubtitle">
      <ListItemHeading title={details?.[0]?.title} timespan={details?.[0]?.timespan} />
      <S.DetailsList data-name="DetailsList">
        {details?.map((detail, index) => (
          <S.DetailsListItem key={`${detail.title}-${index}`} $isCollapsed={isCollapsed}>
            {index >= 1 && <ListItemHeading title={detail.title} timespan={detail.timespan} />}
            {detail.description && detail.description.length >= 1 && !isCollapsed && (
              <S.ListItemDescription>
                <Paragraph
                  size="XS"
                  $color="fgSecondary"
                  $lineHeight={1.33}
                  $whiteSpace="pre-wrap"
                >
                  {detail.description}
                </Paragraph>
              </S.ListItemDescription>
            )}
          </S.DetailsListItem>
        ))}
      </S.DetailsList>
    </S.TimelineEntrySubtitle>
  )
}
