import { NotificationType } from 'src/libs/api/backend/notifications'
import type { UserNotification } from 'src/libs/api/backend/notifications'
import * as S from './notification-tile.styled'
import { Avatar } from 'src/components/primitives/avatar'
import { Button, Flex } from 'src/components/primitives'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { formatDistanceToNowStrict } from 'date-fns'
import locale from 'date-fns/locale/en-US'
import { formatShorthandDistance } from 'src/libs/time'
import { Dropdown } from 'src/components/primitives/dropdown'
import { useCallback } from 'react'
import { InviteeRole, putOrgJoinRequest } from 'src/libs/api/backend/orgs'
import type { OrgJoinRequest } from 'src/libs/api/backend/orgs'
import { useNotifyErrorSuccess } from 'src/hooks/use-notification'
import { useQueryClient } from '@tanstack/react-query'
import { queryKeys } from 'src/libs/query-keys'
import { Icon, Icons } from 'src/components/primitives/icon'
import RouteBuilder from 'src/libs/route-builder'

interface NotificationTileProps {
  notification: UserNotification
}

const dropdownOptions = [
  { title: 'Admin', value: InviteeRole.ADMIN },
  { title: 'Viewer', value: InviteeRole.VIEWER }
]

const JoinRequestActions = ({ orgJoinRequest }: { orgJoinRequest: OrgJoinRequest }): JSX.Element => {
  const queryClient = useQueryClient()
  const { notifyError, notifySuccess } = useNotifyErrorSuccess()

  const refetchNotifications = useCallback(() => {
    void queryClient.invalidateQueries({
      queryKey: [queryKeys.notifications]
    })
    void queryClient.invalidateQueries({
      queryKey: [queryKeys.notificationsCount]
    })
    void queryClient.invalidateQueries({
      queryKey: [queryKeys.orgUsers]
    })
    void queryClient.invalidateQueries({
      queryKey: [queryKeys.orgJoinRequests]
    })
  }, [queryClient])

  const onAccept = useCallback((joinRequest: OrgJoinRequest): void => {
    putOrgJoinRequest(joinRequest.id, { status: 'ACCEPTED' }).then(() => {
      notifySuccess('Join request accepted')
    }).catch((error) => {
      console.error('Error accepting join request', error)
      notifyError('Error accepting join request')
    }).finally(() => {
      refetchNotifications()
    })
  }, [notifyError, notifySuccess, refetchNotifications])

  const onReject = useCallback((joinRequest: OrgJoinRequest): void => {
    console.log('Rejecting join request', joinRequest)
    putOrgJoinRequest(joinRequest.id, { status: 'REJECTED' }).then(() => {
      notifySuccess('Join request rejected')
    }).catch((error) => {
      console.error('Error rejecting join request', error)
      notifyError('Error rejecting join request')
    }).finally(() => {
      refetchNotifications()
    })
  }, [notifyError, notifySuccess, refetchNotifications])

  return (
    <S.NotificationTileActions>
      <Dropdown
        trigger={
          <Button
            $variant="raised"
            $colorTheme="tint"
            $height={24}
            $fontSize={12}
            nested
            trailingIcon={Icons.chevronDown}
          >
            Approve
          </Button>
        }
        items={dropdownOptions.map((option) => ({
          title: option.title,
          value: option.value,
          onSelect: () => { onAccept(orgJoinRequest) }
        }))}
        size="small"
      />
      <Button
        $variant='raised'
        $colorTheme='negative'
        $height={24}
        $fontSize={12}
        onClick={() => { onReject(orgJoinRequest) }}
      >
        Reject
      </Button>
    </S.NotificationTileActions>
  )
}

const TileActions = ({ notification }: NotificationTileProps): JSX.Element | null => {
  switch (notification.type) {
    case NotificationType.EMAIL_ADDED_TO_SEQUENCE:
      if (!notification.sequence) return null
      return (
        <S.NotificationTileActions>
          <S.AddedToSequenceAction href={RouteBuilder.build('SETTINGS_JOB_EMAIL_SEQUENCE', { jobId: notification.sequence.jobId })}>
            <Avatar
              $size={16}
              $shape='soft'
              initials={notification.sequence.job.department?.name}
              photoUrl={null} // TODO Fill in department icon
            />
            <Caption size='XS'>{notification.sequence.job.title}</Caption>
            <Icon name={Icons.chevronRight} size={12} />
          </S.AddedToSequenceAction>
        </S.NotificationTileActions>
      )
    case NotificationType.USER_JOIN_REQUEST:
      if (!notification.orgJoinRequest) return null
      return (
        <JoinRequestActions orgJoinRequest={notification.orgJoinRequest} />
      )
    default:
      return null
  }
}

export const NotificationTile = ({ notification }: NotificationTileProps): JSX.Element => {
  const user = notification.type === NotificationType.USER_JOIN_REQUEST ? notification.orgJoinRequest?.user : notification.sendingUser
  return (
    <S.NotificationTile $isUnread={notification.notificationViews.length === 0}>
      <S.NotificationTileHeader>
        <S.NotificationTileTitle>
          <Avatar
            photoUrl={user?.profilePhoto}
            initials={user?.name}
            $size={32}
            $shape='circle'
            $type='photo'
            fallbackAvatar='random'
          />
          <Flex $direction='column' $flex='1 1 auto' $gap={2}>
            <Flex $gap={12} $align="center">
              <Caption size='SM'>{user?.name}</Caption>
              <Paragraph size='XS' $color='fgTertiary'>{notification.orgJoinRequest?.user.emailAccounts[0].email}</Paragraph>
            </Flex>
            <Paragraph size='XS' $color='fgPrimary'>{notification.message}</Paragraph>
          </Flex>
        </S.NotificationTileTitle>
        <Caption size='XS' $color='fgTertiary'>
          {formatDistanceToNowStrict(new Date(notification.createdAt), {
            addSuffix: false,
            locale: {
              ...locale,
              formatDistance: formatShorthandDistance
            }
          })}
        </Caption>
      </S.NotificationTileHeader>
      <TileActions notification={notification} />
    </S.NotificationTile>
  )
}
