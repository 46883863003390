import styled, { css } from 'styled-components'

export const TimelineActivity = styled.div<{ $newActivityOpen: boolean }>`
  position: relative;
  display: grid;
  grid-template-columns: 1rem 1fr 6rem;
  gap: 1rem;
  width: 100%;
  &:not(:last-child) {
    &:after {
      content: '';
      position: absolute;
      top: calc(1rem + 0.375rem);
      left: 0.5rem;
      width: 1px;
      height: 100%;
      background-color: ${({ theme }) => theme.colors.borderTranslucent};
    }
  }
  ${({ theme, $newActivityOpen }) => $newActivityOpen && `
    &:last-child {
      &:after {
        content: '';
        position: absolute;
        top: calc(1rem + 0.375rem);
        left: 0.5rem;
        height: 100%;
        border-left: 1px dashed ${theme.colors.borderTranslucent};
      }
    }
  `}
`

export const Content = styled.div<{ $columns: string }>`
  grid-column: ${({ $columns }) => $columns};
`

export const Indicator = styled.div<{ $offset: number }>`
  position: relative;
  width: 1rem;
  min-height: 1rem;
  display: flex;
  flex-direction: column;
  transform: ${({ $offset }) => `translateY(${$offset}px)`};
  z-index: 2;
`

const indicatorStyles = css`
  position: relative;
  width: 1.2rem;
  height: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.bgSecondary};
  padding: 0.4rem;
  transform: translateX(-0.1rem);
  z-index: 3;
`

export const IndicatorPrimary = styled.div`
  ${indicatorStyles}
  padding: 0;
  height: 16px;
`

export const IndicatorSecondary = styled.div`
  ${indicatorStyles}
  height: 1.4rem;
`

export const Timestamp = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 0.75rem;
`
