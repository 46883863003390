import { useCallback, useEffect, useLayoutEffect, useRef } from 'react'
import { EditorContent, useEditor } from '@tiptap/react'
import type { Editor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import * as S from './search-refinement-editor.styled'
import Paragraph from '@tiptap/extension-paragraph'
// import RefinementComponentExtension from './extensions/refinement-component-extension'

export interface EditorProps {
  isFocused: boolean
  isEditable?: boolean
  initialContent?: string
  placeholder?: string
  onDataChanged: (data: string) => void
  onEditorHeightChange?: (height: number) => void
  forceEditorFocus?: boolean
  onSubmit: (value: string) => void
  setEditor: (editor: Editor | null) => void
}

export const SearchRefinementEditor = ({
  isFocused,
  isEditable = true,
  initialContent,
  placeholder,
  onDataChanged,
  onEditorHeightChange,
  forceEditorFocus = false,
  onSubmit,
  setEditor
}: EditorProps): JSX.Element => {
  const editor = useEditor({
    content: initialContent,
    onUpdate: ({ editor }) => {
      // Always Text for this feature
      onDataChanged(editor.getText())
    },
    extensions: [
      StarterKit.configure({
        paragraph: false
      }),
      Paragraph.configure({
        HTMLAttributes: {
          class: 'editor-paragraph'
        }
      }),
      Placeholder.configure({
        placeholder
      })
      // RefinementComponentExtension
    ]
  }, [initialContent, placeholder])

  useEffect(() => {
    setEditor(editor)
    return () => {
      setEditor(null)
      editor?.destroy()
    }
  }, [editor, setEditor])

  const editorRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (onEditorHeightChange && editorRef.current) {
      onEditorHeightChange(editorRef.current.offsetHeight)
    }
  })

  useEffect(() => {
    editor?.setEditable(isEditable)
  }, [isEditable, editor])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.metaKey && event.key === 'Enter' && editor) {
        event.preventDefault()
        onSubmit(editor.getText())
      }
    }

    const textarea = editorRef.current
    if (textarea) {
      textarea.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      if (textarea) {
        textarea.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [editor, onSubmit])

  useEffect(() => {
    if (!editor) {
      return
    }

    if (initialContent && initialContent?.length >= 1) {
      editor.commands.setContent(initialContent)
    }
  }, [editor, initialContent])

  const handleSetFocus = useCallback(() => {
    if (!editor) {
      return
    }
    editor.commands.focus()
  }, [editor])

  useEffect(() => {
    if (forceEditorFocus && editor) {
      editor.chain().focus('end').run()
    }
  }, [forceEditorFocus, editor])

  return (
    <S.Editor ref={editorRef} onClick={handleSetFocus} $isFocused={isFocused}>
      <EditorContent editor={editor} />
    </S.Editor>
  )
}
