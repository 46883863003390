import { useMutation } from '@tanstack/react-query'
import { useNotification } from '../use-notification'
import { addCompanyPreference } from 'src/libs/api/backend/company_preferences'
import type { AddCompanyPreference } from 'src/libs/api/backend/company_preferences'
import queryClient from '../query-client'
import { queryKeys } from 'src/libs/query-keys'
import { Icons } from 'src/components/primitives/icon'

interface Res {
  addCompany: (company: AddCompanyPreference) => void
}

export const useAddCompanyPreference = (): Res => {
  const { notify } = useNotification()

  const mutation = useMutation({
    mutationFn: async (company: AddCompanyPreference) => {
      await addCompanyPreference(company)
    },
    onError: (err) => {
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-right',
        icon: Icons.xOctagon,
        message: `An error occurred when adding this company: ${err.message}`
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.companyPreferences]
      })
    }
  })

  const addCompany = (company: AddCompanyPreference): void => {
    mutation.mutate(company)
  }

  return { addCompany }
}
