import { Icon } from 'src/components/primitives/icon'
import type { IconName } from 'src/components/primitives/icon'
import { When } from '../when'
// import type { User } from 'src/libs/api/backend/users'
import { Flex } from 'src/components/primitives/flex'
import { Avatar } from 'src/components/primitives/avatar'
import { isNil } from 'lodash'
import { CandidateJobRejectionReason, CandidateJobStage, getCandidateJobSourceDisplay, getCandidateRejectionReasonDisplay } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateExpanded } from 'src/libs/api/backend/candidate_jobs'
import { Comment, OutboundEmail, Description, ErrorDescription, ManualEmail, CalendarEntry, PositiveDescription } from './elements'
import * as S from './timeline-activity.styled'
import { CandidateActivityType, EmailMessageType } from 'src/libs/api/backend/candidate_activities'
import type { CandidateActivity } from 'src/libs/api/backend/candidate_activities'
import { formatDate } from 'src/utils/format-date'
import { useOrgUsersQuery } from 'src/hooks/queries/use-org-users'
import type { OrgUser } from 'src/libs/api/backend/users'
import type { Color } from 'src/styles/theme/types'
import { Tooltip } from 'src/components/primitives/tooltip'
import { useSession } from 'src/hooks/use-session'

export interface TimelineActivityProps {
  activity: CandidateActivity
  candidate: CandidateExpanded
  onReply?: (emailMessageId: string) => void
  newActivityOpen?: boolean
  onCommentUpdate: (noteId: string, body: string) => void
}

interface ActivityIcon {
  name: IconName
  color: Color
}

function getIconForActivity (activity: CandidateActivity): ActivityIcon | undefined {
  switch (activity.type) {
    case CandidateActivityType.CANDIDATE_JOB_CREATED:
      return { name: 'binoculars', color: 'fgSecondary' }
    case CandidateActivityType.EMAIL_BOUNCED:
      return { name: 'alert-triangle', color: 'negativeFg' }
    case CandidateActivityType.CANDIDATE_JOB_STAGE_TRANSITION:
      switch (activity.candidateJobStageTransition?.toStage) {
        case CandidateJobStage.COMMUNICATING:
          return { name: 'mail', color: 'fgSecondary' }
        case CandidateJobStage.REJECTED:
          return { name: 'x-circle', color: 'negativeFg' }
      }
      break
    case CandidateActivityType.CALENDAR_EVENT:
      return { name: 'calendar', color: 'fgSecondary' }
  }
}

interface ActivityAvatar {
  initials?: string
  photoUrl?: string | null
}

function getAvatarForActivity (activity: CandidateActivity, candidate: CandidateExpanded, orgUsers: OrgUser[]): ActivityAvatar | undefined {
  const candidateAvatar = { initials: candidate.name, photoUrl: candidate.profilePhotoUrl }
  const orgUser = orgUsers.find((orgUser) => orgUser.id === activity.userId)
  const orgUserAvatar = { initials: orgUser?.name ?? 'Unknown', photoUrl: orgUser?.profilePhotoUrl }

  switch (activity.type) {
    case CandidateActivityType.EMAIL:
      switch (activity.emailMessage?.type) {
        case EmailMessageType.SENT:
          return orgUserAvatar
        case EmailMessageType.RECEIVED:
          return candidateAvatar
      }
      break
    case CandidateActivityType.PRIOR_CONTACT:
    case CandidateActivityType.MANUAL_EMAIL:
    case CandidateActivityType.NOTE:
    case CandidateActivityType.CANDIDATE_JOB_STAGE_TRANSITION:
    case CandidateActivityType.CANDIDATE_JOB_CREATED:
      if (!isNil(activity.userId)) {
        return orgUserAvatar
      }
      break
  }
}

function getCandidateJobSource (activity: CandidateActivity, orgUsers: OrgUser[]): string {
  const source = activity.candidateJob?.source
  if (isNil(source) && !isNil(activity.userId)) {
    const orgUser = orgUsers.find((orgUser) => orgUser.id === activity.userId)
    return `manually added by ${orgUser?.name}` ?? 'manually added'
  }
  return `sourced from ${getCandidateJobSourceDisplay(source ?? undefined)}`
}

function getIndicator (isDescription: boolean, avatar: ActivityAvatar | undefined, icon: ActivityIcon | undefined): JSX.Element | undefined {
  if (isDescription && !isNil(icon)) {
    return <Icon {...icon} size={12} />
  }
  if (!isNil(avatar)) {
    return <Avatar $type="photo" key={avatar.photoUrl ?? avatar.initials} $size={16} $shape="soft" {...avatar} />
  }
  if (!isNil(icon)) {
    return <Icon {...icon} size={12} />
  }

  // TODO: maybe return default icon?
}

function getSecondaryIndicator (activity: CandidateActivity): { icon: IconName, tooltip: string } | null {
  if (activity.type === CandidateActivityType.EMAIL && !isNil(activity.emailMessage)) {
    const opened = activity.emailMessage.manualEmailMessage?.opened ?? activity.emailMessage.candidateSequenceStep?.opened
    const openedAt = activity.emailMessage.manualEmailMessage?.openedAt ?? activity.emailMessage.candidateSequenceStep?.openedAt
    if (opened) {
      return {
        icon: 'view',
        tooltip: !isNil(openedAt) ? `Email opened ${formatDate(openedAt, { format: 'TimePassed' })}` : 'Email Opened'
      }
    }
  }

  return null
}

function getIndicatorOffset (activity: CandidateActivity): number {
  const SINGLE_LINE_ACTIVITIES = [
    CandidateActivityType.CANDIDATE_JOB_STAGE_TRANSITION,
    CandidateActivityType.CANDIDATE_JOB_CREATED,
    CandidateActivityType.NOTE,
    CandidateActivityType.PRIOR_CONTACT
  ]
  if (SINGLE_LINE_ACTIVITIES.some(act => act === activity.type)) {
    return 0
  }

  return 6
}

export const TimelineActivity = ({
  // type = 'entry',
  // description,
  // icon = 'binoculars',
  // user,
  // createdAt
  activity,
  candidate,
  onReply,
  newActivityOpen = false,
  onCommentUpdate
}: TimelineActivityProps): JSX.Element => {
  const { data: orgUsers } = useOrgUsersQuery()
  const { user } = useSession()
  const icon = getIconForActivity(activity)
  const avatar = getAvatarForActivity(activity, candidate, orgUsers ?? [])
  const indicatorOffset = getIndicatorOffset(activity)
  const isDescription =
    activity.type === CandidateActivityType.CANDIDATE_JOB_CREATED ||
    activity.type === CandidateActivityType.CANDIDATE_JOB_STAGE_TRANSITION ||
    activity.type === CandidateActivityType.EMAIL_BOUNCED ||
    activity.type === CandidateActivityType.PRIOR_CONTACT
  const CONTENT_WIDTH = !isDescription ? '2 / span 2' : 'auto'

  const secondaryIndicator = getSecondaryIndicator(activity)

  return (
    <S.TimelineActivity $newActivityOpen={newActivityOpen}>
      <S.Indicator $offset={indicatorOffset}>
        <S.IndicatorPrimary>
          {getIndicator(isDescription, avatar, icon)}
        </S.IndicatorPrimary>
        {
          !isNil(secondaryIndicator) && getSecondaryIndicator(activity)?.icon && (
            <S.IndicatorSecondary>
              <Tooltip
                trigger={
                  <div>
                    <Icon name={secondaryIndicator.icon} color="fgTertiary" size={12} />
                  </div>
                }
              >
                {secondaryIndicator.tooltip}
              </Tooltip>
            </S.IndicatorSecondary>
          )
        }
      </S.Indicator>
      <S.Content $columns={CONTENT_WIDTH}>
        <When condition={activity.type === CandidateActivityType.PRIOR_CONTACT}>
          <Description>{avatar?.initials} communicating with candidate</Description>
        </When>
        <When condition={activity.type === CandidateActivityType.CANDIDATE_JOB_CREATED}>
          <Description>Candidate {getCandidateJobSource(activity, orgUsers ?? [])} for {activity.job?.title}</Description>
        </When>
        <When condition={activity.type === CandidateActivityType.CANDIDATE_JOB_STAGE_TRANSITION}>
          <When condition={activity.candidateJobStageTransition?.toStage === CandidateJobStage.PROSPECTING}>
            <Description>{avatar?.initials} added candidate to outreach sequence</Description>
          </When>
          <When condition={activity.candidateJobStageTransition?.toStage === CandidateJobStage.COMMUNICATING}>
            <Description>Candidate responded to outreach sequence</Description>
          </When>
          <When condition={activity.candidateJobStageTransition?.toStage === CandidateJobStage.REJECTED}>
            {activity.candidateJobStageTransition?.rejectionReason === CandidateJobRejectionReason.AUTO_UNRESPONSIVE
              ? <Description>
                  Candidate did not respond to sequence and was automatically archived
                </Description>
              : <Description>
                  {avatar?.initials} rejected the candidate: {getCandidateRejectionReasonDisplay(activity.candidateJobStageTransition?.rejectionReason)}
                </Description>
            }
          </When>
          <When condition={activity.candidateJobStageTransition?.toStage === CandidateJobStage.HIRED}>
            <PositiveDescription>
              {avatar?.initials} marked the candidate as hired
            </PositiveDescription>
          </When>
        </When>
        <When condition={activity.type === CandidateActivityType.EMAIL_BOUNCED}>
          <ErrorDescription>Email to {activity.emailMessage?.to} could not be delivered</ErrorDescription>
        </When>
        <When condition={activity.type === CandidateActivityType.EMAIL}>
          {!isNil(activity.emailMessage) && <OutboundEmail emailMessage={activity.emailMessage} attachments={activity.emailMessage.attachments} onReply={onReply} />}
        </When>
        <When condition={activity.type === CandidateActivityType.MANUAL_EMAIL}>
          {!isNil(activity.manualEmailMessage) &&
            <ManualEmail
              manualEmailMessage={activity.manualEmailMessage}
            />
          }
        </When>
        <When condition={activity.type === CandidateActivityType.NOTE}>
          <Flex $direction="row" $gap={8} $width="100%" $justify="space-between">
            <Description>{avatar?.initials} commented</Description>
            <S.Timestamp>
              <Description>
                {formatDate(activity.date ?? '', {
                  format: 'DaysPassed'
                })}
              </Description>
            </S.Timestamp>
          </Flex>
          {!isNil(activity.candidateNote) && (
            <Comment
              isEditable={user?.id === activity.candidateNote.userId}
              candidateNote={activity.candidateNote}
              onCommentUpdate={onCommentUpdate}
            />
          )}
        </When>
        <When condition={activity.type === CandidateActivityType.CALENDAR_EVENT}>
          {activity.calendarEvent && <CalendarEntry calendarEvent={activity.calendarEvent} />}
        </When>
      </S.Content>
      <When condition={isDescription}>
        <S.Timestamp>
          <Description>
            {formatDate(activity.date ?? '', {
              format: 'DaysPassed'
            })}
          </Description>
        </S.Timestamp>
      </When>
    </S.TimelineActivity>
  )
}
