import { Text } from 'src/components/primitives/text'

import LoginBox from 'src/components/blocks/login-box'
import RouteBuilder from 'src/libs/route-builder'
import { useLocation, useParams } from 'react-router'
import { isNil } from 'lodash'
import { Box } from 'src/components/primitives/box'
import { Avatar } from 'src/components/primitives/avatar'
import { useSession } from 'src/hooks/use-session'
import { Button } from 'src/components/primitives/button'
import { Spacer } from 'src/components/primitives/spacer'
import { Divider } from 'src/components/primitives/divider'
import LoginError from 'src/components/blocks/login-error'
import { AuthLayout } from 'src/components/layouts/auth'
import CONFIG from 'src/config'
import { useGetOrgInvite } from 'src/hooks/useGetOrgInvite'

interface InviteBoxProps {
  logoUrl?: string | null
  title?: string | null
  description?: string | null
  children?: React.ReactNode
}

function InviteBox ({
  logoUrl,
  title,
  description,
  children
}: InviteBoxProps): JSX.Element {
  return (
    <>
      <Box
        $display="flex"
        $gap={12}
        $padding={{
          top: 16,
          right: 16,
          bottom: 16,
          left: 16
        }}
        $border="borderTranslucent"
        $borderRadius="4px"
        >
        <Avatar photoUrl={logoUrl} initials={title} />
        <Box
          $flex="1"
        >
          <Text variant="Label">{title}</Text>
          <Text variant="ParagraphSecondary">{description}</Text>
        </Box>
        {children}
      </Box>
    </>
  )
}

const Invite = (): JSX.Element => {
  const { inviteId, token } = useParams()

  if (isNil(inviteId) || isNil(token)) {
    throw new Error('expected inviteId and token to be defined')
  }

  const { user, emailAccountAccessTokens } = useSession()
  const { data: orgInvite, error } = useGetOrgInvite(inviteId, token)
  const redirectPath = RouteBuilder.build('INVITE_REDIRECT/INVITEID/TOKEN', {
    inviteId,
    token
  })

  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const paramError = params.get('error')

  if (error) {
    return (
      <AuthLayout>
        <LoginError error="The invite link is invalid or has expired. Please contact the person who invited you to resend the invite." />
      </AuthLayout>
    )
  }

  return (
    <AuthLayout>
      <Text variant="HeadingLg">You Have Been Invited to Join</Text>
      {paramError && <><Spacer $size={24} /><LoginError error={paramError} /></>}
      {orgInvite && !user?.id && <LoginBox redirectPath={redirectPath}>
        <Spacer $size={24} />
        <InviteBox
          logoUrl={orgInvite?.org.logoUrl}
          title={orgInvite?.org.name}
          description={orgInvite?.org.domain}
        />
      </LoginBox>}
      {user?.id && <>
        <Spacer $size={24} />
        <InviteBox
          logoUrl={orgInvite?.org.logoUrl}
          title={orgInvite?.org.name}
          description={orgInvite?.org.domain}
        />
        <Spacer $size={24} />
        <Button
          $variant="fill"
          $colorTheme="tint"
          $width="full"
          $align="center"
          href={redirectPath}
        >
          Join Team
        </Button>
        <Divider $color="borderTranslucent" $spacing={{ before: 40, after: 40 }} />
        <Text variant="ParagraphSecondary">You are logged in as</Text>
        <Spacer $size={8} />
        <InviteBox
          // logoUrl={orgInvite?.org.logoUrl}
          title={user.name}
          description={emailAccountAccessTokens?.map(e => e.email).join(', ')}
        >
          <Box
            $flex="0"
          >
            <Button
              $variant="outline"
              $colorTheme="negative"
              $width="auto"
              $align="center"
              href={`${CONFIG.API_DOMAIN}/auth/logout?redirect=${CONFIG.APP_DOMAIN}`}
            >
              Log out
            </Button>
          </Box>
        </InviteBox>
      </>}
    </AuthLayout>
  )
}

export default Invite
